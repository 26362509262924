import {
  ImageBackground,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { GenUtil } from 'src/util/GenUtil';
import { ProfileResp } from 'src/common/response/profile.resp';
import { LinearGradient } from 'expo-linear-gradient';
import { Color } from 'src/constant/Color';
import { InterestService } from 'src/service/InterestService';
import { InterestActionType } from 'src/common/models/interest.model';
import { useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import UserUtil from 'src/util/UserUtil';
import MoreOptionsAction from 'src/components/common/profileCard/actions/MoreOptionsAction';
import UserTickSvg from 'src/assets/icons/user-tick.svg';
import { Verification } from 'src/common/models/profile.model';
import VerifySvg from 'src/assets/icons/verify.svg';

const { height, width } = GenUtil.getDimension();

const CARD_HEIGHT = !GenUtil.isWEB() ? height / 4 : height / 5;
const CARD_WIDTH = width * 0.4;

type Props = {
  item: ProfileResp;
  showAcceptAction?: boolean;
};

const MinimalProfileCard = ({ item, showAcceptAction }: Props) => {
  const [interestAccepted, setInterestaccepted] = useState(false);
  const navigation = useNavigation();

  const handleAcceptAction = async () => {
    console.log({ uuid: item.uuid });
    try {
      const response = await InterestService.actionOnInterest({
        type: InterestActionType.Accept,
        customMessage: '',
        interestUuid: item.interest?.uuid,
      });

      if (response.success) {
        setInterestaccepted(true);
      } else {
        console.log(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const navigateToProfile = () => {
    navigation.navigate('otherpersonprofile', { uuid: item.uuid });
  };

  return (
    <TouchableWithoutFeedback onPress={navigateToProfile}>
      <View style={styles.container}>
        <View style={{ width: '100%', height: '100%' }}>
          <ImageBackground
            source={GenUtil.getProfilePic(
              item,
              require('src/assets/images/profileIcons/placeholder_profile.png'),
            )}
            style={styles.image}
          >
            <LinearGradient
              colors={['#00000000', '#000000']}
              style={{ height: GenUtil.isIOS() ? height / 3.5 : height / 4.5, width: '100%' }}
            ></LinearGradient>
          </ImageBackground>
        </View>

        {showAcceptAction ? (
          <View style={styles.acceptAction}>
            <TouchableOpacity onPress={handleAcceptAction}>
              <Text style={styles.acceptText}>{interestAccepted ? 'Accepted' : 'Accept'}</Text>
            </TouchableOpacity>
          </View>
        ) : (
          <></>
        )}
        <View style={styles.info}>
          <View style={styles.infoContainer}>
            <Text style={styles.infoText}>{item.fullName}</Text>
            {item?.verifications?.find(
              (verification) => verification === Verification.IDVerified,
            ) ? (
              <VerifySvg style={{ marginRight: 8 }} />
            ) : (
              <></>
            )}
          </View>
          <View style={styles.infoContainer}>
            <UserTickSvg style={{ color: Color.white }} />
            <Text style={styles.infoTextSmall}>{item.age}Y, </Text>
            <Text style={styles.infoTextSmall}>{UserUtil.getUserHeight(item.height)}</Text>
          </View>
        </View>
        <MoreOptionsAction profile={item} styles={styles.moreOptions} />
      </View>
    </TouchableWithoutFeedback>
  );
};

export default MinimalProfileCard;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    overflow: 'hidden',
    minHeight: CARD_HEIGHT,
    maxHeight: CARD_HEIGHT,
    maxWidth: CARD_WIDTH,
    backgroundColor: '#fff',
    marginHorizontal: 5,
    borderRadius: 12,
  },
  image: {
    width: CARD_WIDTH,
    height: CARD_HEIGHT,
    resizeMode: 'cover',
  },
  acceptAction: {
    position: 'absolute',
    top: 10,
    right: 10,
    borderRadius: 38,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    borderWidth: 1,
    borderColor: '#fff',
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
  acceptText: {
    fontSize: 13,
    color: '#fff',
  },
  info: {
    position: 'absolute',
    bottom: 15,
    left: 15,
  },
  infoText: {
    color: '#fff',
    fontSize: 12,
    fonWeight: '600',
    fontFamily: 'dmsans-bold',
    marginBottom: 5,
  },
  infoTextSmall: {
    fontSize: 11,
    color: Color.white,
    marginLeft: 10,
  },
  infoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  moreOptions: {
    position: 'absolute',
    bottom: 15,
    right: 13,
  },
});
