/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { DarkTheme, DefaultTheme, NavigationContainer, useRoute } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { ColorSchemeName, StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import Colors, { Color } from 'src/constant/Color';
import useColorScheme from 'src/hooks/useColorScheme';
import ModalScreen from 'src/screens/ModalScreen';
import NotFoundScreen from 'src/screens/NotFoundScreen';
import { WebRootStackParamList, WebRootTabParamList } from 'types';
import PartnerPreference from 'src/screens/PartnerPreference';
import BlockedProfiles from 'src/screens/BlockedProfiles';
import PhoneBook from 'src/screens/PhoneBook';
import RateUs from 'src/screens/RateUs';
import { RootState } from 'src/store/store';
import { useSelector } from 'react-redux';
import SignIn from 'src/screens/SignIn';
import IntroSlider from 'src/screens/IntroSlider';
import StorageUtil from 'src/util/StorageUtil';
import OtherPersonProfile from 'src/screens/UserProfileScreen';
import DrawerHeader from 'src/components/DrawerHeader';
import { ProfileResp } from 'src/common/response/profile.resp';
import OnboardingDetails from 'src/screens/OnboardingScreen';
import Welcome from 'src/screens/Welcome';
import HomeScreen from 'src/screens/HomeScreen';
import { useBottomSheetModal } from '@gorhom/bottom-sheet';
import Messenger from 'src/screens/Messenger';
import ModalProvider from 'src/components/modals/ModalProvider';
import PremiumMemberScreen from 'src/screens/PremiumMemberScreen';
import { GenUtil } from 'src/util/GenUtil';
import Navbar from 'src/components/NavBar';
import { LayoutConstant } from 'src/constant/LayoutConstant';
import PageHeader from 'src/components/common/PageHeader';
import Weblinking from './WebLinkingConfiguration';
import {
  ActivityScreenStack,
  DynamicProfilesStack,
  EditProfileStack,
  SearchScreenStack,
  SettingsScreenStack,
} from './SharedNavigation';
import ForgetPasswordScreen from 'src/screens/ForgetPasswordScreen';
import DynamicProfilesList from 'src/screens/DynamicProfileList';
import EditProfile from 'src/screens/EditProfile';

export default function WebNavigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  const { dismissAll: dissmissAllModals } = useBottomSheetModal();

  return (
    <NavigationContainer
      linking={Weblinking}
      theme={colorScheme === 'light' ? DefaultTheme : DarkTheme}
      onStateChange={() => dissmissAllModals()}
    >
      <View style={{ width: '100%' }}>
        <Navbar />
      </View>
      <View style={styles.webContainerInner}>
        <RootNavigator />
        <ModalProvider />
      </View>
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<WebRootStackParamList>();
const DrawerStack = createNativeStackNavigator();
const BottomTab = createBottomTabNavigator<WebRootTabParamList>();

const scrrensToHideFromBottomTab = ['Profile Activities', 'MatriMilan', 'otherpersonprofile'];

function getIconName(label) {
  const iconMap = {
    data: 'home',
    activity: 'clock-o',
    search: 'search',
    messenger: 'comments',
    member: 'crown',
    upgrade: 'crown',
    'partner preferences': 'intersex',
  };

  return iconMap[label.toLowerCase()];
}

const CustomTabBar = ({ state, descriptors, navigation }) => {
  return (
    <View
      style={{
        backgroundColor: '#fff',
        paddingHorizontal: 8,
        borderWidth: 1,
        borderColor: Color.lightBorder,
        borderRadius: 10,
        position: 'absolute',
        left: -330,
        top: 30,
        width: 300,
        paddingVertical: 15,
      }}
    >
      <DrawerHeader {...{ state, descriptors, navigation }} />
      {state.routes.map((route, index) => {
        const { options } = descriptors[route.key];
        const label: string = options.title || route.name;

        if (scrrensToHideFromBottomTab.includes(label)) return;
        if (label.toLocaleLowerCase().includes('matrimilan')) return;

        const isFocused = state.index === index;

        const onPress = () => {
          const event = navigation.emit({
            type: 'tabPress',
            target: route.key,
            canPreventDefault: true,
          });

          if (!event.defaultPrevented) {
            if (route.name === 'data') navigation.navigate(route.name, { screen: 'home' });
            else navigation.navigate(route.name);
          }
        };

        return (
          <TouchableOpacity
            key={index}
            onPress={onPress}
            style={{
              padding: 8,
              borderBottomWidth: isFocused ? 2 : 1,
              borderBottomColor: isFocused ? Color.black : Color.lightGrey,
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <TabBarIcon name={getIconName(label)} color={isFocused ? Color.black : Color.gray} />
            <Text
              style={{
                color: isFocused ? Color.black : Color.gray,
                marginLeft: 15,
                marginRight: 'auto',
              }}
            >
              {label === 'data' ? 'Home' : label}
            </Text>
            <TabBarIcon name="arrow-right" color={isFocused ? Color.black : Color.gray} size={12} />
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

function BottomTabNavigator({ navigation }) {
  const colorScheme = useColorScheme();

  const userProfile: Partial<ProfileResp> = useSelector(
    (state: RootState) => state?.user?.userProfile,
  );

  const membershipTabTitle = userProfile?.isMembershipActive ? 'Member' : 'Upgrade';
  return (
    <BottomTab.Navigator
      initialRouteName="data"
      screenOptions={({ route }) => ({
        tabBarActiveTintColor: Colors[colorScheme].tint,
        headerShown: false,
        tabBarLabelPosition: 'below-icon',
      })}
      tabBar={(props) => <CustomTabBar {...props} />}
    >
      <BottomTab.Screen name="data" component={DrawerStackNavigator} />
      <BottomTab.Screen
        name="otherpersonprofile"
        component={OtherPersonProfile}
        options={{ headerShown: false }}
      />
      <BottomTab.Screen
        name="search"
        component={SearchScreenStack}
        options={{
          title: 'Search',
          headerShown: false,
        }}
      />
      <BottomTab.Screen
        name="messenger"
        component={Messenger}
        options={{
          title: 'Messenger',
          headerShown: false,
        }}
      />
      <BottomTab.Screen
        name="editprofile"
        component={EditProfile}
        initialParams={{ tabIndex: 1 }}
        options={{
          title: 'Partner Preferences',
          headerShown: false,
        }}
      />
      <BottomTab.Screen
        name="membership"
        component={PremiumMemberScreen}
        options={{
          title: membershipTabTitle,
        }}
      />
    </BottomTab.Navigator>
  );
}

const DrawerStackNavigator = () => {
  return (
    <DrawerStack.Navigator initialRouteName="home">
      <DrawerStack.Screen
        name="home"
        component={HomeScreen}
        options={{ headerShown: false, title: 'MatriMilan' }}
      />
      <DrawerStack.Screen
        name="partnerpreferences"
        component={PartnerPreference}
        options={{
          header: () => <PageHeader heading="Partner Prefrences" />,
        }}
      />
      {/* Add other screens here */}
      <DrawerStack.Screen
        name="blockedprofiles"
        component={BlockedProfiles}
        options={{
          header: () => <PageHeader heading="Blocked Profiles" />,
          title: 'Blocked Profiles',
        }}
      />
      <DrawerStack.Screen
        name="phonebook"
        component={PhoneBook}
        options={{ headerShown: false, title: 'Phone Book' }}
      />
      <DrawerStack.Screen
        name="rateus"
        component={RateUs}
        options={{ headerTitle: 'Rate Us', title: 'Rate Us' }}
      />
      <DrawerStack.Screen
        name="editprofile"
        component={EditProfileStack}
        options={{ headerShown: false }}
      />
      <DrawerStack.Screen
        name="settings"
        component={SettingsScreenStack}
        options={{ headerShown: false }}
      />
      <DrawerStack.Screen
        name={'dynamicprofileslist'}
        component={DynamicProfilesList}
        options={{
          headerShown: false,
          title: 'MatriMilan',
        }}
      />
      <DrawerStack.Screen
        name="activity"
        component={ActivityScreenStack}
        options={{ headerShown: false }}
      />
    </DrawerStack.Navigator>
  );
};

function RootNavigator() {
  const user = useSelector((state: RootState) => state.user.user);
  const isFirstTimeLaunch: boolean = StorageUtil.isFirstTimeLaunch();

  return (
    <Stack.Navigator initialRouteName={user ? 'user' : 'welcome'}>
      {user && (
        <Stack.Screen name="user" component={BottomTabNavigator} options={{ headerShown: false }} />
      )}
      {!user && !GenUtil.isWEB() && (
        <Stack.Screen
          name="intro"
          component={IntroSlider}
          options={{ headerShown: false, title: 'Intro - Matrimilan' }}
        />
      )}
      {!user && (
        <Stack.Screen
          name="welcome"
          component={Welcome}
          options={{ headerShown: false, title: 'Welcome - Matrimilan' }}
        />
      )}
      {!user && (
        <Stack.Screen
          name="login"
          component={SignIn}
          options={{ headerShown: false, title: 'Login - Matrimilan' }}
        />
      )}
      {!user && (
        <Stack.Screen
          name="onboardingdetails"
          component={OnboardingDetails}
          options={{ headerShown: false, title: 'Onboarding Details - Matrimilan' }}
        />
      )}
      {!user && (
        <Stack.Screen
          name="forgetpassowrd"
          component={ForgetPasswordScreen}
          options={{ headerShown: false, title: 'Forget Password - Matrimilan' }}
        />
      )}
      {/* <Stack.Screen
        name={'dynamicprofileslist'}
        component={DynamicProfilesStack}
        options={{
          headerShown: false,
          title: 'MatriMilan',
        }}
      /> */}
      <Stack.Screen name="notfound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen name="Modal" component={ModalScreen} />
      </Stack.Group>
    </Stack.Navigator>
  );
}

function TabBarIcon(props: {
  name: React.ComponentProps<typeof FontAwesome | typeof FontAwesome5>['name'];
  color: string;
  size?: number;
}) {
  const iconSize = props.size ?? 30;

  if (props.name === 'crown')
    return <FontAwesome5 size={25} style={{ marginBottom: -3 }} {...props} />;
  return <FontAwesome size={iconSize} style={{ marginBottom: -3 }} {...props} />;
}

const styles = StyleSheet.create({
  webContainerInner: {
    height: GenUtil.getDimension().height - 85, //65 is navbar height,
    width: LayoutConstant.desktopWidth,
    maxWidth: LayoutConstant.desktopWidth,
    overflow: 'visible',
  },
});
