/* eslint-disable @typescript-eslint/no-floating-promises */
import { Image, StyleSheet, Text, View } from 'react-native';
import React, { useState, useEffect } from 'react';
import CheckboxAdornment from '../atoms/CheckboxAdornment';
import { Gender, PreferenceLevel } from 'src/common/models/profile.model';
import { debounce } from 'lodash';
import { ProfileSearchBody } from 'src/common/dto/profile.dto';
import ModalAdornment from '../atoms/ModalAdornment';
import { HeightData } from 'src/constant/searchProfileOptionsData';
import { GenUtil } from 'src/util/GenUtil';
import EFonts from 'src/constant/EFonts';
import { Color } from 'src/constant/Color';

const HeightRangeComp = ({
  userGender,
  userHeight,
  partnerMinHeight,
  partnerMaxHeight,
  privacyPrefLevel,
  updatePartnerPref,
  updatePrivateSettings,
  strictlyFollow,
  setSearchParams,
}: {
  userGender: string;
  userHeight: number;
  partnerMinHeight: number;
  partnerMaxHeight: number;
  privacyPrefLevel: PreferenceLevel;
  updatePartnerPref: (_) => Promise<void>;
  updatePrivateSettings: (_) => Promise<void>;
  strictlyFollow?: boolean;
  setSearchParams?: (prevParams) => void;
}) => {
  const sliderRange = {
    min: userGender ? userHeight - (userGender === Gender.Male ? 17 : 3) : 122,
    max: userGender ? userHeight + (userGender === Gender.Male ? 3 : 17) : 214,
  };
  const [minHeight, setMinHeight] = useState(partnerMinHeight ?? sliderRange.min);
  const [maxHeight, setMaxHeight] = useState(partnerMaxHeight ?? sliderRange.max);
  const [maxHeightData, setMaxHeightData] = useState(HeightData);
  const [privacyLevel, setPrivacyLevel] = useState(privacyPrefLevel ?? 1);
  const icon = require('src/assets/images/editprofileicons/FrameHeight.png');

  const applyDebounce = debounce(async (heightPref: Partial<ProfileSearchBody>) => {
    if (setSearchParams) {
      setSearchParams((prevParams: Partial<ProfileSearchBody>) => ({
        ...prevParams,
        ...heightPref,
      }));
      return;
    }
    await updatePartnerPref(heightPref);
  }, 1000);

  useEffect(() => {
    const heightPref = {
      minHeight: parseInt(minHeight),
      maxHeight: parseInt(maxHeight),
    };

    if (!(minHeight === partnerMinHeight && maxHeight === partnerMaxHeight))
      applyDebounce(heightPref);
    return () => {
      applyDebounce.cancel();
    };
  }, [minHeight, maxHeight]);

  const updateHeightData = (minHeightValue) => {
    const index = HeightData.findIndex(value => value == minHeightValue);

    if (index === -1) {
      return [];
    }

    const updatedHeightData = HeightData.slice(index);
    setMaxHeightData([...updatedHeightData]);
    if (maxHeight < minHeightValue) {
      setMaxHeight(updatedHeightData[0]);
    }
  }


  return (
    <View>
      <View style={styles.container}>
        <View style={{ flex: 1 }}>
          <Text style={styles.labelStyle}>Min Height</Text>
          <ModalAdornment
            icon={<Image source={icon} style={{ width: 18, height: 18 }} />}
            modalItems={HeightData}
            value={`${GenUtil.convertHeighttoHeightString(minHeight)} (${minHeight}cm)`}
            setValue={setMinHeight}
            setHelperValue={updateHeightData}
            setItemAsHelperValue={true}
            placeHolder="Select"
            isHeight={true}
            modalHeading={'Minimum Height'}
          />
        </View>
        <View style={{ flex: 1 }}>
          <Text style={styles.labelStyle}>Max Height</Text>
          <ModalAdornment
            icon={<Image source={icon} style={{ width: 18, height: 18 }} />}
            modalItems={maxHeightData}
            value={`${GenUtil.convertHeighttoHeightString(maxHeight)} (${maxHeight}cm)`}
            setValue={setMaxHeight}
            placeHolder="Select"
            isHeight={true}
            modalHeading={'Maximum Height'}
          />
        </View>
      </View>
      <View>
        {strictlyFollow && (
          <CheckboxAdornment
            label="Strictly Follow"
            updatePrivateSettings={updatePrivateSettings}
            privacyLevel={privacyLevel}
            setPrivacyLevel={setPrivacyLevel}
            prefPrivacy={{ heightRangePref: privacyLevel }}
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelStyle: {
    flex: 1,
    fontFamily: EFonts.SORA_REGULAR,
    fontStyle: 'normal',
    fontSize: GenUtil.isWEB() ? 12 : 10,
    lineHeight: 20,
    color: Color.lightGrey,
    alignItems: 'center',
    marginLeft: 15,
  },
});

export default HeightRangeComp;
