import { View, Text, Image, StyleSheet } from 'react-native';
import React, { ReactNode, useState } from 'react';
import { Color } from 'src/constant/Color';
import EFonts from 'src/constant/EFonts';
import OutlinedButton from 'src/components/common/buttons/OutlinedButton';
import PhotoCollage from './PhotoCollage';
import { InterestService } from 'src/service/InterestService';
import { GenUtil } from 'src/util/GenUtil';

const InterestSentCard = ({
  name,
  interestUuid,
  gender,
  otherUserGender,
  otherUserProfilePhoto,
  profilePhoto,
  showButton = false,
  msgTime,
  renderTicks,
  currentMessage,
}: {
  name: string;
  interestUuid: string;
  gender: string;
  otherUserGender: string;
  otherUserProfilePhoto: string;
  profilePhoto: string;
  showButton: boolean;
  msgTime: number | Date;
  renderTicks: () => ReactNode;
  currentMessage: object;
}) => {
  const [unsendInterest, setUnsendInterest] = useState(false);
  const interestSentMessage = `You sent an interest to ${name}. Now, wait for ${
    gender === 'Male' ? 'her' : 'him'
  } to Accept/Reject the Interest.`;

  const handleUnsendInterestPressed = async () => {
    const response = await InterestService.deleteInterest(interestUuid);
    if (response.success) setUnsendInterest(true);
  };

  return (
    <View style={styles.container}>
      <View style={styles.upperContainer}>
        <View style={styles.collageContainer}>
          <PhotoCollage
            size={36}
            smallCircleSize={22}
            icon={
              <Image
                style={{ height: 12, width: 12, right: 1, top: 1 }}
                source={require('src/assets/icons/send_icon.png')}
              />
            }
            profilePhoto={profilePhoto}
            gender={gender}
            otherUserGender={otherUserGender}
            otherUserProfilePhoto={otherUserProfilePhoto}
          />
        </View>
        <View style={styles.titleContainer}>
          <Text style={styles.titleStyle}>Interest Sent</Text>
        </View>
      </View>
      <View style={{ marginTop: 8 }}>
        <Text style={styles.msgStyle}>{interestSentMessage}</Text>
      </View>
      {showButton && !unsendInterest ? (
        <View style={{ paddingHorizontal: 45, marginVertical: 8 }}>
          <OutlinedButton
            title="Unsend Interest"
            style={{ borderColor: Color.white, paddingVertical: 5 }}
            textStyle={styles.buttonTextStyle}
            onPress={handleUnsendInterestPressed}
          />
        </View>
      ) : null}
      <View style={styles.bottomContainer}>
        <Text style={styles.timeStyle}>{GenUtil.getTimeFromTimeStamp(msgTime)}</Text>
        {renderTicks(currentMessage)}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 230,
    backgroundColor: '#811EFF',
    borderRadius: 12,
    borderBottomRightRadius: 0,
    paddingHorizontal: 8,
  },
  upperContainer: {
    height: 68,
    borderBottomWidth: 1,
    borderColor: Color.white,
    paddingVertical: 8,
    flexDirection: 'row',
  },
  collageContainer: {
    height: '100%',
    width: 72,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleContainer: {
    flex: 1,
    justifyContent: 'center',
    marginLeft: 8,
  },
  titleStyle: {
    fontFamily: EFonts.SORA_SEMIBOLD,
    fontSize: 15,
    lineHeight: 20,
    color: Color.white,
  },
  msgStyle: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    lineHeight: 20,
    color: Color.white,
  },
  buttonTextStyle: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    lineHeight: 15,
    color: Color.white,
    marginVertical: 0,
    marginHorizontal: 0,
  },
  bottomContainer: {
    flex: 1,
    paddingBottom: 5,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  timeStyle: {
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: 10,
    fontFamily: EFonts.SORA_REGULAR,
    textAlign: 'right',
    marginRight: 5,
  },
});

export default InterestSentCard;
