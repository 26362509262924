/* eslint-disable no-case-declarations */
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import React, { useEffect, useState } from 'react';
import { Icon } from 'react-native-elements';
import PillShapeChip from 'src/components/common/chips/PillShapeChip';
import { ProfileResp } from 'src/common/response/profile.resp';
import { Gender, MaritalStatus } from 'src/common/models/profile.model';
import { ProfessionData, QualificationData } from 'src/common/data/education.data';
import { LanguageData } from 'src/common/data/language.data';
import EFonts from 'src/constant/EFonts';
import { Color } from 'src/constant/Color';
import { GenUtil } from 'src/util/GenUtil';
import { ProfileSearchBody } from 'src/common/dto/profile.dto';
import DOESNT_MATTER_OPTION from 'src/constant/DoesntMatter';
import { ReligionData } from 'src/common/data/religion.data';

const CollapsibleHeader = ({
  header = 'Header',
  isActive = false,
  onPress = () => {},
  initialData = null,
  showPills = true,
  searchParams,
}: {
  header: string;
  isActive: boolean;
  onPress: () => void;
  initialData: Partial<ProfileResp>;
  showPills?: boolean;
  searchParams?: Partial<ProfileSearchBody>;
}) => {
  const [options, setOptions] = useState([] as { key: string; value: string }[]);
  const [profileDetailsCompletionPercent, setProfileDetailsCompletionPercent] = useState(0);
  const [isEditProfileTab, setIsEditProfileTab] = useState(false);

  const computeHiddenPills = ({ total = 0, arr = [{ key: '', value: '' }] }) => {
    if (total > 2) return arr.concat({ key: `+${total - 2} More`, value: `+${total - 2} More` });
    return arr;
  };

  useEffect(() => {
    const partnerPreference = initialData?.partnerPreference;
    switch (header) {
      case 'Looking For':
        const lookingFor = initialData?.gender === Gender.Male ? 'Bride' : 'Groom';
        setOptions([{ key: lookingFor, value: lookingFor }]);
        return;
      case 'Age Range':
        const minAge = searchParams
          ? searchParams.minAge
            ? searchParams.minAge
            : 18
          : partnerPreference?.minAge;
        let maxAge = searchParams
          ? searchParams.maxAge
            ? searchParams.maxAge
            : 99
          : partnerPreference?.maxAge;
        let ageModifier = '';
        if (maxAge === 99) {
          maxAge = 60;
          ageModifier = '+';
        }
        const ageRange = String(minAge) + 'Y-' + String(maxAge) + 'Y' + ageModifier;
        const isAgeOption = minAge && maxAge ? true : false;
        setOptions(
          isAgeOption
            ? [
                {
                  key: ageRange,
                  value: ageRange,
                },
              ]
            : [],
        );
        return;
      case 'Height Range':
        const minHeight = searchParams
          ? searchParams.minHeight
            ? searchParams.minHeight
            : 122
          : partnerPreference?.minHeight;
        const maxHeight = searchParams
          ? searchParams.maxHeight
            ? searchParams.maxHeight
            : 214
          : partnerPreference?.maxHeight;
        const heightRange =
          GenUtil.convertHeighttoHeightString(minHeight) +
          '-' +
          GenUtil.convertHeighttoHeightString(maxHeight);
        const isHeightOption = minHeight && maxHeight ? true : false;

        setOptions(
          isHeightOption
            ? [
                {
                  key: heightRange,
                  value: heightRange,
                },
              ]
            : [],
        );
        return;
      case 'Income Range':
        const currentCountry = initialData?.currentCountry;
        const incomeSuffix = currentCountry === 'India' ? 'LPA' : 'K';
        const partnerMinIncome =
          currentCountry === 'India'
            ? searchParams
              ? searchParams.minInrIncome
                ? searchParams.minInrIncome
                : 0
              : partnerPreference?.inrMinIncome
            : searchParams
            ? searchParams.minUsdIncome
              ? searchParams.minUsdIncome
              : 0
            : partnerPreference?.usdMinIncome;
        let partnerMaxIncome =
          currentCountry === 'India'
            ? searchParams
              ? searchParams.maxInrIncome
                ? searchParams.maxInrIncome
                : 1000
              : partnerPreference?.inrMaxIncome
            : searchParams
            ? searchParams.maxUsdIncome
              ? searchParams.maxUsdIncome
              : 1000
            : partnerPreference?.usdMaxIncome;
        let incomeModifier = '';
        if (partnerMaxIncome === 1000) {
          if (currentCountry === 'India') partnerMaxIncome = 50;
          if (currentCountry !== 'India') partnerMaxIncome = 100;
          incomeModifier = '+';
        }
        const incomeRange =
          String(partnerMinIncome) +
          incomeSuffix +
          '-' +
          String(partnerMaxIncome) +
          incomeSuffix +
          incomeModifier;
        const isIncomeOption =
          (partnerMinIncome && partnerMaxIncome) || partnerMinIncome === 0 || partnerMaxIncome === 0
            ? true
            : false;

        setOptions(
          isIncomeOption
            ? [
                {
                  key: incomeRange,
                  value: incomeRange,
                },
              ]
            : [],
        );
        return;
      case 'Marital Status':
        let maritalStatuses = searchParams
          ? searchParams.maritalStatuses && searchParams.maritalStatuses
          : initialData?.partnerPreference?.maritalStatuses;
        if (!maritalStatuses || !maritalStatuses.length)
          maritalStatuses = [DOESNT_MATTER_OPTION.name];
        setOptions(
          computeHiddenPills({
            total: maritalStatuses?.length,
            arr: maritalStatuses?.slice(0, 2).map((m) => ({ key: m, value: m })),
          }),
        );
        return;
      case 'Religion':
        let religionData = searchParams
          ? searchParams.religions && searchParams.religions
          : initialData?.partnerPreference?.religions;
        if (!religionData || !religionData.length) religionData = [DOESNT_MATTER_OPTION.name];
        setOptions(
          computeHiddenPills({
            total: religionData?.length,
            arr: religionData?.slice(0, 2).map((m) => ({ key: m, value: m })),
          }),
        );
        return;
      case 'Community':
        const communityReligions = initialData?.partnerPreference?.religions;
        const communityIds = initialData?.partnerPreference?.communityIds;
        const communityMap = GenUtil.filterAndExtractValues(
          ReligionData,
          communityReligions,
          'name',
          'communityMap',
        );
        let communities = [];
        for (const item of communityMap) {
          if (communityIds.includes(item.id)) communities.push(item.name);
        }
        if (!communities || !communities.length) communities = [DOESNT_MATTER_OPTION.name];
        setOptions(
          computeHiddenPills({
            total: communities?.length,
            arr: communities?.slice(0, 2).map((m) => ({ key: m, value: m })),
          }),
        );
        return;
      case 'Sub Community':
        const subCommunityReligions = initialData?.partnerPreference?.religions;
        const communityIdList = initialData?.partnerPreference?.communityIds;
        const subCommunityIds = initialData?.partnerPreference?.subCommunityIds;
        const subCommunityMap = GenUtil.filterAndExtractValues(
          ReligionData,
          subCommunityReligions,
          'name',
          'subCommunityMap',
        );
        const filteredSubCommunityMap = GenUtil.filterAndExtractValues(
          subCommunityMap,
          communityIdList,
          'casteId',
        );
        let subCommunities = [];
        for (const item of filteredSubCommunityMap) {
          if (subCommunityIds.includes(item.id)) subCommunities.push(item.name);
        }
        if (!subCommunities || !subCommunities.length) subCommunities = [DOESNT_MATTER_OPTION.name];
        setOptions(
          computeHiddenPills({
            total: subCommunities?.length,
            arr: subCommunities?.slice(0, 2).map((m) => ({ key: m, value: m })),
          }),
        );
        return;
      case 'Mother Tongue':
        const motherTongueIds = initialData?.partnerPreference?.motherTongueIds;
        let motherTongues = motherTongueIds?.map(
          (id) => LanguageData.find((l) => l.id === id).name,
        );
        if (!motherTongues || !motherTongues.length) motherTongues = [DOESNT_MATTER_OPTION.name];
        setOptions(
          computeHiddenPills({
            total: motherTongues?.length,
            arr: motherTongues?.slice(0, 2).map((language) => ({ key: language, value: language })),
          }),
        );
        return;
      case 'Qualification':
        const qualificationIds = searchParams
          ? searchParams.qualificationIds && searchParams.qualificationIds
          : initialData?.partnerPreference?.qualificationIds;
        let qualifications = qualificationIds?.map(
          (id) => QualificationData.find((q) => q.id === id).name,
        );
        if (!qualifications || !qualifications.length) qualifications = [DOESNT_MATTER_OPTION.name];
        setOptions(
          computeHiddenPills({
            total: qualifications?.length,
            arr: qualifications?.slice(0, 2).map((q) => ({ key: q, value: q })),
          }),
        );
        return;
      case 'Profession':
        const professionIds = searchParams
          ? searchParams.professionIds && searchParams.professionIds
          : initialData?.partnerPreference?.professionIds;
        let professions = professionIds?.map((id) => ProfessionData.find((q) => q.id === id).name);
        if (!professions || !professions.length) professions = [DOESNT_MATTER_OPTION.name];
        setOptions(
          computeHiddenPills({
            total: professions?.length,
            arr: professions?.slice(0, 2).map((q) => ({ key: q, value: q })),
          }),
        );
        return;
      case 'Diets':
        let diets = initialData?.partnerPreference?.diets;
        if (!diets || !diets.length) diets = [DOESNT_MATTER_OPTION.name];
        setOptions(
          computeHiddenPills({
            total: diets?.length,
            arr: diets?.slice(0, 2).map((d) => ({ key: d, value: d })),
          }),
        );
        return;
      case 'Countries':
        setOptions(
          computeHiddenPills({
            total: 1,
            arr: [{ key: 'India', value: 'India' }],
          }),
        );
        return;
      case 'States and Cities':
        let statesAndCities = searchParams
          ? searchParams.statesAndCities && searchParams.statesAndCities
          : initialData?.partnerPreference?.states;
        if (!statesAndCities || !statesAndCities.length)
          statesAndCities = [DOESNT_MATTER_OPTION.name];
        setOptions(
          computeHiddenPills({
            total: statesAndCities?.length,
            arr: statesAndCities?.slice(0, 2).map((d) => ({ key: d, value: d })),
          }),
        );
        return;
      case 'State':
        let states = searchParams
          ? searchParams.statesAndCities && searchParams.statesAndCities
          : initialData?.partnerPreference?.states;
        if (!states || !states.length) states = [DOESNT_MATTER_OPTION.name];
        setOptions(
          computeHiddenPills({
            total: states?.length,
            arr: states?.slice(0, 2).map((d) => ({ key: d, value: d })),
          }),
        );
        return;
      case 'City':
        let cities = searchParams
          ? searchParams.statesAndCities && searchParams.statesAndCities
          : initialData?.partnerPreference?.cities;
        if (!cities || !cities.length) cities = [DOESNT_MATTER_OPTION.name];
        setOptions(
          computeHiddenPills({
            total: cities?.length,
            arr: cities?.slice(0, 2).map((d) => ({ key: d, value: d })),
          }),
        );
        return;
      case 'Personal Details':
        setIsEditProfileTab(true);
        setProfileDetailsCompletionPercent(0);
        return;
      case 'Family Details':
        setIsEditProfileTab(true);
        setProfileDetailsCompletionPercent(0);
        return;
      case 'Astro Details':
        setIsEditProfileTab(true);
        setProfileDetailsCompletionPercent(0);
        return;
      case 'Religion Beliefs':
        setIsEditProfileTab(true);
        setProfileDetailsCompletionPercent(0);
        return;
      case 'Educations':
        setIsEditProfileTab(true);
        setProfileDetailsCompletionPercent(0);
        return;
      case 'Career Details':
        setIsEditProfileTab(true);
        setProfileDetailsCompletionPercent(0);
        return;
      case 'Lifestyle And Hobbies':
        setIsEditProfileTab(true);
        setProfileDetailsCompletionPercent(0);
        return;
      default:
        return;
    }
  }, [initialData?.partnerPreference, searchParams, header]);

  return (
    <TouchableOpacity style={styles.wrapper} onPress={onPress}>
      <Text style={styles.headerText}>{header}</Text>
      {!isEditProfileTab ? (
        <View style={styles.chipsContainer}>
          {!isActive && showPills ? (
            <PillShapeChip
              hideExtra
              options={options}
              selected={options.map((v) => v?.key)}
              showTickIcon={false}
              multiSelect={true}
              additionalChipStyle={styles.additionalChipStyle}
            />
          ) : (
            <View
              style={{ flexDirection: 'row', maxWidth: 160, overflow: 'hidden', marginRight: 12 }}
            >
              <Text
                style={{
                  fontFamily: EFonts.SORA_REGULAR,
                  fontSize: 12,
                  lineHeight: 15,
                  color: Color.lightGrey,
                }}
                numberOfLines={1}
              >
                {options.map((item) => item.key).toString() + (options.length > 2 ? ' more' : '')}
              </Text>
            </View>
          )}
        </View>
      ) : (
        <View style={{ flex: 1, alignItems: 'flex-end', paddingRight: 5 }}>
          {!isActive && profileDetailsCompletionPercent > 0 && (
            <Text style={styles.completionText}>{profileDetailsCompletionPercent}% complete</Text>
          )}
        </View>
      )}
      <View>
        {isActive ? (
          <Icon name="chevron-up" type="entypo" size={20} />
        ) : (
          <Icon name="chevron-down" type="entypo" size={20} />
        )}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    paddingVertical: 10,
    paddingHorizontal: 6,
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerText: {
    fontFamily: EFonts.SORA_SEMIBOLD,
    fontSize: 15,
  },
  chipsContainer: {
    flex: 1,
    marginVertical: -30,
    marginTop: -23,
    alignItems: 'flex-end',
  },
  additionalChipStyle: {
    paddingVertical: 3,
  },
  completionText: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    color: '#2AB108',
    lineHeight: 15,
  },
});

export default CollapsibleHeader;
