import { StyleSheet } from 'react-native';

import InboxScreen from 'src/components/InboxScreen';
import MessageScreen from 'src/components/MessageScreen';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { GenUtil } from 'src/util/GenUtil';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { MembershipPermissions, isFeatureEnabled } from 'src/common/data/membership.data';
import ConfirmationModal from 'src/components/modals/ConfirmationModal';
import OtherPersonProfile from 'src/screens/UserProfileScreen';

export default function Messenger({ navigation }) {
  const Stack = createNativeStackNavigator();
  const loggedInUserProfile = useSelector((state: RootState) => state?.user?.userProfile);

  const navigateToScreen = (screenName: string) => () => {
    if (screenName === 'back') {
      navigation.navigate('data');
    } else if (screenName === 'membership') {
      const membershipScreen = GenUtil.isDesktopWeb() ? 'membership' : 'Membership';
      navigation.navigate(membershipScreen);
    }
  };

  if (!isFeatureEnabled(loggedInUserProfile, MembershipPermissions.CanSeeActivityLog))
    return (
      <ConfirmationModal
        title="Upgrade to Premium"
        message="Enjoy chatting with individuals who share your interests by upgrading to a Premium membership!"
        cancelButtonText="Cancel"
        primaryButtonText="Buy Premium"
        onCancel={navigateToScreen('back')}
        onPrimaryButtonPressed={navigateToScreen('membership')}
      />
    );

  return (
    <Stack.Navigator initialRouteName={'InboxScreen'}>
      <Stack.Screen
        name={'InboxScreen'}
        component={InboxScreen}
        options={{
          headerShown: false,
          title: 'My Chats',
        }}
      />
      <Stack.Screen
        name={'MessageScreen'}
        component={MessageScreen}
        options={{
          headerShown: false,
          title: 'My Chats',
        }}
      />
      <Stack.Screen
        name="otherpersonprofile"
        component={OtherPersonProfile}
        options={{ headerShown: false, title: 'Matrimilan' }}
      />
    </Stack.Navigator>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    height: 1,
    width: '80%',
  },
});
