import { View, Text, TouchableOpacity, Image } from 'react-native';
import React, { useCallback, useRef, useState } from 'react';
import EFonts from 'src/constant/EFonts';
import Cropper from 'react-easy-crop';
import PrimaryButton from './common/buttons/PrimaryButton';
import { Color } from 'src/constant/Color';
import { ImageOrVideo } from 'react-native-image-crop-picker';
import * as WebImagePicker from 'expo-image-picker';
import getCroppedImg from 'src/util/CropImage';

// interface ImageObj {
//   sourceURL: string;
// }

interface PickerCustomProps {
  selectedImage: string;
  setSelectedImage: (image: WebImagePicker.ImagePickerAsset) => void;
  onImageSelected: (image: Partial<ImageOrVideo>) => {};
}

const WebImgPickerComp = ({
  selectedImage,
  setSelectedImage,
  onImageSelected,
}: PickerCustomProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState<string>(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const cropperRef = useRef(null);

  const handleSkipPressed = () => {
    const image = { sourceURL: selectedImage };
    setSelectedImage(null);
    setCroppedImage(null);
    onImageSelected(image);
  };

  const cropImage = useCallback(async () => {
    try {
      const croppedImage = (await getCroppedImg(selectedImage, croppedAreaPixels)) as string;
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleDonePressed = () => {
    if (croppedImage) {
      const image = { sourceURL: croppedImage };
      setSelectedImage(null);
      setCroppedImage(null);
      onImageSelected(image);
    }
  };

  return (
    <View style={{ height: '100%', width: '100%', position: 'absolute', backgroundColor: 'white' }}>
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: 10,
        }}
      >
        <Text style={{ fontSize: 15, fontFamily: EFonts.SORA_SEMIBOLD }}>Selected Image :-</Text>
        <TouchableOpacity onPress={handleSkipPressed}>
          <Text style={{ fontSize: 15, fontFamily: EFonts.SORA_REGULAR, color: Color.blue }}>
            Skip
          </Text>
        </TouchableOpacity>
      </View>
      <View style={{ position: 'relative', height: 300 }}>
        <Cropper
          ref={cropperRef}
          image={selectedImage}
          crop={crop}
          zoom={zoom}
          aspect={550/612} // Set the desired aspect ratio for cropping
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
        />
      </View>
      <Text style={{ fontSize: 15, fontFamily: EFonts.SORA_SEMIBOLD, padding: 10 }}>
        Cropped Image :-
      </Text>
      {croppedImage && (
        <View style={{ height: 300 }}>
          <View style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Image source={croppedImage} style={{ aspectRatio: 550/612, width: 200}} />
          </View>
        </View>
      )}
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          position: 'absolute',
          width: '100%',
          bottom: 20,
        }}
      >
        <PrimaryButton
          handleKeyPress={cropImage}
          buttonText="Crop Image"
          buttonStyle={{ backgroundColor: 'green', width: '40%' }}
        />
        {croppedImage && (
          <PrimaryButton
            handleKeyPress={handleDonePressed}
            buttonText="Done"
            buttonStyle={{ width: '40%' }}
          />
        )}
      </View>
    </View>
  );
};

export default WebImgPickerComp;
