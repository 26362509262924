import messaging from '@react-native-firebase/messaging';

export default class NotificationUtil {
  static getFCMToken = async (): Promise<string> => {
    try {
      const newFcmToken = await messaging().getToken();
      return newFcmToken;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  static notificationListener = () => {
    // Foreground state
    messaging().onMessage(async (remoteMessage) => {
      console.log('A new FCM message arrived!', JSON.stringify(remoteMessage));
    });

    // Background State
    messaging().onNotificationOpenedApp((remoteMessage) => {
      console.log(
        'Notification caused app to open from background state:',
        remoteMessage.notification,
      );
    });

    // Check whether an initial notification is available
    messaging()
      .getInitialNotification()
      .then((remoteMessage) => {
        if (remoteMessage) {
          console.log(
            'Notification caused app to open from quit state:',
            remoteMessage.notification,
          );
        }
      })
      .catch((error) => console.log(error));
  };
}
