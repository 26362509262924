import axios, { AxiosResponse } from 'axios';
import Endpoints from 'src/service/Endpoints';
import { BaseResp, HttpResponse } from 'src/common/response/base.resp';
import { ProfileResp } from 'src/common/response/profile.resp';
import { ExcludedProfileBody, ProfileSearchBody } from 'src/common/dto/profile.dto';
import { UserDetailsResp } from 'src/common/response/user.resp';
import { ProfileViewedBody } from 'src/common/dto/view.dto';
import { NotificationSettingsResp } from 'src/common/response/profile-notification-setting.resp';
import { ProfileMembershipDetailsResp } from 'src/common/response/profile-membership.resp';

export class Stats {
  yesterday: number;
  lastWeek: number;
  lastMonth: number;
}

export class StatsResp extends BaseResp {
  interestSentStats: Stats;
  interestReceivedStats: Stats;
}

export class ProfileService {
  static async getProfileDetails(uuid: string): Promise<HttpResponse<ProfileResp>> {
    const response = await axios.get<any, AxiosResponse<HttpResponse<ProfileResp>>>(
      `${Endpoints.PROFILE_DETAILS}/${uuid}`,
    );
    return HttpResponse.fromRes<ProfileResp>(response.data);
  }

  static async getProfiles(
    profileSearchBody: Partial<ProfileSearchBody>,
    page?: number,
  ): Promise<HttpResponse<ProfileResp[]>> {
    const endpoint = `${Endpoints.PROFILES_SEARCH}?page=${page}`;
    const response = await axios.post<any, AxiosResponse<HttpResponse<ProfileResp[]>>>(endpoint, {
      ...profileSearchBody,
    });
    return HttpResponse.fromRes<ProfileResp[]>(response.data);
  }

  static async restrictProfile(
    excludedProfileBody: ExcludedProfileBody,
  ): Promise<HttpResponse<BaseResp>> {
    const response = await axios.post<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.RESTRICT_PROFILE}`,
      { ...excludedProfileBody },
    );
    return HttpResponse.fromRes<BaseResp>(response.data);
  }

  static async undoRestrictProfile(uuid: string): Promise<HttpResponse<BaseResp>> {
    const response = await axios.post<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.UNDO_RESTRICT_PROFILE}`,
      { profileUuid: uuid },
    );
    return HttpResponse.fromRes<BaseResp>(response.data);
  }

  static async fetchRestrictedProfiles(page?: number): Promise<HttpResponse<ProfileResp[]>> {
    const endpoint = page
      ? `${Endpoints.RESTRICTED_PROFILES}?page=${page}`
      : Endpoints.RESTRICTED_PROFILES;
    const response = await axios.get<any, AxiosResponse<HttpResponse<ProfileResp[]>>>(endpoint);
    return HttpResponse.fromRes<ProfileResp[]>(response.data);
  }

  static async fetchSimilarProfile(
    profileUuid: string,
    page = 0,
  ): Promise<HttpResponse<ProfileResp[]>> {
    const response = await axios.post<any, AxiosResponse<HttpResponse<ProfileResp[]>>>(
      `${Endpoints.SIMILAR_PROFILES}?page=${page}`,
      { profileUuid },
    );
    return HttpResponse.fromRes<ProfileResp[]>(response.data);
  }

  static async fetchRecommendedProfiles(
    payload: any,
    page = 0,
  ): Promise<HttpResponse<ProfileResp[]>> {
    const resopnse = await axios.post<any, AxiosResponse<HttpResponse<ProfileResp[]>>>(
      `${Endpoints.RECOMMENDED_PROFILES}?page=${page}`,
      payload,
    );

    return HttpResponse.fromRes<ProfileResp[]>(resopnse.data);
  }

  static async fetchShortlistedProfiles(): Promise<HttpResponse<ProfileResp[]>> {
    const response = await axios.get<any, AxiosResponse<HttpResponse<ProfileResp[]>>>(
      Endpoints.SHORTLIST_PROFILES,
    );

    return HttpResponse.fromRes<ProfileResp[]>(response.data);
  }

  // NOTE: use this to fetch own profile stats
  // static async fetchProfileStats(profileUuid: string): Promise<HttpResponse<StatsResp>> {
  //     const response = await axios.get<any, AxiosResponse<HttpResponse<StatsResp>>>(`${Endpoints.PROFILE_STATS}`, {profileUuid});
  //     return HttpResponse.fromRes<StatsResp>(response.data);
  // }

  static async getLoggedInUserDetails(): Promise<HttpResponse<UserDetailsResp>> {
    const response = await axios.get<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.LOGGED_IN_USER_DETAILS}`,
    );
    return HttpResponse.fromRes<UserDetailsResp>(response.data);
  }

  static async listProfilesDetails(profileUuids: string[]) {
    const respone = await axios.post<{ data: ProfileResp[] }>(`${Endpoints.LIST_PROFILES}`, {
      profileUuids,
    });

    return respone;
  }

  static async updateFamilyDetails(
    profileData: Partial<ProfileResp>,
  ): Promise<HttpResponse<ProfileResp>> {
    const response = await axios.put<any, AxiosResponse<HttpResponse<ProfileResp>>>(
      `${Endpoints.PROFILE_UPDATE_FAMILY}`,
      profileData,
    );
    return HttpResponse.fromRes<ProfileResp>(response.data);
  }

  static async updatePersonalDetails(
    profileData: Partial<ProfileResp>,
  ): Promise<HttpResponse<ProfileResp>> {
    const response = await axios.put<any, AxiosResponse<HttpResponse<ProfileResp>>>(
      `${Endpoints.PROFILE_UPDATE_BASIC}`,
      profileData,
    );
    return HttpResponse.fromRes<ProfileResp>(response.data);
  }

  static async updateAstroDetails(
    profileData: Partial<ProfileResp>,
  ): Promise<HttpResponse<ProfileResp>> {
    const response = await axios.put<any, AxiosResponse<HttpResponse<ProfileResp>>>(
      `${Endpoints.PROFILE_UPDATE_ASTRO}`,
      profileData,
    );
    return HttpResponse.fromRes<ProfileResp>(response.data);
  }

  static async getProfileViewers(page = 0): Promise<HttpResponse<ProfileResp[]>> {
    const response = await axios.get<any, AxiosResponse<HttpResponse<ProfileResp[]>>>(
      `${Endpoints.PROFILE_VIEWERS}?page=${page}`,
    );
    return HttpResponse.fromRes<ProfileResp[]>(response.data);
  }

  static async getProfileViewed(page = 0): Promise<HttpResponse<ProfileResp[]>> {
    const response = await axios.get<any, AxiosResponse<HttpResponse<ProfileResp[]>>>(
      `${Endpoints.PROFILE_VIEWED}?page=${page}`,
    );
    return HttpResponse.fromRes<ProfileResp[]>(response.data);
  }

  static async getShortlistedProfiles(page = 0): Promise<HttpResponse<ProfileResp[]>> {
    const response = await axios.get<any, AxiosResponse<HttpResponse<ProfileResp[]>>>(
      `${Endpoints.SHORTLISTED_PROFILE}?page=${page}`,
    );
    return HttpResponse.fromRes<ProfileResp[]>(response.data);
  }

  static async addShortlistedProfiles(profileUuid: string): Promise<HttpResponse<BaseResp>> {
    const response = await axios.post<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.ADD_SHORTLISTED_PROFILE}`,
      {
        profileUuid,
      },
    );

    return HttpResponse.fromRes<BaseResp>(response.data);
  }

  static async removeShortlistedProfiles(profileUuid: string): Promise<HttpResponse<BaseResp>> {
    const response = await axios.post<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.REMOVE_SHORTLISTED_PROFILE}`,
      {
        profileUuid,
      },
    );

    return HttpResponse.fromRes<BaseResp>(response.data);
  }

  static async sendProfileView(
    payload: Pick<ProfileViewedBody, 'profileViewedTimes' | 'profileUuid' | 'lastViewedAt'>,
  ): Promise<HttpResponse<BaseResp>> {
    const response = await axios.post<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.SEND_PROFILE_VIEW}`,
      [payload],
    );

    return HttpResponse.fromRes<BaseResp>(response.data);
  }

  static async getLastSearchParams(): Promise<HttpResponse<ProfileSearchBody>> {
    const response = await axios.get<any, AxiosResponse<HttpResponse<ProfileSearchBody>>>(
      `${Endpoints.GET_LAST_SEARCH_PARAMS}`,
    );
    return HttpResponse.fromRes<ProfileSearchBody>(response.data);
  }

  static async getProfileActivity(page?: number): Promise<HttpResponse<any>> {
    const endpoint = page
      ? `${Endpoints.PROFILE_ACTIVITY}?page=${page}`
      : Endpoints.PROFILE_ACTIVITY;
    const response = await axios.get<any, AxiosResponse<HttpResponse<any>>>(endpoint);
    return HttpResponse.fromRes<any>(response.data);
  }

  static async getNotificationSettings() {
    const response = await axios.get<any, AxiosResponse<HttpResponse<NotificationSettingsResp>>>(
      `${Endpoints.NOTIFICATIONS_SETTINGS}`,
    );
    return HttpResponse.fromRes<NotificationSettingsResp>(response.data);
  }

  static async getMembershipDetails() {
    const response = await axios.get<
      any,
      AxiosResponse<HttpResponse<ProfileMembershipDetailsResp>>
    >(Endpoints.MEMBERSHIP_DETAILS);

    return HttpResponse.fromRes<ProfileMembershipDetailsResp>(response.data);
  }
}
