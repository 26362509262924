import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userReducer from 'src/store/reducer/userReducer';
import { persistReducer, persistStore } from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ErrorReducer from 'src/store/reducer/errorReducer';
import UiReducer from 'src/store/reducer/uiReducer';
import dimensionsReducer from './reducer/dimensionsReducer';

const persistConfig = {
  key: 'root',
  version: 1,
  storage: AsyncStorage,
  blacklist: ['error', 'ui', 'dimension'],
};

const rootReducer = combineReducers({
  user: userReducer,
  error: ErrorReducer,
  ui: UiReducer,
  dimension: dimensionsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
