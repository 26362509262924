import { Text, TextStyle, View, ViewStyle } from 'react-native';
import { Color } from 'src/constant/Color';
import Button from './common/buttons/Button';

export enum PermissionType {
  LocationForeground = 'location',
  Notifications = 'notifications',
}

interface Props {
  permissionType: PermissionType;
  requestPermission?: () => Promise<void>;
}

const permissionHeading: Record<PermissionType, string> = {
  [PermissionType.LocationForeground]: 'Grant Location Access',
  [PermissionType.Notifications]: 'Allow access to your Notification',
};

const permissionText: Record<PermissionType, string> = {
  [PermissionType.LocationForeground]:
    'Allow access to your location to provide a better experience',
  [PermissionType.Notifications]:
    'This app would like to send you notifications. Do you allow this?',
};

export const GrantPermission = ({ permissionType, requestPermission }: Props) => {
  return (
    <View style={$container}>
      <Text style={$text}>{permissionHeading[permissionType]}</Text>
      <Text style={[$text, $textSmall]}>{permissionText[permissionType]}</Text>
      <Button
        variant={'primary'}
        size={'small'}
        styles={{ borderRadius: 8 }}
        onPress={() => void requestPermission()}
      >
        Grant Now
      </Button>
    </View>
  );
};

const $container: ViewStyle = {
  flex: 1,
  backgroundColor: Color.darkPurple,
  marginTop: 20,
  minHeight: 110,
  maxHeight: 120,
  width: '100%',
  borderRadius: 12,
  justifyContent: 'center',
  alignItems: 'center',
  paddingVertical: 15,
};

const $text: TextStyle = {
  color: Color.white,
  fontSize: 12,
  fontWeight: '600',
  marginBottom: 5,
};

const $textSmall: TextStyle = {
  fontSize: 12,
  fontWeight: '400',
  marginBottom: 10,
};
