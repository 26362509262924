import { firestore } from 'firebaseConfig';
import { ChatResp } from 'src/common/response/chat.resp';
import { ProfileResp } from 'src/common/response/profile.resp';
import { ProfileService } from 'src/service/ProfileService';

export default class ChatUtil {
  static getTimestamp(data: ProfileResp) {
    const timestamp: string | Date = data.chat?.updatedAt;

    if (typeof timestamp === 'object') {
      const { seconds, nanoseconds } = timestamp as firebase.firestore.Timestamp;

      const date = new Date(seconds * 1000 + nanoseconds / 1000000);
      return date.getTime() / 1000;
    }
    return Date.parse(timestamp) / 1000;
  }

  static async mergeChatGroupsWithUserDetails(chats: ChatResp[], loggedInUserProfileUuid: string) {
    const profileUuids = [
      ...new Set(chats.map((chat) => [chat.profileUuid1, chat.profileUuid2]).flat()),
    ].filter((profileUuid) => profileUuid != loggedInUserProfileUuid);

    const chatMap: { [key: string]: ChatResp } = {};
    for (const chat of chats) {
      chatMap[chat.profileUuid1] = chat;
      chatMap[chat.profileUuid2] = chat;
    }
    const profiles = await ProfileService.listProfilesDetails(profileUuids);
    const profilesWithData = profiles.data.data;
    const profilesWithChat = profilesWithData;

    for (const profile of profilesWithChat) {
      profile.chat = chatMap[profile.uuid];
    }
    profilesWithChat.sort((a, b) => {
      const timestampA = ChatUtil.getTimestamp(a);
      const timestampB = ChatUtil.getTimestamp(b);
      return timestampB - timestampA;
    });

    return profilesWithChat;
  }

  static async fetchChatBetweenUsers(...userUUIDs: string[]) {
    try {
      const collectionRef = firestore
        .collection('chat-groups')
        .where('profileUuid1', 'in', userUUIDs)
        .where('profileUuid2', 'in', userUUIDs);

      const querySnapshot = await collectionRef.get();
      const chats = querySnapshot.docs.map((docSnap) => docSnap.data() as ChatResp);

      return chats;
    } catch (error) {
      console.error(error);
    }
  }
}
