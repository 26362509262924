import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { Image, TouchableOpacity, StyleSheet, FlatList } from 'react-native';
import { ActivityIndicator, IconButton } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { MembershipPermissions, isFeatureEnabled } from 'src/common/data/membership.data';
import { ContactsViewedResponse, ContactsViewed } from 'src/common/dto/contact.dto';
import { ProfileMembershipDetailsResp } from 'src/common/response/profile-membership.resp';
import { View, Text } from 'src/components/Themed';
import ConfirmationModal from 'src/components/modals/ConfirmationModal';
import { Color } from 'src/constant/Color';
import EFonts from 'src/constant/EFonts';
import { ContactService } from 'src/service/ContactService';
import { ProfileService } from 'src/service/ProfileService';
import { ModalTypes, showModal } from 'src/store/reducer/uiReducer';
import { RootState } from 'src/store/store';
import { GenUtil } from 'src/util/GenUtil';
import NotFoundScreen from './NotFoundScreen';

const PhonebookScreen = () => {
  const [selectedTab, setSelectedTab] = useState(TABS[0]);
  const [contacts, setContacts] = useState<Partial<ContactsViewedResponse>>(null);
  const [profileMembershipDetails, setProfileMembershipDetails] =
    useState<Partial<ProfileMembershipDetailsResp>>(null);
  const loggedInUserProfile = useSelector((state: RootState) => state?.user?.userProfile);
  const [error, setError] = useState(false);

  const navigation = useNavigation();

  const fetchContactViewed = async () => {
    setContacts(null);
    const request =
      selectedTab === TABS[0] ? ContactService.getContactViewed : ContactService.getContactViewers;
    try {
      const [contactsResp, membershipDetails] = await Promise.all([
        request(),
        ProfileService.getMembershipDetails(),
      ]);
      setContacts(contactsResp.data);
      setProfileMembershipDetails(membershipDetails.data);
    } catch (error) {
      setError(true);
    }
  };

  useEffect(() => {
    fetchContactViewed();
  }, [selectedTab]);

  const navigateToScreen = (screenName: string) => () => {
    if (screenName === 'back') {
      const homeScreen = GenUtil.isDesktopWeb() ? 'home' : 'data';
      navigation.canGoBack() ? navigation.goBack() : navigation.navigate(homeScreen);
    } else if (screenName === 'membership') {
      GenUtil.isDesktopWeb()
        ? navigation.navigate('membership')
        : navigation.navigate('data', { screen: 'Membership' });
    }
  };

  if (!isFeatureEnabled(loggedInUserProfile, MembershipPermissions.CanSeePhoneBook))
    return (
      <ConfirmationModal
        title="Upgrade to Premium"
        message="Unlock Phonebook with Premium Membership!"
        cancelButtonText="Cancel"
        primaryButtonText="Buy Premium"
        onCancel={navigateToScreen('back')}
        onPrimaryButtonPressed={navigateToScreen('membership')}
      />
    );

  if (error) return <NotFoundScreen title={'Something went wrong.'} />;

  return (
    <View style={styles.container}>
      <View style={{ marginBottom: 15 }}>
        <Tabs selectedTab={selectedTab} setSelectedTab={(tab: string) => setSelectedTab(tab)} />
      </View>
      {contacts ? (
        contacts.length > 0 ? (
          <View style={{ flex: 1, paddingBottom: 20 }}>
            <Text style={styles.totalContactsText}>
              Viewing the contact of profile will not affect your total contact views (
              {profileMembershipDetails?.contactViewLeft} total)
            </Text>
            <FlatList data={contacts} renderItem={({ item }) => <CallLogItem contact={item} />} />
          </View>
        ) : (
          <View style={{ minHeight: 50, justifyContent: 'center' }}>
            <Text style={{ textAlign: 'center' }}>No Contacts found.</Text>
          </View>
        )
      ) : (
        <View style={{ marginTop: 30, alignItems: 'center' }}>
          <ActivityIndicator />
        </View>
      )}
    </View>
  );
};

const CallLogItem = ({ contact }: { contact: ContactsViewed }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const showMoreOptions = () => {
    dispatch(
      showModal({
        type: ModalTypes.UserContactBottomSheet,
        childData: {
          profile: contact,
        },
      }),
    );
  };

  const navigateToProfile = () => {
    navigation.navigate('otherpersonprofile', { uuid: contact.uuid });
  };

  return (
    <View key={contact.uuid} style={styles.callLogCard}>
      <TouchableOpacity onPress={navigateToProfile}>
        <Image source={{ uri: contact.profilePhoto.originalUrl }} style={styles.userPhoto} />
      </TouchableOpacity>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          borderBottomColor: Color.lightGrey,
          borderBottomWidth: 1,
          paddingBottom: 15,
        }}
      >
        <View style={styles.userInfo}>
          <Text style={styles.userName}>{`${contact.fullName}, ${contact.age}`}</Text>
          <Text
            style={styles.userLocation}
          >{`${contact?.height}cm • ${contact?.currentLocation}`}</Text>
          <TouchableOpacity onPress={showMoreOptions}>
            <Text style={styles.viewContactText}>View Contact</Text>
          </TouchableOpacity>
        </View>
        <TouchableOpacity
          onPress={() => {
            console.log('Make phone call');
          }}
        >
          <IconButton icon="phone-outline" size={27} iconColor={Color.red} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const TABS = ['Contact Viewed', 'Contact Viewers'];

const Tabs = ({ selectedTab = '', setSelectedTab = (_) => {} }) => {
  return (
    <View style={styles.tabWrapper}>
      {TABS.map((tab, index) => (
        <View
          key={index}
          style={[
            selectedTab === tab
              ? StyleSheet.compose(styles.tabBorder, styles.tabBorderSelectedOvveride)
              : styles.tabBorder,
            { justifyContent: 'center', alignItems: 'center' },
          ]}
        >
          <TouchableOpacity
            onPress={() => setSelectedTab(tab)}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              paddingBottom: 0,
            }}
          >
            <Text
              style={[
                selectedTab === tab
                  ? StyleSheet.compose(styles.tabText, styles.tabTextSelectedOvveride)
                  : styles.tabText,
              ]}
            >
              {tab}
            </Text>
          </TouchableOpacity>
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 2,
    paddingHorizontal: 10,
  },
  totalContactsText: {
    marginBottom: 10,
    marginHorizontal: 7,
    borderColor: Color.lightGrey,
    borderWidth: 1,
    paddingVertical: 10,
    paddingRight: 20,
    paddingLeft: 10,
    fontSize: 12.5,
    borderRadius: 10,
    fontFamily: EFonts.DMSANS_REGULAR,
  },
  callLogCard: {
    flexDirection: 'row',
    padding: 10,
    marginBottom: 10,
  },
  userPhoto: {
    width: 55,
    height: 55,
    borderRadius: 27.5,
    marginRight: 10,
  },
  userInfo: {
    flex: 1,
  },
  userName: {
    fontFamily: EFonts.DMSANS_MEDIUM,
    fontSize: 15,
    marginBottom: 5,
  },
  userLocation: {
    color: Color.lightGrey,
    fontFamily: EFonts.DMSANS_REGULAR,
    fontSize: 12,
    marginBottom: 12,
  },
  viewContactText: {
    fontFamily: EFonts.DMSANS_BOLD,
    color: Color.red,
    fontSize: 12,
  },
  tabWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginHorizontal: 10,
    marginTop: 20,
    borderBottomWidth: 1,
    borderBottomColor: Color.lightBorder,
  },
  tabText: {
    fontFamily: EFonts.SORA_REGULAR,
    color: Color.lightBlack,
    marginBottom: 5,
    fontSize: 12,
    lineHeight: 15,
  },
  tabTextSelectedOvveride: {
    fontFamily: EFonts.SORA_SEMIBOLD,
    lineHeight: 21,
    color: Color.purple,
  },
  tabBorder: {
    borderBottomColor: 'transparent',
    flex: 1,
  },
  tabBorderSelectedOvveride: {
    borderBottomColor: Color.purple,
    borderBottomWidth: 1,
  },
});

export default PhonebookScreen;
