import axios, { AxiosResponse } from 'axios';
import { HttpResponse } from 'src/common/response/base.resp';
import { MembershipResp, OrderResp } from 'src/common/response/membership.resp';
import Endpoints from './Endpoints';

export class OrdersService {
  static async listProducts(): Promise<HttpResponse<MembershipResp[]>> {
    const response = await axios.get<any, AxiosResponse<HttpResponse<MembershipResp[]>>>(
      Endpoints.LIST_PRODUCTS,
    );
    return HttpResponse.fromRes<MembershipResp[]>(response.data);
  }

  static async createOrder(productId: string | number): Promise<HttpResponse<OrderResp>> {
    const response = await axios.post<any, AxiosResponse<HttpResponse<OrderResp>>>(
      Endpoints.PLACE_ORDER,
      {
        productId,
      },
    );

    return HttpResponse.fromRes<OrderResp>(response.data);
  }

  static async verifyOrder(
    orderId: string,
    paymentId: string,
    signature: string,
  ): Promise<AxiosResponse> {
    return await axios.post<any, AxiosResponse>(Endpoints.VERIFY_ORDER, {
      razorpayOrderId: orderId,
      razorpayPaymentId: paymentId,
      razorpaySignature: signature,
    });
  }
}
