import React, { useState } from 'react';
import { StyleSheet, FlatList, TouchableOpacity, Image } from 'react-native';
import { View, Text } from 'src/components/Themed';
import EFonts from 'src/constant/EFonts';
import { GenUtil } from 'src/util/GenUtil';
import { ProfileResp } from 'src/common/response/profile.resp';
import { Color } from 'src/constant/Color';
import ModalAdornment from 'src/components/editProfileComponents/atoms/ModalAdornment';
import { FamilyType, FamilyValue, FatherStatus } from 'src/common/models/profile.model';
import { useAutoCompleteLocation } from 'src/hooks/useAutoCompleteLocation';
import { majorCities } from 'src/screens/OnboardingScreen';
import SaveButton from 'src/components/editProfileComponents/SaveButton';
import { ProfileService } from 'src/service/ProfileService';
import { useDispatch } from 'react-redux';
import { setUserProfile } from 'src/store/reducer/userReducer';
import CustomModal from 'src/components/CustomModal';
import AddSiblingsComp, {
  SiblingsGeneratedText,
} from 'src/components/editProfileComponents/edit-profile/AddSiblingsComp';
import { ScrollView } from 'react-native';
import { LocationType } from 'src/common/models/location.model';
import { AlertTypeEnum, showError } from 'src/store/reducer/errorReducer';

const { width } = GenUtil.getDimension();

export default function MyFamilyDetails({ profileData }: { profileData: Partial<ProfileResp> }) {
  const dispatch = useDispatch();
  const [isValidating, setIsValidating] = useState(false);
  const [aboutFamilyText, setAboutFamilyText] = useState(
    profileData.aboutFamily ? profileData.aboutFamily : '',
  );
  const [fatherStatus, setFatherStatus] = useState(profileData.fatherStatus ?? '');
  const [motherStatus, setMotherStatus] = useState(profileData.motherStatus ?? '');
  const [familyCurrLoc, setFamilyCurrLoc] = useState(profileData.familyCurrentLocation ?? '');
  const [familyNativeLoc, setFamilyNativeLoc] = useState(profileData.familyNativeLocation ?? '');
  const [familyType, setFamilyType] = useState(profileData.familyType ?? '');
  const [familyValues, setFamilyValues] = useState(profileData.familyValue ?? '');
  const [familyExtra, setFamilyExtra] = useState({
    numOfBrother: profileData.familyExtra?.numOfBrother ?? 0,
    numOfSister: profileData.familyExtra?.numOfSister ?? 0,
    numOfBrotherMarried: profileData.familyExtra?.numOfBrotherMarried ?? 0,
    numOfSisterMarried: profileData.familyExtra?.numOfSisterMarried ?? 0,
    numOfElderBrother: profileData.familyExtra?.numOfElderBrother ?? 0,
    numOfElderSister: profileData.familyExtra?.numOfElderSister ?? 0,
  });
  const [isAddSiblingsVisible, setIsAddSiblingsVisible] = useState(false);
  const [locationData, searchedLocation, setSearchedLocation] = useAutoCompleteLocation(
    500,
    majorCities,
  );

  const INFO_ROWS = [
    {
      id: 1,
      icon: require('src/assets/images/editprofileicons/FrameFatherstatus.png'),
      label: 'Father',
      itemArray: Object.values(FatherStatus).map((value) => value as string),
      value: fatherStatus,
      setValue: setFatherStatus,
    },
    {
      id: 2,
      icon: require('src/assets/images/editprofileicons/Framemotherstatus.png'),
      label: 'Mother',
      itemArray: Object.values(FatherStatus)
        .map((value) => value as string)
        .concat('House Wife'),
      value: motherStatus,
      setValue: setMotherStatus,
    },
    {
      id: 3,
      icon: require('src/assets/images/editprofileicons/FrameCurrentLocation.png'),
      label: 'Current Location',
      itemArray: (searchedLocation === '' ? majorCities : locationData).map((item) =>
        GenUtil.getLocationName(
          item.cityName,
          item.stateName,
          item.countryName,
          LocationType.City,
          true,
        ),
      ),
      value: familyCurrLoc,
      setValue: setFamilyCurrLoc,
    },
    {
      id: 4,
      icon: require('src/assets/images/editprofileicons/FrameCurrentLocation.png'),
      label: 'Native Place',
      itemArray: (searchedLocation === '' ? majorCities : locationData).map((item) =>
        GenUtil.getLocationName(
          item.cityName,
          item.stateName,
          item.countryName,
          LocationType.City,
          true,
        ),
      ),
      value: familyNativeLoc,
      setValue: setFamilyNativeLoc,
    },
    {
      id: 5,
      icon: require('src/assets/images/editprofileicons/FrameFamval.png'),
      label: 'Family Type',
      itemArray: Object.values(FamilyType).map((value) => value as string),
      value: familyType,
      setValue: setFamilyType,
    },
    {
      id: 6,
      icon: require('src/assets/images/editprofileicons/FrameFamval.png'),
      label: 'Family Values',
      itemArray: Object.values(FamilyValue).map((value) => value as string),
      value: familyValues,
      setValue: setFamilyValues,
    },
  ];

  const aboutFamilyPlaceholder = `Example:- "My father is a businessman and my mother is a house wife, we belong from XYZ city and we're living in Delhi since 1980"`;
  const myFamilyDetails = INFO_ROWS.filter((item) => item);

  const handleSave = async () => {
    try {
      setIsValidating(true);
      const familyCurrentLocation = familyCurrLoc.split(',');
      const familyNativeLocation = familyNativeLoc.split(',');
      const data = {
        fatherStatus,
        motherStatus,
        familyCurrentLocation: {
          countryName: familyCurrentLocation?.[2].trim(),
          stateName: familyCurrentLocation?.[1].trim(),
          cityName: familyCurrentLocation?.[0].trim(),
        },
        familyNativeLocation: {
          countryName: familyNativeLocation?.[2].trim(),
          stateName: familyNativeLocation?.[1].trim(),
          cityName: familyNativeLocation?.[0].trim(),
        },
        aboutFamily: aboutFamilyText,
        familyType,
        familyValue: familyValues,
        familyAffluenceLevel: 'Middle Class',
        numOfBrother: familyExtra.numOfBrother,
        numOfSister: familyExtra.numOfSister,
        numOfBrotherMarried: familyExtra.numOfBrotherMarried,
        numOfSisterMarried: familyExtra.numOfSisterMarried,
        numOfElderBrother: familyExtra.numOfElderBrother,
        numOfElderSister: familyExtra.numOfElderSister,
      };
      const updateFamilyResponse = await ProfileService.updateFamilyDetails(data);
      //Update Redux
      if (updateFamilyResponse?.httpCode === 200) {
        dispatch(setUserProfile(updateFamilyResponse?.data));
        dispatch(showError({ alertType: AlertTypeEnum.Success, message: 'Saved' }));
      }
      setIsValidating(false);
      console.log(updateFamilyResponse);
    } catch (error) {
      console.log(error);
      setIsValidating(false);
      dispatch(
        showError({ alertType: AlertTypeEnum.Error, message: GenUtil.getMessageFromError(error) }),
      );
    }
  };

  const renderItem = ({ item, index }) => (
    <View style={styles.info}>
      <Text style={styles.infoLabel}>{item?.label}</Text>

      <ModalAdornment
        icon={
          <Image
            source={item?.icon}
            style={
              item?.label === 'Father'
                ? { width: 18, height: 5, marginTop: 7, marginBottom: 6 }
                : { width: 18, height: 18 }
            }
          />
        }
        modalItems={item?.itemArray}
        value={item?.value}
        placeHolder="Select"
        setValue={item?.setValue}
        searchedLocation={searchedLocation}
        setSearchedLocation={setSearchedLocation}
        isLocation={item?.label === 'Current Location' || item?.label === 'Native Place'}
        modalHeading={item?.label}
        maxValueLength={GenUtil.isWEB() ? 24 : 12}
      />
    </View>
  );

  return (
    <View style={styles.container}>
      <View style={styles.info}>
        <Text style={styles.infoLabel}>About Family</Text>
        <View style={{ width: width * 0.9 }}>
          <ModalAdornment
            value={aboutFamilyText}
            inputTextFunction={setAboutFamilyText}
            isInputText={true}
            placeHolder={aboutFamilyPlaceholder}
            modalHeading="Write about your family"
          />
        </View>
      </View>
      <FlatList
        data={myFamilyDetails}
        renderItem={renderItem}
        keyExtractor={(item) => item.id.toString()}
        numColumns={2}
        columnWrapperStyle={{ marginTop: 6, justifyContent: 'space-between' }}
      />
      <View style={{ marginVertical: 10 }}>
        <Text style={styles.infoLabel}>Siblings</Text>
        <TouchableOpacity style={styles.TextButton} onPress={() => setIsAddSiblingsVisible(true)}>
          {Object.keys(profileData.familyExtra).length > 0 ? (
            <ScrollView style={{ maxHeight: 100 }} showsVerticalScrollIndicator={false}>
              <SiblingsGeneratedText siblingsData={familyExtra} />
            </ScrollView>
          ) : (
            <Text style={styles.placeHolderText}>Something about your siblings...</Text>
          )}
        </TouchableOpacity>
        <CustomModal
          visible={isAddSiblingsVisible}
          renderComponent={
            <AddSiblingsComp
              onPress={() => setIsAddSiblingsVisible(false)}
              familyExtra={familyExtra}
              setFamilyExtra={setFamilyExtra}
            />
          }
        />
      </View>
      <View style={styles.saveButtonWrapper}>
        <SaveButton buttonText="Save" handleKeyPress={handleSave} isValidating={isValidating} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Color.white,
    paddingHorizontal: 6,
  },
  aboutMeStyle: {
    width: width * 0.9,
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    borderWidth: 1,
    borderColor: '#D6D6D6',
    lineHeight: 24,
    padding: 9,
    borderRadius: 8,
    marginTop: 4,
  },
  infoLabel: {
    flex: 1,
    fontFamily: EFonts.SORA_REGULAR,
    fontStyle: 'normal',
    fontSize: GenUtil.isWEB() ? 12 : 10,
    lineHeight: 20,
    color: Color.lightGrey,
    alignItems: 'center',
    marginLeft: 15,
  },
  info: {
    width: width * 0.45,
  },
  saveButtonWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  TextButton: {
    width: width * 0.9,
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    lineHeight: 24,
    borderWidth: 1,
    borderColor: Color.lightBorder,
    borderRadius: 8,
    padding: 9,
  },
  placeHolderText: {
    fontFamily: EFonts.SORA_REGULAR,
    fontStyle: 'normal',
    fontSize: 12,
    lineHeight: 20,
    color: Color.lightBlack,
  },
});
