import {
  ActivityIndicator,
  FlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { useEffect, useState } from 'react';
import { Color } from 'src/constant/Color';
import MimialProfileCard from 'src/components/common/profileCard/MinimalProfileCard';
import { ProfileResp } from 'src/common/response/profile.resp';
import { InterestService } from 'src/service/InterestService';
import { InterestsStatus, SentType } from 'src/common/models/interest.model';

enum Tab {
  ACCEPTED = 'Interest Accepted',
  SENT = 'Interest Sent',
}

const InterestsSection = () => {
  const [currentTab, setCurrentTab] = useState<Tab>(Tab.ACCEPTED);
  const [profilesData, setProfilesData] = useState<ProfileResp[]>(null);
  const [totalInterest,setTotalInterest] = useState({
    interestAccepted : 0,
    interestSent:0
  })

  const fetchInterestProfiles = async () => {
    try {
      setProfilesData(null);
      const responses = await Promise.all([
        InterestService.fetchInterests({
          sentType: SentType.Sent,
          status: InterestsStatus.Accepted,
        }),
        InterestService.fetchInterests({
          sentType: SentType.Received,
          status: InterestsStatus.Accepted,
        }),
        InterestService.fetchInterests({
          sentType: SentType.Sent,
          status: InterestsStatus.Pending,
        }),
      ]);

      const profileResp = 
        responses
          .filter((response) => response.success && response.data)
          .map((response) => response.data as ProfileResp[])
      
      setTotalInterest({
        interestAccepted: [profileResp[0],profileResp[1]].flat().length,
        interestSent: profileResp[2].length
      })

      if(currentTab === Tab.ACCEPTED){
        setProfilesData([profileResp[0],profileResp[1]].flat())
      }
      if(currentTab === Tab.SENT){
        setProfilesData(profileResp[2])
      }
    } catch (error) {
      setProfilesData([]);
    }
  };

  useEffect(() => {
    fetchInterestProfiles().catch((e) => setProfilesData([]));
  }, [currentTab]);

  return (
    <View style={styles.container}>
      <View style={styles.tabContainer}>
        {Object.values(Tab).map((tab) => {
          const isSelected = currentTab === tab;
          return (
            <TouchableOpacity
              style={[styles.tab, isSelected ? styles.selectedTab : {}]}
              activeOpacity={100}
              key={tab}
              onPress={() => setCurrentTab(tab)}
            >
              <View style={styles.textWrapper}>
                <Text style={[styles.tabText, isSelected ? styles.selectedTabText : {}]}>{tab}</Text>
                {profilesData && <Text style={styles.count}> {tab === Tab.ACCEPTED ? totalInterest.interestAccepted : totalInterest.interestSent}</Text>}
              </View>
            </TouchableOpacity>
          );
        })}
      </View>
      {profilesData?.length > 0 ? (
        <FlatList
          data={profilesData}
          renderItem={({ item }) => <MimialProfileCard item={item} />}
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          keyExtractor={(item) => item.uuid}
        />
      ) : (
        <>
          {profilesData ? (
            <View style={{ minHeight: 50, justifyContent: 'center' }}>
              <Text style={{ textAlign: 'center' }}>No Profiles Found</Text>
            </View>
          ) : (
            <View style={{ minHeight: 50, justifyContent: 'center' }}>
              <ActivityIndicator />
            </View>
          )}
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '90%',
    marginTop: 20,
  },
  tabContainer: {
    width: '100%',
    flexDirection: 'row',
    marginBottom: 10,
  },
  tab: {
    width: '50%',
    padding: 15,
    borderBottomWidth: 2,
    borderBottomColor: Color.gray,
    alignItems: 'center'
  },
  selectedTab: {
    borderBottomWidth: 2.5,
    borderBottomColor: Color.purple,
  },
  selectedTabText: {
    color: Color.purple,
    fontWeight: '600',
  },
  tabText: {
    color: Color.black,
    textAlign: 'center',
  },
  button: {
    backgroundColor: Color.black,
    alignSelf: 'center',
    borderRadius: 8,
    marginTop: 15,
  },
  textWrapper: {
    flexDirection: 'row'
  },
  count: {
    backgroundColor: Color.purple,
    color: Color.white,
    marginLeft: 5,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 10,
    paddingRight: 10
  }
});

export default InterestsSection;
