const MIN_FEET = 4;
const MAX_FEET = 7;
const MAX_INCH = 5;

export const AgeData = [
  20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 32, 33, 34, 35, 36, 37, 38, 39, 40,
];

const MinAgeData = [];
const MaxAgeData = [];
for (let i = 18; i < 100; i++) MinAgeData.push(i);
for (let i = 21; i < 100; i++) MaxAgeData.push(i);

const IncomeData: number[] = [];
for (let i = 0; i < 1000; i++) {
  IncomeData.push(i);
}
const IncomeDataDollar: number[] = [];
for (let i = 10000; i < 10000000; i += 1000) {
  IncomeDataDollar.push(i);
}
const HeightData = [];
function feetAndInchesToCm(feet, inches) {
  const cmPerFoot = 30.48 - 0.01;
  const cmPerInch = 2.54 + 0.005;
  const totalCm = feet * cmPerFoot + inches * cmPerInch;

  return totalCm;
}

for (let feet = MIN_FEET; feet <= MAX_FEET; feet++) {
  let startInches = 5
  if (feet > MIN_FEET) startInches = 1;
  for (let inches = startInches; inches <= 12; inches++) {
    if (feet === MAX_FEET && inches > MAX_INCH) {
      break;
    }

    const heightInCm = feetAndInchesToCm(feet, inches);
    const inIntegerFormat = Math.round(heightInCm);
    HeightData.push(inIntegerFormat)
  }
}

export { IncomeData, IncomeDataDollar, HeightData, MinAgeData, MaxAgeData };
