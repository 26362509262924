import { useDispatch } from 'react-redux';
import { InterestActionType } from 'src/common/models/interest.model';
import { ProfileResp } from 'src/common/response/profile.resp';
import { InterestService } from 'src/service/InterestService';
import { toggleRefresh } from 'src/store/reducer/uiReducer';

export enum ActionTypes {
  Accept = 'accept',
  Decline = 'decline',
  Send = 'send',
  Delete = 'delete',
  Reminder = 'reminder',
}

export type ActionT = {
  label: string;
  onClick: () => Promise<void>;
  disabled?: boolean;
  showLabel: boolean;
  type: ActionTypes;
};

const ONCLICK_ACTIONS = {
  [ActionTypes.Accept]: async (profile: Partial<ProfileResp>) =>
    await InterestService.actionOnInterest({
      interestUuid: profile.interest?.uuid,
      type: InterestActionType.Accept,
      customMessage: '',
    }),
  [ActionTypes.Decline]: async (profile: Partial<ProfileResp>) =>
    await InterestService.actionOnInterest({
      interestUuid: profile.interest?.uuid,
      type: InterestActionType.Decline,
      customMessage: '',
    }),
  [ActionTypes.Delete]: async (profile: Partial<ProfileResp>) =>
    await InterestService.deleteInterest(profile.interest.uuid),
  [ActionTypes.Reminder]: async (profile: Partial<ProfileResp>) =>
    await InterestService.sendInterestReminder({
      interestUuid: profile.interest.uuid,
      customMessage: '',
    }),
};

export default function useProfileActions(profile: Partial<ProfileResp>): Array<ActionT> {
  if (!profile || !profile.actions) return;

  const { actions } = profile;
  const dispath = useDispatch();

  const handleOnClick = (actionType: string) => async () => {
    try {
      if (actionType === ActionTypes.Send) return;
      const request = ONCLICK_ACTIONS[actionType];
      await request(profile);
      dispath(toggleRefresh());
    } catch (err) {
      console.log(err);
    }
  };

  const actionItems = [];
  for (const action of actions) {
    if (action.action.type === 'Interest') {
      actionItems.push({
        label: action.name,
        onClick: handleOnClick(action.action.actionType),
        disabled: false,
        showLabel: true,
        type: action.action.actionType,
      });
    }
  }

  return actionItems;
}
