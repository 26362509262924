/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useState } from 'react';
import { View, Text, StyleSheet, Image, FlatList, TouchableOpacity } from 'react-native';

import { ManglikStatus, RashiSign } from 'src/common/models/profile.model';
import { Color } from 'src/constant/Color';
import EFonts from 'src/constant/EFonts';
import { GenUtil } from 'src/util/GenUtil';
import { NakshatraData, NakshatraName } from 'src/common/data/astro.data';
import ModalAdornment from 'src/components/editProfileComponents/atoms/ModalAdornment';
import { useAutoCompleteLocation } from 'src/hooks/useAutoCompleteLocation';
import { majorCities } from 'src/screens/OnboardingScreen';
import SaveButton from 'src/components/editProfileComponents/SaveButton';
import { ProfileService } from 'src/service/ProfileService';
import { useDispatch } from 'react-redux';
import { setUserProfile } from 'src/store/reducer/userReducer';
import { LocationType } from 'src/common/models/location.model';
import { AlertTypeEnum, showError } from 'src/store/reducer/errorReducer';
import { DateField } from 'src/components/common/DateField';
import dayjs from 'dayjs';

const { width } = GenUtil.getDimension();

export interface AstroInfo {
  rashi: RashiSign;
  manglik: ManglikStatus;
  nakshatra: NakshatraName;
  birthDate: string;
  birthTime: string;
  birthPlace: string;
}

const MyAstroDetails = ({ profileData }: { profileData: AstroInfo }) => {
  const dispatch = useDispatch();
  const [isValidating, setIsValidating] = useState(false);
  const [rashi, setRashi] = useState(profileData.rashi ?? '');
  const [manglik, setManglik] = useState(profileData.manglik ?? '');
  const [nakshatra, setNakshatra] = useState(profileData.nakshatra ?? '');
  const [birthPlace, setBirthPlace] = useState(profileData.birthPlace ?? '');
  const [birthTime, setBirthTime] = useState(profileData.birthTime ?? '');
  const [birthDate, setBirthDate] = useState(profileData.birthDate ?? '');
  const [locationData, searchedLocation, setSearchedLocation] = useAutoCompleteLocation(
    500,
    majorCities,
  );

  const detailsArray = [
    // {
    //   id: 1,
    //   icon: require('src/assets/images/profileIcons/astrology.png'),
    //   label: 'Rashi',
    //   itemArray: Object.values(RashiSign).map((value) => value as string),
    //   value: rashi,
    //   setValue: setRashi,
    // },
    {
      id: 2,
      icon: require('src/assets/images/profileIcons/dot-inside-a-circle.png'),
      label: 'Manglik',
      value: manglik,
      itemArray: Object.values(ManglikStatus).map((value) => value as string),
      setValue: setManglik,
    },
    // {
    //   id: 3,
    //   icon: require('src/assets/images/profileIcons/parchment.png'),
    //   label: 'Nakshatra',
    //   itemArray: Object.values(NakshatraData).map((value) => value.name),
    //   value: nakshatra,
    //   setValue: setNakshatra,
    // },
    {
      id: 4,
      icon: require('src/assets/images/profileIcons/cake.png'),
      label: 'Birth Date',
      value: birthDate,
      setValue: setBirthDate,
    },
    {
      id: 5,
      icon: require('src/assets/images/profileIcons/clock.png'),
      label: 'Birth Time',
      value: birthTime,
      setValue: setBirthTime,
    },
    {
      id: 6,
      icon: require('src/assets/images/profileIcons/google-maps.png'),
      label: 'Birth Place',
      itemArray: (searchedLocation === '' ? majorCities : locationData).map((item) =>
        GenUtil.getLocationName(item.cityName, item.stateName, item.countryName, LocationType.City),
      ),
      value: birthPlace,
      setValue: setBirthPlace,
    },
  ];

  const filteredData = detailsArray.filter((item) => item);

  const renderItem = ({ item, index }) => (
    <View style={styles.info}>
      <Text
        style={[
          styles.infoLabel,
          { marginTop: item?.label === 'Birth Date' || item?.label === 'Manglik' ? null : 6 },
        ]}
      >
        {item?.label}
      </Text>

      {item?.label === 'Birth Date' ? (
        <DateField
          date={item?.value}
          prevIcon={<Image source={item?.icon} style={{ width: 18, height: 18 }} />}
          onChange={item?.setValue}
          placeholder="Select"
          mode="date"
        />
      ) : item?.label === 'Birth Time' ? (
        <DateField
          date={item?.value}
          prevIcon={<Image source={item?.icon} style={{ width: 18, height: 18 }} />}
          onChange={item?.setValue}
          placeholder="Select"
          mode="time"
        />
      ) : (
        <ModalAdornment
          icon={<Image source={item?.icon} style={{ width: 18, height: 18 }} />}
          modalItems={item?.itemArray}
          value={item?.value}
          setValue={item?.setValue}
          placeHolder="Select"
          searchedLocation={searchedLocation}
          setSearchedLocation={setSearchedLocation}
          isLocation={item?.label === 'Birth Place'}
          modalHeading={item?.label}
          maxValueLength={GenUtil.isWEB() ? 24 : 12}
        />
      )}
    </View>
  );

  const handleSave = async () => {
    try {
      setIsValidating(true);
      const nakshatraId = NakshatraData.find((i) => i.name === nakshatra)?.id;
      const data = {
        rashi,
        manglik,
        nakshatraId,
        birthDate: dayjs(birthDate).format('YYYY-MM-DD'),
        birthTime: dayjs(birthTime).format('HH:mm'),
        birthPlace,
      };
      const updateAstroDetailsResponse = await ProfileService.updateAstroDetails(data);
      if (updateAstroDetailsResponse?.httpCode === 200) {
        //update redux
        dispatch(setUserProfile(updateAstroDetailsResponse?.data));
        dispatch(showError({ alertType: AlertTypeEnum.Success, message: 'Saved' }));
      }
      setIsValidating(false);
    } catch (error) {
      setIsValidating(false);
      dispatch(
        showError({ alertType: AlertTypeEnum.Error, message: GenUtil.getMessageFromError(error) }),
      );
      console.log(error);
    }
  };
  return (
    <View style={styles.container}>
      <FlatList
        data={filteredData}
        renderItem={renderItem}
        keyExtractor={(item) => item.id.toString()}
        numColumns={2}
        columnWrapperStyle={{
          justifyContent: 'space-between',
        }}
      />
      <View style={styles.saveButtonWrapper}>
        <SaveButton buttonText="Save" handleKeyPress={handleSave} isValidating={isValidating} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Color.white,
    paddingHorizontal: 6,
  },

  infoLabel: {
    flex: 1,
    fontFamily: EFonts.SORA_REGULAR,
    fontStyle: 'normal',
    fontSize: GenUtil.isWEB() ? 12 : 10,
    lineHeight: 20,
    color: Color.lightGrey,
    alignItems: 'center',
    marginLeft: 15,
  },
  info: {
    width: width * 0.45,
  },
  saveButtonWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});
export default MyAstroDetails;
