import { StyleSheet, Text, View } from 'react-native';
import React, { useState } from 'react';
import { Color } from 'src/constant/Color';
import OnboardingPhoneInputComp from 'src/components/onboarding-components/OnboardingPhoneInputComp';
import OnboardingPasswordInputComp from 'src/components/onboarding-components/OnboardingPasswordInputComp';
import { useNavigation } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import { AlertTypeEnum, showError } from 'src/store/reducer/errorReducer';
import { GenUtil } from 'src/util/GenUtil';
import EFonts from 'src/constant/EFonts';
import OtpInputs from 'react-native-otp-inputs';
import UserService from 'src/service/UserService';
import SafeContainer from 'src/components/SafeContainer';

const { height, width } = GenUtil.getDimension();

const ForgetPasswordScreen = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('IN');
  const [country, setCountry] = useState({ cca2: 'IN', callingCode: ['91'] });
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setconfirmPassword] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const navigation = useNavigation();
  const dispatch = useDispatch();

  const handleSendOtpPressed = async () => {
    try {
      const forgotPasswordBody = {
        mobile: '+' + country.callingCode[0] + phoneNumber,
      };
      const response = await UserService.forgotPassword(forgotPasswordBody);
      if (!response.success) return;
      setOtpSent(true);
    } catch (error) {
      dispatch(
        showError({ message: GenUtil.getMessageFromError(error), alertType: AlertTypeEnum.Error }),
      );
    }
  };

  const handleContinuePressed = async () => {
    try {
      if (otp.length !== 6) {
        alert('Otp should be 6 characters long');
        return;
      }

      const resetPasswordBody = {
        mobile: '+' + country.callingCode[0] + phoneNumber,
        otp,
        newPassword: password,
      };

      const response = await UserService.resetPassword(resetPasswordBody);
      if (!response.success) return;

      dispatch(
        showError({
          message: 'Password updated successfully, please login',
          alertType: AlertTypeEnum.Success,
        }),
      );

      navigation.navigate('login');
    } catch (error) {
      dispatch(
        showError({ message: GenUtil.getMessageFromError(error), alertType: AlertTypeEnum.Error }),
      );
    }
  };
  return (
    <SafeContainer
      style={{ flex: 1, backgroundColor: Color.white }}
      showPageHeader={true}
      heading="Forget Password"
    >
      {!otpSent ? (
        <OnboardingPhoneInputComp
          phoneNumber={phoneNumber}
          countryCode={countryCode}
          country={country}
          shouldCheckUser={false}
          setPhoneNumber={setPhoneNumber}
          setCountryCode={setCountryCode}
          setCountry={setCountry}
          next={handleSendOtpPressed}
        />
      ) : (
        <View style={{ flex: 1 }}>
          <View style={styles.otpContainer}>
            <Text style={styles.otpTextStyle}>Enter otp</Text>
            <OtpInputs
              handleChange={(code) => setOtp(code)}
              numberOfInputs={6}
              autofillFromClipboard={false}
              style={styles.otpStyle}
              inputStyles={styles.otpInput}
              textContentType="oneTimeCode"
              keyboardType="phone-pad"
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          </View>
          <OnboardingPasswordInputComp
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setconfirmPassword={setconfirmPassword}
            next={handleContinuePressed}
          />
        </View>
      )}
    </SafeContainer>
  );
};

const styles = StyleSheet.create({
  otpContainer: {
    paddingTop: 40,
    paddingHorizontal: width * 0.05,
    backgroundColor: 'transparent',
    marginBottom: 30,
  },
  otpTextStyle: {
    fontFamily: EFonts.SORA_LIGHT,
    fontSize: 28,
    lineHeight: 38,
    color: Color.lightBlack,
    marginTop: 10,
    marginBottom: 10,
  },
  otpStyle: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
    marginLeft: 7,
    marginTop: GenUtil.isWEB() ? null : 39,
  },
  otpInput: {
    backgroundColor: Color.backgroundGray,
    borderRadius: 80,
    height: 40,
    width: 50,
    fontFamily: EFonts.SORA_SEMIBOLD,
    fontSize: 20,
    borderWidth: 1.2,
    textAlign: 'center',
    outlineStyle: 'none',
    marginRight: 11,
    color: Color.purple,
    borderColor: Color.purple,
  },
});

export default ForgetPasswordScreen;
