import { ImageBackground, StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native';
import { ProfileResp } from 'src/common/response/profile.resp';
import { GenUtil } from 'src/util/GenUtil';
import { LinearGradient } from 'expo-linear-gradient';
import { Color } from 'src/constant/Color';
import MessageAction from 'src/components/common/profileCard/actions/MessageAction';
import StarProfileAction from 'src/components/common/profileCard/actions/StarProfileAction';
import GalleryAction from 'src/components/common/profileCard/actions/GalleryAction';
import { Verification } from 'src/common/models/profile.model';
import Button from 'src/components/common/buttons/Button';
import UserUtil from 'src/util/UserUtil';
import InterestAction from 'src/components/common/profileCard/actions/InterestAction';
import { useNavigation } from '@react-navigation/native';
import VerifySvg from 'src/assets/icons/verify.svg';
import UserTickSvg from 'src/assets/icons/user-tick.svg';
import RingSvg from 'src/assets/icons/ring.svg';
import MapPinSvg from 'src/assets/icons/map-pin.svg';
import CurrencySvg from 'src/assets/icons/currency.svg';
import CrownSvg from 'src/assets/icons/crown.svg';
import React from 'react';

const { height, width } = GenUtil.getDimension();

const CARD_HEIGHT = GenUtil.isWEB() ? height / 2.2 : height / 1.5;
const CARD_WIDTH = width * 0.9;

type Props = {
  profile: ProfileResp;
};

const ProfileCardXL = ({ profile }: Props) => {
  const navigation = useNavigation();

  const navigateToProfile = () => {
    navigation.navigate('otherpersonprofile', { uuid: item.uuid });
  };

  return (
    <TouchableWithoutFeedback onPress={navigateToProfile}>
      <View style={styles.container}>
        <ImageBackground
          source={GenUtil.getProfilePic(
            profile,
            require('src/assets/images/profileIcons/placeholder_profile.png'),
          )}
          style={styles.imageBg}
        >
          <LinearGradient
            colors={['#00000000', '#000000']}
            style={{ height: CARD_HEIGHT, width: '100%' }}
          ></LinearGradient>
        </ImageBackground>
        <View style={styles.premiumButton}>
          <Button
            onPress={() => {}}
            variant={'primary'}
            styles={{ backgroundColor: 'rgba(0, 0,0, 0.7)', marginLeft: 10 }}
            textStyles={{ color: '#FFE600' }}
            beforeIcon={
              <View style={{ marginRight: 8 }}>
                <CrownSvg width={16} height={16} />
              </View>
            }
          >
            Premium
          </Button>
        </View>
        <View style={styles.galleryContainer}>
          <GalleryAction imagesCount={profile.medias?.length} />
        </View>

        <View style={styles.info}>
          <View style={styles.infoContainer}>
            <Text style={styles.infoText}>{profile.fullName}</Text>
            {profile?.verifications?.find(
              (verification) => verification === Verification.IDVerified,
            ) ? (
              <VerifySvg style={{ marginLeft: 8 }} />
            ) : (
              <></>
            )}
          </View>
          <View style={styles.infoContainer}>
            <Text style={[styles.infoTextSmall, { marginLeft: 0, marginVertical: 5 }]}>
              {'Last seen '}
            </Text>
          </View>
          <View style={styles.infoColumn}>
            <View style={styles.infoContainer}>
              <UserTickSvg style={{ color: Color.white }} />
              <Text style={styles.infoTextSmall}>{profile.age}Y,</Text>
              <Text style={styles.infoTextSmall}>{UserUtil.getUserHeight(profile.height)}</Text>
            </View>
            <View style={styles.infoContainer}>
              <RingSvg style={{ color: Color.white }} />
              <Text style={styles.infoTextSmall}>{profile.maritalStatus}</Text>
            </View>
          </View>
          <View style={styles.infoColumn}>
            <View style={styles.infoContainer}>
              <MapPinSvg style={{ color: Color.white }} />
              <Text style={styles.infoTextSmall}>{profile.currentLocation}</Text>
            </View>
            <View style={styles.infoContainer}>
              <CurrencySvg style={{ color: Color.white }} />
              <Text style={styles.infoTextSmall}>{UserUtil.getUserIncome(profile)}</Text>
            </View>
          </View>
          <View style={styles.actionContainer}>
            <View style={styles.subActionContainer}>
              <MessageAction profile={profile} />
              <InterestAction profile={profile} />
            </View>
            <StarProfileAction profile={profile} />
          </View>
          {profile.chat?.unreadMessages ? (
            <Text style={[styles.infoTextSmall, { marginLeft: 0, marginTop: 8 }]}>
              She sent you a message. Check Now.
            </Text>
          ) : (
            <></>
          )}
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
};

export default ProfileCardXL;

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    minHeight: CARD_HEIGHT,
    maxHeight: CARD_HEIGHT,
    width: CARD_WIDTH,
    alignSelf: 'center',
    borderRadius: 17,
    marginVertical: 15,
  },
  imageBg: {
    width: '100%',
    height: CARD_HEIGHT,
    resizeMode: 'cover',
  },
  info: {
    width: '100%',
    position: 'absolute',
    bottom: 12,
    left: 12,
  },
  infoText: {
    color: '#fff',
    fontSize: 12,
    fonWeight: '600',
    fontFamily: 'dmsans-bold',
  },
  infoTextSmall: {
    fontSize: 11,
    color: Color.white,
    marginLeft: 10,
  },
  infoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  infoColumn: {
    width: '92%',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 5,
    justifyContent: 'space-between',
  },
  greenDot: {
    backgroundColor: Color.green,
  },
  dot: {
    width: 5,
    height: 5,
    borderRadius: 3,
  },
  redDot: {
    backgroundColor: Color.red,
  },
  subActionContainer: {
    flexDirection: 'row',
  },
  actionContainer: {
    marginTop: 15,
    width: '92%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  galleryContainer: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  premiumButton: {
    position: 'absolute',
    top: 10,
    left: 0,
  },
});
