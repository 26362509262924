/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import {
  DarkTheme,
  DefaultTheme,
  NavigationContainer,
  StackActions,
  useNavigation,
} from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { ColorSchemeName, Text, TouchableOpacity, View } from 'react-native';

import Colors, { Color } from 'src/constant/Color';
import useColorScheme from 'src/hooks/useColorScheme';
import ModalScreen from 'src/screens/ModalScreen';
import NotFoundScreen from 'src/screens/NotFoundScreen';
import {
  RootDrawerParamList,
  RootStackParamList,
  RootTabParamList,
  RootTabScreenProps,
} from 'types';
import LinkingConfiguration from 'src/navigation/LinkingConfiguration';
import {
  createDrawerNavigator,
  DrawerContentComponentProps,
  DrawerContentScrollView,
} from '@react-navigation/drawer';
import MyMembership from 'src/screens/MyMembership';
import BlockedProfiles from 'src/screens/BlockedProfiles';
import PhoneBook from 'src/screens/PhoneBook';
import RateUs from 'src/screens/RateUs';
import Help from 'src/screens/Help';
import { RootState } from 'src/store/store';
import { useDispatch, useSelector } from 'react-redux';
import SignIn from 'src/screens/SignIn';
import IntroSlider from 'src/screens/IntroSlider';
import StorageUtil from 'src/util/StorageUtil';
import OtherPersonProfile from 'src/screens/UserProfileScreen';
import HeaderBackButton from 'src/components/HeaderBackButton';
import HeaderTitle from 'src/components/HeaderTitle';
import DrawerHeader from 'src/components/DrawerHeader';
import CustomDrawerItem from 'src/components/CustomDrawerItem';
import { ProfileResp } from 'src/common/response/profile.resp';
import OnboardingDetails from 'src/screens/OnboardingScreen';
import EditProfile from 'src/screens/EditProfile';
import Welcome from 'src/screens/Welcome';
import HomeScreen from 'src/screens/HomeScreen';
import { useBottomSheetModal } from '@gorhom/bottom-sheet';
import Messenger from 'src/screens/Messenger';
import ModalProvider from 'src/components/modals/ModalProvider';
import { HeaderLeft } from 'src/components/HeaderLeft';
import HeaderRight from 'src/components/HeaderRight';
import PremiumMemberScreen from 'src/screens/PremiumMemberScreen';
import { GenUtil } from 'src/util/GenUtil';
import {
  ActivityScreenStack,
  EditProfileStack,
  SearchScreenStack,
  SettingsScreenStack,
} from './SharedNavigation';
import ForgetPasswordScreen from 'src/screens/ForgetPasswordScreen';
import EFonts from 'src/constant/EFonts';
import { setUser } from 'src/store/reducer/userReducer';
import Constant from 'src/constant/Constant';
import DynamicProfilesList from 'src/screens/DynamicProfileList';

export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  const { dismissAll: dissmissAllModals } = useBottomSheetModal();

  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'light' ? DefaultTheme : DarkTheme}
      onStateChange={() => dissmissAllModals()}
    >
      <RootNavigator />
      <ModalProvider />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

const Drawer = createDrawerNavigator<RootDrawerParamList>();

const displayNames: { [key: string]: string } = {
  data: 'Home',
  mymembership: 'My Membership',
  mylocation: 'My Location',
  partnerpreferences: 'Partner Preferences',
  blockedprofiles: 'Blocked Profiles',
  phonebook: 'Phone Book',
  settings: 'Settings',
  rateus: 'Rate Us',
  help: 'Help',
  notifications: 'Notifications',
  otherpersonprofile: 'Other Person Profile',
  birthday: 'Birth Day',
  addphotos: 'Add Photos',
  filter: 'Filter',
  kundli: 'Kundli',
  profilevisitors: 'Profile Visitors',
  dynamicprofilelist: 'Dynamic Profile',
  editprivacypolicyscreen: 'Privacy Policy',
};

const screensToRemoveFromDrawer = [
  'mymembership',
  'editprofile',
  'editphotoscreen',
  'editprivacypolicyscreen',
  'notifications',
  'activity',
  'otherpersonprofile',
  'dynamicprofileslist',
];

const removeScreenFromDrawer = (screenName: string) => {
  return screensToRemoveFromDrawer.includes(screenName);
};

const CustomDrawerContent = (props: DrawerContentComponentProps) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const handleLogOut = () => {
    dispatch(setUser(null));
    StorageUtil.removeKey(Constant.AUTH_TOKEN);
    StorageUtil.removeKey(Constant.FIREBASE_TOKEN);
    navigation.dispatch(StackActions.replace('welcome'));
  };

  return (
    <>
      <DrawerContentScrollView {...props} showsVerticalScrollIndicator={false}>
        <DrawerHeader {...props} />
        <View style={{ marginBottom: 10 }}>
          {props.state?.routeNames?.map((screenName: string, index) => {
            return removeScreenFromDrawer(screenName) ? null : (
              <CustomDrawerItem
                key={`${screenName}-${index}`}
                isActive={props.state.routeNames[props.state.index] === screenName}
                screenName={screenName}
                navigation={props.navigation}
                displayName={displayNames[screenName] ?? screenName}
              />
            );
          })}
        </View>
        <TouchableOpacity
          style={{ paddingVertical: 10, paddingHorizontal: 10, marginHorizontal: 10 }}
          onPress={handleLogOut}
        >
          <Text style={{ fontFamily: EFonts.SORA_SEMIBOLD, fontSize: 15, color: 'red' }}>
            Log out
          </Text>
        </TouchableOpacity>
        {/* INFO: Uncomment to get back default drawer list */}
        {/* <DrawerItemList {...props} /> */}
      </DrawerContentScrollView>
      <View
        style={{ paddingVertical: 10, paddingHorizontal: 10, marginHorizontal: 10, bottom: 10 }}
      >
        <Text style={{ fontFamily: EFonts.SORA_REGULAR, fontSize: 12, }}>{GenUtil.getAppVersion()}</Text>
      </View>
    </>
  );
};

function DrawerNavigator() {
  return (
    <>
      <Drawer.Navigator
        initialRouteName={'data'}
        useLegacyImplementation
        drawerContent={(props) => <CustomDrawerContent {...props} />}
      >
        <Drawer.Screen
          name="data"
          component={BottomTabNavigator}
          options={{ headerShown: false }}
        />
        <Drawer.Screen
          name="mymembership"
          component={MyMembership}
          options={{ headerLeft: () => <HeaderBackButton />, headerTitle: () => null }}
        />
        <Drawer.Screen
          name="partnerpreferences"
          component={EditProfile}
          options={{ headerShown: false, title: 'My Profile' }}
          initialParams={{ tabIndex: 1 }}
        />
        <Drawer.Screen
          name="blockedprofiles"
          component={BlockedProfiles}
          options={{ headerTitle: 'Blocked Profiles', title: 'Blocked Profiles' }}
        />
        <Drawer.Screen name="phonebook" component={PhoneBook} options={{ title: 'Phone Book' }} />
        <Drawer.Screen
          name="settings"
          component={SettingsScreenStack}
          options={{ headerShown: false }}
        />
        <Drawer.Screen
          name="rateus"
          component={RateUs}
          options={{ headerTitle: 'Rate Us', title: 'Rate Us' }}
        />
        <Drawer.Screen name="help" component={Help} options={{ title: 'Help' }} />
        <Drawer.Screen
          name="editprofile"
          component={EditProfileStack}
          options={{ headerShown: false }}
        />
        <Drawer.Screen
          name="otherpersonprofile"
          component={OtherPersonProfile}
          options={{ headerShown: false, title: 'MatriMilan' }}
        />
        <Drawer.Screen
          name="activity"
          component={ActivityScreenStack}
          options={{ headerShown: false }}
        />
      </Drawer.Navigator>
    </>
  );
}

function RootNavigator() {
  const user = useSelector((state: RootState) => state.user.user);
  const isFirstTimeLaunch: boolean = StorageUtil.isFirstTimeLaunch();

  return (
    <Stack.Navigator
      initialRouteName={
        user ? 'user' : isFirstTimeLaunch ? (GenUtil.isWEB() ? 'welcome' : 'intro') : 'welcome'
      }
    >
      {/* {user ? (
        <Stack.Screen name="user" component={DrawerNavigator} options={{ headerShown: false }} />
      ) : isFirstTimeLaunch ? (
        <Stack.Screen name="onboarding" component={IntroSlider} options={{ headerShown: false }} />
      ) : (
        <Stack.Screen name="welcome" component={Welcome} options={{ headerShown: false }} />
      )} */}
      {user && (
        <Stack.Screen name="user" component={DrawerNavigator} options={{ headerShown: false }} />
      )}
      <Stack.Screen
        name="intro"
        component={IntroSlider}
        options={{ headerShown: false, title: 'Intro - Matrimilan' }}
      />
      {!user && (
        <Stack.Screen
          name="welcome"
          component={Welcome}
          options={{ headerShown: false, title: 'Welcome - Matrimilan' }}
        />
      )}
      {!user && (
        <Stack.Screen
          name="login"
          component={SignIn}
          options={{ headerShown: false, title: 'Login - Matrimilan' }}
        />
      )}
      {!user && (
        <Stack.Screen
          name="onboardingdetails"
          component={OnboardingDetails}
          options={{ headerShown: false, title: 'Onboarding Details - Matrimilan' }}
        />
      )}
      {!user && (
        <Stack.Screen
          name="forgetpassowrd"
          component={ForgetPasswordScreen}
          options={{ headerShown: false, title: 'Forget Password - Matrimilan' }}
        />
      )}
      <Stack.Screen name="notfound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen name="Modal" component={ModalScreen} />
      </Stack.Group>
    </Stack.Navigator>
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */

function getIconName(label) {
  const iconMap = {
    home: 'home',
    activity: 'clock-o',
    search: 'search',
    messenger: 'comments',
    member: 'crown',
    upgrade: 'crown',
  };

  return iconMap[label.toLowerCase()]; // Replace 'default-icon' with a fallback icon name if needed
}

const BottomTab = createBottomTabNavigator<RootTabParamList>();

function BottomTabNavigator({ navigation }) {
  const colorScheme = useColorScheme();

  const userProfile: Partial<ProfileResp> = useSelector(
    (state: RootState) => state?.user?.userProfile,
  );

  const membershipTabTitle = userProfile?.isMembershipActive ? 'Member' : 'Upgrade';

  return (
    <BottomTab.Navigator
      initialRouteName="Home"
      screenOptions={({ route }) => ({
        tabBarActiveTintColor: Colors[colorScheme].tint,
        headerLeft: () => <HeaderLeft />,
        headerTitle: () => <HeaderTitle routeName={route.name} />,
        headerRight: () => <HeaderRight isNotification={true} />,
        tabBarLabelPosition: 'below-icon',
      })}
    >
      <BottomTab.Screen
        name="Home"
        component={HomeScreen}
        options={({ navigation }: RootTabScreenProps<'Home'>) => ({
          title: 'Home',
          tabBarIcon: ({ color }) => <TabBarIcon name="home" color={color} />,
        })}
      />
      <BottomTab.Screen
        name="Search"
        component={SearchScreenStack}
        options={{
          title: 'Search',
          tabBarIcon: ({ color }) => <TabBarIcon name="search" color={color} />,
          headerShown: false,
        }}
      />
      <BottomTab.Screen
        name="Messenger"
        component={Messenger}
        options={{
          title: 'Messenger',
          tabBarIcon: ({ color }) => <TabBarIcon name="comments" color={color} />,
          headerShown: false,
        }}
      />
      <BottomTab.Screen
        name="Membership"
        component={PremiumMemberScreen}
        options={{
          title: membershipTabTitle,
          tabBarIcon: ({ color }) => <TabBarIcon name="crown" color={color} />,
          headerRight: () => null,
        }}
      />
      <BottomTab.Screen
        name={'dynamicprofileslist'}
        component={DynamicProfilesList}
        options={{
          headerShown: false,
          tabBarStyle: {
            display: 'none',
          },
          tabBarButton: () => null,
        }}
      />
    </BottomTab.Navigator>
  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: React.ComponentProps<typeof FontAwesome>['name'];
  color: string;
  size?: number;
}) {
  const iconSize = props.size ?? 30;

  if (props.name === 'crown')
    return <FontAwesome5 size={25} style={{ marginBottom: -3 }} {...props} />;
  return <FontAwesome size={iconSize} style={{ marginBottom: -3 }} {...props} />;
}
