import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, TouchableOpacity } from 'react-native';
import { Text, View } from 'src/components/Themed';
import { RadioButton } from 'react-native-paper';
import { Color } from 'src/constant/Color';
import PrimaryButton from 'src/components/common/buttons/PrimaryButton';
import EFonts from 'src/constant/EFonts';
import { OrdersService } from 'src/service/OrderService';
import { Feather } from '@expo/vector-icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { MembershipResp } from 'src/common/response/membership.resp';
import { GenUtil } from 'src/util/GenUtil';
import PaymentSuccessModal from 'src/components/modals/PaymentSuccessModal';
import { useNavigation } from '@react-navigation/native';
import RazorpayCheckout from 'react-native-razorpay';
import { setUserProfile } from 'src/store/reducer/userReducer';
import { ProfileService } from 'src/service/ProfileService';
import { ProfileResp } from 'src/common/response/profile.resp';

const { width } = GenUtil.getDimension();

type RazorPaySuccess = {
  razorpay_order_id: string;
  razorpay_payment_id: string;
  razorpay_signature: string;
};

export default function BuyMembershipComp() {
  const [selectedMembership, setSelectedMembership] = useState<string | number>(undefined);
  const [memberships, setMemberships] = useState<MembershipResp[]>([]);
  const [isPaymentInProgress, setIsPaymentInProgress] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedBenefits, setSelectedBenefits] = useState<string[]>([]);

  const { user } = useSelector((state: RootState) => state.user);
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const fetchMemberships = async () => {
    try {
      const response = await OrdersService.listProducts();
      if (response.success) {
        setMemberships(response.data);
        setSelectedBenefits(response.data[0].membershipBenefitDetails);
        setSelectedMembership(response.data[0].id);
      } else {
        console.log(response.message, response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleVerifyPayment = async (res: RazorPaySuccess) => {
    try {
      const data = await OrdersService.verifyOrder(
        res.razorpay_order_id,
        res.razorpay_payment_id,
        res.razorpay_signature,
      );
      if (data.status === 201) {
        setShowSuccessModal(true);
        const updatedUserDetailsResponse = await ProfileService.getLoggedInUserDetails();
        dispatch(setUserProfile(updatedUserDetailsResponse?.data?.profile as ProfileResp));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchMemberships().catch((err) => {
      console.log('error', err);
    });
  }, []);

  const handleMembershipOrder = async () => {
    try {
      setIsPaymentInProgress(true);
      if (!selectedMembership) return;

      const response = await OrdersService.createOrder(selectedMembership);
      if (response.success) {
        const options = {
          key: 'rzp_test_ilGlCh2mEpGWS4',
          amount: response.data.amount,
          currency: 'INR',
          name: 'Matri Milan',
          order_id: response.data.razorpayOrderId,
          prefill: {
            name: user.name,
            email: user.email,
            contact: user.mobile,
          },
          theme: {
            color: Color.purple,
          },
        };

        RazorpayCheckout.open(options)
          .then(async (response: RazorPaySuccess) => {
            await handleVerifyPayment(response);
          })
          .catch((error: any) => {
            setIsPaymentInProgress(false);
            console.log({ error });
          });
      } else {
        setIsPaymentInProgress(false);
        console.log(response.message, response);
      }
    } catch (error) {
      setIsPaymentInProgress(false);
      console.log(error);
    }
  };

  return (
    <View style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={styles.benefitsListContainer}>
          <Text style={styles.benefitsListTitle}>{'Become premium member'}</Text>
          <Text style={styles.pageDesc}>{'What you will get in premium subscriptions?'}</Text>

          <View>
            {selectedBenefits?.map((value, key) => (
              <View key={key} style={styles.benefitsTextContainer}>
                <View style={styles.featherContainer}>
                  <Feather name={'check'} size={12} color={Color.white} />
                </View>
                <Text style={{ fontWeight: '500' }}>{value}</Text>
              </View>
            ))}
          </View>
        </View>
        <View style={styles.benefitsMap}>
          <RadioButton.Group
            onValueChange={(value) => setSelectedMembership(value)}
            value={selectedMembership as string}
          >
            {memberships.map((value, key) => (
              <TouchableOpacity
                activeOpacity={100}
                onPress={() => {
                  setSelectedMembership(value.id);
                  setSelectedBenefits(value.membershipBenefitDetails);
                }}
                key={key}
              >
                <View style={selectedMembership === value.id ? styles.selectedCard : styles.card}>
                  <View style={styles.radioButton}>
                    <RadioButton.Android
                      color={Color.purple}
                      value={value.id as string}
                      key={key}
                    />
                  </View>
                  <View style={styles.benefitsFirstFlex}>
                    <Text>
                      <Text style={styles.benefitsTitle}>{value.name}</Text>
                    </Text>
                    <View style={styles.benefitsDesc}>
                      <Text style={styles.benefitsDescItem}>
                        {value.membershipBenefits.contacts &&
                          `Get upto ${value.membershipBenefits.contacts} Contacts`}
                      </Text>
                      <Text style={styles.benefitsDescItem}>
                        {value.membershipBenefits.highlights &&
                          `Get upto ${value.membershipBenefits.highlights} Highlights`}
                      </Text>
                      <Text style={styles.benefitsDescItem}>
                        {value.membershipBenefits.premiumInterests &&
                          `Send upto ${value.membershipBenefits.premiumInterests} Interests`}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.benefitsSecondFlex}>
                    <Text style={styles.price}>
                      {value.currency} {value.amount}
                    </Text>
                    <Text style={styles.benefitsDesc}>onwards</Text>
                  </View>
                </View>
              </TouchableOpacity>
            ))}
          </RadioButton.Group>
        </View>
      </ScrollView>
      <View style={styles.permiumButton}>
        <PrimaryButton
          buttonText={'Start Premium'}
          handleKeyPress={handleMembershipOrder}
          buttonDisabled={!selectedMembership || isPaymentInProgress}
          isValidating={isPaymentInProgress}
        />
      </View>
      {showSuccessModal ? (
        <PaymentSuccessModal
          visible={showSuccessModal}
          order={memberships.find((mem) => mem.id === selectedMembership)}
          onPress={() => {
            setShowSuccessModal(false);
            navigation.navigate('Home');
          }}
        />
      ) : (
        <></>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  benefitsListTitle: {
    fontSize: 27,
    color: Color.purple,
    fontFamily: EFonts.DMSANS_BOLD,
  },
  benefitsListContainer: {
    width: '100%',
    marginTop: 40,
    marginBottom: 20,
    paddingHorizontal: 20,
  },
  pageDesc: {
    fontSize: 22,
    fontFamily: EFonts.DMSANS_REGULAR,
    marginTop: 15,
    marginBottom: 20,
  },
  card: {
    width: width / 1.1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 20,
    padding: 20,
    elevation: 6,
    shadowColor: '#BEBEBE',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.22,
    shadowRadius: 24,
    borderRadius: 20,
    marginVertical: 10,
    borderColor: 'transparent',
    borderWidth: 3,
  },
  selectedCard: {
    width: width / 1.1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: 20,
    padding: 20,
    elevation: 6,
    shadowColor: '#BEBEBE',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.22,
    shadowRadius: 24,
    borderRadius: 20,
    borderColor: Color.purple,
    borderWidth: 3,
  },
  benefitsTitle: {
    fontFamily: EFonts.DMSANS_BOLD,
    fontSize: 14,
  },
  benefitsText: {
    fontSize: 11,
    fontFamily: EFonts.DMSANS_REGULAR,
    alignSelf: 'flex-end',
  },
  benefitsDesc: {
    fontFamily: EFonts.DMSANS_REGULAR,
    fontSize: 10,
    marginTop: 7,
  },
  price: {
    fontSize: 15,
    fontFamily: EFonts.DMSANS_MEDIUM,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 3,
  },
  benefitsMap: {
    marginBottom: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },
  benefitsSecondFlex: {
    flex: 8,
    alignItems: 'flex-end',
  },
  benefitsFirstFlex: {
    flex: 15,
  },
  permiumButton: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 15,
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  radioButton: {
    flex: 3,
    marginRight: 10,
    marginLeft: -10,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  featherContainer: {
    marginRight: 12,
    backgroundColor: Color.purple,
    width: 20,
    height: 20,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  benefitsTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 5,
  },
  benefitsDescItem: {
    color: Color.themeSilverGray,
    fontFamily: EFonts.DMSANS_REGULAR,
    fontSize: 11,
    marginLeft: 5,
    marginBottom: 3,
  },
});
