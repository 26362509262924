import { View, Text, StyleSheet,ScrollView } from 'react-native';
import React, { useState, useEffect } from 'react';

import PillShapeChip from 'src/components/common/chips/PillShapeChip';
import EFonts from 'src/constant/EFonts';
import { Color } from 'src/constant/Color';
import { GenUtil } from 'src/util/GenUtil';
import {ChildStatus, MaritalStatus} from 'src/common/models/profile.model'

interface option {
    key: string,
    value: string,
}
const { width } = GenUtil.getDimension();

const MaritalStatusComp = ({
  next = () => {},
  prev = () => {},
  maritalStatus = null,
  setMaritalStatus = (_) => {},
  childStatus = null,
  setChildStatus = (_) => {},
}) => {
  const [maritalStatusOption, setMaritalStatusOption] = useState<option[]>();
  const [maritalSelected, setMaritalSelected] = useState(maritalStatus ? [maritalStatus] : []);

  const [childStatusOption, setChildStatusOption] = useState<option[]>();
  const [childStatusSelected, setChildStatusSelected] = useState(childStatus ? [childStatus] : []);

  const [enableChildInfo, setEnableChildInfo] = useState<boolean>(false);

  const generateOptions = (enumObject) => {
    return Object.keys(enumObject).map((key) => ({ key: enumObject[key], value: enumObject[key] }));
  };
  
  useEffect(() => {
    setMaritalStatusOption(generateOptions(MaritalStatus));
    setChildStatusOption(generateOptions(ChildStatus));
  }, []);

  useEffect(() => {
    if(maritalSelected.length > 0 ) {
      setEnableChildInfo(maritalSelected[0] !== MaritalStatus.NeverMarried);
      setMaritalStatus(maritalSelected[0])
    }
    if(childStatusSelected.length > 0){
      setChildStatus(childStatusSelected[0])
    }
  }, []);

  const maritalChipClicked = (selectedValue) => {
    if(selectedValue) {
      setMaritalStatus(selectedValue)
      if(selectedValue !== MaritalStatus.NeverMarried){
        setEnableChildInfo(true)
      }else{
        setEnableChildInfo(false)
        setTimeout(() => next(),500)
      }
    }
  }

  const childChipClicked = (selectedValue) => {
    if(selectedValue){
      setChildStatus(selectedValue);
      setTimeout(()=>next(),500);
    }
  }

  return (
    <View style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={styles.item}>
          <Text style={styles.title}>Your Marital Status</Text>
          <PillShapeChip
            options={maritalStatusOption}
            selected={maritalSelected}
            setSelected={setMaritalSelected}
            callChipClicked={true}
            chipClicked={maritalChipClicked}
          />
        </View>
        {enableChildInfo && (
          <View>
            <Text style={styles.title}>Children Status</Text>
            <PillShapeChip
                options={childStatusOption}
                selected={childStatusSelected}
                setSelected={setChildStatusSelected}
                callChipClicked={true}
                chipClicked={childChipClicked}
            /> 
          </View>
        )}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
    title: {
      fontFamily: EFonts.SORA_LIGHT,
      fontSize: 28,
      lineHeight: 38,
      color: Color.lightBlack,
      marginTop: 10,
    },
    container: {
      paddingTop: 20,
      flex: GenUtil.isWEB() ? null : 1,
      paddingHorizontal: width * 0.05,
      backgroundColor: 'transparent',
    },
    item: {
      marginBottom: 25
    }
  });

export default MaritalStatusComp;
