import { View } from 'src/components/Themed';
import { StyleSheet } from 'react-native';
import React, { useState, useEffect, useRef } from 'react';
import ChoicesSelector from 'src/components/editProfileComponents/ChoicesSelector';
import { ReligionData } from 'src/common/data/religion.data';
import { ReligionType } from 'src/common/models/profile.model';
import SaveButton from '../SaveButton';
import { GenUtil } from 'src/util/GenUtil';
import EFonts from 'src/constant/EFonts';
import DOESNT_MATTER_OPTION from 'src/constant/DoesntMatter';

interface CommunityData {
  name: string;
  id: number;
  religionId: number;
  order: number;
}

export default function CommunitySelectionComp({
  partnerReligions = [],
  updatePartnerPref,
  partnerCommunities = [],
}: {
  partnerReligions: ReligionType[];
  updatePartnerPref: (_) => Promise<void>;
  partnerCommunities: number[];
  partnerSubCommunities: number[];
}) {
  const [communityData, setCommunityData] = useState([] as CommunityData[]);
  const [selectedCommunities, setSelectedCommunities] = useState([]);
  const [selectedCommunitiesIds, setSelectedCommunitiesIds] = useState(partnerCommunities ?? []);
  const [isValidating, setIsValidating] = useState(false);
  const [initializingSelectedCommunities, setInitializingSelectedCommunities] = useState(true);

  useEffect(() => {
    const communityList = GenUtil.filterAndExtractValues(
      ReligionData,
      partnerReligions,
      'name',
      'communityMap',
    );
    setCommunityData([DOESNT_MATTER_OPTION, ...communityList]);

    if (initializingSelectedCommunities) {
      let tempSelectedComm = [];
      communityList.map((community) => {
        if (partnerCommunities?.includes(community.id)) tempSelectedComm.push(community.name);
      });
      if (!tempSelectedComm.length) tempSelectedComm = [DOESNT_MATTER_OPTION.name];
      setSelectedCommunities(tempSelectedComm);
      setInitializingSelectedCommunities(false);
    }
  }, [partnerReligions]);

  useEffect(() => {
    const tempCommIds: number[] = [];
    if (selectedCommunities.length) {
      communityData.map((res) => {
        if (selectedCommunities.includes(res.name)) tempCommIds.push(res.id);
      });
    }
    setSelectedCommunitiesIds(tempCommIds);
  }, [selectedCommunities]);

  const onPressDone = async () => {
    setIsValidating(true);
    await updatePartnerPref({
      communityIds:
      selectedCommunities[0] === DOESNT_MATTER_OPTION.name ? [] : selectedCommunitiesIds,
    });
    setIsValidating(false);
  };

  return (
    <View style={{ bottom: 20 }}>
      <ChoicesSelector
        dropdownItems={communityData.map((res) => res.name)}
        placeHolder="Select communities"
        selectedValues={selectedCommunities}
        setSelectedValues={setSelectedCommunities}
        title='Community'
        isDoneValidating={isValidating}
        onPressDone={onPressDone}
      />
    </View>
  );
}
