import { ActivityIndicator, FlatList, View, Text } from 'react-native';
import React, { useEffect, useState } from 'react';
import { ProfileService } from 'src/service/ProfileService';
import { ProfileResp } from 'src/common/response/profile.resp';
import SmallDetailedProfileCard from 'src/components/common/profileCard/SmallDetailedProfileCard';
import Button from 'src/components/common/buttons/Button';
import HomeSection from 'src/components/common/HomeSection';
import { ProfileTabsEnum } from 'src/screens/DynamicProfileList';
import UserHeartSvg from 'src/assets/icons/user-heart.svg';
import RouteSvg from 'src/assets/icons/route.svg';
import UserTickSvg from 'src/assets/icons/user-tick.svg';
import { Color } from 'src/constant/Color';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { RecommendedType } from 'src/common/models/profile.model';
import { GrantPermission, PermissionType } from './GrantPermission';
import useLocation from 'src/hooks/useLocation';
import { HttpResponse } from 'src/common/response/base.resp';

type ProfileSection = {
  label: LABELS;
  icon: (color: string) => React.ReactElement;
};

const ICON_MARGIN = 10;

enum LABELS {
  SIMILAR_PROFILES = 'Similar Profiles',
  NEAR_YOU = 'Near you',
  RECENTLY_JOINED = 'Recently joined',
}

const PROFILE_SECTION: ProfileSection[] = [
  {
    label: LABELS.SIMILAR_PROFILES,
    icon: (color: string) => <UserHeartSvg style={{ color, marginRight: ICON_MARGIN }} />,
  },
  {
    label: LABELS.NEAR_YOU,
    icon: (color: string) => <RouteSvg style={{ color, marginRight: ICON_MARGIN }} />,
  },
  {
    label: LABELS.RECENTLY_JOINED,
    icon: (color: string) => <UserTickSvg style={{ color, marginRight: ICON_MARGIN }} />,
  },
];

const API_REQUESTS = {
  [LABELS.NEAR_YOU]: async () =>
    await ProfileService.fetchRecommendedProfiles({
      recommendedType: RecommendedType.Nearby,
      lat: '28.6139',
      lng: '77.2090',
    }),
  [LABELS.SIMILAR_PROFILES]: async (uuid: string) => await ProfileService.fetchSimilarProfile(uuid),
  [LABELS.RECENTLY_JOINED]: async () =>
    await ProfileService.fetchRecommendedProfiles({
      recommendedType: RecommendedType.RecentlyJoined,
    }),
};

const RecommendedProfilesCard = () => {
  const [recommendedProfiles, setRecommendedProfiles] = useState<ProfileResp[]>(null);
  const [selectedProfileType, setSelectedProfileType] = useState<LABELS>(PROFILE_SECTION[0].label);
  const { uuid: profileUuid } = useSelector((state: RootState) => state.user.userProfile);
  const { askPermission, location, permissionGranted: locationPermissionGranted } = useLocation();

  const fetchRecommendedProfiles = async () => {
    setRecommendedProfiles(null);
    try {
      let response: HttpResponse<ProfileResp[]>;

      if (selectedProfileType === LABELS.NEAR_YOU) {
        if (!locationPermissionGranted) return;
        response = await ProfileService.fetchRecommendedProfiles({
          recommendedType: RecommendedType.Nearby,
          lat: location.latitude,
          lng: location.longitude,
        });
      } else {
        const fetchRequest = API_REQUESTS[selectedProfileType];
        response = await fetchRequest(profileUuid);
      }

      if (response.success) {
        setRecommendedProfiles(response.data);
      } else {
        setRecommendedProfiles([]);
        console.log(response.message, response);
      }
    } catch (error) {
      setRecommendedProfiles([]);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchRecommendedProfiles();
  }, [selectedProfileType, locationPermissionGranted]);

  const renderChips = (section: ProfileSection, isSelected: boolean) => {
    const { label, icon } = section;

    return (
      <Button
        onPress={() => setSelectedProfileType(section.label)}
        size={'small'}
        variant={isSelected ? 'primary' : 'secondary'}
        styles={{ marginRight: 5 }}
        beforeIcon={icon(isSelected ? Color.white : Color.purple)}
      >
        {label}
      </Button>
    );
  };

  return (
    <HomeSection
      title={'Recommended Profiles'}
      navigateOnViewAll={{
        to: 'dynamicprofileslist',
        state: {
          title: 'Recommended Profiles',
          tabs: [
            ProfileTabsEnum.SimilarProfiles,
            ProfileTabsEnum.Nearyou,
            ProfileTabsEnum.RecentlyJoined,
          ],
        },
      }}
    >
      <FlatList
        data={PROFILE_SECTION}
        renderItem={({ item }) => renderChips(item, item.label === selectedProfileType)}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        keyExtractor={(item) => item.label}
        style={{ marginBottom: 15 }}
      />
      {selectedProfileType === LABELS.NEAR_YOU && !locationPermissionGranted ? (
        <GrantPermission
          permissionType={PermissionType.LocationForeground}
          requestPermission={askPermission}
        />
      ) : (
        <>
          {recommendedProfiles ? (
            recommendedProfiles.length > 0 ? (
              <FlatList
                data={recommendedProfiles}
                renderItem={({ item }) => <SmallDetailedProfileCard item={item} />}
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                keyExtractor={(item) => item.uuid}
              />
            ) : (
              <View style={{ minHeight: 100, alignItems: 'center', justifyContent: 'center' }}>
                <Text>No Profiles found</Text>
              </View>
            )
          ) : (
            <View style={{ minHeight: 100, justifyContent: 'center' }}>
              <ActivityIndicator />
            </View>
          )}
        </>
      )}
    </HomeSection>
  );
};

export default RecommendedProfilesCard;
