import {
  ActivityIndicator,
  ImageBackground,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { Color } from 'src/constant/Color';
import React, { useEffect, useState } from 'react';
import { ProfileResp } from 'src/common/response/profile.resp';
import { ProfileService } from 'src/service/ProfileService';
import { AvatarGroup } from 'src/components/common/avatar';
import LockSvg from 'src/assets/icons/lock.svg';
import { ProfileTabsEnum } from 'src/screens/DynamicProfileList';
import { useNavigation } from '@react-navigation/native';
import { GenUtil } from 'src/util/GenUtil';

const ProfileViewsCard = ({ hideProfiles }: { hideProfiles: boolean }) => {
  const navigation = useNavigation();
  const [profilesData, setProfilesData] = useState<ProfileResp[]>([]);
  const fetchProfileViews = async () => {
    try {
      const response = await ProfileService.getProfileViewed();
      if (response.success) {
        setProfilesData(response.data ?? []);
      } else {
        console.log(response.message, response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchProfileViews();
  }, []);

  const handlePress = () => {
    if (hideProfiles) {
      const membershipScreen = GenUtil.isDesktopWeb() ? 'membership' : 'Membership';
      return navigation.navigate(membershipScreen);
    }

    navigation.navigate('dynamicprofileslist', {
      title: 'Profile Viewed',
      tabs: [ProfileTabsEnum.ProfileViewed],
    });
  };

  return (
    <TouchableOpacity 
      style={styles.container}
      activeOpacity={100}
      onPress={profilesData && profilesData.length > 0 ? handlePress : () => {}}
    >
      <ImageBackground
        source={require('src/assets/images/background/lines.png')}
        style={styles.imgBg}
      >
        <View style={styles.header}>
          <Text style={styles.textHeading}>Profile You Viewed</Text>
          {hideProfiles && <LockSvg />}
        </View>
        <View style={styles.avatarContainer}>
          {profilesData ? (
            profilesData.length > 0 ? (
              <AvatarGroup
                data={profilesData.map((p) => GenUtil.getProfilePic(p))}
                blurImages={hideProfiles}
                showRemainingNumber={hideProfiles}
              />
            ) : (
              <View style={{ height: '100%', justifyContent: 'center' }}>
                <Text style={styles.actionText}>0 Profiles Viewed</Text>
              </View>
            )
          ) : (
            <View style={{ minHeight: 50, justifyContent: 'center' }}>
              <ActivityIndicator />
            </View>
          )}
        </View>
        {profilesData && profilesData.length > 0 && (
          <View>
            <Text style={styles.actionText}>{hideProfiles ? 'Upgrade Profile' : 'View All'}</Text>
          </View>
        )}
      </ImageBackground>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '49%',
    backgroundColor: '#FF725E',
    borderRadius: 12,
    height: 190,
  },
  imgBg: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 15,
  },
  header: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textHeading: {
    color: Color.white,
    fontWeight: '600',
    fontSize: 15,
    letterSpacing: -0.01,
    fontFamily: 'dmsans-bold',
    alignSelf: 'flex-start',
  },
  avatarContainer: {
    alignSelf: 'center',
  },
  action: {
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionText: {
    color: Color.white,
    fontFamily: 'dmsans-bold',
  },
});

export default ProfileViewsCard;
