/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from 'types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      user: {
        path: 'user',
        screens: {
          data: {
            screens: {
              Home: 'home',
              Membership: 'membership',
              Search: {
                screens: {
                  SearchScreen: 'search',
                  SearchedProfilesScreen: 'searchedprofilesscreen',
                  SearchFiltersScreen: 'searchfiltersscreen',
                },
              },
              Messenger: {
                path: 'chat',
                screens: {
                  InboxScreen: 'inboxscreen',
                  MessageScreen: 'messagescreen',
                },
              },
              dynamicprofileslist: 'dynamicprofileslist',
            },
          },
          editprofile: {
            screens: {
              editprofile: 'editprofile',
              editphotoscreen: 'editphotoscreen',
            },
          },
          settings: {
            screens: {
              settings: 'settings',
              editprivacypolicyscreen: 'editprivacypolicyscreen',
              notifications: 'notifications',
            },
          },
          activity: {
            screens: {
              ActivityScreen: 'activity',
            },
          },
          mymembership: 'mymembership',
          mylocation: 'mylocation',
          partnerpreferences: 'partnerpreferences',
          blockedprofiles: 'blockedprofiles',
          phonebook: 'phonebook',
          rateus: 'rateus',
          help: 'help',
          successstories: 'successstories',
          birthday: 'birthday',
          addphotos: 'addphotos',
          filter: 'filter',
          profile: 'profile',
          otherpersonprofile: 'otherpersonprofile',
          kundli: 'kundli',
        },
      },
      welcome: 'welcome',
      onboardingdetails: 'onboardingdetails',
      intro: 'intro',
      login: 'login',
      forgetpassowrd: 'forget',
      Modal: 'modal',
      notfound: '*',
    },
  },
};

export default linking;
