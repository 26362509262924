import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Entypo, Feather, MaterialCommunityIcons } from '@expo/vector-icons';
import { Text } from 'src/components/Themed';
import { Color } from 'src/constant/Color';
import { CircularProgressBase } from 'react-native-circular-progress-indicator';
import EFonts from 'src/constant/EFonts';
import { getDaysRemaining, getDayRemainingPercentage } from 'src/util/date';
import { ProfileMembershipDetailsResp } from 'src/common/response/profile-membership.resp';
import { GenUtil } from 'src/util/GenUtil';

export default function UserMembershipComp({
  membershipDetails,
}: {
  membershipDetails: Partial<ProfileMembershipDetailsResp>;
}) {
  const planStatus = 'Active';
  const benefits: string[] = membershipDetails?.membership?.membershipBenefitDetails;

  return (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        <View style={styles.iconContainer}>
          <MaterialCommunityIcons name="crown-outline" color={'#fa73be'} size={30} />
        </View>
        <Text style={styles.heading}>{"You're a premium member!"}</Text>
      </View>
      <View style={styles.card}>
        <View>
          <View style={styles.activeContainer}>
            <View style={styles.activeDot}></View>
            <Text style={styles.active}>{planStatus} Plan</Text>
          </View>

          <Text style={styles.eRishta}>{membershipDetails?.membership?.name}</Text>
          <Text style={styles.validTill}>
            Valid till: {GenUtil.formatTimeAccDuration(membershipDetails?.membershipExpiredOn)}
          </Text>
          <View style={styles.benefitsContainer}>
            <Text style={styles.benefitHeading}>Current Benefits</Text>
            {benefits &&
              benefits.map((value, key) => (
                <View key={key} style={styles.benefitsTextContainer}>
                  <View style={styles.featherContainer}>
                    <Feather name={'check'} size={12} color={Color.white} />
                  </View>
                  <Text style={styles.benefits}>{value}</Text>
                </View>
              ))}
          </View>
        </View>
        <View style={styles.circularProgressContainer}>
          <TouchableOpacity style={styles.circularProgressTouchable}>
            <CircularProgressBase
              value={getDayRemainingPercentage(membershipDetails?.membershipExpiredOn)}
              radius={40}
              duration={1000}
              activeStrokeColor={Color.green}
              inActiveStrokeColor={Color.backgroundGray}
              activeStrokeWidth={7}
              inActiveStrokeWidth={7}
              maxValue={100}
            />
            <View style={styles.infinityIconStyle}>
              <Entypo name="infinity" size={35} />
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  titleContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 20,
  },
  iconContainer: {
    backgroundColor: '#ffe3fd',
    borderRadius: 23,
    width: 45,
    height: 45,
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading: {
    fontSize: 18,
    fontFamily: EFonts.DMSANS_MEDIUM,
    marginTop: 10,
  },
  activeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  active: {
    fontFamily: EFonts.DMSANS_MEDIUM,
    fontSize: 10,
    marginLeft: 7,
  },
  activeDot: {
    backgroundColor: Color.green,
    width: 5,
    height: 5,
    borderRadius: 3,
  },
  eRishta: {
    fontFamily: EFonts.DMSANS_BOLD,
    fontSize: 17,
    marginTop: 10,
  },
  benefitHeading: {
    fontFamily: EFonts.DMSANS_MEDIUM,
    fontSize: 14,
    marginBottom: 5,
  },
  benefits: {
    fontSize: 12,
    fontFamily: EFonts.DMSANS_REGULAR,
    marginLeft: 5,
  },
  validTill: {
    fontFamily: EFonts.DMSANS_REGULAR,
    fontSize: 11,
  },
  benefitsContainer: {
    marginTop: 70,
  },
  card: {
    width: '90%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 20,
    padding: 20,
    elevation: 6,
    shadowColor: '#BEBEBE',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.22,
    shadowRadius: 24,
    borderRadius: 20,
    marginVertical: 10,
    height: 120,
    backgroundColor: '#fff',
  },
  benefitsTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 3,
  },
  circularProgressContainer: {
    flex: 1,
    alignItems: 'flex-end',
  },
  circularProgressTouchable: {
    flex: 1,
    justifyContent: 'center',
  },
  infinityIconStyle: {
    position: 'absolute',
    marginVertical: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  featherContainer: {
    marginRight: 5,
    backgroundColor: Color.purple,
    width: 20,
    height: 20,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
});
