import React, { useState, useEffect } from 'react';
import { Alert, StyleSheet, ImageBackground, ScrollView } from 'react-native';

import OnboardingNameAndDOBComp from 'src/components/onboarding-components/OnboardingNameAndDOBComp';
import OnboardingLocationInputComp from 'src/components/onboarding-components/OnboardingLocationInputComp';
import OnboardingRelationSelectionComp from 'src/components/onboarding-components/OnboardingRelationSelectionComp';
import OnboardinPhoneInputComp from 'src/components/onboarding-components/OnboardingPhoneInputComp';
import OnboardingOTPComp from 'src/components/onboarding-components/OnboardingOTPComp';
import OnboardingDegreeSelectionComp from 'src/components/onboarding-components/OnboardingDegreeSelectionComp';
import OnboardingReligionSelectionComp from 'src/components/onboarding-components/OnboardingReligionSelectionComp';
import OnboardingSubCommunityInputComp from 'src/components/onboarding-components/OnboardingSubCommunityInputComp';
import OnboardingProfessionSelectionComp from 'src/components/onboarding-components/OnboardingProfessionSelectionComp';
import OnboardingSelectedDetailsComp from 'src/components/onboarding-components/OnboardingSelectedDetailsComp';
import OnboardingHeaderComp from 'src/components/onboarding-components/OnboardingHeaderComp';
import OnboardingPasswordInputComp from 'src/components/onboarding-components/OnboardingPasswordInputComp';
import OnboardingMaritalStatusComp from 'src/components/onboarding-components/OnboardingMaritalStatusComp'

import { useAutoCompleteLocation } from 'src/hooks/useAutoCompleteLocation';
import { SearchLocationResp } from 'src/common/response/location.resp';
import { ChildStatus, Gender, MaritalStatus, ReligionType } from 'src/common/models/profile.model';
import { ReligionData } from 'src/common/data/religion.data';
import { GenUtil } from 'src/util/GenUtil';
import { SignupBody } from 'src/common/dto/user.dto';
import { ProfessionId, QualificationId } from 'src/common/data/education.data';
import { UserRelation } from 'src/common/models/user.model';
import { AuthService } from 'src/service/AuthService';
import { LocationInf, LocationType } from 'src/common/models/location.model';
import { useDispatch } from 'react-redux';
import { AlertTypeEnum, showError } from 'src/store/reducer/errorReducer';

const steps = [
  'enterphonenumber',
  'enterotp',
  'enterpassword',
  'lookingFor',
  'info',
  'location',
  'religion',
  'subcommunity',
  'profession',
  'maritalstatus',
  'degree',
];

//hardcoded the major cities to show on the screen when the input is empty
export const majorCities: Partial<SearchLocationResp>[] = [
  {
    lat: 12.97194,
    lng: 77.59369,
    locationType: LocationType.City,
    cityName: 'Bengaluru',
    stateName: 'Karnataka',
    countryName: 'India',
    fullName: 'Bengaluru, Karnataka, India',
  },
  {
    lat: 13.08784,
    lng: 80.27847,
    locationType: LocationType.City,
    cityName: 'Chennai',
    stateName: 'Tamil Nadu',
    countryName: 'India',
    fullName: 'Chennai, Tamil Nadu, India',
  },
  {
    lat: 17.38405,
    lng: 78.45636,
    locationType: LocationType.City,
    cityName: 'Hyderabad',
    stateName: 'Telangana',
    countryName: 'India',
    fullName: 'Hyderabad, Telangana, India',
  },
  {
    lat: 22.54111111,
    lng: 88.33777778,
    locationType: LocationType.City,
    cityName: 'Kolkata',
    stateName: 'West Bengal',
    countryName: 'India',
    fullName: 'Kolkata, West Bengal, India',
  },
  {
    lat: 19.07283,
    lng: 72.88261,
    locationType: LocationType.City,
    cityName: 'Mumbai',
    stateName: 'Maharashtra',
    countryName: 'India',
    fullName: 'Mumbai, Maharashtra, India',
  },
  {
    lat: 28.63576,
    lng: 77.22445,
    locationType: LocationType.City,
    cityName: 'New Delhi',
    stateName: 'Delhi',
    countryName: 'India',
    fullName: 'New Delhi, Delhi, India',
  },
];

const OnboardingDetails = ({ navigation }) => {
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(steps[0]);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  //LOOKING FOR:
  const [gender, setGender] = useState('' as Gender);
  const [profileRelationToUser, setProfileRelationToUser] = useState<string>('');
  const [lookingForGender, setLookingForGender] = useState('' as Gender);

  const [lookingFor, setLookingFor] = useState<string>('');
  const [userRelationToProfile, setUserRelationToProfile] = useState('' as UserRelation);

  //PHONE:
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('IN');
  const [country, setCountry] = useState({ cca2: 'IN', callingCode: ['91'] });
  //ENTER_OTP:
  const [otpToken, setOtpToken] = useState('');
  //INFO:
  const [name, setName] = useState('');
  const [tempName, setTempName] = useState(name);
  const [formattedDate, setFormattedDate] = useState('');
  const [infoDisabled, setInfoDisabled] = useState(true);
  //LOCATION:
  const [location, setLocation] = useState({} as Partial<SearchLocationResp>);
  const [locationSignUp, setLocationSignUp] = useState({} as LocationInf);
  const [locationData, searchedLocation, setSearchedLocation] = useAutoCompleteLocation(
    500,
    majorCities,
  );
  const locationArray = locationData;
  const locationArrayFilter = locationData?.map((value) => {
    const val = GenUtil.getLocationName(
      value?.cityName,
      value?.stateName,
      value?.countryName,
      value?.locationType,
    );
    return { key: String(value?.lat) + String(value?.lng), value: val };
  });

  //RELIGION:
  const [religion, setReligion] = useState('' as ReligionType);
  // SUBCOMMUNITY:
  const [subCommunity, setSubCommunity] = useState(
    null as { name: string; id: number; religionId: number; order: number },
  );
  //DEGREE:
  const [degree, setDegree] = useState(
    null as {
      name: string;
      id: QualificationId;
      category: string;
    },
  );
  const [signUpValidating, setSignUpValidating] = useState(false);
  //PROFESSION
  const [profession, setProfession] = useState(
    null as { name: string; id: ProfessionId; category: string },
  );
  //MARITAL STATUS
  const [maritalStatus, setMaritalStatus] = useState('' as MaritalStatus)
  const [childStatus, setChildStatus] = useState('' as ChildStatus)
  //PASSWORD
  const [password, setPassword] = useState('');
  const [confirmPassword, setconfirmPassword] = useState('');
  const [height, setHeight] = useState();

  const next = () => {
    const index = steps.findIndex((s) => s === activeStep);
    let skipped = false;
    if (index === 6) {
      ReligionData.map((res) => {
        if (
          res.name === religion &&
          (res.name === ReligionType.Other || GenUtil.isEmpty(res.communityMap))
        ) {
          setActiveStep(steps[index + 2]);
          setSubCommunity(null);
          skipped = true;
        }
      });
    }
    if (!skipped && index !== -1 && index < steps.length - 1) {
      setActiveStep(steps[index + 1]);
      setActiveStepIndex(index + 1);
    }
    return;
  };

  const prev = () => {
    const index = steps.findIndex((s) => s === activeStep);
    let skipped = false;
    if (index === 8 && religion) {
      ReligionData.map((res) => {
        if (
          res.name === religion &&
          (res.name === ReligionType.Other || GenUtil.isEmpty(res.communityMap))
        ) {
          setActiveStep(steps[index - 2]);
          skipped = true;
        }
      });
    }
    if (!skipped && index !== -1 && index > 0) {
      setActiveStep(steps[index - 1]);
      setActiveStepIndex(index - 1);
    } else if (index === 0) {
      navigation.navigate('welcome');
    }
    return;
  };

  useEffect(() => {
    if (religion) {
      setTimeout(() => next(), 500);
      setSubCommunity(null);
    }
  }, [religion]);

  useEffect(() => {
    if (gender === Gender.Female) {
      setHeight(162);
    } else {
      setHeight(175);
    }
  }, [gender]);

  const checkUser = async ({ mobile = '' }) => {
    try {
      if (mobile) {
        const body = {
          mobile,
        };
        const response = await AuthService.eligibleOTP(body);
        return response;
      }
    } catch (error) {
      console.log(error);
      dispatch(
        showError({ message: GenUtil.getMessageFromError(error), alertType: AlertTypeEnum.Error }),
      );
    }
  };

  const signUp = async () => {
    if (otpToken) {
      try {
        setSignUpValidating(true);
        const body: Partial<SignupBody> = {
          name,
          profileName: name?.split(' ')[0],
          religion,
          password,
          otpToken,
          communityId: subCommunity.id,
          professionId: profession.id,
          highestQualificationId: degree.id,
          userRelation: userRelationToProfile,
          lookingFor: lookingForGender,
          currentLocation: locationSignUp,
          mobile: '+' + country?.callingCode[0] + phoneNumber,
          dob: formattedDate,
          height,
          maritalStatus,
          childStatus,
        };
        const response = await AuthService.signUpUser(body);
        console.log(response);
        setSignUpValidating(false);
        navigation.navigate('login');
        dispatch(
          showError({ message: 'Signed up, please login', alertType: AlertTypeEnum.Success }),
        );
      } catch (error) {
        dispatch(
          showError({
            message: GenUtil.getMessageFromError(error),
            alertType: AlertTypeEnum.Error,
          }),
        );
        console.log(error);
        setSignUpValidating(false);
      }
    }
  };

  return (
    <ImageBackground
      source={
        activeStep === 'lookingFor'
          ? require('src/assets/images/onboardingImages/bg1.png')
          : activeStep === 'info'
          ? require('src/assets/images/onboardingImages/bg2.png')
          : require('src/assets/images/onboardingImages/bg4.png')
      }
      style={{ flex: 1 }}
    >
      <OnboardingHeaderComp prev={prev} />
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.scrollViewContainer}
      >
        {activeStepIndex > 0 && tempName && (
          <OnboardingSelectedDetailsComp
            name={tempName}
            gender={gender}
            birthDay={formattedDate}
            location={location}
            religion={{ religion, subCommunity }}
            profession={profession}
            maritalStatus={maritalStatus}
            childStatus={childStatus}
            degree={degree}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        )}
        {activeStep === 'lookingFor' && (
          <OnboardingRelationSelectionComp
            next={next}
            prev={prev}
            gender={gender}
            setGender={setGender}
            profileRelationToUser={profileRelationToUser}
            setProfileRelationToUser={setProfileRelationToUser}
            lookingFor={lookingFor}
            setLookingFor={setLookingFor}
            userRelationToProfile={userRelationToProfile}
            setUserRelationToProfile={setUserRelationToProfile}
            lookingForGender={lookingForGender}
            setLookingForGender={setLookingForGender}
          />
        )}
        {activeStep === 'info' && (
          <OnboardingNameAndDOBComp
            relation={profileRelationToUser}
            gender={gender}
            next={next}
            prev={prev}
            name={name}
            setName={setName}
            setTempName={setTempName}
            formattedDate={formattedDate}
            setFormattedDate={setFormattedDate}
            infoDisabled={infoDisabled}
            setInfoDisabled={setInfoDisabled}
            height={height}
            setHeight={setHeight}
          />
        )}
        {activeStep === 'degree' && (
          <OnboardingDegreeSelectionComp
            next={next}
            prev={prev}
            degree={degree}
            setDegree={setDegree}
            signUp={signUp}
            signUpValidating={signUpValidating}
          />
        )}
        {activeStep === 'enterphonenumber' && (
          <OnboardinPhoneInputComp
            phoneNumber={phoneNumber}
            countryCode={countryCode}
            country={country}
            setPhoneNumber={setPhoneNumber}
            setCountryCode={setCountryCode}
            setCountry={setCountry}
            next={next}
            prev={prev}
            checkUser={checkUser}
            setActiveStep={setActiveStep}
            navigateToSignIn={() => navigation.navigate('login')}
          />
        )}
        {activeStep === 'enterotp' && (
          <OnboardingOTPComp
            phoneNumber={phoneNumber}
            country={country}
            next={next}
            prev={prev}
            setOtpToken={setOtpToken}
          />
        )}
        {activeStep === 'location' && (
          <OnboardingLocationInputComp
            next={next}
            prev={prev}
            location={location}
            setLocation={setLocation}
            setLocationSignUp={setLocationSignUp}
            searchedLocation={searchedLocation}
            setSearchedLocation={setSearchedLocation}
            locationArray={locationArray}
            locationArrayFilter={locationArrayFilter}
          />
        )}
        {activeStep === 'religion' && (
          <OnboardingReligionSelectionComp
            prev={prev}
            next={next}
            religion={religion}
            setReligion={setReligion}
          />
        )}
        {activeStep === 'subcommunity' && (
          <OnboardingSubCommunityInputComp
            prev={prev}
            next={next}
            subCommunity={subCommunity}
            setSubCommunity={setSubCommunity}
            religion={religion}
          />
        )}
        {activeStep === 'profession' && (
          <OnboardingProfessionSelectionComp
            next={next}
            prev={prev}
            profession={profession}
            setProfession={setProfession}
          />
        )}
        {activeStep === 'maritalstatus' && (
          <OnboardingMaritalStatusComp 
            next={next}
            prev={prev}
            maritalStatus={maritalStatus}
            setMaritalStatus={setMaritalStatus}
            childStatus={childStatus}
            setChildStatus={setChildStatus}
          />
        )}
        {activeStep === 'enterpassword' && (
          <OnboardingPasswordInputComp
            next={next}
            prev={prev}
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setconfirmPassword={setconfirmPassword}
          />
        )}
      </ScrollView>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  scrollViewContainer: {
    flex: 1,
  },
});

export default OnboardingDetails;
