import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum ModalTypes {
  UserProfileOptionsModal = 'UserProfileOptionsModal',
  SendInterestModal = 'SendInterestModal',
  UserContactBottomSheet = 'UserContactBottomSheet',
  ProfileActionBottomSheet = 'ProfileActionBottomSheet',
}

type ModalState = {
  type: ModalTypes | null;
  childData: Record<string, any> | null;
};

export interface UiInitialState {
  modal: ModalState;
  refresh: boolean;
}

const initialState: UiInitialState = {
  modal: {
    type: null,
    childData: null,
  },
  refresh: false,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    showModal: (state, action: PayloadAction<ModalState>) => {
      state.modal = action.payload;
    },
    hideModal: (state) => {
      state.modal.type = null;
      state.modal.childData = null;
    },
    toggleRefresh: (state) => {
      state.refresh = !state.refresh;
    },
  },
});

export const { showModal, hideModal, toggleRefresh } = uiSlice.actions;

export default uiSlice.reducer;
