import { View } from 'src/components/Themed';
import React, { useState, useEffect, useRef } from 'react';
import ChoicesSelector from 'src/components/editProfileComponents/ChoicesSelector';
import { ReligionData } from 'src/common/data/religion.data';
import { ReligionType } from 'src/common/models/profile.model';
import { ProfileSearchBody } from 'src/common/dto/profile.dto';
import DOESNT_MATTER_OPTION from 'src/constant/DoesntMatter';

export default function ReligionSelectionComp({
  partnerReligions = [],
  updatePartnerPref,
  setSearchParams,
}: {
  partnerReligions: ReligionType[];
  updatePartnerPref: (_) => Promise<void>;
  setSearchParams?: (prevParams) => void;
}) {
  const religionData = [DOESNT_MATTER_OPTION.name, ...ReligionData.map((res) => res.name)];
  const [selectedReligions, setSelectedReligions] = useState(
    partnerReligions.length ? partnerReligions : [DOESNT_MATTER_OPTION.name],
  );
  const isInitialRender = useRef(true);

  useEffect(()=>{
    if(!isInitialRender.current){
      const selectedReligionsData = 
        selectedReligions[0] === DOESNT_MATTER_OPTION.name
          ? []
          : selectedReligions
      if(setSearchParams) {
        setSearchParams((prevParams: Partial<ProfileSearchBody>) => ({
          ...prevParams,
          ...{ religions: selectedReligionsData },
        }));
        return;
      }
      void updatePartnerPref({religions: selectedReligionsData})
    }else{
      isInitialRender.current = false;
    }
  },[selectedReligions])

  return (
    <View style={{ bottom: 20 }}>
      <ChoicesSelector
        dropdownItems={religionData}
        placeHolder="Select religions"
        selectedValues={selectedReligions}
        setSelectedValues={setSelectedReligions}
        title='Religion'
      />
    </View>
  );
}

