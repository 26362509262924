import { View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native';
import React, { useEffect, useState } from 'react';
import { ProfileResp } from 'src/common/response/profile.resp';
import { Color } from 'src/constant/Color';
import EFonts from 'src/constant/EFonts';
import { FontAwesome } from '@expo/vector-icons';
import { ProfileService } from 'src/service/ProfileService';
import { useDispatch } from 'react-redux';
import { AlertTypeEnum, showError } from 'src/store/reducer/errorReducer';
import { GenUtil } from 'src/util/GenUtil';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { MembershipPermissions, isFeatureEnabled } from 'src/common/data/membership.data';
import ConfirmationModal from 'src/components/modals/ConfirmationModal';

import VerticalFlashList from 'src/components/VerticalFlashList';
import { Icon } from 'react-native-elements';
import SafeContainer from 'src/components/SafeContainer';

const ActivityCard = ({ item }) => {
  const navigation = useNavigation();
  const showMoreThanWeekTime = true;

  const getImage = (profile: ProfileResp) => {
    const imageProps = { source: GenUtil.getProfilePic(profile) };

    return imageProps;
  };

  const imageProps = getImage(item?.profile);

  const handleOnPress = () => {
    navigation.navigate('otherpersonprofile', { uuid: item?.profile?.profileId });
  };

  return (
    <TouchableOpacity style={styles.activityContainer} onPress={handleOnPress}>
      <TouchableOpacity style={styles.imageContainer} onPress={handleOnPress}>
        <Image
          {...imageProps}
          style={{ height: 65, width: 65, borderRadius: 65 / 2 }}
          resizeMode="cover"
        />
      </TouchableOpacity>
      <View style={{ flex: 3.5, paddingLeft: 5 }}>
        <View style={{ width: '100%', height: '50%', justifyContent: 'center' }}>
          <Text style={styles.activityTextStyle} numberOfLines={2}>
            {item?.activityName}
          </Text>
        </View>
        <View style={styles.activityTimeContainer}>
          <FontAwesome name="clock-o" size={22} color={Color.gray} />
          <Text style={styles.activityTimeStyle}>
            {GenUtil.formatTimeAccDuration(item?.createdAt, showMoreThanWeekTime)}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const ActivityScreen = () => {
  const loggedInUserProfile = useSelector((state: RootState) => state?.user?.userProfile);
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [activities, setActivities] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const getActivity = async (page = 0) => {
    try {
      const response = await ProfileService.getProfileActivity(page);
      if (response.data.length === 0) return [];
      setCurrentPage(currentPage + 1);
      return response.data;
    } catch (error) {
      dispatch(
        showError({ message: GenUtil.getMessageFromError(error), alertType: AlertTypeEnum.Error }),
      );
    }
  };

  const fetchMore = async () => {
    try {
      if (activities.length === 0) return;

      const moreActivities = await getActivity(currentPage);
      setActivities([...activities, ...moreActivities]);
    } catch (error) {
      dispatch(
        showError({ message: GenUtil.getMessageFromError(error), alertType: AlertTypeEnum.Error }),
      );
    }
  };

  useEffect(() => {
    getActivity()
      .then((response) => {
        setActivities(response);
      })
      .catch((error) => console.log('error in fetching activities = ', error));
  }, []);

  const navigateToScreen = (screenName: string) => () => {
    if (screenName === 'back') {
      navigation.canGoBack()
        ? navigation.goBack()
        : navigation.navigate('data', { screen: 'home' });
    } else if (screenName === 'membership') {
      GenUtil.isDesktopWeb()
        ? navigation.navigate('membership')
        : navigation.navigate('data', { screen: 'Membership' });
    }
  };

  if (!isFeatureEnabled(loggedInUserProfile, MembershipPermissions.CanSeeActivityLog))
    return (
      <ConfirmationModal
        title="Upgrade to Premium"
        message="Unlock activity log insights with Premium Membership!"
        cancelButtonText="Cancel"
        primaryButtonText="Buy Premium"
        onCancel={navigateToScreen('back')}
        onPrimaryButtonPressed={navigateToScreen('membership')}
      />
    );

  return (
    <SafeContainer
      showPageHeader={true}
      heading="Profile Activities"
      leftIcon={<Icon name="arrow-back" />}
      onPressLeftIcon={navigateToScreen('back')}
      style={{ flex: 1 }}
    >
      <View style={{ flex: 1, padding: 10 }}>
        {activities?.length ? (
          <VerticalFlashList
            data={activities}
            renderItem={({ item, index }) => (
              <View style={{ marginVertical: 5 }} key={index}>
                <ActivityCard item={item} />
              </View>
            )}
            onEndReached={fetchMore}
            estimatedItemSize={90}
          />
        ) : null}
      </View>
    </SafeContainer>
  );
};

const styles = StyleSheet.create({
  activityContainer: {
    width: '100%',
    height: 80,
    borderWidth: 1,
    borderColor: Color.lightBorder,
    flexDirection: 'row',
    backgroundColor: Color.white,
    borderRadius: 12,
  },
  imageContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  activityTextStyle: {
    fontSize: 13,
    fontFamily: EFonts.SORA_REGULAR,
    color: Color.lightBlack,
  },
  activityTimeContainer: {
    width: '100%',
    height: '50%',
    alignItems: 'center',
    flexDirection: 'row',
  },
  activityTimeStyle: {
    marginLeft: 10,
    fontSize: 12,
    color: Color.lightGrey,
    fontFamily: EFonts.SORA_REGULAR,
  },
});

export default ActivityScreen;
