import { StyleProp, Text, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import { Color } from 'src/constant/Color';
import EFonts from 'src/constant/EFonts';
import { GenUtil } from 'src/util/GenUtil';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import { useMemo, useState } from 'react';
import { DatePicker, TimePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

interface Props {
  /**
   * Represents the selected date value for the DateField component
   */
  date: Date | string;

  /**
   * Callback function that is called when the date value is changed.
   */
  onChange: (date: Date) => void;

  /**
   * Optional placeholder text to display when no date is selected.
   */
  placeholder?: string;

  /**
   * Optional React node to display as the previous icon.
   */
  prevIcon?: React.ReactNode;

  /**
   * Optional React node to display as the next icon.
   */
  nextIcon?: React.ReactNode;

  /**
   * Style object to customize the container of the DateField component.
   */
  constainerStyle?: ViewStyle;

  /**
   * Style object to customize the text style of the DateField component.
   */
  textStyle?: TextStyle;

  /**
   * Style object to customize the style of the previous icon.
   */
  iconStyle?: ViewStyle;

  /**
   * Style object to customize the style of the next icon.
   */
  iconNextStyle?: ViewStyle;

  /**
   * Specifies the mode of the DateField component, which can be 'date', 'time', or 'datetime'.
   */
  mode: 'date' | 'time' | 'datetime';
}

const { width } = GenUtil.getDimension();

export const DateField = ({
  date,
  prevIcon,
  nextIcon,
  onChange,
  placeholder,
  mode,
  constainerStyle,
  textStyle,
  iconStyle,
  iconNextStyle,
}: Props) => {
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);
  const currentDate = dayjs().startOf('day').toDate();
  const isWeb = useMemo(() => GenUtil.isWEB(), []);

  const showDatePicker = () => setDatePickerVisible(true);

  const hideDatePicker = () => setDatePickerVisible(false);

  const handleConfirm = (date: Date) => {
    onChange(date);
    hideDatePicker();
  };

  const formatDate = (date: Date | string) => {
    switch (mode) {
      case 'date':
        return dayjs(date).format('DD/MM/YYYY');
      case 'time':
        return dayjs(date).format('HH:mm A');
      case 'datetime':
        return dayjs(date).format('DD/MM/YYYY HH:mm');
      default:
        return '';
    }
  };

  const disabledDate = (date: Dayjs): boolean => {
    return date.isAfter(dayjs());
  };

  if (isWeb) {
    const DateTime = mode === 'date' ? DatePicker : mode === 'time' ? TimePicker : View;
    return (
      <View style={[$wrapperPicker, constainerStyle]}>
        {prevIcon && <TouchableOpacity style={[$icon, iconStyle]}>{prevIcon}</TouchableOpacity>}
        <DateTime
          style={{ width: '100%' }}
          onChange={handleConfirm}
          format={formatDate}
          value={date ? dayjs(date) : ''}
          suffixIcon={<></>}
          allowClear={false}
          bordered={false}
          showToday={false}
          disabledDate={disabledDate}
          placeholder={placeholder}
        />
        {nextIcon && <TouchableOpacity style={[$icon, iconNextStyle]}>{nextIcon}</TouchableOpacity>}
      </View>
    );
  }

  return (
    <>
      <TouchableOpacity onPress={showDatePicker}>
        <View>
          <View style={[$wrapperPicker, constainerStyle]}>
            {prevIcon && (
              <TouchableOpacity onPress={showDatePicker} style={[$icon, iconStyle]}>
                {prevIcon}
              </TouchableOpacity>
            )}
            <Text style={[$inputPicker, textStyle]}>{!date ? placeholder : formatDate(date)}</Text>
            {nextIcon && (
              <TouchableOpacity onPress={showDatePicker} style={[$icon, iconNextStyle]}>
                {nextIcon}
              </TouchableOpacity>
            )}
          </View>
        </View>
      </TouchableOpacity>
      <DateTimePickerModal
        isVisible={isDatePickerVisible}
        mode={mode}
        onConfirm={handleConfirm}
        onCancel={hideDatePicker}
        date={date ? new Date(date) : currentDate}
        {...(mode === 'date' && { maximumDate: currentDate })}
      />
    </>
  );
};

const $wrapperPicker: ViewStyle = {
  minHeight: 35,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: 20,
  backgroundColor: Color.white,
  paddingLeft: 10,
  marginVertical: 5,
  marginRight: 5,
  borderWidth: 1,
  borderColor: Color.lightBorder,
};

const $icon: ViewStyle = {
  paddingVertical: 3,
};

const $inputPicker: TextStyle = {
  backgroundColor: Color.white,
  flex: 1,
  fontSize: 12,
  lineHeight: 15,
  fontFamily: EFonts.SORA_REGULAR,
  color: Color.black,
  paddingRight: 5,
  marginLeft: 10.83,
  width: width * 0.1,
};
