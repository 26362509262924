import React, { useState } from 'react';
import { TextInput, StyleSheet, TouchableOpacity, Image } from 'react-native';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import CountryPicker from 'react-native-country-picker-modal';

import { Text, View, KeyboardAvoidingView } from 'src/components/Themed';
import PrimaryButton from 'src/components/common/buttons/PrimaryButton';
import EFonts from 'src/constant/EFonts';
import { Color } from 'src/constant/Color';
import { GenUtil } from 'src/util/GenUtil';
import { AuthService } from 'src/service/AuthService';
import { useDispatch } from 'react-redux';
import { AlertTypeEnum, showError } from 'src/store/reducer/errorReducer';

const { width } = GenUtil.getDimension();

const OnboardingPhoneInputComp = ({
  phoneNumber,
  setPhoneNumber,
  countryCode,
  setCountryCode,
  country,
  setCountry,
  shouldCheckUser = true,
  next = () => {},
  checkUser = async (_) => {},
  setActiveStep = (_) => {},
  navigateToSignIn = () => {},
}) => {
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [isValidNumber, setIsValidNumber] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const dispatch = useDispatch();

  const handleRequestOTP = async () => {
    try {
      setIsValidating(true);
      const code = country.callingCode;
      const mobile = '+' + code + phoneNumber;
      const userStatus = shouldCheckUser && (await checkUser({ mobile }));
      if (!shouldCheckUser || userStatus?.success) {
        if (!shouldCheckUser || userStatus?.data?.isNewUser) {
          await AuthService.sendOTP({ mobile });
          next();
        } else if (!userStatus?.data?.isPasswordSet) {
          setActiveStep('enterpassword');
        } else {
          dispatch(
            showError({
              alertType: AlertTypeEnum.Success,
              message: 'You already have an account! Kindly log in',
            }),
          );
          navigateToSignIn();
        }
      }
      setIsValidating(false);
      setPhoneNumberError('');
    } catch (error) {
      dispatch(
        showError({ message: GenUtil.getMessageFromError(error), alertType: AlertTypeEnum.Error }),
      );
      setIsValidating(false);
    }
  };

  const validatePhoneNumber = (text) => {
    const phoneNumberObj = parsePhoneNumberFromString(text, countryCode);
    if (!phoneNumberObj || !phoneNumberObj.isValid()) {
      setPhoneNumberError('Please enter a valid phone number');
      setIsValidNumber(false);
      return;
    } else {
      setPhoneNumberError('');
      setIsValidNumber(true);
      return;
    }
  };

  const data = {
    title: 'Enter Your Mobile No.',
    buttonDisabled: false,
    isValidating: false,
  };

  return (
    <View style={styles.container}>
      <Text style={styles.header}>{data.title}</Text>
      <View style={styles.inContainer}>
        <View
          style={[styles.inputContainer, phoneNumberError ? styles.errorInputContainerStyle : null]}
        >
          {GenUtil.isWEB() ? (
            <CountryPicker
              countryCode={countryCode}
              withFlag
              withFlagButton
              renderFlagButton={({ onOpen }) => {
                return (
                  <TouchableOpacity onPress={onOpen} style={styles.flagButton}>
                    <View style={styles.flagImageContainer}>
                      <Image
                        source={{
                          uri: `http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`,
                        }}
                        style={{
                          width: 25,
                          height: 15,
                        }}
                      />
                      <Text style={styles.countryCodeTextStyle}>+{country.callingCode[0]}</Text>
                      <Image
                        source={require('src/assets/icons/Vectorchevrondown.png')}
                        style={styles.downIcon}
                      />
                    </View>
                  </TouchableOpacity>
                );
              }}
              containerButtonStyle={[
                styles.countryPicker,
                phoneNumberError ? styles.errorCountryPicker : null,
              ]}
              withCallingCode
              withFilter
              withEmoji
              onSelect={(country) => {
                setCountryCode(country.cca2);
                setCountry({
                  cca2: country.cca2,
                  callingCode: [country.callingCode[0]],
                });
              }}
            />
          ) : (
            <CountryPicker
              countryCode={countryCode}
              withFlag
              withFlagButton
              containerButtonStyle={[
                styles.countryPicker,
                phoneNumberError ? styles.errorCountryPicker : null,
              ]}
              withCallingCode
              withFilter
              withEmoji
              onSelect={(country) => {
                setCountryCode(country.cca2);
                setCountry({
                  cca2: country.cca2,
                  callingCode: [country.callingCode[0]],
                });
              }}
            />
          )}

          <TextInput
            style={[styles.inputStyle, phoneNumberError ? styles.errorInput : null]}
            keyboardType="number-pad"
            onChangeText={(text) => {
              setPhoneNumber(text);
              validatePhoneNumber(text);
            }}
            value={phoneNumber}
            placeholder="Mobile Number"
            placeholderTextColor={Color.gray}
          />
        </View>
        {phoneNumberError ? <Text style={styles.errorText}>{phoneNumberError}</Text> : null}
      </View>
      <KeyboardAvoidingView behavior={'position'} style={styles.buttonWrapper}>
        <PrimaryButton
          buttonDisabled={!isValidNumber}
          isValidating={isValidating}
          buttonText="Send OTP"
          handleKeyPress={handleRequestOTP}
        />
      </KeyboardAvoidingView>
      {/* </ImageBackground> */}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: width * 0.05,
    paddingTop: 20,
    backgroundColor: 'transparent',
  },
  message: {
    fontFamily: EFonts.SORA_LIGHT,
    marginTop: 10,
    fontSize: 15,
    marginRight: 49,
    lineHeight: 26,
    color: Color.lightBlack,
  },
  inContainer: {
    justifyContent: 'center',
    marginTop: GenUtil.isWEB() ? null : 30,
  },
  buttonWrapper: {
    position: 'absolute',
    alignSelf: 'center',
    bottom: 35,
  },
  header: {
    fontSize: 30,
    fontFamily: EFonts.SORA_REGULAR,
    lineHeight: 38,
    color: Color.lightBlack,
    marginTop: GenUtil.isWEB() ? 10 : 0,
  },
  inputContainer: {
    position: 'absolute',
    flexDirection: 'row',
    borderRadius: 24,
    paddingHorizontal: 10,
    marginRight: 38,
    marginBottom: 480,
    color: Color.purple,
    top: GenUtil.isWEB() ? 30 : 0,
    borderColor: '#D6D6D6',
    borderWidth: 1,
  },
  inputStyle: {
    flex: 1,
    paddingVertical: GenUtil.isAndroid() ? 10 : 17,
    fontFamily: EFonts.SORA_BOLD,
    fontSize: 15,
    width: 220,
    color: Color.purple,
    marginLeft: 10,
    outlineStyle: 'none',
  },
  countryPicker: {
    flexDirection: 'row',
    borderRightWidth: 1,
    borderRightStyle: 'dotted',
    borderRightColor: Color.gray,
    paddingRight: 10,
    paddingLeft: 5,
    marginRight: 10,
    marginTop: GenUtil.isAndroid() ? 10 : 7,
  },
  errorCountryPicker: {
    borderColor: Color.red,
  },
  input: {
    flex: 1,
    paddingVertical: 10,
    fontFamily: EFonts.DMSANS_BOLD,
    fontSize: 20,
    color: Color.purple,
    marginLeft: 10,
  },
  errorInput: {
    borderColor: Color.red,
  },
  errorInputContainerStyle: {
    borderColor: Color.red,
    borderWidth: 1,
  },
  errorText: {
    color: Color.red,
    fontFamily: EFonts.DMSANS_REGULAR,
    fontSize: 13,
    marginTop: GenUtil.isWEB() ? 95 : GenUtil.isIOS() ? 65 : 85,
  },
  flagButton: {
    marginRight: 5,
    borderRightWidth: 1,
    borderRightStyle: 'dotted',
    borderRightColor: Color.gray,
  },
  flagImageContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  countryCodeTextStyle: {
    marginLeft: 8,
    marginRight: 11,
    fontFamily: EFonts.SORA_REGULAR,
    color: Color.lightBlack,
  },
  downIcon: {
    width: 12,
    height: 6,
    marginRight: 8,
  },
});

export default OnboardingPhoneInputComp;
