import { View } from 'src/components/Themed';
import React, { useEffect, useState } from 'react';
import ChoicesSelector from 'src/components/editProfileComponents/ChoicesSelector';
import { CommonService } from 'src/service/CommonService';
import DOESNT_MATTER_OPTION from 'src/constant/DoesntMatter';

const StatesCitiesSelectionComp = ({
  initialStatesAndCities = [],
  setSearchParams,
}) => {
  const [isDoneValidating, setIsDoneValidating] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(
    initialStatesAndCities.length ? initialStatesAndCities : [DOESNT_MATTER_OPTION.name],
  );
  const [locationData, setLocationData] = useState([]);

  useEffect(() => {
    CommonService.getCities('India')
      .then((response) =>
        setLocationData([
          DOESNT_MATTER_OPTION.name,
          ...response.data.states.map((item) => item.name),
          ...response.data.cities.map((item) => item.name),
        ]),
      )
      .catch((error) => console.log(error));
  }, []);

  const onPressDone = async () => {
    const validPreferences =
      selectedLocation[0] === DOESNT_MATTER_OPTION.name ? [] : selectedLocation;

    try {
      setIsDoneValidating(true);
      if (setSearchParams) {
        setSearchParams((prevParams) => ({
          ...prevParams,
          ...{ statesAndCities: validPreferences },
        }));
        setIsDoneValidating(false);
        return;
      }
      setIsDoneValidating(false);
    } catch (error) {
      console.log(error);
      setIsDoneValidating(false);
    }
  };

  const onPressRemove = async (updatedStatesCities = []) => {
    try {
      if (setSearchParams) {
        setSearchParams((prevParams) => ({
          ...prevParams,
          ...{ statesAndCities: updatedStatesCities },
        }));
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <View style={{ bottom: 20 }}>
      <ChoicesSelector
        dropdownItems={locationData}
        placeHolder="Search States and Cities here..."
        selectedValues={selectedLocation}
        setSelectedValues={setSelectedLocation}
        onPressDone={onPressDone}
        onPressRemove={onPressRemove}
        isDoneValidating={isDoneValidating}
        title='States and Cities'
      />
    </View>
  );
};

export default StatesCitiesSelectionComp;
