import { RefreshControl, ScrollView, StyleSheet, View } from 'react-native';
import WelcomeCard from 'src/components/WelcomeCard';
import ProfileProgressCard from 'src/components/ProfileProgressCard';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import RecievedInterestsSection from 'src/components/RecievedInterestsSection';
import UserNotificationsSection from 'src/components/UserNotificationsSection';
import UnlockPremiumCard from 'src/components/UnlockPremiumCard';
import ProfileVisitorsCard from 'src/components/ProfileVisitorsCard';
import ProfileViewsCard from 'src/components/ProfileViewsCard';
import ShortlistedProfilesSection from 'src/components/ShortlistedProfilesSection';
import HandPickerProfilesSection from 'src/components/HandPickerProfilesSection';
import AppStatsCard from 'src/components/AppStatsCard';
import UnlockProfileView from 'src/components/UnlockProfileViewsCard';
import InterestsSection from 'src/components/InterestsSection';
import RecommendedProfilesSection from 'src/components/RecommendedProfilesCard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import UserUtil from 'src/util/UserUtil';
import { Color } from 'src/constant/Color';
import { MembershipPermissions, isFeatureEnabled } from 'src/common/data/membership.data';
import NotificationUtil from 'src/util/NotificationUtil';
import UserService from 'src/service/UserService';
import StorageUtil from 'src/util/StorageUtil';
import { ProfileService } from 'src/service/ProfileService';
import { setUser, setUserProfile } from 'src/store/reducer/userReducer';

const HomeScreen = () => {
  const [refreshing, setRefreshing] = useState(false);
  const dispatch = useDispatch();
  const { userProfile, user } = useSelector((state: RootState) => state.user);
  const isPremiumMember = useMemo(() => UserUtil.isPremiumMember(userProfile), [userProfile]);
  
  const onRefresh = useCallback(() => {
    setRefreshing(true);
    const token = StorageUtil.getAuthTokenFromLocalStorage();
    if (token) {
      ProfileService.getLoggedInUserDetails()
        .then((response) => {
          dispatch(setUser(response.data.user));
          dispatch(setUserProfile(response.data.profile));
          setRefreshing(false);
        })
        .catch((err) => {
          console.log(err);
          setRefreshing(false);
        });
    }
  }, []);

  useEffect(() => {
    NotificationUtil.getFCMToken()
      .then(async (token) => {
        await UserService.updateUser(user?.uuid, { fcmToken: token });
      })
      .catch(() => {});
  }, []);

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={styles.screen}
      contentContainerStyle={styles.container}
      refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
    >
      <WelcomeCard />
      <RecievedInterestsSection />
      <ProfileProgressCard />
      <UserNotificationsSection />
      <InterestsSection />
      {!isPremiumMember ? <UnlockPremiumCard /> : <></>}
      {userProfile && <RecommendedProfilesSection />}
      <View style={styles.visitorsContainer}>
        <ProfileVisitorsCard
          hideProfiles={!isFeatureEnabled(userProfile, MembershipPermissions.CanSeeProfileVisitors)}
        />
        <ProfileViewsCard
          hideProfiles={!isFeatureEnabled(userProfile, MembershipPermissions.CanSeeProfileVisited)}
        />
      </View>
      <ShortlistedProfilesSection />
      {!isPremiumMember ? <UnlockProfileView /> : <></>}
      <HandPickerProfilesSection />
      <AppStatsCard />
    </ScrollView>
  );
};

export default HomeScreen;

const styles = StyleSheet.create({
  screen: {
    flex: 1,
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Color.white,
  },
  visitorsContainer: {
    marginTop: 20,
    width: '90%',
    flexDirection: 'row',
  },
});
