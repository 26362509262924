import { View, Image, ScrollView, Text, StyleSheet, TouchableOpacity } from 'react-native';
import React, { useEffect, useState } from 'react';
import { DraggableGrid } from 'react-native-draggable-grid';
import Dialog, { DialogContent } from 'react-native-popup-dialog';
import { Divider, Icon } from 'react-native-elements';
import { useDispatch, useSelector } from 'react-redux';
import ImagePicker, { ImageOrVideo } from 'react-native-image-crop-picker';
import * as WebImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';
import _ from 'lodash';

import { GenUtil } from 'src/util/GenUtil';
import EFonts from 'src/constant/EFonts';
import { Color } from 'src/constant/Color';
import { RootState } from 'src/store/store';
import {
  MediaCategory,
  PreSignedUrlBody,
  ProfileMediaUpdateBody,
} from 'src/common/models/media.model';
import ProfileMediaService from 'src/service/ProfileMediaService';
import { HttpResponse } from 'src/common/response/base.resp';
import { ProfileMediaBody } from 'src/common/models/media.model';
import {
  ProfileMediaCategory,
  ProfileMediaStatus,
  ProfileMediaType,
} from 'src/common/models/profile-media.model';
import { ProfileService } from 'src/service/ProfileService';
import { PreSignedUrlResp, ProfileMediaResp } from 'src/common/response/profile-media.resp';
import FullScreenImage from 'src/components/common/FullScreenImageViewer';
import { v4 } from 'uuid';
import {
  ImagePickerCanceledResult,
  ImagePickerSuccessResult,
} from 'expo-image-picker/build/ImagePicker.types';
import WebImgPickerComp from './WebImagePicker';
import CustomModal from './CustomModal';
import { setUserProfile } from 'src/store/reducer/userReducer';
import { AlertTypeEnum, showError } from 'src/store/reducer/errorReducer';

const { height, width } = GenUtil.getDimension();
const COMPRESSED_IMG_WIDTH = 500;
const COMPRESSED_IMG_QUALITY = 1;

interface ExtendedProfileMediaResp extends ProfileMediaResp {
  key: string | number;
  disabledDrag?: boolean;
  disabledReSorted?: boolean;
}

type PartialExtendedProfileMediaResp = Partial<ExtendedProfileMediaResp> &
  Required<Pick<ExtendedProfileMediaResp, 'key'>>;

type UploadButtonProps = {
  setAreActionsVisible: (boolean) => void;
};

const UploadButton = ({ setAreActionsVisible }: UploadButtonProps) => {
  const plusIcon = require('src/assets/icons/plus-icon-grey4x.png');

  const handleUploadPress = () => {
    setAreActionsVisible(true);
  };

  return (
    <View style={styles.uploadButton}>
      <TouchableOpacity onPress={handleUploadPress}>
        <View style={{ alignItems: 'center', top: 65 }}>
          <Image style={{ height: 14, width: 14 }} source={plusIcon} />
        </View>
        <View style={{ alignItems: 'center', top: 78 }}>
          <Text style={styles.uploadButtonText}>Upload New</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
};

// TODO: change serial = images.length + 1 to images.length

const ImageGalleryComp = () => {
  const [selectedImage, setSelectedImage] = useState<WebImagePicker.ImagePickerAsset>();
  const [areActionsVisible, setAreActionsVisible] = useState(false);
  const [images, setImages] = useState<Partial<PartialExtendedProfileMediaResp[]>>([]);
  const [fullScreenImage, setFullScreenImage] = useState<PartialExtendedProfileMediaResp>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [profileImage, setProfileImage] = useState<PartialExtendedProfileMediaResp>();
  const loggedInUser = useSelector((state: RootState) => state.user.user);
  const userProfile = useSelector((state: RootState) => state.user.userProfile);
  const [imageDeleted, setImageDeleted] = useState(null);

  const dispatch = useDispatch();
  const objToRenderUploadBtn: PartialExtendedProfileMediaResp = {
    blurredUrl: '',
    category: ProfileMediaCategory.Normal,
    headshotUrl: '',
    originalUrl: '',
    profileUuid: '',
    serial: 0,
    status: ProfileMediaStatus.Active,
    uuid: 'b4fdf0f2',
    key: 'b4fdf0f2',
    disabledDrag: true,
    disabledReSorted: true,
  };

  const pickImageWeb = async () => {
    // Ask the user for the permission to access the media library
    const permissionResult = await WebImagePicker.requestMediaLibraryPermissionsAsync();

    if (permissionResult.granted === false) {
      alert("You've refused to allow this appp to access your photos!");
      return;
    }
    const result: ImagePickerSuccessResult | ImagePickerCanceledResult =
      await WebImagePicker.launchImageLibraryAsync({
        mediaTypes: WebImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        base64: false,
        aspect: [4, 3],
      });

    if (!result.canceled) {
      setSelectedImage(result.assets[0]);
    }
  };

  const getUserImages = async () => {
    try {
      const response = await ProfileService.getProfileDetails(loggedInUser.profileUuid);
      return response.data.medias;
    } catch (error) {
      console.log('Failed to fetch user details', error);
    }
  };

  const assignAndSetImage = () => {
    getUserImages()
      .then((response: PartialExtendedProfileMediaResp[]) => {
        response = _.sortBy(response, ['serial']);
        const updatedResponse = addKeyInImageObjects(response);
        setImages([objToRenderUploadBtn, ...updatedResponse]);
      })
      .catch((error) => console.log('Error', error));
  }

  useEffect(() => {
    assignAndSetImage();
  }, [imageDeleted]);

  const addKeyInImageObjects = (images: PartialExtendedProfileMediaResp[]) => {
    const updatedImages = images.map((imageObj) => {
      return {
        ...imageObj,
        key: imageObj.uuid,
      };
    });

    return updatedImages;
  };

  const renderItem = (item: PartialExtendedProfileMediaResp, index) => {
    if (index === 0) {
      return <UploadButton setAreActionsVisible={setAreActionsVisible} key={item.uuid} />;
    }

    const handleMorePressed = () => {
      setProfileImage(item);
      setIsModalVisible(true);
    };

    return (
      <View key={item.uuid} style={styles.renderItemContainer}>
        <Image source={{ uri: item.originalUrl }} style={{ height: 160, borderRadius: 12 }} />
        <View style={styles.actionableContainer}>
          <TouchableOpacity style={styles.iconContainer} onPress={handleMorePressed}>
            <Icon name={'more-vertical'} type={'feather'} color={Color.white} size={15} />
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  const openGallery = async () => {
    try {
      const image: ImageOrVideo = await ImagePicker.openPicker({
        cropping: true,
        mediaType: 'photo',
        freeStyleCropEnabled: true,
        forceJpg: true,
      });

      await onImageSelected(image);
    } catch (error) {
      console.log('Failed to open gallery', error);
    }
  };

  const handleOpenGallery = async () => {
    if (GenUtil.isWEB()) {
      await pickImageWeb();
      setAreActionsVisible(false);
      return;
    }

    setAreActionsVisible(false);
    await openGallery();
  };

  const openCamera = async () => {
    try {
      const image: ImageOrVideo = await ImagePicker.openCamera({
        cropping: true,
        mediaType: 'photo',
        freeStyleCropEnabled: true,
        forceJpg: true,
        useFrontCamera: true,
      });

      await onImageSelected(image);
    } catch (error) {
      console.log('Failed to lauch camera', error);
    }
  };

  const handelOpenCamera = async () => {
    setAreActionsVisible(false);
    await openCamera();
  };

  const onImageSelected = async (image: ImageOrVideo) => {
    try {
      const imgUrl = image.path ?? image.sourceURL;
      const resizedImage = await resizePhoto(imgUrl);
      const urlBody: PreSignedUrlBody = {
        category: MediaCategory.ProfilePhoto,
        uploads: [
          {
            ext: 'jpg',
          },
        ],
      };
      const response: HttpResponse<PreSignedUrlResp[][]> =
        await ProfileMediaService.getPreSignedUrlForImage(urlBody);
      if (!response.success) return;

      const uploadURL = response.data[0][0].preSignedUploadUrl;
      const uploadResponse = await uploadFile(uploadURL, resizedImage);

      if (!uploadResponse.success) return null;

      const originalImgS3Key: string = response.data[0][0].destS3Key;
      const blurredImgS3Key: string = response.data[0][1].destS3Key;
      const headshotImgS3Key: string = response.data[0][2].destS3Key;

      const originalUrl: string = response.data[0][0].publicUrl;
      const blurredUrl: string = response.data[0][1].publicUrl;
      const headshotUrl: string = response.data[0][2].publicUrl;

      const profileMediaBody: ProfileMediaBody = {
        mediaType: ProfileMediaType.Photo,
        originalImgS3Key,
        blurredImgS3Key,
        headshotImgS3Key,
        category: ProfileMediaCategory.Normal,
        serial: images.length,
      };

      const uuid = v4();
      const uploadedImage = {
        blurredUrl,
        category: ProfileMediaCategory.Normal,
        headshotUrl,
        originalUrl,
        profileUuid: '',
        serial: images.length,
        status: ProfileMediaStatus.Active,
        createdAt: '',
        uuid,
        key: uuid,
      };

      await saveImageAtBackend([profileMediaBody]);
    } catch (error) {
      console.log('error in onImageSelect', error);
    }
  };

  const uploadFile = async (uploadUrl: string, resizedImage: ImageManipulator.ImageResult) => {
    try {
      const formData = new FormData();
      const fileName = resizedImage.uri.split('/').pop();
      const imageUri = resizedImage.uri;

      if (GenUtil.isWEB()) {
        const file = await fetch(resizedImage.uri);
        const imageBlob = await file.blob();

        formData.append('file', imageBlob);
      } else {
        formData.append('file', {
          uri: imageUri,
          name: fileName,
          type: 'image/jpeg',
        });
      }

      formData.append('preSignedUrl', uploadUrl);
      const response = await ProfileMediaService.uploadMedia(formData);
      return response;
    } catch (error) {
      console.log('Failed to upload file', error);
    }
  };

  const saveImageAtBackend = async (profileMediaBody: ProfileMediaBody[]) => {
    try {
      const respone = await ProfileMediaService.addMedia(profileMediaBody);
      console.log('add media response =', respone);
      assignAndSetImage();
      return respone;
    } catch (error) {
      console.log('error in saving image at backend', error);
    }
  };

  const resizePhoto = async (imageUri: string, compressedImgQuality = COMPRESSED_IMG_QUALITY) => {
    const resizedPhoto = await ImageManipulator.manipulateAsync(
      imageUri,
      [{ resize: { width: COMPRESSED_IMG_WIDTH } }], // resize to width of 300 and preserve aspect ratio
      { compress: compressedImgQuality, format: ImageManipulator.SaveFormat.JPEG, base64: false },
    );

    return resizedPhoto;
  };

  const updateImagesAtBackend = async (images: PartialExtendedProfileMediaResp[]) => {
    try {
      const updateMediaBody: ProfileMediaUpdateBody[] = images.map((imageObj) => {
        return {
          uuid: imageObj.uuid,
          category: imageObj.category,
          serial: imageObj.serial,
        };
      });

      await ProfileMediaService.updateMedia(updateMediaBody);
    } catch (error) {
      console.log('Error in updateImagesAtBackend', error);
    }
  };

  const handleOnDragRelease = async (images: PartialExtendedProfileMediaResp[]) => {
    setImages(images);
    const imagesWithoutFakeImgObj = images.slice(1);
    const imagesWithUpdatedSerial = imagesWithoutFakeImgObj.map((image, index) => ({
      ...image,
      serial: index + 1,
    }));
    await updateImagesAtBackend(imagesWithUpdatedSerial);
  };

  const handleSetProfilePressed = async () => {
    try {
      const lastProfileImage = images.filter(i => i.category === ProfileMediaCategory.Dp);
      const removeOlderImageBody = lastProfileImage.map((lastDpProfiles) => {
        const updateMediaBody : ProfileMediaUpdateBody = {
          uuid : lastDpProfiles.uuid,
          category: ProfileMediaCategory.Normal,
          serial: lastDpProfiles.serial
        }
        return updateMediaBody;
      });

      const updateMediaBody: ProfileMediaUpdateBody = {
        uuid: profileImage.uuid,
        category: ProfileMediaCategory.Dp,
        serial: profileImage.serial,
      };
      delete profileImage.key;
      const updatedProfile = { ...profileImage, category: ProfileMediaCategory.Dp };

      const response = await ProfileMediaService.updateMedia([updateMediaBody, ...removeOlderImageBody]);
      if (response.success) {
        setIsModalVisible(false);
        assignAndSetImage()
        dispatch(setUserProfile({ ...userProfile, profilePhoto: updatedProfile }));
        dispatch(showError({ alertType: AlertTypeEnum.Success, message: 'Profile updated' }));
      }
    } catch (error) {
      dispatch(
        showError({ alertType: AlertTypeEnum.Error, message: GenUtil.getMessageFromError(error) }),
      );
      setIsModalVisible(false);
    }
  };

  const handleDeletePressed = async () => {
    try {
      const response = await ProfileMediaService.deleteMedia(profileImage.uuid);
      if (response.success) {
        setIsModalVisible(false);
        setImageDeleted(profileImage.uuid);
        dispatch(showError({ alertType: AlertTypeEnum.Success, message: 'Picture deleted' }));
      }
    } catch (error) {
      dispatch(
        showError({ alertType: AlertTypeEnum.Error, message: GenUtil.getMessageFromError(error) }),
      );
      setIsModalVisible(false);
    }
  };


  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingHorizontal: 20,
        }}
      >
        <View>
          <Text style={styles.primaryText}>All photos</Text>
        </View>
        <View>
          {images.length - 1 > 0 ? (
            <Text style={styles.secondaryText}>{`${images.length - 1} ${images.length - 1 > 1 ? 'Photos' : 'Photo'}`}</Text>
          ) : null}
        </View>
      </View>
      <ScrollView
        style={{
          width: width,
          paddingHorizontal: 12,
        }}
      >
        <DraggableGrid
          data={images}
          renderItem={(item: PartialExtendedProfileMediaResp, index) => renderItem(item, index)}
          itemHeight={174}
          numColumns={3}
          onDragRelease={(data: PartialExtendedProfileMediaResp[]) => {
            handleOnDragRelease(data)
              .then(() => {})
              .catch(() => {});
          }}
          onItemPress={(item: PartialExtendedProfileMediaResp) => {
            setFullScreenImage(item);
          }}
        />
        {fullScreenImage && (
          <FullScreenImage
            visible={Boolean(fullScreenImage)}
            images={[{ url: fullScreenImage.originalUrl }]}
            hideFullScreen={() => setFullScreenImage(null)}
          />
        )}
        {isModalVisible && (
          <CustomModal
            visible={isModalVisible}
            renderComponent={
              <View style={GenUtil.isWEB() ? styles.modalContainerWeb : styles.modalContainerMob}>
                <View style={styles.modalHeader}>
                  <Text style={styles.modalHeaderText}>Select an option</Text>
                  <TouchableOpacity
                    onPress={() => setIsModalVisible(false)}
                    style={styles.closeIconContainer}
                  >
                    <Icon name="close" type="material" />
                  </TouchableOpacity>
                </View>
                <TouchableOpacity
                  style={styles.profileIconContainer}
                  onPress={handleSetProfilePressed}
                >
                  <Icon name="person" />
                  <Text style={{ marginLeft: 8 }}>Set as profile picture</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.profileIconContainer} onPress={handleDeletePressed}>
                  <Icon name="delete" color={Color.red} />
                  <Text style={{ marginLeft: 8, color: Color.red }}>Delete</Text>
                </TouchableOpacity>
              </View>
            }
            onClose={() => setIsModalVisible(false)}
          />
        )}
      </ScrollView>
      <Dialog
        visible={areActionsVisible}
        dialogStyle={styles.dialogStyle}
        onTouchOutside={() => {
          setAreActionsVisible(false);
        }}
      >
        <DialogContent>
          <View>
            <TouchableOpacity
              style={styles.actionsStyle}
              onPress={() => {
                handleOpenGallery()
                  .then(() => {})
                  .catch(() => {});
              }}
            >
              <Text style={styles.actionsTextStyle}>Pick from gallery</Text>
            </TouchableOpacity>
            <Divider />
            {!GenUtil.isWEB() && (
              <>
                <TouchableOpacity
                  style={styles.actionsStyle}
                  onPress={() => {
                    handelOpenCamera()
                      .then(() => {})
                      .catch(() => {});
                  }}
                >
                  <Text style={styles.actionsTextStyle}>Take photo</Text>
                </TouchableOpacity>
                <Divider />
              </>
            )}
            <TouchableOpacity
              style={styles.actionsStyle}
              onPress={() => {
                setAreActionsVisible(false);
              }}
            >
              <Text style={[styles.actionsTextStyle, { color: 'red' }]}>Cancel</Text>
            </TouchableOpacity>
          </View>
        </DialogContent>
      </Dialog>
      {selectedImage?.uri ? (
        <WebImgPickerComp
          selectedImage={selectedImage?.uri}
          setSelectedImage={setSelectedImage}
          onImageSelected={onImageSelected}
        />
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  uploadButton: {
    height: 160,
    width: 106,
    backgroundColor: '#F5F5F5',
    borderWidth: 1,
    borderColor: '#D3D3D3',
    borderStyle: 'dashed',
    marginTop: 14,
    borderRadius: 12,
  },
  uploadButtonText: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    lineHeight: 15,
    color: Color.lightGrey,
  },
  actionsStyle: {
    height: 60,
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionsTextStyle: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 18,
  },
  primaryText: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 17,
    lineHeight: 21,
    color: Color.black,
  },
  secondaryText: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    lineHeight: 15,
    color: Color.lightGrey,
    alignSelf: 'center',
  },
  modalContainerWeb: {
    backgroundColor: '#fff',
    borderRadius: 6,
    width: width * 0.5,
    margin: 'auto',
  },
  modalContainerMob: {
    backgroundColor: '#fff',
    borderRadius: 6,
    width: width * 0.9,
    height: height * 0.3,
  },
  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    borderBottomWidth: 1,
    borderRadius: 6,
    borderBottomColor: Color.lightGrey,
  },
  modalHeaderText: {
    fontSize: 17,
    fontFamily: EFonts.DMSANS_BOLD,
    color: Color.purple,
  },
  renderItemContainer: {
    marginTop: 14,
    borderRadius: 12,
    height: 160,
    width: 106,
    position: 'relative',
  },
  actionableContainer: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
  iconContainer: {
    backgroundColor: 'rgba(0,0,0,.3)',
    width: 20,
    height: 20,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  profileIconContainer: {
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center',
  },
  closeIconContainer: {
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  dialogStyle: {
    borderRadius: 12,
    width: GenUtil.isWEB() ? width : width,
    position: 'absolute',
    bottom: 0,
  },
});

export default ImageGalleryComp;
