import React, { useState } from 'react';
import { Layout, Menu, Drawer } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, BellOutlined } from '@ant-design/icons';
import { Color } from 'src/constant/Color';
import { StackActions, useNavigation } from '@react-navigation/native'; // No need to import NavigationContainer here
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { Text, TouchableOpacity, View } from 'react-native';
import EFonts from 'src/constant/EFonts';
import { StyleSheet } from 'react-native';
import { LayoutConstant } from 'src/constant/LayoutConstant';
import { setUser } from 'src/store/reducer/userReducer';
import Constant from 'src/constant/Constant';
import StorageUtil from 'src/util/StorageUtil';
import { GenUtil } from 'src/util/GenUtil';

const { Header, Content } = Layout;
const menuItems = ['partnerpreferences', 'blockedprofiles', 'phonebook', 'rateus', 'settings'];

const Navbar = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const [currentPath, setCurrentPath] = useState();
  const [selectedKey, setSelectedKey] = useState(['-1']);
  const [selectedItem, setSelectedItem] = useState();

  function findFirstMatchingWordIndex(path: string) {
    const lowerCaseInput: string = path.toLowerCase();
    return menuItems.findIndex((word) => lowerCaseInput.includes(word.toLowerCase()));
  }

  const toggleDrawer = () => {
    const path = window.location.pathname;
    if (!drawerVisible) {
      const index = findFirstMatchingWordIndex(path);
      setSelectedKey([index.toString()]);
      setDrawerVisible(true);
    } else {
      setDrawerVisible((prev) => !prev);
    }
  };

  const handleDrawerItemClick = (screenName, key) => {
    if (key === '0') {
      navigation.navigate('data', {
        screen: 'editprofile',
        params: { screen: 'editprofile', params: { tabIndex: 1 } },
      });
      toggleDrawer();
      return;
    }
    navigation.navigate('data', { screen: screenName });
    setSelectedKey([key]);

    toggleDrawer(); // Close the drawer after navigation
  };

  const handleLogOut = () => {
    dispatch(setUser(null));
    StorageUtil.removeKey(Constant.AUTH_TOKEN);
    StorageUtil.removeKey(Constant.FIREBASE_TOKEN);
    toggleDrawer();
    navigation.dispatch(StackActions.replace('welcome'));
  };

  return (
    <Header style={styles.headerStyle}>
      <View style={styles.ationableContainer}>
        <TouchableOpacity
          style={styles.homeButtonContainer}
          onPress={() => {
            navigation.navigate(user ? 'home' : 'welcome');
          }}
        >
          <Text style={styles.textStyle}>MatriMilan</Text>
        </TouchableOpacity>
        {user ? (
          <View style={styles.rightIconsContainer}>
            <BellOutlined
              style={styles.notificationIconStyle}
              onClick={() => navigation.navigate('activity')}
            />
            <div style={styles.drawerMenuButtonStyle} onClick={toggleDrawer}>
              {drawerVisible ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
            </div>
          </View>
        ) : null}
      </View>

      <Drawer
        placement="right"
        onClose={toggleDrawer}
        open={drawerVisible}
        drawerStyle={{ padding: 0, margin: 0 }}
      >
        <View style={{ height: '100%', justifyContent: 'space-between' }}>
          <Menu
            theme="light"
            mode="vertical"
            style={{ borderRight: 'none' }}
            selectedKeys={selectedKey}
          >
            <Menu.Item
              key="0"
              onClick={() => {
                handleDrawerItemClick('partnerpreferences', '0');
              }}
            >
              Partner Preferences
            </Menu.Item>
            <Menu.Item
              key="1"
              onClick={() => {
                handleDrawerItemClick('blockedprofiles', '1');
              }}
            >
              Blocked Profiles
            </Menu.Item>
            <Menu.Item
              key="2"
              onClick={() => {
                handleDrawerItemClick('phonebook', '2');
              }}
            >
              Phone Book
            </Menu.Item>
            <Menu.Item
              key="3"
              onClick={() => {
                handleDrawerItemClick('rateus', '3');
              }}
            >
              Rate Us
            </Menu.Item>
            <Menu.Item
              key="4"
              onClick={() => {
                handleDrawerItemClick('settings', '4');
              }}
            >
              Account & Settings
            </Menu.Item>
            <Menu.Item key="5" onClick={handleLogOut} style={{ color: 'red' }}>
              Log out
            </Menu.Item>
          </Menu>
          <View style={styles.versionBottomCenter}>
            <Text style={{fontFamily: EFonts.SORA_REGULAR, fontSize: 12}}>{GenUtil.getAppVersion()}</Text>
          </View>
        </View>
      </Drawer>
    </Header>
  );
};

const styles = StyleSheet.create({
  headerStyle: {
    backgroundColor: Color.white,
    paddingLeft: 100,
    paddingRight: 100,
    marginBottom: 20,
    height: LayoutConstant.navbarHeight,
  },
  ationableContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  notificationIconStyle: {
    fontSize: 24,
    marginRight: '16px',
    color: Color.black,
    cursor: 'pointer',
  },
  drawerMenuButtonStyle: {
    fontSize: 24,
    color: Color.black,
    cursor: 'pointer',
  },
  homeButtonContainer: {
    height: LayoutConstant.navbarHeight,
    alignItems: 'center',
    justifyContent: 'center',
  },
  textStyle: {
    fontFamily: EFonts.SORA_SEMIBOLD,
    fontSize: 24,
    color: Color.purple,
  },
  rightIconsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  versionBottomCenter: {
    margin: 4,
    paddingLeft: 16,
    paddingRight: 16,
  }
});

export default Navbar;
