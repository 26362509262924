import React, { useState, useEffect } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { View, Text } from 'src/components/Themed';
import EFonts from 'src/constant/EFonts';
import { GenUtil } from 'src/util/GenUtil';
import { ProfileResp } from 'src/common/response/profile.resp';
import { Color } from 'src/constant/Color';
import InputAdornment from 'src/components/common/input/InputAdornment';
import ModalAdornment from 'src/components/editProfileComponents/atoms/ModalAdornment';
import {
  ProfessionData,
  QualificationData,
  QualificationDataMap,
} from 'src/common/data/education.data';
import { useDispatch } from 'react-redux';
import { CareerDetailsBody } from 'src/common/dto/profile.dto';
import {
  EmploymentRecord,
  EmploymentType,
  ResidencyStatus,
  SettingAbroad,
} from 'src/common/models/profile.model';
import { setUserProfile } from 'src/store/reducer/userReducer';
import { ProfileUpdateService } from 'src/service/ProfileUpdateService';
import SaveButton from '../SaveButton';
import { AlertTypeEnum, showError } from 'src/store/reducer/errorReducer';
import { Icon } from 'react-native-elements';

const { width } = GenUtil.getDimension();
const professions = ProfessionData.map((res) => res.name);
const years = [];
for (let i = 1970; i <= new Date().getFullYear(); i++) years.push(i.toString());
const incomeOptions = [];
for (let i = 0; i <= 1000; i++) incomeOptions.push(i.toString());

export default function MyCareer({
  profileCareerDetails,
}: {
  profileCareerDetails: Partial<ProfileResp>;
}) {
  const [employementsCount, setEmployementsCount] = useState(
    Math.max(profileCareerDetails?.employments?.length, 1),
  );
  const components = [];
  const [aboutCareer, setAboutCareer] = useState(profileCareerDetails?.aboutCareer ?? '');
  const [employements, setEmployements] = useState(
    profileCareerDetails?.employments?.map((res) => {
      return {
        companyName: res?.companyName,
        yearStarted: res?.yearStarted?.toString(),
        yearCompleted: res?.yearCompleted?.toString(),
        position: res?.position,
      };
    }),
  );
  const [profession, setProfession] = useState(profileCareerDetails?.profession ?? '');
  const [employementType, setEmployementType] = useState(
    profileCareerDetails?.employmentType ?? ('' as EmploymentType),
  );
  const [settlingAbroad, setSettlingAbroad] = useState(
    profileCareerDetails?.settlingAbroad ?? ('' as SettingAbroad),
  );
  const [income, setIncome] = useState(profileCareerDetails?.inrIncome?.toString() ?? '');
  const [currentlySelectedCompany, setCurrentlySelectedCompany] = useState(null as string);
  const [currentlySelectedYearCompleted, setCurrentlySelectedYearCompleted] = useState('');
  const [currentlySelectedYearStarted, setCurrentlySelectedYearStarted] = useState('');
  const [currentlySelectedPosition, setCurrentlySelectedPosition] = useState(null as string);
  const [isValidating, setIsValidating] = useState(false);
  const aboutCareerPlaceholder = `Example:- "I'm working as a Software professional for past 4 years but I started my career as a salesperson."`;

  const dispatch = useDispatch();

  const [index, setIndex] = useState(1);

  function onPressAddMore() {
    setCurrentlySelectedCompany(null);
    setCurrentlySelectedYearCompleted('');
    setCurrentlySelectedYearStarted('');
    setCurrentlySelectedPosition(null);
    setEmployementsCount((prev) => prev + 1);
  }

  const ifAnyEmployementsFieldIsEmpty = (i: number) => {
    return (
      !employements[i] ||
      !employements[i].companyName ||
      !employements[i].position ||
      !employements[i].yearStarted ||
      !employements[i].yearCompleted
    );
  };
  const deleteEmployementHandler = (i: number) => {
    if (i < employements.length) {
      setEmployements((prev) => {
        prev.splice(i, 1);
        return prev;
      });
    }
    setEmployementsCount((prev) => prev - 1);
    if (employementsCount === 1) {
      onPressAddMore();
    }
  };

  const validateCareerData = (data: Partial<CareerDetailsBody>) => {
    const { aboutCareer, employments } = data;

    let errorMessage = '';
    if (!aboutCareer || !aboutCareer.length) errorMessage = 'About Career should not be empty.';
    else if (aboutCareer.length < 10)
      errorMessage = 'About Career length must be at least 10 characters long';

    employments.forEach((item) => {
      if (!item.companyName || !item.companyName.length)
        errorMessage = 'Company Name should not be empty.';
      else if (item.companyName.length < 2)
        errorMessage = 'Company Name length must be at least 2 characters long.';
      else if (!item.position || !item.position.length)
        errorMessage = 'Position should not be empty.';
      else if (item.position.length < 2)
        errorMessage = 'Position length must be at least 2 characters long.';
      else if (!item.yearCompleted || !item.yearStarted) errorMessage = 'Please enter valid Years';
      else if (item.yearCompleted < item.yearStarted)
        errorMessage = 'Year From should not be less than Year To.';
    });

    if (errorMessage.length > 0) {
      dispatch(showError({ alertType: AlertTypeEnum.Error, message: errorMessage }));
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    try {
      setIsValidating(true);
      let professionId: number = null;
      ProfessionData.map((res) => {
        if (res.name === profession) {
          professionId = res.id;
        }
      });
      const employementsWithIntYears = [] as EmploymentRecord[];
      if (employements.length) {
        employements.map((res) => {
          employementsWithIntYears.push({
            companyName: res.companyName,
            position: res.position,
            yearStarted: parseInt(res.yearStarted),
            yearCompleted: parseInt(res.yearCompleted),
          });
        });
      }
      const data: Partial<CareerDetailsBody> = {
        aboutCareer: aboutCareer,
        professionId: professionId,
        settlingAbroad: settlingAbroad,
        employments: employementsWithIntYears,
        inrIncome: parseInt(income),
        residencyStatus: ResidencyStatus.Citizen,
        employmentType: employementType,
      };
      const filteredData: Partial<CareerDetailsBody> = Object.fromEntries(
        Object.entries(data).filter(([_, value]) => value !== null && value !== undefined && value !== "")
      );
      if (!validateCareerData(filteredData)) return setIsValidating(false);
      const updateCareerDetailsResponse = await ProfileUpdateService.updateCareer(filteredData);
      if (updateCareerDetailsResponse?.httpCode === 200) {
        //update redux
        dispatch(setUserProfile(updateCareerDetailsResponse?.data));
        dispatch(showError({ alertType: AlertTypeEnum.Success, message: 'Saved' }));
      }
      setIsValidating(false);
    } catch (error) {
      console.log(error);
      setIsValidating(false);
      dispatch(
        showError({ alertType: AlertTypeEnum.Error, message: GenUtil.getMessageFromError(error) }),
      );
    }
  };

  function renderEmployementHistorySection() {
    for (let i = 0; i < employementsCount; i++) {
      components.push(
        <View key={i} style={{ marginTop: 15 }}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View>{i === 0 && <Text style={styles.tittle}>Employment History</Text>}</View>
          </View>
          <View style={{ marginBottom: 8 }}>
            <Text style={styles.infoLabel}>Company Name</Text>
            <InputAdornment
              placeholder="Type here..."
              textStyle={{ color: Color.lightBlack, fontFamily: EFonts.SORA_REGULAR }}
              value={employements?.[i]?.companyName ?? ''}
              setValue={setCurrentlySelectedCompany}
              helperValue={i}
              setHelperValue={setIndex}
            />
          </View>
          <View style={{ marginBottom: 8 }}>
            <Text style={styles.infoLabel}>Position</Text>
            <InputAdornment
              placeholder="Type here..."
              textStyle={{ color: Color.lightBlack, fontFamily: EFonts.SORA_REGULAR }}
              value={employements?.[i]?.position ?? ''}
              setValue={setCurrentlySelectedPosition}
              helperValue={i}
              setHelperValue={setIndex}
            />
          </View>

          <View style={styles.row}>
            <View style={{ width: width * 0.45 }}>
              <Text style={styles.infoLabel}>Year From</Text>
              <ModalAdornment
                placeHolder="Select"
                modalItems={years.slice(
                  years.indexOf(profileCareerDetails.dob.split('-')[0] ?? '1970'),
                  years.length,
                )}
                value={employements?.[i]?.yearStarted}
                setValue={setCurrentlySelectedYearStarted}
                helperValue={i}
                setHelperValue={setIndex}
                modalHeading={'Year From'}
              />
            </View>
            <View style={{ width: width * 0.45 }}>
              <Text style={styles.infoLabel}>Year To</Text>
              <ModalAdornment
                disabled={!employements?.[i]?.yearStarted}
                styleOverride={!employements?.[i]?.yearStarted}
                placeHolder="Select"
                modalItems={years.slice(
                  years.indexOf(employements?.[i]?.yearStarted),
                  years.length,
                )}
                value={employements?.[i]?.yearCompleted}
                setValue={setCurrentlySelectedYearCompleted}
                helperValue={i}
                setHelperValue={setIndex}
                modalHeading={'Year To'}
              />
            </View>
          </View>

          {employements?.length != 0 && (
            <TouchableOpacity
              style={{
                alignItems: 'center',
                padding: 5,
                flexDirection: 'row',
                justifyContent: 'center',
              }}
              onPress={() => deleteEmployementHandler(i)}
            >
              <Icon name="delete-outline" color={Color.red} size={20} />
              {i === employementsCount - 1 && (
                <TouchableOpacity
                  disabled={ifAnyEmployementsFieldIsEmpty(i)}
                  onPress={onPressAddMore}
                  style={{ flexDirection: 'row', alignItems: 'center' }}
                >
                  <Icon
                    name="add"
                    color={ifAnyEmployementsFieldIsEmpty(i) ? Color.gray : '#8041C6'}
                    size={16}
                  />
                  <Text
                    style={
                      ifAnyEmployementsFieldIsEmpty(i)
                        ? styles.disabledAddMoreButton
                        : styles.addMoreButton
                    }
                  >
                    Add More
                  </Text>
                </TouchableOpacity>
              )}
            </TouchableOpacity>
          )}
          <View style={styles.divider}></View>
        </View>,
      );
    }
    return components;
  }

  //Editting empoyement history
  useEffect(() => {
    if (employements[index] && currentlySelectedCompany !== null) {
      setEmployements((prev) => {
        prev[index].companyName = currentlySelectedCompany;
        return [...prev];
      });
    }
  }, [currentlySelectedCompany]);

  useEffect(() => {
    if (employements[index] && currentlySelectedYearStarted) {
      setEmployements((prev) => {
        prev[index].yearStarted = currentlySelectedYearStarted;

        if (
          employements[index].yearCompleted != '' &&
          parseInt(currentlySelectedYearStarted) > parseInt(employements[index].yearCompleted)
        )
          prev[index].yearCompleted = '';

        return [...prev];
      });
    }
  }, [currentlySelectedYearStarted]);

  useEffect(() => {
    if (employements[index] && currentlySelectedYearCompleted) {
      setEmployements((prev) => {
        prev[index].yearCompleted = currentlySelectedYearCompleted;
        return [...prev];
      });
    }
  }, [currentlySelectedYearCompleted]);

  useEffect(() => {
    if (employements[index] && currentlySelectedPosition !== null) {
      setEmployements((prev) => {
        prev[index].position = currentlySelectedPosition;
        return [...prev];
      });
    }
  }, [currentlySelectedPosition]);

  //Adding employement history
  useEffect(() => {
    if (
      !employements[index] &&
      (currentlySelectedCompany !== null ||
        currentlySelectedYearStarted ||
        currentlySelectedYearCompleted ||
        currentlySelectedPosition !== null)
    ) {
      setEmployements((prev) => [
        ...prev,
        {
          companyName: currentlySelectedCompany,
          yearCompleted: currentlySelectedYearCompleted,
          yearStarted: currentlySelectedYearStarted,
          position: currentlySelectedPosition,
        },
      ]);
    }
  }, [
    currentlySelectedCompany,
    currentlySelectedYearStarted,
    currentlySelectedYearCompleted,
    currentlySelectedPosition,
  ]);

  return (
    <View style={styles.container}>
      <Text style={styles.infoLabel}>About Career</Text>
      <View style={{ marginTop: 4, marginBottom: 16 }}>
        <ModalAdornment
          isInputText={true}
          modalHeading="Write about your career"
          placeHolder={aboutCareerPlaceholder}
          value={aboutCareer}
          inputTextFunction={setAboutCareer}
        />
      </View>
      <View>
        <Text style={styles.infoLabel}>Profession</Text>
        <ModalAdornment
          placeHolder="Select"
          modalItems={professions}
          value={profession}
          setValue={setProfession}
          modalHeading={'Profession'}
          maxValueLength={50}
        />
      </View>
      <View>
        <Text style={styles.infoLabel}>Employement Type</Text>
        <ModalAdornment
          placeHolder="Select"
          modalItems={Object.values(EmploymentType)}
          value={employementType}
          setValue={setEmployementType}
          modalHeading={'Employement Type'}
          maxValueLength={50}
        />
      </View>
      <View style={styles.row}>
        <View style={{ width: width * 0.45 }}>
          <Text style={styles.infoLabel}>Setting Abroad</Text>
          <ModalAdornment
            placeHolder="Select"
            modalItems={Object.values(SettingAbroad)}
            value={settlingAbroad}
            setValue={setSettlingAbroad}
            modalHeading={'Setting Abroad'}
            maxValueLength={GenUtil.isWEB() ? 28 : 18}
          />
        </View>
        <View style={{ width: width * 0.45 }}>
          <Text style={styles.infoLabel}>Income (in LPA)</Text>
          <ModalAdornment
            placeHolder="In LPA"
            modalItems={incomeOptions}
            value={income}
            setValue={setIncome}
            modalHeading={'Income (in LPA)'}
          />
        </View>
      </View>

      {renderEmployementHistorySection()}

      <View style={styles.saveButtonWrapper}>
        <SaveButton buttonText="Save" isValidating={isValidating} handleKeyPress={handleSave} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 5,
    marginHorizontal: 5,
  },
  header: {
    marginBottom: 10,
    fontFamily: EFonts.SORA_MEDIUM,
    fontSize: 17,
    lineHeight: 18,
    color: Color.lightBlack,
  },
  aboutTextStyle: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    borderWidth: 1,
    borderColor: '#D6D6D6',
    lineHeight: 24,
    padding: 9,
    borderRadius: 8,
    marginTop: 4,
    marginBottom: 16,
  },
  infoLabel: {
    flex: 1,
    fontFamily: EFonts.SORA_REGULAR,
    fontStyle: 'normal',
    fontSize: GenUtil.isWEB() ? 12 : 10,
    lineHeight: 20,
    color: Color.lightGrey,
    alignItems: 'center',
    marginLeft: 12,
  },
  tittle: {
    color: '#2E2835',
    fontSize: 12,
    fontFamily: EFonts.SORA_SEMIBOLD,
    marginBottom: 10,
    marginLeft: 5,
  },
  row: {
    flexDirection: 'row',
    // flex: 1,
    marginVertical: 5,
    justifyContent: 'space-between',
  },
  addMoreButton: {
    fontSize: 12,
    fontFamily: EFonts.SORA_MEDIUM,
    color: '#8041C6',
  },
  disabledAddMoreButton: {
    fontSize: 12,
    fontFamily: EFonts.SORA_MEDIUM,
    color: Color.gray,
  },
  divider: {
    marginVertical: 15,
    borderWidth: 1,
    borderStyle: 'dashed',
    width: '100%',
    borderColor: '#D6D6D6',
  },
  saveButtonWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});
