import { View, Text } from 'src/components/Themed';
import React from 'react';
import { StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import { Avatar } from 'react-native-paper';
import { Icon } from 'react-native-elements';

import { Gender } from 'src/common/models/profile.model';
import { GenUtil } from 'src/util/GenUtil';
import { Color } from 'src/constant/Color';
import { LocationType } from 'src/common/models/location.model';

const { width } = GenUtil.getDimension();

const OnboardingSelectedDetailsComp = ({
  name = null,
  gender = null,
  birthDay = null,
  location = null,
  religion = null,
  profession = null,
  maritalStatus = null,
  childStatus = null,
  degree = null,
  setActiveStep = (_) => {},
}) => {
  const Item = ({ text, step, iconName, iconType }) => {
    return (
      <View style={styles.item}>
        <Icon
          name={iconName}
          type={iconType}
          size={15}
          color={Color.lightGrey}
          style={{ marginRight: 10 }}
        />
        <View style={styles.itemTextWrapper}>
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            <Text style={styles.text}>{text}</Text>
          </ScrollView>
        </View>
        <TouchableOpacity onPress={() => setActiveStep(step)}>
          <Icon name="pencil" type="ionicon" size={15} color={Color.purple} />
        </TouchableOpacity>
      </View>
    );
  };
  return (
    <View style={styles.container}>
      <View style={styles.contentWrapper}>
        {gender && name && birthDay && (
          <View>
            <Avatar.Image
              size={50}
              source={
                gender === Gender.Male
                  ? require('src/assets/images/placeholder-male.png')
                  : require('src/assets/images/placeholder-female.png')
              }
            />
          </View>
        )}
        {name && birthDay && (
          <View style={styles.content}>
            <View style={styles.item}>
              <Text style={styles.nameText}>{name}</Text>
              <TouchableOpacity onPress={() => setActiveStep('info')}>
                <Icon name="pencil" type="ionicon" size={15} color={Color.purple} />
              </TouchableOpacity>
            </View>
            <Item text={birthDay} step="info" iconName="gift" iconType="ionicon" />
            {location &&
              GenUtil.getLocationName(
                location.cityName,
                location.stateName,
                location.countryName,
                LocationType.City,
              ) && (
              <Item
                text={GenUtil.getLocationName(
                  location.cityName,
                  location.stateName,
                  location.countryName,
                  LocationType.City,
                )}
                step="location"
                iconName="location"
                iconType="ionicon"
              />
            )}
            {religion && religion.religion && (
              <Item
                text={`${religion.religion}${
                  religion?.subCommunity?.name ? ', ' + religion?.subCommunity?.name : ''
                }`}
                step="religion"
                iconName="pray"
                iconType="font-awesome-5"
              />
            )}
            {profession && (
              <Item
                text={profession?.name}
                step="profession"
                iconName="user-md"
                iconType="font-awesome-5"
              />
            )}
            {maritalStatus && (
              <Item
                text={maritalStatus}
                step='maritalstatus'
                iconName="ring"
                iconType="font-awesome-5"
              />
            )}
            {degree && (
              <Item
                text={degree?.name}
                step="degree"
                iconName="ribbon-outline"
                iconType="ionicon"
              />
            )}
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: width * 0.05,
    marginTop: GenUtil.isWEB() ? 10 : 0,
    marginBottom: 15,
  },
  contentWrapper: {
    flexDirection: 'row',
    padding: 10,
    borderRadius: 10,
    shadowOffset: {
      width: 3,
      height: 3,
    },
    shadowOpacity: 0.5,
    shadowRadius: 2,
    shadowColor: Color.gray,
    elevation: 10,
  },
  content: {
    marginHorizontal: 15,
  },
  nameText: {
    fontFamily: 'sora-semibold',
    color: Color.black,
    fontSize: 18,
    marginRight: 10,
  },
  text: {
    fontFamily: 'sora-regular',
    color: Color.lightGrey,
    fontSize: 13,
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  itemTextWrapper: {
    maxWidth: width * 0.5,
    marginRight: 10,
  },
});

export default OnboardingSelectedDetailsComp;
