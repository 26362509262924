import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { WebRootStackParamList } from 'types';

const Weblinking: LinkingOptions<WebRootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      user: {
        path: 'user',
        screens: {
          data: {
            screens: {
              home: 'home',
              partnerpreferences: 'partnerpreferences',
              blockedprofiles: 'blockedprofiles',
              phonebook: 'phonebook',
              rateus: 'rateus',
              editprofile: {
                screens: {
                  editprofile: 'editprofile',
                  editphotoscreen: 'editphotoscreen',
                },
              },
              settings: {
                screens: {
                  settings: 'settings',
                  editprivacypolicyscreen: 'editprivacypolicyscreen',
                  notifications: 'notifications',
                },
              },
              activity: {
                screens: {
                  ActivityScreen: 'activity',
                },
              },
              dynamicprofileslist: 'dynamicprofileslist',
            },
          },
          otherpersonprofile: 'otherpersonprofile',
          search: {
            screens: {
              search: 'search',
              searchedprofilesscreen: 'searchedprofiles',
              searchfiltersscreen: 'searchfilters',
            },
          },
          messenger: {
            path: 'chat',
            screens: {
              InboxScreen: 'inboxscreen',
              MessageScreen: 'messagescreen',
            },
          },
          membership: 'membership',
        },
      },
      intro: 'intro',
      welcome: 'welcome',
      login: 'login',
      forgetpassowrd: 'forget',
      onboardingdetails: 'onboardingdetails',

      Modal: 'modal',
      notfound: '*', // Keep it unchanged
    },
  },
};

export default Weblinking;
