/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  StyleSheet,
  TextInput,
  TouchableOpacity,
  Image,
  ScrollView,
  ActivityIndicator,
} from 'react-native';
import { View, Text } from 'src/components/Themed';
import Modal from 'react-native-modal';
import React, { useEffect, useState } from 'react';
import { Color } from 'src/constant/Color';
import Icon from 'react-native-vector-icons/FontAwesome';
import EFonts from 'src/constant/EFonts';
import { GenUtil } from 'src/util/GenUtil';
import SaveButton from 'src/components/editProfileComponents/SaveButton';
import DOESNT_MATTER_OPTION from 'src/constant/DoesntMatter';
const { height, width } = GenUtil.getDimension();

const ModalAdornment = ({
  icon = null,
  disabled = false,
  preprocessItems = null,
  modalItems = [],
  placeHolder = '',
  value = '',
  setValue = (_) => {},
  helperValue = null,
  setHelperValue = (_) => {},
  isMultiSelect = false,
  selectedValues = [],
  setSelectedValues = (_) => {},
  styleOverride = false,
  searchedLocation = '',
  setSearchedLocation = (_) => {},
  isLocation = false,
  isReligiousBelief = false,
  label = '',
  isInputText = false,
  onPressDone = null,
  isDoneValidating = false,
  religiousBeliefs = {} as { [key: string]: string },
  setReligiousBeliefs = (_) => {},
  inputTextFunction = (_) => {},
  maxValueLength = 12,
  modalHeading = '',
  isHeight = false,
  setItemAsHelperValue = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputText, setInputText] = useState(isInputText && value ? value : '');
  const [filteredModalItems, setFilteredModalItems] = useState(modalItems);
  const filteredLocationModalItems =
    searchedLocation === ''
      ? modalItems
      : modalItems.filter((item) => item.toLowerCase().includes(searchedLocation.toLowerCase()));

  useEffect(() => {
    if (filteredModalItems?.[0] === modalItems?.[0]) return;
    setFilteredModalItems(modalItems);
  }, [modalItems, isModalOpen]);

  const handleSearch = (text) => {
    if (text === '') {
      setFilteredModalItems(modalItems);
    } else {
      const filteredItems = modalItems.filter((item) =>
        (preprocessItems ? preprocessItems(item) : item)
          .toString()
          .toLowerCase()
          .includes(text.toLowerCase()),
      );
      setFilteredModalItems(filteredItems);
    }
  };

  const handleSave = () => {
    inputTextFunction && inputTextFunction(inputText);
    setIsModalOpen(false);
  };
  const handleLocationSearch = (text) => setSearchedLocation(text);

  const handleModalItemSelect = (item) => {
    // console.log(item);
    setHelperValue(setItemAsHelperValue ? item : helperValue);
    if (isReligiousBelief) setReligiousBeliefs({ ...religiousBeliefs, [label]: item });
    else setValue(item);

    if (!selectedValues.includes(item)) {
      if (item === DOESNT_MATTER_OPTION || item === DOESNT_MATTER_OPTION.name)
        setSelectedValues([item]);
      else
        setSelectedValues((prev) => [
          ...prev.filter(
            (val) => val !== DOESNT_MATTER_OPTION && val !== DOESNT_MATTER_OPTION.name,
          ),
          item,
        ]);
    } else
      setSelectedValues((prev) => {
        prev.splice(prev.indexOf(item), 1);
        if (prev.length === 0) {
          prev = [DOESNT_MATTER_OPTION.name];
        }
        return [...prev];
      });
    if (!isMultiSelect) setIsModalOpen(false);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <TouchableOpacity
      disabled={disabled}
      style={
        styleOverride
          ? StyleSheet.compose(styles.wrapper, styles.wrapperOverride)
          : [styles.wrapper, { borderRadius: isInputText ? 8 : 20 }]
      }
      onPress={() => setIsModalOpen(true)}
    >
      {icon && <TouchableOpacity style={styles.icon}>{icon}</TouchableOpacity>}
      {!isInputText ? (
        <>
          {!isReligiousBelief ? (
            <Text style={styleOverride ? styles.inputAlter : styles.input}>
              {value === ''
                ? placeHolder
                : value?.length > maxValueLength
                ? value.slice(0, maxValueLength) + '...'
                : value}{' '}
            </Text>
          ) : (
            <Text style={styleOverride ? styles.inputAlter : styles.input}>
              {religiousBeliefs?.[label] === ''
                ? placeHolder
                : religiousBeliefs?.[label]?.length > 24
                ? religiousBeliefs?.[label]?.slice(0, 20) + '...'
                : religiousBeliefs?.[label]}{' '}
            </Text>
          )}
          <TouchableOpacity style={styles.icon} onPress={() => setIsModalOpen(true)}>
            <Image
              source={require('../../../assets/icons/Vectorchevrondown.png')}
              style={{ width: 12, height: 6 }}
            />
          </TouchableOpacity>
        </>
      ) : (
        <TouchableOpacity style={styles.TextButton} onPress={() => setIsModalOpen(true)}>
          {value.length > 0 ? (
            <ScrollView style={{ maxHeight: 100 }} showsVerticalScrollIndicator={false}>
              <Text style={styles.TextButton}>{value === '' ? placeHolder : value.replace(/^./, value[0].toUpperCase())}</Text>
            </ScrollView>
          ) : (
            <Text style={styles.placeHolderText}>{placeHolder}</Text>
          )}
        </TouchableOpacity>
      )}

      {isModalOpen && (
        <Modal
          isVisible={isModalOpen}
          onDismiss={handleModalClose}
          onBackButtonPress={handleModalClose}
          onBackdropPress={handleModalClose}
        >
          <View
            style={[
              styles.modalContainer,
              { height: isInputText ? 350 : height * 0.6 },
              GenUtil.isWEB() ? { margin: 'auto' } : {},
            ]}
          >
            <View style={styles.modalHeader}>
              <Text style={styles.modalHeaderText}>
                {isInputText ? (modalHeading !== '' ? modalHeading : 'Enter Text') : (modalHeading !== '' ? modalHeading : 'Select Here')}
              </Text>
              <TouchableOpacity onPress={handleModalClose}>
                <Icon color={Color.lightGrey} name="close" type="material" size={18} />
              </TouchableOpacity>
            </View>
            {!isInputText && (
              <TextInput
                style={styles.searchInput}
                onChangeText={isLocation ? handleLocationSearch : handleSearch}
                placeholder="Search..."
                placeholderTextColor={Color.gray}
                autoFocus={GenUtil.isWEB() ? true : false}
              />
            )}
            <View style={styles.modalBody}>
              {isInputText ? (
                <>
                  <TextInput
                    value={inputText}
                    onChangeText={(value) => setInputText(value)}
                    placeholder={placeHolder !== '' ? placeHolder : 'Write...'}
                    placeholderTextColor={Color.gray}
                    style={styles.textInput}
                    scrollEnabled={false}
                    multiline={true}
                  />
                  <View style={styles.saveButton}>
                    <SaveButton
                      buttonText="Save"
                      handleKeyPress={handleSave}
                      buttonDisabled={inputText === ''}
                    />
                  </View>
                </>
              ) : (
                <View>
                  <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={{ height: isMultiSelect ? height * 0.37 : height * 0.42 }}
                  >
                    {(isLocation ? filteredLocationModalItems : filteredModalItems).map(
                      (item, index) => {
                        return (
                          <TouchableOpacity
                            key={index}
                            style={styles.modalItem}
                            onPress={() => handleModalItemSelect(item)}
                          >
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                              <Text style={styles.textStyle}>
                                {preprocessItems
                                  ? preprocessItems(item)
                                  : isHeight
                                  ? `${GenUtil.convertHeighttoHeightString(item)} (${item}cm)`
                                  : item}
                              </Text>
                              {(preprocessItems
                                ? selectedValues.map((s) => preprocessItems(s))
                                : selectedValues
                              ).includes(preprocessItems ? preprocessItems(item) : item) && (
                                <Image
                                  source={require('src/assets/icons/tick.png')}
                                  style={{ height: 18, width: 18 }}
                                />
                              )}
                            </View>
                          </TouchableOpacity>
                        );
                      },
                    )}
                  </ScrollView>
                  {isMultiSelect && (
                    <TouchableOpacity
                      style={{ width: '20%', alignSelf: 'center', marginTop: 10 }}
                      onPress={async () => {
                        onPressDone && (await onPressDone());
                        setIsModalOpen(false);
                      }}
                    >
                      <Text
                        style={{
                          borderColor: Color.purple,
                          borderWidth: 1,
                          borderRadius: 10,
                          fontSize: 16,
                          color: Color.purple,
                          textAlign: 'center',
                          paddingVertical: 5,
                        }}
                      >
                        {isDoneValidating ? (
                          <ActivityIndicator size={15} color={Color.purple} />
                        ) : (
                          'Done'
                        )}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              )}
            </View>
          </View>
        </Modal>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    backgroundColor: Color.white,
    borderRadius: 15,
    width: width * 0.9,
    height: height * 0.6,
  },
  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 25,
    borderBottomWidth: 1,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomColor: Color.lightGrey,
    height: 40,
  },
  modalHeaderText: {
    fontSize: 17,
    fontFamily: EFonts.DMSANS_BOLD,
    color: Color.purple,
  },
  modalBody: {
    flex: 1,
    padding: 20,
    borderRadius: 20,
    height: GenUtil.isWEB() ? height * 0.6 : null,
  },
  searchInput: {
    backgroundColor: Color.backgroundGray,
    color: Color.black,
    borderRadius: 5,
    paddingVertical: 5,
    paddingHorizontal: 10,
    marginTop: 15,
    marginHorizontal: 20,
    borderWidth: 1,
    borderColor: Color.purple,
    outlineStyle: 'none',
  },
  input: {
    backgroundColor: Color.white,
    flex: 1,
    fontSize: 12,
    lineHeight: 15,
    fontFamily: EFonts.SORA_REGULAR,
    color: Color.lightBlack,
    paddingRight: 5,
    marginLeft: 10.83,
    outlineStyle: 'none',
    width: width * 0.1,
  },
  inputAlter: {
    backgroundColor: Color.white,
    flex: 1,
    fontSize: 12,
    lineHeight: 15,
    fontFamily: EFonts.SORA_REGULAR,
    color: Color.lightGrey,
    paddingRight: 5,
    marginLeft: 10.83,
    outlineStyle: 'none',
    width: width * 0.1,
  },
  inputOverride: {
    backgroundColor: Color.white,
  },
  icon: {
    paddingVertical: 3,
  },
  wrapper: {
    flexDirection: 'row',
    minHeight: 38,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 20,
    backgroundColor: Color.white,
    paddingVertical: 5,
    paddingHorizontal: 10,
    marginVertical: 5,
    marginRight: 5,
    borderWidth: 1,
    borderColor: Color.lightBorder,
  },
  wrapperOverride: {
    backgroundColor: Color.white,
    borderWidth: 1,
    borderRadius: 80,
  },

  modalItem: {
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: Color.gray,
  },
  textStyle: {
    fontFamily: EFonts.SORA_REGULAR,
  },
  TextButton: {
    width: width * 0.83,
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    lineHeight: 24,
  },
  textInput: {
    borderColor: '#D6D6D6',
    borderWidth: 1,
    textAlignVertical: 'top',
    overflow: 'hidden',
    borderRadius: 8,
    padding: 5,
    outlineStyle: 'none',
    fontFamily: EFonts.SORA_REGULAR,
    height: '100%',
  },
  saveButton: {
    marginTop: 10,
    alignSelf: 'center',
  },
  placeHolderText: {
    fontFamily: EFonts.SORA_REGULAR,
    fontStyle: 'normal',
    fontSize: 12,
    lineHeight: 20,
    color: Color.gray,
  },
});

export default ModalAdornment;
