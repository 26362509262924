import { StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native';
import { Color } from 'src/constant/Color';
import ChatSvg from 'src/assets/icons/chat.svg';
import { ProfileResp } from 'src/common/response/profile.resp';
import { ProfileAction, ProfileActionName } from 'src/common/models/profile.model';
import { useNavigation } from '@react-navigation/native';
import { GenUtil } from 'src/util/GenUtil';

type Props = {
  profile: Partial<ProfileResp>;
  style?: ViewStyle;
};

export default function MessageAction({ profile, style }: Props) {
  const navigation = useNavigation();

  const onChatPress = () => {
    const parentScreen = GenUtil.isDesktopWeb() ? 'messenger' : 'Messenger';
    navigation.navigate(parentScreen, { screen: 'MessageScreen', params: { uuid: profile.uuid } });
  };

  if (!profile.actions?.some((action: ProfileAction) => action.name === ProfileActionName.Chat))
    return <></>;
  return (
    <TouchableOpacity style={[styles.chatIcon, style]} onPress={onChatPress}>
      <ChatSvg color={Color.white} />
      {profile?.chat?.unreadMessages > 0 ? (
        <View style={styles.messageCount}>
          <Text style={styles.messageText}>{profile?.chat?.unreadMessages}</Text>
        </View>
      ) : (
        <></>
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  chatIcon: {
    width: 38,
    height: 38,
    borderRadius: 100,
    borderColor: Color.white,
    borderWidth: 1.5,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  messageCount: {
    width: 17,
    height: 17,
    backgroundColor: '#FF0000',
    borderRadius: 50,
    position: 'absolute',
    top: -5,
    right: -5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  messageText: {
    fontSize: 11,
    color: Color.white,
    font: 'dmsans-bold',
    fontWeight: '600',
  },
});
