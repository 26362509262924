import React, { useEffect, useState } from 'react';
import { FlatList, Image, Text, View } from 'react-native';
import { NavigationProp, ParamListBase } from '@react-navigation/native';
// TODO: instead of react-native-elements, use react-native-paper or build it custom, across the codebase, not just this file
import { Avatar, Icon, ListItem } from 'react-native-elements';
import { useSelector } from 'react-redux';
import { firestore } from 'firebaseConfig';
import { ActivityIndicator, Badge } from 'react-native-paper';
import firebase from 'firebase/compat';

import { inboxStyles } from 'src/components/InboxScreen.styles';
import { strings } from 'src/util/LocalizationUtil';
import { Color } from 'src/constant/Color';
import { FullLoader } from 'src/components/loader/FullLoader';
import FullScreenImage from 'src/components/common/FullScreenImageViewer';
import DataUtil from 'src/util/DataUtil';
import { RootState } from 'src/store/store';
import { ChatCategory, ChatMessage, ChatMessageType } from 'src/common/models/chat.model';
import { ProfileResp } from 'src/common/response/profile.resp';
import { ChatResp } from 'src/common/response/chat.resp';
import ChipWithBadge from 'src/components/common/chips/ChipWithBadge';
import SafeContainer from './SafeContainer';
import { GenUtil } from 'src/util/GenUtil';
import VerticalFlashList from './VerticalFlashList';
import ChatUtil from 'src/util/ChatUtil';

const GROUP_SIZE = 10;

type DocumentSnapshot = firebase.firestore.DocumentSnapshot;

interface Props {
  navigation: NavigationProp<ParamListBase>;
}

type Category = 'accepted' | 'received' | 'sent' | 'filtered' | 'archived';
type ChatGroups = Record<Category, ProfileResp[]>;

const InboxScreen: React.FC<Props> = ({ navigation }) => {
  const [groups, setGroups] = useState<ProfileResp[]>([]);
  const [loading, setLoading] = useState(false);
  const [fullScreenVisible, setFullScreenVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState({});
  const [categories] = useState([
    ChatCategory.Accepted,
    'Received',
    'Sent',
    ChatCategory.Filtered,
    ChatCategory.Archived,
  ]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [lastVisible, setLastVisible] = useState<DocumentSnapshot>();
  const [listeners, setListeners] = useState<firebase.Unsubscribe[]>([]);
  const [chatStats, setChatStats] = useState({
    accepted: 0,
    declined: 0,
    pending: 0,
    filtered: 0,
    archived: 0,
  });
  const [chatGroups, setChatGroups] = useState<ChatGroups>({
    accepted: [],
    received: [],
    sent: [],
    filtered: [],
    archived: [],
  });

  const loggedInUser = useSelector((state: RootState) => state.user.user);
  const loggedInUserProfileUuid = loggedInUser?.profileUuid;
  const loggedInUserUuid = loggedInUser?.uuid;

  const getStats = () => {
    setChatStats({
      accepted: 3,
      declined: 4,
      pending: 5,
      filtered: 6,
      archived: 7,
    });
  };

  const handleCategoryClicked = (index: number, category: string) => {
    setSelectedIndex(index);
    getGroupForUser(category);
  };

  const collectionRef = firestore.collection('chat-groups');

  const acceptedChatsQuery = collectionRef
    .where('profileUuids', 'array-contains-any', [loggedInUserProfileUuid])
    .where('chatCategory', '==', 'Accepted')
    .orderBy('updatedAt', 'desc');

  const receivedChatsQuery = collectionRef
    .where('interestReceiverProfileUuid', '==', loggedInUserProfileUuid)
    .where('chatCategory', '==', 'Pending')
    .orderBy('updatedAt', 'desc');

  const sentChatsQuery = collectionRef
    .where('interestSenderProfileUuid', '==', loggedInUserProfileUuid)
    .where('chatCategory', 'in', ['Pending', 'Archived', 'Filtered'])
    .orderBy('updatedAt', 'desc');

  const filteredChatsQuery = collectionRef
    .where('interestReceiverProfileUuid', '==', loggedInUserProfileUuid)
    .where('chatCategory', '==', 'Filtered')
    .orderBy('updatedAt', 'desc');

  const archivedChatsQuery = collectionRef
    .where('interestReceiverProfileUuid', '==', loggedInUserProfileUuid)
    .where('chatCategory', '==', 'Archived')
    .orderBy('updatedAt', 'desc');

  const getQuery = (chatCategory) => {
    let collectionRef: firebase.firestore.Query;
    if (chatCategory === ChatCategory.Accepted) {
      collectionRef = acceptedChatsQuery;
    } else if (chatCategory === 'Received') {
      collectionRef = receivedChatsQuery;
    } else if (chatCategory === 'Sent') {
      collectionRef = sentChatsQuery;
    } else if (chatCategory === ChatCategory.Filtered) {
      collectionRef = filteredChatsQuery;
    } else if (chatCategory === ChatCategory.Archived) {
      collectionRef = archivedChatsQuery;
    }

    return collectionRef;
  };

  const getGroupForUser = (chatCategory: string) => {
    try {
      const category: Category = chatCategory.toLocaleLowerCase() as Category;
      if (chatGroups[category].length > 0) {
        setGroups(chatGroups[category]);
        return;
      }

      setLoading(true);
      let collectionRef = getQuery(chatCategory);
      collectionRef = collectionRef.limit(GROUP_SIZE);

      const unSubscribe = collectionRef.onSnapshot((querySnap) => {
        const lastVisible = querySnap.docs[querySnap.docs.length - 1];

        setLastVisible(lastVisible);
        const chats = querySnap.docs.map((docSnap) => {
          const data = docSnap.data() as ChatResp;
          return data;
        });

        const groupPromise = ChatUtil.mergeChatGroupsWithUserDetails(
          chats,
          loggedInUserProfileUuid,
        );
        groupPromise
          .then((response) => {
            setGroups(response);
            saveChats(response, category);
          })
          .catch((error) => console.log('error in group promise=', error))
          .finally(() => setLoading(false));
      });

      setListeners([unSubscribe]);
    } catch (error) {
      console.log('error in fetching grps', error);
      setLoading(false);
    }
  };

  const saveChats = (groups: ProfileResp[], chatCategory: Category, appendGroups = false) => {
    setChatGroups((prevChatGroups) => {
      const updatedGroups: ProfileResp[] = appendGroups
        ? [...prevChatGroups[chatCategory], ...groups]
        : groups;
      return { ...prevChatGroups, [chatCategory]: updatedGroups };
    });
  };

  const loadMoreGroups = () => {
    const chatCategory = categories[selectedIndex];
    let query = getQuery(chatCategory);
    query = query.startAfter(lastVisible).limit(GROUP_SIZE);
    const category = chatCategory.toLocaleLowerCase() as Category;

    const unSubscribe: firebase.Unsubscribe = query.onSnapshot((querySnap) => {
      const lastVisible = querySnap.docs.length && querySnap.docs[querySnap.docs.length - 1];

      setLastVisible(lastVisible);

      const chats = querySnap.docs.map((docSanp) => {
        const data = docSanp.data() as ChatResp;
        return data;
      });

      const groupPromise = ChatUtil.mergeChatGroupsWithUserDetails(chats, loggedInUserProfileUuid);
      groupPromise
        .then((response) => {
          setGroups([...groups, ...response]);
          saveChats(response, category, true);
        })
        .catch((error) => console.log('error in groupPromise = ', error));
    });

    setListeners((prevListeners) => [...prevListeners, unSubscribe]);
  };

  // // load screen data
  useEffect(() => {
    getGroupForUser(ChatCategory.Accepted);
    setSelectedIndex(0);
    getStats();

    return () => {
      listeners.map((unSubscribe) => unSubscribe()); //unsubscribing listeners
    };
  }, []);

  const onChatPress = (index: number) => {
    const group = encodeURIComponent(JSON.stringify(groups[index]));
    navigation.navigate('MessageScreen', { group });
  };

  const getLastChatMessage = (message: ChatMessage) => {
    if (
      message.messageType === ChatMessageType.Blocked ||
      message.messageType === ChatMessageType.UnBlocked ||
      message.messageType === ChatMessageType.InterestArchived
    )
      return;

    if (
      message.messageType === ChatMessageType.InterestAccepted ||
      message.messageType === ChatMessageType.InterestDeclined
    )
      return message.messageType;

    if (message.messageType === ChatMessageType.InterestSent) {
      if (message.interestReceiverProfileUuid === loggedInUserProfileUuid) {
        return 'Interest Received';
      } else return message.messageType;
    }
  };

  const emptyListMessage = () => {
    return (
      <View style={inboxStyles.emptyListContainer}>
        <Text style={inboxStyles.emptyListMessage}>{strings.empty_message_list}</Text>
        <Icon
          containerStyle={{ padding: 10 }}
          name="frown"
          type="font-awesome-5"
          color={Color.white}
          size={25}
        />
      </View>
    );
  };

  return (
    <SafeContainer
      showPageHeader={true}
      heading="Chats"
      style={inboxStyles.container}
      leftIcon={<Icon name="arrow-back" />}
      onPressLeftIcon={() => navigation.navigate(GenUtil.isDesktopWeb() ? 'data' : 'Home')}
    >
      {loading ? (
        <View style={inboxStyles.loaderContainer}>
          <FullLoader message={''} />
          <ActivityIndicator />
        </View>
      ) : (
        <View style={{ flex: 1 }}>
          <View style={inboxStyles.categoriesContainer}>
            {categories.map((category, index) => {
              const isSelected = index === selectedIndex;

              return (
                <View style={{ marginRight: 10, marginBottom: 10 }} key={index}>
                  <ChipWithBadge
                    isSelected={isSelected}
                    onPress={() => handleCategoryClicked(index, category)}
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    badgeValue={chatStats[category.toLocaleLowerCase()]}
                    title={category}
                    showBadge={false}
                  />
                </View>
              );
            })}
          </View>
          <View>
            {imageUrl ? (
              <FullScreenImage
                hideFullScreen={() => setFullScreenVisible(false)}
                visible={fullScreenVisible}
                images={[imageUrl]}
              />
            ) : null}
          </View>
          <View style={inboxStyles.groupsContainer}>
            {groups?.length ? (
              <VerticalFlashList
                data={groups}
                renderItem={({ item: group, index }: { item: ProfileResp; index: number }) => {
                  const maleAvatar = 'src/assets/images/placeholder-male.png';
                  const femaleAvatar = 'src/assets/images/placeholder-female.png';
                  const profilePhoto = group?.profilePhoto?.originalUrl;
                  const photoUrl = !group?.profilePhoto.originalUrl
                    ? group.gender === 'Male'
                      ? maleAvatar
                      : femaleAvatar
                    : profilePhoto;

                  const imageProps = { source: GenUtil.getProfilePic(group) };
                  const lastMessageType = group?.chat?.lastChatMessage?.messageType;
                  const isUnreadMessage = group?.chat?.usersUnreadMessages[loggedInUserUuid] > 0;

                  return (
                    <View>                    
                      <ListItem
                        containerStyle={inboxStyles.groupItem}
                        key={group.uuid}
                        onPress={() => onChatPress(index)}
                        bottomDivider
                      >
                        <Avatar
                          size="medium"
                          avatarStyle={inboxStyles.groupAvatar}
                          rounded
                          onPress={() => {
                            setImageUrl({
                              ...(photoUrl.includes('src/assets/images')
                                ? { url: imageProps.source }
                                : { url: photoUrl }),
                            });
                            setFullScreenVisible(true);
                          }}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...imageProps}
                        />

                        <ListItem.Content>
                          <View
                            style={{
                              flexDirection: 'row',
                              width: '100%',
                            }}
                          >
                            <Text style={inboxStyles.chatName} numberOfLines={1}>
                              {group.fullName}
                            </Text>
                            <Text style={inboxStyles.basicDetails} numberOfLines={1}>
                              {group?.age && group.age} Years
                              {group?.currentCity && `, ${group.currentCity}`}
                            </Text>
                          </View>
                          <View
                            style={{
                              width: '100%',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            {lastMessageType === 'image' ? (
                              <Icon
                                style={inboxStyles.cameraIcon}
                                size={18}
                                name="camera"
                                type="font-awesome"
                                color={Color.gray}
                              />
                            ) : (
                              <View style={{ flex: 1 }}>
                                <Text style={inboxStyles.lastMessage} numberOfLines={1}>
                                  {group?.chat?.lastChatMessage?.messageType === 'text'
                                    ? DataUtil.getFirstLine(group?.chat?.lastChatMessage?.content)
                                    : getLastChatMessage(group?.chat?.lastChatMessage)}
                                </Text>
                              </View>
                            )}
                          </View>
                        </ListItem.Content>
                        {isUnreadMessage && (
                          <View>
                            <Badge style={{ backgroundColor: '#FFA242', color: Color.white }}>
                              {group?.chat?.usersUnreadMessages[loggedInUserUuid]}
                            </Badge>
                          </View>
                        )}
                        {group.chat.lastChatMessage.senderProfileUuid !== loggedInUserProfileUuid &&
                        !isUnreadMessage ? (
                          <View style={inboxStyles.enterIconContainer}>
                            <Image
                              style={{ height: 8, width: 8 }}
                              source={require('src/assets/icons/enter_icon.png')}
                            />
                          </View>
                        ) : null}
                      </ListItem>
                    </View>
                  );
                }}
                estimatedItemSize={90}
                onEndReached={loadMoreGroups}
              />
            ) : (
              emptyListMessage()
            )}
          </View>
        </View>
      )}
    </SafeContainer>
  );
};

export default InboxScreen;
