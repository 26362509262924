import { Text, View } from 'src/components/Themed';
import { StyleSheet } from 'react-native';

import { Color } from 'src/constant/Color';
import { ProfileResp } from 'src/common/response/profile.resp';
import EFonts from 'src/constant/EFonts';

const UserProfileReligionBeliefComp = ({ profileData }: { profileData: Partial<ProfileResp> }) => {
  return (
    <>
      {profileData.religionExtra?.religiousBeliefs?.length ? (
        <View style={styles.container}>
          <Text style={styles.heading}>Religion Beliefs</Text>
          <View style={styles.subheadingsContainer}>
            <Text style={styles.subHeading}>Questions</Text>
            <Text style={styles.subHeading}>Answers</Text>
          </View>

          {profileData.religionExtra?.religiousBeliefs?.map((res, index) => {
            return (
              <View key={index}>
                <View style={styles.row}>
                  <View style={styles.questionTextContainer}>
                    <Text style={styles.serialNosAndAnswers}>{index + 1}.</Text>
                    <Text style={styles.questionText}>{res?.label}</Text>
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text style={[styles.serialNosAndAnswers, { alignSelf: 'flex-end' }]}>
                      {res?.value.replace(/^./, res?.value[0].toUpperCase())}
                    </Text>
                  </View>
                </View>

                {index != profileData.religionExtra?.religiousBeliefs?.length - 1 && (
                  <View style={styles.divider}></View>
                )}
              </View>
            );
          })}
        </View>
      ) : (
        <></>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
    paddingHorizontal: 12,
    paddingTop: 16,
    paddingBottom: 4,
    shadowColor: '#BEBEBE',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.22,
    shadowRadius: 24,
    marginVertical: 5,
    marginHorizontal: 16,
    elevation: 6,
  },
  heading: {
    fontSize: 17,
    fontFamily: EFonts.SORA_SEMIBOLD,
    lineHeight: 21,
    marginBottom: 16,
    color: Color.lightBlack,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 12,
  },
  subHeading: {
    color: '#A2A2A2',
    fontSize: 12,
    fontFamily: EFonts.SORA_REGULAR,
    lineHeight: 15,
  },
  subheadingsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 2,
  },
  divider: {
    borderBottomWidth: 1,
    marginLeft: 34.5,
    marginRight: 13.5,
    borderStyle: 'dashed',
    borderColor: '#D6D6D6',
  },
  questionText: {
    marginLeft: 10,
    color: Color.lightBlack,
    fontFamily: EFonts.SORA_REGULAR,
    lineHeight: 15,
    fontSize: 12,
    flex: 1,
  },
  serialNosAndAnswers: {
    fontSize: 12,
    fontFamily: EFonts.SORA_SEMIBOLD,
    color: Color.lightBlack,
    lineHeight: 15,
  },
  questionTextContainer: {
    flexDirection: 'row',
    flex: 1,
  },
});

export default UserProfileReligionBeliefComp;
