import { Alert, Image, Keyboard, StyleSheet, TextInput, TouchableOpacity } from 'react-native';
import { Text, View } from 'src/components/Themed';
import { Color } from 'src/constant/Color';
import { StackActions, useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import Onboarding from 'src/components/Onboarding';
import { useDispatch } from 'react-redux';
import { setUser, setUserProfile, signIn } from 'src/store/reducer/userReducer';
import { AuthService } from 'src/service/AuthService';
import Icon from 'react-native-vector-icons/FontAwesome';
import StorageUtil from 'src/util/StorageUtil';
import { GenUtil } from 'src/util/GenUtil';
import { signInToFirebase } from 'firebaseConfig';
import { AlertTypeEnum, showError } from 'src/store/reducer/errorReducer';
import { ProfileStatus } from 'src/common/models/profile.model';
import { ProfileUpdateService } from 'src/service/ProfileUpdateService';
import { PrivacySettingsBody } from 'src/common/dto/profile.dto';
import ConfirmationModal from 'src/components/modals/ConfirmationModal';
import EFonts from 'src/constant/EFonts';
import CountryPicker from 'react-native-country-picker-modal';

export default function SignIn({ navigation }) {
  /*
  use this credentials to login {
    "emailOrMobile": "anas.ansal10@gmail.com",
    "password":"123456"
    }
   */
  const [emailOrMobile, setEmailOrMobile] = useState('');
  const [password, setPassword] = useState('');
  const [emailValid, setEmailValid] = useState({});
  const [passwordValid, setPasswordValid] = useState({});
  const [profileStatus, setProfileStatus] = useState('live');
  const [response, setResponse] = useState();
  const [countryCode, setCountryCode] = useState('IN');
  const [country, setCountry] = useState({ cca2: 'IN', callingCode: ['91'] });
  const dispatch = useDispatch();

  const validateEmailOrMobile = (email: string) => {
    return (
      String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ) || String(email).match(/^\+[1-9]{1}[0-9]{3,14}$/)
    );
  };

  const phoneNumberRegex = /^(?:\+?(?:[0-9]\s?){6,14}[0-9]|\d{6,14}\s?\+?)$/;

  const validatePassword = (password: string) => {
    return password?.trim().length > 2;
  };

  const handleChangeEmailOrMobile = (email: string) => {
    setEmailValid(phoneNumberRegex.test(email));
    setEmailOrMobile(email);
  };

  const makeProfileLive = async () => {
    try {
      const privacySettingsBody: PrivacySettingsBody = {
        status: ProfileStatus.Live,
      };
      const response = await ProfileUpdateService.updatePrivacySettings(privacySettingsBody);
      if (!response.success) return;

      dispatch(
        showError({ message: 'Your profile is live now', alertType: AlertTypeEnum.Success }),
      );
    } catch (error) {
      dispatch(
        showError({ message: GenUtil.getMessageFromError(error), alertType: AlertTypeEnum.Error }),
      );
    }
  };

  const handleChangePassword = (password: string) => {
    setPasswordValid(validatePassword(password));
    setPassword(password);
  };

  const loginUser = (response) => {
    try {
      dispatch(setUserProfile(response.data.profile));
      dispatch(setUser(response.data.user));
      const firebaseToken: string = response.data.firebaseToken;
      signInToFirebase(firebaseToken);

      StorageUtil.setAuthTokenInLocalStorage(response.data.token);
      StorageUtil.setFirebaseAuthTokenInLocalStorage(firebaseToken);
    } catch (error) {
      console.log('Error in login user', error);
    }
  };

  const handleKeyPress = async () => {
    console.log({ emailOrMobile, password });
    Keyboard.dismiss();
    try {
      const loginBody = {
        emailOrMobile:
          emailOrMobile.charAt(0) === '+'
            ? emailOrMobile
            : `+${country.callingCode[0]}` + emailOrMobile,
        password,
      };

      const response = await AuthService.loginUser(loginBody);

      if (response.success) {
        setResponse(response);
        const status = response?.data?.profile?.status;
        setProfileStatus(status);
        if (status === 'live') {
          loginUser(response);
          navigation.navigate('user');
        }
      }
      return;
    } catch (error) {
      console.error(error);
      dispatch(
        showError({ alertType: AlertTypeEnum.Error, message: GenUtil.getMessageFromError(error) }),
      );
    }
  };

  const handleSignUp = () => {
    navigation.navigate('onboardingdetails');
    console.log('signedup!');
  };

  const handleConfirmPressed = async () => {
    loginUser(response);
    await makeProfileLive();
  };

  const handleOnCancel = () => {
    navigation.dispatch(StackActions.replace('welcome'));
  };

  return (
    <>
      {profileStatus !== 'live' ? (
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          }}
        >
          <ConfirmationModal
            cancelButtonText="Cancel"
            primaryButtonText="Confirm"
            message={`It looks like your profile is ${profileStatus}, make it visible to everyone`}
            title="Make profile visible"
            onCancel={handleOnCancel}
            onPrimaryButtonPressed={handleConfirmPressed}
            primaryButtonStyle={{ backgroundColor: Color.purple }}
          />
        </View>
      ) : (
        <Onboarding
          title="Sign In"
          buttonText="Sign In"
          Component={
            <React.Fragment>
              <Text style={styles.info}>Please enter below details to continue</Text>

              <Text style={styles.labelText}>Mobile</Text>
              <View style={styles.inputContainer}>
                {GenUtil.isWEB() ? (
                  <CountryPicker
                    countryCode={countryCode}
                    withFlag
                    withFlagButton
                    renderFlagButton={({ onOpen }) => {
                      return (
                        <TouchableOpacity onPress={onOpen} style={styles.flagButton}>
                          <View style={styles.flagImageContainer}>
                            <Image
                              source={{
                                uri: `http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`,
                              }}
                              style={{
                                width: 25,
                                height: 15,
                              }}
                            />
                            <Text style={styles.countryCodeTextStyle}>
                              +{country.callingCode[0]}
                            </Text>
                            <Image
                              source={require('src/assets/icons/Vectorchevrondown.png')}
                              style={styles.downIcon}
                            />
                          </View>
                        </TouchableOpacity>
                      );
                    }}
                    containerButtonStyle={[
                      styles.countryPicker,
                      emailValid ? styles.errorCountryPicker : null,
                    ]}
                    withCallingCode
                    withFilter
                    withEmoji
                    onSelect={(country) => {
                      setCountryCode(country.cca2);
                      setCountry({
                        cca2: country.cca2,
                        callingCode: [country.callingCode[0]],
                      });
                    }}
                  />
                ) : (
                  <CountryPicker
                    countryCode={countryCode}
                    withFlag
                    withFlagButton
                    containerButtonStyle={[
                      styles.countryPicker,
                      emailValid ? styles.errorCountryPicker : null,
                    ]}
                    withCallingCode
                    withFilter
                    withEmoji
                    onSelect={(country) => {
                      setCountryCode(country.cca2);
                      setCountry({
                        cca2: country.cca2,
                        callingCode: [country.callingCode[0]],
                      });
                    }}
                  />
                )}
                <TextInput
                  style={[styles.inputStyle, emailValid ? styles.errorInput : null]}
                  keyboardType="number-pad"
                  onChangeText={(text) => {
                    handleChangeEmailOrMobile(text);
                  }}
                  value={emailOrMobile}
                  placeholder="Mobile Number"
                  placeholderTextColor={Color.gray}
                />
              </View>
              <View
                style={
                  emailValid
                    ? styles.errorView
                    : StyleSheet.compose(styles.errorView, styles.errorViewAppearOverride)
                }
              >
                <Icon name="warning" color={Color.red} />
                <Text style={styles.errorInfo}>Enter a valid mobile number</Text>
              </View>

              <Text style={styles.labelText}>Password</Text>

              <TextInput
                style={
                  passwordValid
                    ? styles.textInput
                    : StyleSheet.compose(styles.textInput, styles.textInputErrorOverride)
                }
                value={password}
                onChangeText={(value) => handleChangePassword(value)}
                placeholder={'●●●●●●'}
                secureTextEntry
                autoCorrect={false}
                placeholderTextColor={Color.gray}
              />
              <View
                style={
                  passwordValid
                    ? styles.errorView
                    : StyleSheet.compose(styles.errorView, styles.errorViewAppearOverride)
                }
              >
                <Icon name="warning" color={Color.red} />
                <Text style={styles.errorInfo}>Password must be atleast 3 characters</Text>
              </View>
              <TouchableOpacity onPress={() => navigation.navigate('forgetpassowrd')}>
                <Text
                  style={{
                    fontFamily: EFonts.SORA_REGULAR,
                    fontSize: 12,
                    color: Color.purple,
                    paddingTop: 10,
                  }}
                >
                  Forgot password?
                </Text>
              </TouchableOpacity>
              <View style={styles.singUpMsgStyle}>
                <Text style={styles.noAcc}>Don't have an account? </Text>
                <TouchableOpacity onPress={handleSignUp}>
                  <Text style={styles.signUp}>Sign up</Text>
                </TouchableOpacity>
              </View>
            </React.Fragment>
          }
          handleKeyPress={handleKeyPress}
          buttonDisabled={
            emailOrMobile?.trim().length < 3 ||
            password?.trim().length < 3 ||
            !emailValid ||
            !passwordValid
          }
          isMinimumPaddingTop
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  textInput: {
    fontSize: 18,
    fontWeight: '700',
    color: Color.purple,
    backgroundColor: Color.white,
    width: '100%',
    paddingVertical: 12,
    paddingHorizontal: 15,
    borderRadius: 24,
    borderWidth: 1,
    borderColor: Color.lightBorder,
  },
  textInputErrorOverride: {
    borderWidth: 1,
    borderColor: Color.red,
  },
  info: {
    color: Color.gray,
    marginTop: 10,
    left: 5,
    top: -15,
    fontSize: 15,
  },
  signUp: {
    color: Color.purple,
    // top: 6,
    fontSize: 15,
    fontWeight: 'bold',
  },
  noAcc: {
    zIndex: 1,
    color: Color.gray,
    fontSize: 15,
  },
  labelText: {
    color: Color.gray,
    marginVertical: 10,
    left: 5,
  },
  errorView: {
    flexDirection: 'row',
    marginTop: 5,
    left: 5,
    opacity: 0,
    alignItems: 'center',
  },
  errorViewAppearOverride: {
    opacity: 1,
  },
  errorInfo: {
    color: Color.red,
    marginLeft: 5,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  singUpMsgStyle: {
    zIndex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 30,
    alignSelf: 'center',
  },
  flagButton: {
    marginRight: 5,
    borderRightWidth: 1,
    borderRightStyle: 'dotted',
    borderRightColor: Color.gray,
  },
  flagImageContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  countryCodeTextStyle: {
    marginLeft: 8,
    marginRight: 11,
    fontFamily: EFonts.SORA_REGULAR,
    color: Color.lightBlack,
  },
  downIcon: {
    width: 12,
    height: 6,
    marginRight: 8,
  },
  errorCountryPicker: {
    borderColor: Color.red,
  },
  countryPicker: {
    flexDirection: 'row',
    borderRightWidth: 1,
    borderRightStyle: 'dotted',
    borderRightColor: Color.gray,
    paddingRight: 10,
    paddingLeft: 5,
    marginRight: 10,
    marginTop: GenUtil.isAndroid() ? 10 : 7,
  },
  errorInput: {
    borderColor: Color.red,
  },
  inputStyle: {
    flex: 1,
    paddingVertical: GenUtil.isAndroid() ? 10 : 17,
    fontFamily: EFonts.SORA_BOLD,
    fontSize: 15,
    width: 220,
    color: Color.purple,
    marginLeft: 10,
    outlineStyle: 'none',
  },
  inputContainer: {
    flexDirection: 'row',
    borderRadius: 24,
    paddingHorizontal: 10,
    color: Color.purple,
    borderColor: Color.lightBorder,
    borderWidth: 1,
    height: 50,
  },
  errorInputContainerStyle: {
    borderColor: Color.red,
    borderWidth: 1,
  },
});
