import React from 'react';
import { ScrollView, StyleSheet, ActivityIndicator, Animated } from 'react-native';
import { useEffect, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';

import UserProfileMutualMatchesComp from 'src/components/user-profile-components/UserProfileMutualMatchesComp';
import UserProfileEducationAndCareerComp from 'src/components/user-profile-components/UserProfileEducationAndCareerComp';
import UserProfilePersonalDetailsComp from 'src/components/user-profile-components/UserProfilePersonalDetailsComp';
import UserProfileFamilyDetailsComp from 'src/components/user-profile-components/UserProfileFamilyDetailsComp';
import UserProfileAstroDetailsComp from 'src/components/user-profile-components/UserProfileAstroDetailsComp';
import UserProfilePhotosComp from 'src/components/user-profile-components/UserProfilePhotosComp';
import UserProfileBasicDetailsComp from 'src/components/user-profile-components/UserProfileBasicDetailsComp';
import UserProfileLifestyleAndHobbiesComp from 'src/components/user-profile-components/UserProfileLifestyleAndHobbiesComp';
import UserProfileReligionBeliefComp from 'src/components/user-profile-components/UserProfileReligionBeliefComp';
import UserProfilePartnerPreferenceComp from 'src/components/user-profile-components/UserProfilePartnerPreferenceComp';

import { ProfileService } from 'src/service/ProfileService';
import { InterestService } from 'src/service/InterestService';
import { Color } from 'src/constant/Color';
import { ProfileResp } from 'src/common/response/profile.resp';
import { ProfileAction, ProfileActionName, ReligionType } from 'src/common/models/profile.model';
import { View } from 'src/components/Themed';
import { GenUtil } from 'src/util/GenUtil';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import MessageAction from 'src/components/common/profileCard/actions/MessageAction';
import StarProfileAction from 'src/components/common/profileCard/actions/StarProfileAction';
import InterestAction from 'src/components/common/profileCard/actions/InterestAction';
import NotFoundScreen from 'src/screens/NotFoundScreen';

const { width } = GenUtil.getDimension();

const UserProfileScreen = ({ route, navigation }) => {
  const { uuid } = route.params;
  const [profileData, setProfileData] = useState({} as Partial<ProfileResp>);
  const [isFullScreenImageSet, setIsFullScreenImageSet] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState({
    isError: false,
    errorMessage: '',
  });
  const loggedInUserProfile = useSelector((state: RootState) => state?.user?.userProfile);
  const refresh = useSelector((state: RootState) => state.ui.refresh);

  const isViewingOwnProfile = () => {
    return uuid === loggedInUserProfile?.uuid || uuid === loggedInUserProfile?.profileId;
  };

  const initiateGetProfileDetails = async () => {
    setLoading(true);
    try {
      const response = await ProfileService.getProfileDetails(uuid);
      setProfileData(response.data);
      setLoading(false);
    } catch (error) {
      const { response } = error;
      setLoading(false);
      let message = 'Something went wrong.';
      if (response) {
        if (response.status === 400 || response.status === 403)
          message = 'You are not allowed to view this profile.';
        else if (response.status === 404)
          message = "The Profile you are looking for doesn't exist.";
      }
      setErrorState({
        isError: true,
        errorMessage: message,
      });
    }
  };

  useEffect(() => {
    initiateGetProfileDetails();
  }, [uuid, loggedInUserProfile, refresh]);

  useEffect(() => {
    if (!profileData.fullName) return;
    navigation.setOptions({
      title: `${profileData.fullName}'s profile - Matrimilan`,
    });
  }, [profileData]);

  const fadeAnim = new Animated.Value(1); // Initial value for opacity: 0

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: isFullScreenImageSet ? 0 : 1,
      duration: 200,
      useNativeDriver: true,
    }).start();
  }, [fadeAnim]);

  const sendProfileView = async () => {
    try {
      const response = await ProfileService.sendProfileView({
        profileUuid: uuid as string,
        profileViewedTimes: 1,
        lastViewedAt: new Date() as unknown as string,
      });

      if (!response.success) {
        console.log('Errror sending profile view', response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    sendProfileView();
  }, []);

  useFocusEffect(() => {
    if (
      !profileData.actions?.some(
        (action: ProfileAction) => action.name === ProfileActionName.AcceptInterest,
      )
    )
      return;

    const timer = setTimeout(async () => {
      try {
        await InterestService.sendInterestView({
          interestUuid: profileData.interest?.uuid as string,
          lastViewedAt: new Date() as unknown as string,
          viewedTimes: 1,
        });
      } catch (error) {
        console.log(error);
      }
    }, 7000); // 7 seconds dealay

    return () => clearTimeout(timer);
  });

  if (errorState.isError)
    return <NotFoundScreen navigation={navigation} title={errorState.errorMessage} />;

  return (
    <React.Fragment>
      {loading ? (
        <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
          <ActivityIndicator size={30} />
        </View>
      ) : (
        <>
          <ScrollView showsVerticalScrollIndicator={false} style={styles.container}>
            <Animated.View style={{ opacity: fadeAnim }}>
              <UserProfileBasicDetailsComp
                profileData={profileData}
                setFullScreenImage={setIsFullScreenImageSet}
                showMoreOptions={!isViewingOwnProfile()}
              />
              <UserProfilePersonalDetailsComp profileData={profileData} />
              {!isViewingOwnProfile() && (
                <UserProfileMutualMatchesComp mutualMatches={profileData.mutualMatchData} />
              )}
              <UserProfileFamilyDetailsComp profileData={profileData} />
              {profileData.medias?.length > 0 && (
                <UserProfilePhotosComp
                  profileData={profileData}
                  setFullScreenImage={setIsFullScreenImageSet}
                />
              )}
              {profileData.religion === ReligionType.Hindu && (
                <UserProfileAstroDetailsComp profileData={profileData} />
              )}
              <UserProfileReligionBeliefComp profileData={profileData} />
              <UserProfileEducationAndCareerComp
                profileData={profileData}
                type="educationDetails"
              />
              <UserProfileEducationAndCareerComp profileData={profileData} type="careerDetails" />
              <UserProfileLifestyleAndHobbiesComp profileData={profileData} />
              {!isViewingOwnProfile() && (
                <UserProfilePartnerPreferenceComp profileData={profileData} />
              )}
            </Animated.View>
          </ScrollView>
          {!isViewingOwnProfile() && (
            <View style={styles.container}>
              <View style={styles.interestActionContainer}>
                <View style={styles.subActionContainer}>
                  <MessageAction profile={profileData} />
                  <InterestAction profile={profileData as ProfileResp} />
                </View>
                <View style={styles.subActionsContainerRight}>
                  <StarProfileAction profile={profileData} />
                </View>
              </View>
            </View>
          )}
        </>
      )}
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Color.white,
  },
  text: {
    paddingLeft: '6%',
    color: Color.halfBlack,
    fontSize: 18,
  },
  txt: {
    color: Color.halfBlack,
    fontSize: 18,
  },
  box: {
    backgroundColor: 'mistyrose',
    height: 100,
    marginTop: 30,
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  miniBox: {
    backgroundColor: Color.gray,
    marginTop: '6%',
    marginBottom: '6%',
    alignSelf: 'flex-start',
    padding: 5,
    borderRadius: 50,
    fontSize: 20,
    color: Color.halfBlack,
  },
  heading: {
    fontSize: 25,
    color: 'slategray',
    fontWeight: 'bold',
    marginTop: '8%',
  },
  iconAndText: {
    flexDirection: 'row',
    paddingTop: '10%',
    alignItems: 'center',
  },
  workIcon: {
    backgroundColor: Color.gray,
    borderRadius: 50,
    padding: 10,
  },
  aboutEducation: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Color.gray,
    padding: '5%',
    marginTop: '10%',
  },
  buttonContainer: {
    marginBottom: GenUtil.isWEB() ? 20 : 30,
    marginTop: 40,
    marginHorizontal: 30,
    flexDirection: 'row',
    justifyContent: 'space-around',
    flex: 1,
  },
  cross: {
    marginLeft: GenUtil.isWEB() ? 15 : 50,
  },
  crossImage: {
    height: 50,
    width: 50,
  },
  button: {
    backgroundColor: '#501FDA',
    width: width * 0.75,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 15,
    borderRadius: 24,
  },
  buttonDisabledOverride: {
    backgroundColor: Color.gray,
  },
  buttonText: {
    fontSize: 17,
    fontFamily: 'sora-regular',
  },
  actionsContainer: {
    flexDirection: 'row',
    marginTop: 30,
    marginBottom: 20,
    marginHorizontal: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteIcon: {
    width: 37,
    height: 37,
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 7,
  },
  subActionsContainerRight: {
    alignItems: 'center',
    backgroundColor: '#d9d9d9',
  },
  subActionContainer: {
    flexDirection: 'row',
    marginVertical: 10,
    backgroundColor: '#d9d9d9',
    alignItems: 'center',
  },
  interestActionContainer: {
    flexDirection: 'row',
    marginVertical: 15,
    marginHorizontal: 10,
    paddingHorizontal: 10,
    alignItems: 'center',
    borderRadius: 20,
    justifyContent: 'space-between',
    backgroundColor: '#d9d9d9',
  },
});

export default UserProfileScreen;
