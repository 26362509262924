import { View, Text, GestureResponderEvent } from 'react-native';
import React from 'react';
import { Button } from 'react-native-paper';
import { Color } from 'src/constant/Color';

const FilledButton = ({
  title = 'Search',
  textStyle,
  style,
  onPress,
  buttonDisabled = false,
}: {
  title: string;
  textStyle: object;
  style: object;
  onPress: (e: GestureResponderEvent) => void;
  buttonDisabled?: boolean;
}) => {
  return (
    <Button
      mode="contained"
      labelStyle={textStyle}
      style={style}
      onPress={onPress}
      disabled={buttonDisabled}
    >
      {title}
    </Button>
  );
};

export default FilledButton;
