import { StyleSheet } from 'react-native';
import { Text, View } from 'src/components/Themed';
import PrimarySwitch from 'src/components/common/switch/PrimarySwitch';
import { Color } from 'src/constant/Color';
import EFonts from 'src/constant/EFonts';
import SafeContainer from 'src/components/SafeContainer';
import { Icon } from 'react-native-elements';
import { useNavigation } from '@react-navigation/native';
import { useEffect, useState } from 'react';
import { ProfileService } from 'src/service/ProfileService';
import { useDispatch } from 'react-redux';
import { AlertTypeEnum, showError } from 'src/store/reducer/errorReducer';
import { GenUtil } from 'src/util/GenUtil';
import { ProfileUpdateService } from 'src/service/ProfileUpdateService';
import { NotificationSettingsResp } from 'src/common/response/profile-notification-setting.resp';

type NotificationData = {
  profileViewNotification: boolean;
  interestAcceptedNotification: boolean;
  interestReceivedNotification: boolean;
  pendingInterestNotification: boolean;
  nearbyRecommendationNotification: boolean;
  dailyRecommendationNotification: boolean;
};

const NotificationSettingSelectionComp = ({
  item,
  isSelected,
  itemKey,
  fetchNotifications,
}: {
  item: { heading: string; description: string };
  isSelected: boolean;
  itemKey: keyof NotificationSettingsResp;
  fetchNotifications: () => void;
}) => {
  const dispatch = useDispatch();
  const handleOnPress = async () => {
    try {
      const notificationSettingsBody: Partial<NotificationSettingsResp> = {
        [itemKey]: !isSelected,
      };

      const response = await ProfileUpdateService.updateNotificationSettings(
        notificationSettingsBody,
      );
      if (!response.success) return;
      dispatch(
        showError({ message: 'Settings updated successfully', alertType: AlertTypeEnum.Success }),
      );
      fetchNotifications();
    } catch (error) {
      dispatch(
        showError({ message: GenUtil.getMessageFromError(error), alertType: AlertTypeEnum.Error }),
      );
    }
  };

  return (
    <View style={{ flexDirection: 'row' }}>
      <View style={{ flex: 4 }}>
        <View style={{ marginBottom: 6 }}>
          <Text style={styles.headingStyle}>{item.heading}</Text>
        </View>
        <View>
          <Text style={styles.descriptionStyle}>{item.description}</Text>
        </View>
      </View>
      <View style={{ flex: 1, alignItems: 'flex-end' }}>
        <PrimarySwitch
          color={Color.purple}
          enabled={isSelected}
          onPress={() => {
            void handleOnPress();
          }}
        />
      </View>
    </View>
  );
};

export default function Notifications() {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const [notificationSettings, setNotificationSetting] =
    useState<Partial<NotificationSettingsResp>>(null);
  const fetchNotifications = async () => {
    try {
      const response = await ProfileService.getNotificationSettings();
      if (!response.success) return;
      setNotificationSetting(response.data);
    } catch (error) {
      dispatch(
        showError({ message: GenUtil.getMessageFromError(error), alertType: AlertTypeEnum.Error }),
      );
    }
  };

  useEffect(() => {
    void fetchNotifications();
  }, []);

  const data = {
    dailyRecommendationNotification: {
      heading: 'Daily Recomandations',
      description: 'Daily notification for new recommendations based on your preferences',
    },
    pendingInterestNotification: {
      heading: 'Pending Interests',
      description: 'Biweekly notification reminding you to respond your pending interests',
    },
    profileViewNotification: {
      heading: 'Profile View',
      description: 'Get notification when someone views your profile',
    },
    interestAcceptedNotification: {
      heading: 'Interest Accepted',
      description: 'Get notification to know who accepted your interests',
    },
    interestReceivedNotification: {
      heading: 'Interest received',
      description: 'Get notification when you receive any interests',
    },
    nearbyRecommendationNotification: {
      heading: 'Nearby Recommendation',
      description: 'Get notification when someone nearby you joins the platform',
    },
  };

  return (
    <SafeContainer
      showPageHeader={true}
      heading="Notification Settings"
      style={{ flex: 1, backgroundColor: Color.white }}
      leftIcon={<Icon name="arrow-back" />}
      onPressLeftIcon={() => navigation.navigate('settings')}
    >
      {notificationSettings ? (
        <View style={{ flex: 1, marginTop: 30, paddingHorizontal: 16 }}>
          {Object.keys(data)?.map((item: keyof NotificationData, index) => {
            return (
              <View key={index} style={{ marginBottom: 30 }}>
                <NotificationSettingSelectionComp
                  item={data[item]}
                  isSelected={!!notificationSettings[item]}
                  itemKey={item}
                  fetchNotifications={() => void fetchNotifications()}
                />
              </View>
            );
          })}
        </View>
      ) : null}
    </SafeContainer>
  );
}

const styles = StyleSheet.create({
  headingStyle: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    lineHeight: 15,
    color: Color.lightBlack,
  },
  descriptionStyle: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    lineHeight: 20,
    color: Color.lightGrey,
  },
});
