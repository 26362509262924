import * as Location from 'expo-location';
import { useState, useEffect } from 'react';

const useLocation = () => {
  const [permissionGranted, setPermissionGranted] = useState<boolean>(false);
  const [location, setLocation] = useState<Location.LocationObjectCoords | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const askPermission = async () => {
    const { status } = await Location.requestForegroundPermissionsAsync();
    if (status === Location.PermissionStatus.GRANTED) {
      await fetchLocation();
    }
    setPermissionGranted(status === Location.PermissionStatus.GRANTED);
  };

  const fetchLocation = async () => {
    try {
      const { coords } = await Location.getCurrentPositionAsync({});
      setLocation(coords);
    } catch (error) {
      console.error(error);
    }
  };

  const checkPermission = async () => {
    try {
      setIsLoading(true); // Set loading state to true
      const { status } = await Location.getForegroundPermissionsAsync();
      setPermissionGranted(status === Location.PermissionStatus.GRANTED);
      if (status === Location.PermissionStatus.GRANTED) {
        await fetchLocation();
      }
    } catch (error) {
      console.error('An error occurred while checking permissions:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void checkPermission();
  }, []);

  return { permissionGranted, askPermission, location, isLoading };
};

export default useLocation;
