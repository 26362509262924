import { View } from 'src/components/Themed';
import React, { useEffect, useState } from 'react';
import ChoicesSelector from 'src/components/editProfileComponents/ChoicesSelector';
import { CommonService } from 'src/service/CommonService';
import DOESNT_MATTER_OPTION from 'src/constant/DoesntMatter';

const CitySelectionComp = ({
  initialCities = [],
  updatePartnerPref = async (_) => {},
  setSearchParams,
}) => {
  const [isDoneValidating, setIsDoneValidating] = useState(false);
  const [selectedCities, setSelectedCities] = useState(
    initialCities.length ? initialCities : [DOESNT_MATTER_OPTION.name],
  );
  const [citiesData, setCitiesData] = useState([]);

  useEffect(() => {
    CommonService.getCities('India')
      .then((response) =>
        setCitiesData([
          DOESNT_MATTER_OPTION.name,
          ...response.data.cities.map((item) => item.name),
        ]),
      )
      .catch((error) => console.log(error));
  }, []);

  const onPressDone = async () => {
    const validPreferences = selectedCities[0] === DOESNT_MATTER_OPTION.name ? [] : selectedCities;

    try {
      setIsDoneValidating(true);
      if (setSearchParams) {
        setSearchParams((prevParams) => ({
          ...prevParams,
          ...{ statesAndCities: validPreferences },
        }));
        setIsDoneValidating(false);
        return;
      }
      await updatePartnerPref({ cities: validPreferences });
      setIsDoneValidating(false);
    } catch (error) {
      console.log(error);
      setIsDoneValidating(false);
    }
  };

  const onPressRemove = async (updatedCities = []) => {
    try {
      if (setSearchParams) {
        setSearchParams((prevParams) => ({
          ...prevParams,
          ...{ statesAndCities: updatedCities },
        }));
        return;
      }
      await updatePartnerPref({ cities: updatedCities });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <View style={{ bottom: 20 }}>
      <ChoicesSelector
        dropdownItems={citiesData}
        placeHolder="Search City here..."
        selectedValues={selectedCities}
        setSelectedValues={setSelectedCities}
        onPressDone={onPressDone}
        onPressRemove={onPressRemove}
        isDoneValidating={isDoneValidating}
        title='City'
      />
    </View>
  );
};

export default CitySelectionComp;
