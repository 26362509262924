import {
  Alert,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import BottomSheet from 'src/components/common/BottomSheet';
import { useMemo } from 'react';
import { Icon } from 'react-native-elements';
import { Color } from 'src/constant/Color';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { hideModal, ModalTypes, showModal, toggleRefresh } from 'src/store/reducer/uiReducer';
import { ExcludedProfileBody } from 'src/common/dto/profile.dto';
import { ExcludedStatus, ProfileAction, ProfileActionName } from 'src/common/models/profile.model';
import { ProfileService } from 'src/service/ProfileService';
import { GenUtil } from 'src/util/GenUtil';
import { useNavigation } from '@react-navigation/native';

type Item = {
  label: string;
  icon: React.ReactElement;
  onPress: () => void;
  variant?: 'danger' | 'standard';
};

const showConfirmationAlert = (message: string, onConfirmation: () => void) => {
  if (GenUtil.isWEB()) {
    if (confirm(message)) {
      onConfirmation();
    }
  } else {
    Alert.alert('', message, [
      {
        text: 'No',
      },
      {
        text: 'Yes',
        onPress: onConfirmation,
      },
    ]);
  }
};

export const UserProfileOptionsModal = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { profile } = useSelector((state: RootState) => state.ui.modal.childData);

  const closeModal = () => {
    dispatch(hideModal());
  };

  const manageProfileRestriction = () => {
    const isBlocked = profile.actions?.some(
      (action: ProfileAction) => action.name === ProfileActionName.Unblock,
    );

    const performBlock = async () => {
      const excludedProfileBody: ExcludedProfileBody = {
        profileUuid: profile.uuid,
        type: ExcludedStatus.Block,
        customMessage: 'Block user',
      };
      await ProfileService.restrictProfile(excludedProfileBody);
    };

    const performUnblock = async () => {
      await ProfileService.undoRestrictProfile(profile.uuid);
    };

    const label = isBlocked ? 'Unblock' : 'Block';
    const request = isBlocked ? performUnblock : performBlock;

    return { label, request };
  };

  const options = useMemo((): Item[] => {
    const { label, request } = manageProfileRestriction();
    return [
      {
        label,
        icon: <Icon name={'block'} type={'material'} size={22} color={Color.red} />,
        onPress: () => {
          showConfirmationAlert(
            `Are you sure you want to ${label.toLocaleLowerCase()} this user?`,
            async () => {
              await request();
              dispatch(toggleRefresh());
              return navigation.canGoBack()
                ? navigation.goBack()
                : navigation.navigate('data', { screen: 'home' });
            },
          );
        },
        variant: 'danger',
      },
      {
        label: 'Report',
        icon: <Icon name={'alert-octagon'} type={'feather'} color={Color.red} size={20} />,
        onPress: () => {
          showConfirmationAlert('Are you sure you want to report this user?', async () => {
            const excludedProfileBody: ExcludedProfileBody = {
              profileUuid: profile.uuid,
              type: ExcludedStatus.Report,
              customMessage: 'Report user',
            };
            await ProfileService.restrictProfile(excludedProfileBody);
            return navigation.canGoBack()
              ? navigation.goBack()
              : navigation.navigate('data', { screen: 'home' });
          });
        },
        variant: 'danger',
      },
      {
        label: 'View Contact details',
        icon: <Icon name="address-book" type="font-awesome" color={Color.gray} size={20} />,
        onPress: () => {
          dispatch(
            showModal({
              type: ModalTypes.UserContactBottomSheet,
              childData: {
                profile,
              },
            }),
          );
        },
        variant: 'standard',
      },
    ];
  }, []);

  return (
    <BottomSheet onDismiss={() => closeModal()} snapPoints={['55%']} webModalHeight={250}>
      <View style={styles.header}>
        <Text style={styles.title}>More Options</Text>
        <TouchableWithoutFeedback onPress={closeModal}>
          <Icon type={'feather'} name={'x'} />
        </TouchableWithoutFeedback>
      </View>
      {options.map((option) => {
        return <ListItem item={option} key={option.label} closeModal={closeModal} />;
      })}
    </BottomSheet>
  );
};

const ListItem = ({ item, closeModal }: { item: Item; closeModal: () => void }) => {
  return (
    <TouchableOpacity
      onPress={() => {
        closeModal();
        item.onPress();
      }}
    >
      <View style={styles.item}>
        {item.icon ? item.icon : <></>}
        <Text style={[styles.text, item.variant === 'danger' ? styles.textDanger : {}]}>
          {item.label}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  header: {
    marginHorizontal: 20,
    paddingVertical: 15,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderColor: Color.lightGrey,
    marginBottom: 15,
  },
  title: { fontSize: 18 },
  item: {
    padding: 15,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  textDanger: {
    color: Color.red,
  },
  text: {
    fontSize: 17,
    marginLeft: 20,
    borderBottomColor: Color.gray,
  },
});
