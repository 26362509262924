import { View } from 'src/components/Themed';
import { StyleSheet } from 'react-native';
import React, { useState, useEffect } from 'react';
import ChoicesSelector from 'src/components/editProfileComponents/ChoicesSelector';
import { ReligionData } from 'src/common/data/religion.data';
import { ReligionType } from 'src/common/models/profile.model';
import SaveButton from 'src/components/editProfileComponents/SaveButton';
import { GenUtil } from 'src/util/GenUtil';
import EFonts from 'src/constant/EFonts';
import DOESNT_MATTER_OPTION from 'src/constant/DoesntMatter';

interface SubCommunityData {
  name: string;
  id: number;
  religionId: number;
  casteId: number;
  order: number;
}

export default function ReligionSelectionComp({
  partnerReligions = [],
  updatePartnerPref,
  partnerCommunities = [],
  partnerSubCommunities = [],
}: {
  partnerReligions: ReligionType[];
  updatePartnerPref: (_) => Promise<void>;
  partnerCommunities: number[];
  partnerSubCommunities: number[];
}) {
  const [subCommunityData, setSubCommunityData] = useState([] as SubCommunityData[]);
  const [selectedSubCommunities, setSelectedSubCommunities] = useState([]);
  const [selectedSubCommunitiesIds, setSelectedSubCommunitiesIds] = useState(
    partnerSubCommunities ?? [],
  );
  const [isValidating, setIsValidating] = useState(false);
  const [initializingSelectedSubCommunities, setInitializingSelectedSubCommunities] =
    useState(true);

  useEffect(() => {
    const subCommunityList = GenUtil.filterAndExtractValues(
      ReligionData,
      partnerReligions,
      'name',
      'subCommunityMap',
    );
    const filteredSubCommunityList = GenUtil.filterAndExtractValues(
      subCommunityList,
      partnerCommunities,
      'casteId',
    );
    setSubCommunityData([DOESNT_MATTER_OPTION, ...filteredSubCommunityList]);

    if (initializingSelectedSubCommunities) {
      let tempSelectedSubComm = [];
      filteredSubCommunityList.map((subCommunity) => {
        if (partnerSubCommunities?.includes(subCommunity.id))
          tempSelectedSubComm.push(subCommunity.name);
      });
      if (!tempSelectedSubComm.length) tempSelectedSubComm = [DOESNT_MATTER_OPTION.name];
      setSelectedSubCommunities(tempSelectedSubComm);
      setInitializingSelectedSubCommunities(false);
    }
  }, [partnerCommunities]);

  useEffect(() => {
    const tempSubCommIds: number[] = [];
    if (selectedSubCommunities.length) {
      subCommunityData.map((res) => {
        if (selectedSubCommunities.includes(res.name)) tempSubCommIds.push(res.id);
      });
    }
    setSelectedSubCommunitiesIds(tempSubCommIds);
  }, [selectedSubCommunities]);

  const onPressDone = async () => {
    setIsValidating(true);
    await updatePartnerPref({
      subCommunityIds:
        selectedSubCommunities[0] === DOESNT_MATTER_OPTION.name ? [] : selectedSubCommunitiesIds,
    });
    setIsValidating(false);
  };

  return (
    <View style={{ bottom: 20 }}>
      <ChoicesSelector
        dropdownItems={subCommunityData.map((res) => res.name)}
        placeHolder="Select sub communities"
        selectedValues={selectedSubCommunities}
        setSelectedValues={setSelectedSubCommunities}
        title='Sub Community'
        onPressDone={onPressDone}
        isDoneValidating={isValidating}
      />
    </View>
  );
}
