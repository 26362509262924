import React from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { Icon } from 'react-native-elements';
import { Color } from 'src/constant/Color';
import { useNavigation } from '@react-navigation/native';
import { GenUtil } from 'src/util/GenUtil';

const HeaderBackButton = ({ icon = null, iconStyle = null, onPressIcon = null }) => {
  const navigation = useNavigation();

  function onBackPress() {
    const screen = GenUtil.isDesktopWeb() ? 'home' : 'Home';
    navigation.canGoBack() ? navigation.goBack() : navigation.navigate(screen, { screen });
  }

  return (
    <TouchableOpacity style={iconStyle} onPress={onPressIcon ?? onBackPress}>
      {icon ?? (
        <Icon
          name="ios-chevron-back-circle-outline"
          color={Color.purple}
          size={30}
          type="ionicon"
        />
      )}
    </TouchableOpacity>
  );
};

export default HeaderBackButton;
