import { View, Text, Image, StyleSheet } from 'react-native';
import React, { useEffect, useState } from 'react';
import { Color } from 'src/constant/Color';
import EFonts from 'src/constant/EFonts';
import OutlinedButton from 'src/components/common/buttons/OutlinedButton';
import FilledButton from '../common/buttons/FilledButton';
import PhotoCollage from './PhotoCollage';
import { InterestService } from 'src/service/InterestService';
import { InterestActionType, InterestsStatus } from 'src/common/models/interest.model';
import { ProfileService } from 'src/service/ProfileService';
import { GenUtil } from 'src/util/GenUtil';

const InterestReceviedCard = ({
  name,
  interestUuid,
  profileIds,
  gender,
  otherUserGender,
  otherUserProfilePhoto,
  profilePhoto,
  showButtons = false,
  msgTime,
}: {
  name: string;
  interestUuid: string;
  profileIds: string[];
  gender: string;
  otherUserGender: string;
  otherUserProfilePhoto: string;
  profilePhoto: string;
  showButtons: boolean;
  msgTime: number | Date;
}) => {
  const [buttonPressed, setButtonPressed] = useState(false);
  const message = `You received an interest from ${name}.`;

  const getInterest = async () => {
    try {
      const response = await ProfileService.listProfilesDetails(profileIds);
      const profiles = response.data.data;
      for (const { interest } of profiles) {
        if (interest) {
          return interest;
        }
      }
      return null;
    } catch (error) {
      console.log('Error in getInterest', error);
    }
  };
  const handleRejectPressed = async () => {
    const interest = await getInterest();
    if (interest === null) {
      alert("This interest dosn't exsist");
      return;
    }

    if (interest.status === InterestsStatus.Declined) {
      alert('This interest is already declined!!!');
      return;
    }
    const actionBody = {
      interestUuid: interestUuid,
      customMessage: 'Interest Declined',
      type: InterestActionType.Decline,
    };
    const response = await InterestService.actionOnInterest(actionBody);
    if (response.success) setButtonPressed(true);
  };

  const handleAcceptPressed = async () => {
    try {
      const interest = await getInterest();
      if (interest === null) {
        alert("This interest dosn't exsist");
        return;
      }

      if (interest.status === InterestsStatus.Accepted) {
        alert('This interest is already accepted!!!');
        return;
      }
      const actionBody = {
        interestUuid: interestUuid,
        customMessage: 'Interest Accepted',
        type: InterestActionType.Accept,
      };
      const response = await InterestService.actionOnInterest(actionBody);
      if (response.success) setButtonPressed(true);
    } catch (error) {
      console.log('Error in handleAcceptPressed', error);
    }
  };
  return (
    <View style={styles.cardContainer}>
      <View style={styles.upperContainer}>
        <View style={styles.collageContainer}>
          <PhotoCollage
            size={36}
            smallCircleSize={22}
            icon={
              <Image
                style={{ height: 12, width: 12, left: 1, bottom: 1 }}
                source={require('src/assets/icons/receiveIcon.png')}
              />
            }
            profilePhoto={profilePhoto}
            gender={gender}
            otherUserGender={otherUserGender}
            otherUserProfilePhoto={otherUserProfilePhoto}
          />
        </View>
        <View style={styles.titleContainer}>
          <Text style={styles.titleStyle}>Interest Received</Text>
        </View>
      </View>
      <View style={{ marginTop: 8 }}>
        <Text style={styles.messageStyle}>{message}</Text>
      </View>
      {showButtons && !buttonPressed ? (
        <View style={styles.buttonsContainer}>
          <OutlinedButton
            title="Reject"
            style={styles.rejectButtonStyle}
            textStyle={styles.rejectButtonTextStyle}
            onPress={handleRejectPressed}
          />
          <FilledButton
            title="Accept"
            style={styles.acceptButtonStyle}
            textStyle={styles.acceptButtonTextStyle}
            onPress={handleAcceptPressed}
          />
        </View>
      ) : null}
      <View style={{ flex: 1, paddingBottom: 5 }}>
        <Text style={styles.timeStyle}>{GenUtil.getTimeFromTimeStamp(msgTime)}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    width: 230,
    backgroundColor: '#F6F6F6',
    borderRadius: 12,
    borderBottomLeftRadius: 0,
    paddingHorizontal: 8,
  },
  upperContainer: {
    height: 68,
    borderBottomWidth: 1,
    borderColor: '#E1E1E1',
    paddingVertical: 8,
    flexDirection: 'row',
  },
  collageContainer: {
    height: '100%',
    width: 72,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleStyle: {
    fontFamily: EFonts.SORA_SEMIBOLD,
    fontSize: 15,
    lineHeight: 20,
    color: Color.lightBlack,
  },
  messageStyle: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    lineHeight: 20,
    color: Color.lightBlack,
  },
  buttonsContainer: {
    marginVertical: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
  },
  rejectButtonStyle: {
    borderColor: Color.lightBlack,
    paddingVertical: 5,
    paddingHorizontal: 5,
  },
  rejectButtonTextStyle: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    lineHeight: 15,
    color: Color.lightBlack,
    marginVertical: 0,
    marginHorizontal: 0,
  },
  acceptButtonStyle: {
    backgroundColor: Color.purple,
    paddingVertical: 5,
    paddingHorizontal: 5,
    borderWidth: 1,
  },
  acceptButtonTextStyle: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    lineHeight: 15,
    color: Color.white,
    marginVertical: 0,
    marginHorizontal: 0,
  },
  timeStyle: {
    color: '#595959',
    fontSize: 10,
    fontFamily: EFonts.SORA_REGULAR,
  },
});

export default InterestReceviedCard;
