import { ActivityIndicator, ScrollView, StyleSheet, Text, View } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from '../../types';
import { useEffect, useState } from 'react';
import Button from 'src/components/common/buttons/Button';
import { GenUtil } from 'src/util/GenUtil';
import { ProfileResp } from 'src/common/response/profile.resp';
import ProfilesRecyclerList, { ProfileComponentEnum } from 'src/components/ProfilesRecyclerList';
import { ProfileService } from 'src/service/ProfileService';
import { InterestService } from 'src/service/InterestService';
import { InterestsStatus, SentType } from 'src/common/models/interest.model';
import { RecommendedType } from 'src/common/models/profile.model';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import SafeContainer from 'src/components/SafeContainer';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import ProfileNotFound from 'src/components/errors/ProfileNotFound';

type Props = NativeStackScreenProps<RootStackParamList, 'dynamicprofileslist'>;

export enum ProfileTabsEnum {
  SimilarProfiles = 'Similar Profiles',
  Nearyou = 'Near You',
  RecentlyJoined = 'Recently Joined',
  InterestReceived = 'Interest Received',
  InterestSent = 'Interest Sent',
  ShortlistedProfiles = 'Shortlisted Profiles',
  HandPickedProfiles = 'Handpicked profiles',
  ProfileVisitors = 'Profile Visitors',
  ProfileViewed = 'Profile Viewed',
  InterestExpiring = 'Interest Expiring',
  InterestFiltered = 'Interest Filtered',
  InterestArchived = 'Interest Archived',
  YouAccepted = 'You Accepted',
  TheyAccepted = 'They Accepted',
  YouDeclined = 'You Declined',
  TheyDeclined = 'They Declined',
}

const REQUEST_BY_TABS = {
  [ProfileTabsEnum.SimilarProfiles]: async (page: number, uuid: string) =>
    await ProfileService.fetchSimilarProfile(uuid, page),
  [ProfileTabsEnum.InterestReceived]: async (page: number) =>
    await InterestService.fetchInterests({
      sentType: SentType.Received,
      status: InterestsStatus.Pending,
      page,
    }),
  [ProfileTabsEnum.InterestSent]: async (page: number) =>
    await InterestService.fetchInterests({
      sentType: SentType.Sent,
      status: InterestsStatus.Pending,
      page,
    }),
  [ProfileTabsEnum.YouAccepted]: async (page: number) =>
    await InterestService.fetchInterests({
      sentType: SentType.Received,
      status: InterestsStatus.Accepted,
      page,
    }),
  [ProfileTabsEnum.TheyAccepted]: async (page: number) =>
    await InterestService.fetchInterests({
      sentType: SentType.Sent,
      status: InterestsStatus.Accepted,
      page,
    }),
  [ProfileTabsEnum.YouDeclined]: async (page: number) =>
    await InterestService.fetchInterests({
      sentType: SentType.Received,
      status: InterestsStatus.Declined,
      page,
    }),
  [ProfileTabsEnum.TheyDeclined]: async (page: number) =>
    await InterestService.fetchInterests({
      sentType: SentType.Sent,
      status: InterestsStatus.Declined,
      page,
    }),
  [ProfileTabsEnum.InterestFiltered]: async (page: number) =>
    await InterestService.fetchInterests({
      sentType: SentType.Received,
      status: InterestsStatus.Filtered,
      page,
    }),
  [ProfileTabsEnum.InterestArchived]: async (page: number) =>
    await InterestService.fetchInterests({
      sentType: SentType.Received,
      status: InterestsStatus.Archived,
      page,
    }),
  [ProfileTabsEnum.Nearyou]: async (page: number) =>
    // TODO: pass correct lat & lng of user
    await ProfileService.fetchRecommendedProfiles(
      {
        recommendedType: RecommendedType.Nearby,
        lat: '28.6139',
        lng: '77.2090',
      },
      page,
    ),
  [ProfileTabsEnum.RecentlyJoined]: async (page: number) =>
    await ProfileService.fetchRecommendedProfiles(
      {
        recommendedType: RecommendedType.RecentlyJoined,
      },
      page,
    ),
  [ProfileTabsEnum.ShortlistedProfiles]: async (page: number) =>
    await ProfileService.getShortlistedProfiles(page),
  // TODO: add correct api request for Handpicked profiles
  [ProfileTabsEnum.HandPickedProfiles]: async (page: number) =>
    await ProfileService.getProfiles({}, page),
  [ProfileTabsEnum.ProfileVisitors]: async (page: number) =>
    await ProfileService.getProfileViewers(page),
  [ProfileTabsEnum.ProfileViewed]: async (page: number) =>
    await ProfileService.getProfileViewed(page),
  // TODO: add correct api request for Interest expiring profiles
  [ProfileTabsEnum.InterestExpiring]: async (page: number) =>
    await ProfileService.getProfiles({}, page),
};

const { height, width } = GenUtil.getDimension();

export default function DynamicProfilesList({ route }: Props) {
  const { title, tabs } = route.params as {
    title: string;
    tabs: ProfileTabsEnum | ProfileTabsEnum[];
  };
  const isFocused = useIsFocused();
  const [pageTitle, setPageTitle] = useState(title);
  const pageTabs = [...(Array.isArray(tabs) ? tabs : tabs.split(','))];
  const [selectedTab, setSelectedTab] = useState<ProfileTabsEnum>(pageTabs[0] as ProfileTabsEnum);
  const [listData, setListData] = useState<ProfileResp[]>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const { uuid: profileUuid } = useSelector((state: RootState) => state.user.userProfile);
  const refresh = useSelector((state: RootState) => state.ui.refresh);
  const navigation = useNavigation();

  const fetchProfiles = async (page: number) => {
    if (page === -1) return;
    try {
      if (page === 0) setListData(null);

      const fetchRequest = REQUEST_BY_TABS[selectedTab];
      const response = await fetchRequest(page, profileUuid);

      if (response.success) {
        const responseData = response.data as ProfileResp[];
        setListData((prevListData) => [...(prevListData ?? []), ...responseData]);
        setPageNumber(responseData.length > 0 ? page + 1 : -1);
      } else {
        setListData([]);
      }
    } catch (e) {
      setListData([]);
    }
  };

  useEffect(() => {
    fetchProfiles(0).catch((err) => {
      console.error(err);
    });
    setPageTitle(selectedTab);
  }, [selectedTab, refresh]);

  useEffect(() => {
    if (isFocused && pageTabs[0] !== selectedTab) {
      setSelectedTab(pageTabs[0] as ProfileTabsEnum);
    }
  }, [isFocused]);

  const getSubHeadingForNoProfile = (selectedTab: string) => {
    const tabMessage = {
      'Similar Profiles': 'No similar profiles found.',
      'Near You': 'No profiles near your location.',
      'Recently Joined': 'No new profiles recently joined.',
      'Interest Received': 'No one has shown interest in your profile yet.',
      'Interest Sent': 'You haven\'t sent any interests yet.',
      'Shortlisted Profiles': 'You haven\'t shortlisted any profiles yet.',
      'Handpicked profiles': 'No handpicked profiles found.',
      'Profile Visitors': 'No one has visited your profile yet.',
      'Profile Viewed': 'You haven\'t viewed any profiles yet.',
      'Interest Expiring': 'No expiring interests at the moment.',
      'Interest Filtered': 'No interests match your current filters.',
      'Interest Archived': 'No archived interests found.',
      'You Accepted': 'You haven\'t accepted any interests yet.',
      'They Accepted': 'No one has accepted your interests yet.',
      'You Declined': 'You haven\'t declined anyone yet.',
      'They Declined': 'No one has declined your profile yet.'
    }
    return tabMessage[selectedTab] || 'No data found for the selected tab.'
  }

  return (
    <SafeContainer showPageHeader={true} heading={pageTitle} style={{ flex: 1 }}>
      {pageTabs?.length > 1 && (
        <View style={{ marginTop: -20, zIndex: -20 }}>
          <ScrollView horizontal={true} style={styles.containerStyle}>
            {pageTabs.map((tab) => {
              const isSelected = tab === selectedTab;
              return (
                <Button
                  onPress={() => setSelectedTab(tab as ProfileTabsEnum)}
                  key={tab}
                  variant={isSelected ? 'primary' : 'secondary'}
                  styles={{ marginLeft: 10 }}
                  showOpacity={false}
                >
                  {tab}
                </Button>
              );
            })}
          </ScrollView>
        </View>
      )}
      <View style={{ flex: 1, width: '100%' }}>
        {listData ? (
          listData?.length > 0 ? (
            <ProfilesRecyclerList
              data={listData}
              componentType={ProfileComponentEnum.HORIZONTAL_PROFILE_CARD}
              onEndReached={() => fetchProfiles(pageNumber)}
            />
          ) : (
            <ProfileNotFound 
              buttonVisibility={true}
              onPressButtonText='Back'
              subHeading={getSubHeadingForNoProfile(selectedTab)}
              onButtonPress={()=> navigation.goBack()}
              overrideStyle={{imageStyle:{marginTop:-75}}}
            />
          )
        ) : (
          <>
            <ActivityIndicator />
          </>
        )}
      </View>
    </SafeContainer>
  );
}

const styles = StyleSheet.create({
  containerStyle: {
    maxHeight: height * 0.8,
    maxWidth: width,
    marginRight: 0,
    backgroundColor: '#FAF5FF',
    paddingBottom: 10,
    paddingTop: 30,
  },
});
