import React from 'react';
import Collapsible from './molecules/Collapsible';
import { ScrollView, StyleSheet, View } from 'react-native';
import { ProfileResp } from 'src/common/response/profile.resp';
import PrimaryButton from '../common/buttons/PrimaryButton';
import EFonts from 'src/constant/EFonts';
import { ProfileSearchBody } from 'src/common/dto/profile.dto';

export interface CustomCollapsibleProps {
  initialData: Partial<ProfileResp>;
  sections: string[];
  expandAll: boolean;
  strictlyFollow?: boolean;
  showPills?: boolean;
  searchParams?: Partial<ProfileSearchBody>;
  setSearchParams?: (T: Partial<ProfileSearchBody>) => void;
  showSearchButton?: boolean;
  handleSearchPressed?: () => void;
  buttonText?: string;
}

const CustomCollapsible = ({
  initialData = null,
  sections = [],
  expandAll = false,
  strictlyFollow = true,
  showPills = true,
  searchParams,
  setSearchParams,
  showSearchButton = false,
  handleSearchPressed,
  buttonText = 'Search',
}: CustomCollapsibleProps) => {
  return (
    <>
      <ScrollView style={styles.scrollView} showsVerticalScrollIndicator={false}>
        <Collapsible
          initialData={initialData}
          sections={sections}
          expandAll={expandAll}
          strictlyFollow={strictlyFollow}
          showPills={showPills}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      </ScrollView>
      {showSearchButton && (
        <View
          style={{
            alignItems: 'center',
            marginTop: 16,
            marginBottom: 16,
          }}
        >
          <PrimaryButton
            handleKeyPress={handleSearchPressed}
            buttonText={buttonText}
            buttonStyle={{ height: 50 }}
            textStyle={{ fontFamily: EFonts.SORA_SEMIBOLD, fontSize: 15, lineHeight: 19 }}
          />
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
  },
});

export default CustomCollapsible;
