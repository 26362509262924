/* eslint-disable @typescript-eslint/no-floating-promises */
import { Image, StyleSheet, Text, View } from 'react-native';
import React, { useState, useEffect } from 'react';
import CheckboxAdornment from 'src/components/editProfileComponents/atoms/CheckboxAdornment';
import { Gender, PreferenceLevel } from 'src/common/models/profile.model';
import { debounce } from 'lodash';
import { ProfileSearchBody } from 'src/common/dto/profile.dto';
import ModalAdornment from '../atoms/ModalAdornment';
import EFonts from 'src/constant/EFonts';
import { GenUtil } from 'src/util/GenUtil';
import { Color } from 'src/constant/Color';

const MAX_ALLOWED_AGE = 65;
const MIN_AGE_MINIMUM_VALUE = 21;
const MAX_AGE_MINIMUM_VALUE = 21;

const AgeRangeComp = ({
  userGender,
  userAge,
  partnerMinAge,
  partnerMaxAge,
  privacyPrefLevel,
  updatePartnerPref,
  updatePrivateSettings,
  strictlyFollow = true,
  setSearchParams,
}: {
  userGender: string;
  userAge: number;
  partnerMinAge: number;
  partnerMaxAge: number;
  privacyPrefLevel: PreferenceLevel;
  updatePartnerPref: (_) => Promise<void>;
  updatePrivateSettings: (_) => Promise<void>;
  strictlyFollow?: boolean;
  setSearchParams?: (prevParams) => void;
}) => {
  const sliderRange = {
    min: userGender ? userAge - (userGender === Gender.Male ? 7 : 3) : MIN_AGE_MINIMUM_VALUE,
    max: userGender ? userAge + (userGender === Gender.Male ? 3 : 7) : MAX_ALLOWED_AGE,
  };

  const [minAge, setMinAge] = useState(partnerMinAge ?? sliderRange.min);
  const [maxAge, setMaxAge] = useState(partnerMaxAge ?? sliderRange.max);
  const MinAgeData = Array.from({ length: MAX_ALLOWED_AGE - MIN_AGE_MINIMUM_VALUE + 1 }, (_, index) => MIN_AGE_MINIMUM_VALUE + index);
  const MaxAgeData = Array.from({ length: MAX_ALLOWED_AGE - MAX_AGE_MINIMUM_VALUE + 1 }, (_, index) => MAX_AGE_MINIMUM_VALUE + index);
  const [maxAgeData, setMaxAgeData] = useState(MaxAgeData)

  const updateMaxAgeData = (minAgeValue) => {
    if (minAgeValue >= MAX_AGE_MINIMUM_VALUE && minAgeValue <= MAX_ALLOWED_AGE) {
      const newMaxAgeData = Array.from({ length: MAX_ALLOWED_AGE - minAgeValue + 1 }, (_, index) => minAgeValue + index);
      setMaxAgeData(newMaxAgeData);
      if(maxAge < minAgeValue){
        setMaxAge(newMaxAgeData[0]);
      }
    }
    else if(minAgeValue < 21) setMaxAgeData(MaxAgeData);
  };
  

  const [privacyLevel, setPrivacyLevel] = useState(privacyPrefLevel ?? 1);
  const icon = require('src/assets/images/editprofileicons/FrameAge.png');

  const applyDebounce = debounce(async (agePref: Partial<ProfileSearchBody>) => {
    if (setSearchParams) {
      setSearchParams((prevParams: Partial<ProfileSearchBody>) => ({ ...prevParams, ...agePref }));
      return;
    }
    await updatePartnerPref(agePref);
  }, 1000);

  useEffect(() => {
    const agePref = {
      minAge,
      maxAge,
    };

    if (!(minAge === partnerMinAge && maxAge === partnerMaxAge)) applyDebounce(agePref);
    return () => {
      applyDebounce.cancel();
    };
  }, [minAge, maxAge]);

  return (
    <View>
      <View style={styles.container}>
        <View style={{ flex: 1 }}>
          <Text style={styles.labelStyle}>Min Age</Text>
          <ModalAdornment
            icon={<Image source={icon} style={{ width: 18, height: 18 }} />}
            modalItems={MinAgeData}
            value={minAge}
            setValue={setMinAge}
            setHelperValue={updateMaxAgeData}
            setItemAsHelperValue={true}
            placeHolder="Select"
            modalHeading={'Minimum Age'}
          />
        </View>
        <View style={{ flex: 1 }}>
          <Text style={styles.labelStyle}>Max Age</Text>
          <ModalAdornment
            icon={<Image source={icon} style={{ width: 18, height: 18 }} />}
            modalItems={maxAgeData}
            value={maxAge}
            setValue={setMaxAge}
            placeHolder="Select"
            modalHeading={'Maximum Age'}
          />
        </View>
      </View>
      <View>
        {strictlyFollow && (
          <CheckboxAdornment
            label="Strictly Follow"
            updatePrivateSettings={updatePrivateSettings}
            privacyLevel={privacyLevel}
            setPrivacyLevel={setPrivacyLevel}
            prefPrivacy={{ ageRangePref: privacyLevel }}
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelStyle: {
    flex: 1,
    fontFamily: EFonts.SORA_REGULAR,
    fontStyle: 'normal',
    fontSize: GenUtil.isWEB() ? 12 : 10,
    lineHeight: 20,
    color: Color.lightGrey,
    alignItems: 'center',
    marginLeft: 15,
  },
});

export default AgeRangeComp;
