/* eslint-disable no-case-declarations */
import React, { useCallback, useEffect, useState } from 'react';
import { ScrollView, Text, View, Image } from 'react-native';

import { ProfileResp } from 'src/common/response/profile.resp';
import { Color } from 'src/constant/Color';
import { CustomCollapsibleProps } from 'src/components/editProfileComponents/CustomCollapsible';
import { AstroInfo } from 'src/components/editProfileComponents/edit-profile/MyAstroDetails';

//Edit Profile
import CollapsibleHeader from 'src/components/editProfileComponents/atoms/CollapsibleHeader';
import CollapsibleContent from 'src/components/editProfileComponents/atoms/CollapsibleContent';
import MyPersonalDetails from 'src/components/editProfileComponents/edit-profile/MyPersonalDetails';
import MyFamilyDetails from 'src/components/editProfileComponents/edit-profile/MyFamilyDetails';
import MyLifeStyleAndHobbies from 'src/components/editProfileComponents/edit-profile/MyLifestyleAndHobbies';
import MyReligionBeliefs from 'src/components/editProfileComponents/edit-profile/MyReligionBeliefs';
import MyAstroDetails from 'src/components/editProfileComponents/edit-profile/MyAstroDetails';
import MyCareer from 'src/components/editProfileComponents/edit-profile/MyCareer';
import MyEducations from 'src/components/editProfileComponents/edit-profile/MyEducations';

//Set Partner Preference
import AboutPartnerComp from 'src/components/editProfileComponents/partner-preference/AboutPartnerComp';
import LookingForComp from 'src/components/editProfileComponents/partner-preference/LookingForComp';
import AgeRangeComp from 'src/components/editProfileComponents/partner-preference/AgeRangeComp';
import IncomeRangeComp from 'src/components/editProfileComponents/partner-preference/IncomeRangeComp';
import HeightRangeComp from 'src/components/editProfileComponents/partner-preference/HeightRangeComp';
import MaritalStatus from 'src/components/editProfileComponents/partner-preference/MaritalStatus';
import QualificationSelectionComp from 'src/components/editProfileComponents/partner-preference/QualificationSelectionComp';
import ProfessionSelectionComp from 'src/components/editProfileComponents/partner-preference/ProfessionSelectionComp';
import MotherTongueSelectionComp from 'src/components/editProfileComponents/partner-preference/MotherTongueSelectionComp';
import DietSelectionComp from 'src/components/editProfileComponents/partner-preference/DietSelectionComp';
import { ProfileUpdateService } from 'src/service/ProfileUpdateService';
import { PartnerPrefBody, PrivacySettingsBody } from 'src/common/dto/profile.dto';
import { useDispatch } from 'react-redux';
import { setUserProfile } from 'src/store/reducer/userReducer';
import ReligionSelectionComp from 'src/components/editProfileComponents/partner-preference/ReligionSelectionComp';
import { EducationMedium, EmploymentType } from 'src/common/models/profile.model';
import { AlertTypeEnum, showError } from 'src/store/reducer/errorReducer';
import { GenUtil } from 'src/util/GenUtil';
import { ProfileSearchBody } from 'src/common/dto/profile.dto';
import InputAdornment from 'src/components/common/input/InputAdornment';
import StatesCitiesSelectionComp from 'src/components/editProfileComponents/partner-preference/StatesCitiesSelectionComp';
import StateSelectionComp from 'src/components/editProfileComponents/partner-preference/StateSelectionComp';
import CitySelectionComp from 'src/components/editProfileComponents/partner-preference/CitySelectionComp';
import CommunitySelectionComp from 'src/components/editProfileComponents/partner-preference/CommunitySelectionComp';
import SubCommunitySelectionComp from 'src/components/editProfileComponents/partner-preference/SubCommunitySelectionComp';

const RenderContent = ({
  title = '',
  index = 0,
  isActive = false,
  initialData = null,
  strictlyFollow = true,
  searchParams,
  setSearchParams,
}: {
  title: string;
  index: number;
  isActive: boolean;
  initialData: Partial<ProfileResp>;
  strictlyFollow?: boolean;
  searchParams?: Partial<ProfileSearchBody>;
  setSearchParams?: (T: Partial<ProfileSearchBody>) => void;
}) => {
  // console.log('initial data in render content', initialData);

  const dispatch = useDispatch();

  const updatePartnerPreference = async (partnerPref: Partial<PartnerPrefBody>) => {
    try {
      const updatePartnerPrefResponse = await ProfileUpdateService.updatePartnerPreference(
        partnerPref,
      );
      if (updatePartnerPrefResponse?.httpCode === 200) {
        //update redux
        dispatch(setUserProfile(updatePartnerPrefResponse?.data));
        dispatch(showError({ alertType: AlertTypeEnum.Success, message: 'Saved' }));
      }
    } catch (err) {
      dispatch(
        showError({ alertType: AlertTypeEnum.Error, message: GenUtil.getMessageFromError(err) }),
      );
    }
  };

  const updatePrivacySettings = async (privacySettings: Partial<PrivacySettingsBody>) => {
    try {
      const updatedPrivacySettingsResp = await ProfileUpdateService.updatePrivacySettings(
        privacySettings,
      );
      if (updatedPrivacySettingsResp?.httpCode === 200) {
        //update redux
        dispatch(setUserProfile(updatedPrivacySettingsResp?.data));
      }
    } catch (err) {
      dispatch(
        showError({ alertType: AlertTypeEnum.Error, message: GenUtil.getMessageFromError(err) }),
      );
    }
  };

  const render = (t: string) => {
    // console.log(initialData);
    switch (t) {
      //Edit Profile
      case 'Personal Details':
        const personalDetails: Partial<ProfileResp> = {
          fullName: initialData.fullName ?? '',
          gender: initialData?.gender,
          dob: initialData.dob,
          age: initialData.age,
          profileHandler: initialData.profileHandler,
          maritalStatus: initialData.maritalStatus,
          height: initialData.height,
          healthStatuses: initialData.healthStatuses?.length > 0 ? initialData.healthStatuses : [],
          livingWith: initialData.livingWith,
          currentLocation: initialData.currentLocation ?? '',
          motherTongue: initialData.motherTongue ?? '',
          aboutMe: initialData.aboutMe ?? '',
          hashtags: initialData.hashtags,
          religion: initialData.religion,
          communityId: initialData.communityId,
          subCommunityId: initialData.subCommunityId,
          marriedChildStatus: initialData.marriedChildStatus,
        };
        return <MyPersonalDetails profileData={personalDetails} />;
      case 'Educations':
        const educationDetails: Partial<ProfileResp> = {
          aboutEducation: initialData.aboutEducation ?? '',
          educationLevel: initialData.educationLevel ?? null,
          educationMedium: initialData.educationMedium ?? ('' as EducationMedium),
          schoolBoard: initialData.schoolBoard ?? null,
          educations: initialData.educations ?? [],
          dob: initialData.dob,
        };
        return <MyEducations profileEducationDetails={educationDetails} />;
      case 'Career Details':
        const careerDetails: Partial<ProfileResp> = {
          aboutCareer: initialData.aboutCareer ?? '',
          professionId: initialData.professionId ?? null,
          profession: initialData.profession ?? '',
          employmentType: initialData.employmentType ?? ('' as EmploymentType),
          inrIncome: initialData.inrIncome ?? null,
          settlingAbroad: initialData.settlingAbroad,
          employments: initialData.employments ?? [],
          dob: initialData.dob,
        };
        return <MyCareer profileCareerDetails={careerDetails} />;
      case 'Family Details':
        const familyDetails: Partial<ProfileResp> = {
          fatherStatus: initialData.fatherStatus,
          motherStatus: initialData.motherStatus,
          aboutFamily: initialData.aboutFamily,
          familyCurrentLocation: initialData.familyCurrentLocation,
          familyNativeLocation: initialData.familyNativeLocation ?? '',
          familyType: initialData.familyType,
          familyValue: initialData.familyValue,
          familyExtra: initialData.familyExtra,
        };
        return <MyFamilyDetails profileData={familyDetails} />;
      case 'Religion Beliefs':
        const religionDetails: Partial<ProfileResp> = {
          religion: initialData?.religion,
          gender: initialData?.gender,
          religionExtra: initialData?.religionExtra,
        };
        return <MyReligionBeliefs profileData={religionDetails} />;
      case 'Lifestyle And Hobbies':
        const lifestyleHobbiesDetails: Partial<ProfileResp> = {
          diet: initialData.diet,
          smoking: initialData.smoking,
          drinking: initialData.drinking,
          assets: initialData.assets,
          pets: initialData.pets,
          favHobbyAndInterestIds: initialData.favHobbyAndInterestIds,
          favMusicIds: initialData.favMusicIds,
          favBookGenreIds: initialData.favBookGenreIds,
          favActivityAndSportIds: initialData.favActivityAndSportIds,
          favCuisineIds: initialData.favCuisineIds,
          favMovieGenreIds: initialData.favMovieGenreIds,
        };
        return <MyLifeStyleAndHobbies profileData={lifestyleHobbiesDetails} />;
      case 'Astro Details':
        const astroDetails: AstroInfo = {
          rashi: initialData.rashi,
          manglik: initialData.manglik,
          nakshatra: initialData.nakshatra,
          birthPlace: initialData.astroDetails.birthPlace,
          birthTime: initialData.astroDetails.birthTime,
          birthDate: initialData.astroDetails.birthDate,
        };
        return <MyAstroDetails profileData={astroDetails} />;
      //Set Partner Preference
      case 'About Partner':
        return (
          <AboutPartnerComp
            aboutPartnerText={initialData?.aboutPartner}
            updatePartnerPref={updatePartnerPreference}
          />
        );
      case 'Looking For':
        return <LookingForComp gender={initialData?.gender} />;
      case 'Age Range':
        const minAge = searchParams
          ? searchParams.minAge
            ? searchParams.minAge
            : 18
          : initialData?.partnerPreference.minAge;
        const maxAge = searchParams
          ? searchParams.maxAge
            ? searchParams.maxAge
            : 99
          : initialData?.partnerPreference.maxAge;
        return (
          <AgeRangeComp
            userGender={initialData?.gender}
            userAge={initialData?.age}
            partnerMinAge={minAge}
            partnerMaxAge={maxAge}
            updatePartnerPref={updatePartnerPreference}
            updatePrivateSettings={updatePrivacySettings}
            privacyPrefLevel={initialData?.partnerPreference?.ageRangePref}
            strictlyFollow={strictlyFollow}
            setSearchParams={setSearchParams}
          />
        );
      case 'Height Range':
        const minHeight = searchParams
          ? searchParams.minHeight
            ? searchParams.minHeight
            : 100
          : initialData?.partnerPreference.minHeight;

        const maxHeight = searchParams
          ? searchParams.maxHeight
            ? searchParams.maxHeight
            : 250
          : initialData?.partnerPreference.maxHeight;
        return (
          <HeightRangeComp
            userGender={initialData?.gender}
            userHeight={initialData?.height}
            partnerMinHeight={minHeight}
            partnerMaxHeight={maxHeight}
            updatePartnerPref={updatePartnerPreference}
            updatePrivateSettings={updatePrivacySettings}
            privacyPrefLevel={initialData?.partnerPreference?.heightRangePref}
            strictlyFollow={strictlyFollow}
            setSearchParams={setSearchParams}
          />
        );
      case 'Income Range':
        const partnerPreference = initialData?.partnerPreference;
        const currentCountry = initialData?.currentCountry;
        const partnerMinIncome =
          currentCountry === 'India'
            ? searchParams
              ? searchParams.minInrIncome
                ? searchParams.minInrIncome
                : 0
              : partnerPreference?.inrMinIncome
            : searchParams
            ? searchParams.minUsdIncome
              ? searchParams.minUsdIncome
              : 0
            : partnerPreference?.usdMinIncome;
        const partnerMaxIncome =
          currentCountry === 'India'
            ? searchParams
              ? searchParams.maxInrIncome
                ? searchParams.maxInrIncome
                : 1000
              : partnerPreference?.inrMaxIncome
            : searchParams
            ? searchParams.maxUsdIncome
              ? searchParams.maxUsdIncome
              : 0
            : partnerPreference?.usdMaxIncome;

        return (
          <IncomeRangeComp
            partnerMinIncome={partnerMinIncome}
            partnerMaxIncome={partnerMaxIncome}
            country={currentCountry}
            updatePartnerPref={updatePartnerPreference}
            updatePrivateSettings={updatePrivacySettings}
            privacyPrefLevel={initialData?.partnerPreference?.incomePref}
            strictlyFollow={strictlyFollow}
            setSearchParams={setSearchParams}
          />
        );
      case 'Marital Status':
        const maritalStatuses = searchParams
          ? searchParams.maritalStatuses
            ? searchParams.maritalStatuses
            : []
          : initialData?.partnerPreference?.maritalStatuses;
        return (
          <MaritalStatus
            partnerMaritalStatuses={maritalStatuses}
            updatePartnerPref={updatePartnerPreference}
            setSearchParams={setSearchParams}
          />
        );
      case 'Mother Tongue':
        const toungeIds = searchParams
          ? searchParams.motherTongueIds
            ? searchParams.motherTongueIds
            : []
          : initialData?.partnerPreference?.motherTongueIds;
        return (
          <MotherTongueSelectionComp
            initialTongueIds={toungeIds}
            updatePartnerPref={updatePartnerPreference}
            setSearchParams={setSearchParams}
          />
        );
      case 'Qualification':
        const qualificationIds = searchParams
          ? searchParams.qualificationIds
            ? searchParams.qualificationIds
            : []
          : initialData?.partnerPreference?.qualificationIds;
        return (
          <QualificationSelectionComp
            initialQualificationIds={qualificationIds ?? []}
            updatePartnerPref={updatePartnerPreference}
            setSearchParams={setSearchParams}
          />
        );
      case 'Profession':
        const professionIds = searchParams
          ? searchParams.professionIds
            ? searchParams.professionIds
            : []
          : initialData?.partnerPreference?.professionIds;
        return (
          <ProfessionSelectionComp
            initialProfessionIds={professionIds ?? []}
            updatePartnerPref={updatePartnerPreference}
            setSearchParams={setSearchParams}
          />
        );
      case 'Religion':
        const religions = searchParams
          ? searchParams.religions
            ? searchParams.religions
            : []
          : initialData?.partnerPreference?.religions;
        return (
          <ReligionSelectionComp
            partnerReligions={religions ?? []}
            updatePartnerPref={updatePartnerPreference}
            setSearchParams={setSearchParams}
          />
        );
      case 'Community':
        const communityReligions = initialData?.partnerPreference?.religions;
        const communityIds = initialData?.partnerPreference?.communityIds;
        return (
          <CommunitySelectionComp
            partnerReligions={communityReligions ?? []}
            updatePartnerPref={updatePartnerPreference}
            partnerCommunities={communityIds ?? []}
            partnerSubCommunities={[]}
          />
        );
      case 'Sub Community':
        const religionList = initialData?.partnerPreference?.religions;
        const communityIdList = initialData?.partnerPreference?.communityIds;
        const subCommunityIdList = initialData?.partnerPreference?.subCommunityIds;
        return (
          <SubCommunitySelectionComp
            partnerReligions={religionList ?? []}
            updatePartnerPref={updatePartnerPreference}
            partnerCommunities={communityIdList ?? []}
            partnerSubCommunities={subCommunityIdList ?? []}
          />
        );
      case 'Diets':
        const diets = searchParams
          ? searchParams.diets
            ? searchParams.diets
            : []
          : initialData?.partnerPreference?.diets;
        return (
          <DietSelectionComp
            initialDiets={diets}
            updatePartnerPref={updatePartnerPreference}
            setSearchParams={setSearchParams}
          />
        );
      case 'Countries':
        return (
          <InputAdornment
            value={'India'}
            icon={
              <Image
                source={require('src/assets/images/editprofileicons/FrameCurrentLocation.png')}
                style={{ width: 18, height: 18 }}
              />
            }
            editable={false}
          />
        );
      case 'States and Cities':
        const statesAndCities = searchParams
          ? searchParams.statesAndCities
            ? searchParams.statesAndCities
            : []
          : initialData?.partnerPreference?.states;
        return (
          <StatesCitiesSelectionComp
            initialStatesAndCities={statesAndCities}
            setSearchParams={setSearchParams}
          />
        );
      case 'State':
        const states = initialData?.partnerPreference?.states;
        return (
          <StateSelectionComp
            initialStates={states}
            updatePartnerPref={updatePartnerPreference}
            setSearchParams={setSearchParams}
          />
        );
      case 'City':
        const cities = initialData?.partnerPreference?.cities;
        return (
          <CitySelectionComp
            initialCities={cities}
            updatePartnerPref={updatePartnerPreference}
            setSearchParams={setSearchParams}
          />
        );
      default:
        return <Text>{t}</Text>;
    }
  };
  return <React.Fragment>{render(title)}</React.Fragment>;
};

const CollapsibleItem = ({
  initialData = null,
  section,
  index,
  expandAll,
  strictlyFollow = true,
  showPills = true,
  searchParams,
  setSearchParams,
}: {
  initialData: Partial<ProfileResp>;
  section: string;
  index: number;
  expandAll: boolean;
  strictlyFollow?: boolean;
  showPills?: boolean;
  searchParams?: Partial<ProfileSearchBody>;
  setSearchParams?: (T: Partial<ProfileSearchBody>) => void;
}) => {
  const [isActive, setIsActive] = useState(false);

  const onPress = useCallback(() => {
    setIsActive((prevIsActive) => !prevIsActive);
  }, []);

  useEffect(() => {
    setIsActive(expandAll);
  }, [expandAll]);

  return (
    <View
      style={{ borderBottomWidth: 1, borderBottomColor: Color.lightBorder, marginHorizontal: 10 }}
    >
      <CollapsibleHeader
        header={section}
        isActive={isActive}
        onPress={onPress}
        initialData={initialData}
        showPills={showPills}
        searchParams={searchParams}
      />
      {isActive && (
        <CollapsibleContent
          key={index}
          content={
            <RenderContent
              initialData={initialData}
              title={section}
              index={index}
              isActive={isActive}
              strictlyFollow={strictlyFollow}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          }
        />
      )}
    </View>
  );
};

const Collapsible = ({
  initialData = null,
  sections = [],
  expandAll = false,
  strictlyFollow = true,
  showPills = true,
  searchParams,
  setSearchParams,
}: CustomCollapsibleProps) => {
  return (
    <ScrollView style={{ flex: 1 }}>
      {sections.map((section: string, index) => (
        <CollapsibleItem
          initialData={initialData}
          key={index}
          section={section}
          expandAll={expandAll}
          index={index}
          strictlyFollow={strictlyFollow}
          showPills={showPills}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      ))}
    </ScrollView>
  );
};

export default Collapsible;
