import { View, Text, StyleSheet, TouchableOpacity, SafeAreaView, Image } from 'react-native';
import React, { useEffect, useState } from 'react';
import CustomCollapsible from 'src/components/editProfileComponents/CustomCollapsible';
import { useSelector } from 'react-redux';
import { ProfileResp } from 'src/common/response/profile.resp';
import { RootState } from 'src/store/store';
import { Color } from 'src/constant/Color';
import EFonts from 'src/constant/EFonts';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import InputAdornment from 'src/components/common/input/InputAdornment';
import PrimaryButton from 'src/components/common/buttons/PrimaryButton';
import { ProfileService } from 'src/service/ProfileService';

const SearchOptions = [
  'Age Range',
  'Height Range',
  'Income Range',
  'Marital Status',
  'Religion',
  'Countries',
  'States and Cities',
  'Qualification',
  'Profession',
];
const availableTabs = ['Search by Criteria', 'Search by Profile Id'];

const Tabs = ({ selectedTab = '', setSelectedTab = (_) => {} }) => {
  return (
    <View style={styles.tabWrapper}>
      {availableTabs.map((tab, index) => (
        <View
          key={index}
          style={[
            selectedTab === tab
              ? StyleSheet.compose(styles.tabBorder, styles.tabBorderSelectedOvveride)
              : styles.tabBorder,
            { justifyContent: 'center', alignItems: 'center' },
          ]}
        >
          <TouchableOpacity
            onPress={() => setSelectedTab(tab)}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              paddingBottom: 0,
            }}
          >
            <Text
              style={[
                selectedTab === tab
                  ? StyleSheet.compose(styles.tabText, styles.tabTextSelectedOvveride)
                  : styles.tabText,
              ]}
            >
              {tab}
            </Text>
          </TouchableOpacity>
        </View>
      ))}
    </View>
  );
};

const SearchScreen = () => {
  const profileData: Partial<ProfileResp> = useSelector(
    (state: RootState) => state.user.userProfile,
  );
  const navigation = useNavigation();
  const [partnerPreferenceActiveSections, setPartnerPreferenceActiveSections] = useState([]);
  const [selectedTab, setSelectedTab] = useState(availableTabs[0]);
  const [searchParams, setSearchParams] = useState([]);
  const [profileId, setProfileId] = useState('');
  const [expandAll, setExpandAll] = useState(false);
  const isFocused = useIsFocused();

  const handleSearchPressed = () => {
    if (selectedTab === availableTabs[0]) {
      navigation.navigate('searchedprofilesscreen', searchParams);
    } else {
      navigation.navigate('otherpersonprofile', { uuid: profileId });
    }
    setExpandAll(false);
  };

  useEffect(() => {
    ProfileService.getLastSearchParams()
      .then((response) => {
        setSearchParams(response.data);
        setExpandAll(true);
      })
      .catch((error) => console.log(error));
  }, [isFocused]);

  return (
    <SafeAreaView
      style={{ flex: 1, backgroundColor: selectedTab === availableTabs[0] ? Color.white : '' }}
    >
      <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <View style={{ flex: 1, marginTop: 17 }}>
        {selectedTab === availableTabs[0] ? (
          <CustomCollapsible
            initialData={profileData}
            sections={SearchOptions}
            activeSections={partnerPreferenceActiveSections}
            onChange={setPartnerPreferenceActiveSections}
            strictlyFollow={false}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            expandAll={expandAll}
            showSearchButton={true}
            handleSearchPressed={handleSearchPressed}
          />
        ) : (
          <View style={{ paddingHorizontal: 16, flex: 1 }}>
            <Text
              style={{
                fontFamily: EFonts.SORA_SEMIBOLD,
                fontSize: 15,
                lineHeight: 19,
                color: Color.lightBlack,
              }}
            >
              Search by Profile id
            </Text>
            <InputAdornment
              placeholder="Search here..."
              value={profileId}
              setValue={setProfileId}
              styleOverride={true}
              icon={<Image source={require('src/assets/icons/search.png')} />}
              inputStyle={{
                height: 38,
                backgroundColor: Color.white,
                borderWidth: 1,
                borderColor: Color.lightBorder,
              }}
              textStyle={{
                fontFamily: EFonts.SORA_REGULAR,
                fontSize: 12,
                lineHeight: 15,
                color: Color.lightGrey,
                backgroundColor: Color.white,
              }}
            />
            <View
              style={{
                alignSelf: 'center',
                flex: 1,
                position: 'absolute',
                bottom: 10,
              }}
            >
              <PrimaryButton
                handleKeyPress={handleSearchPressed}
                buttonText="Search"
                buttonStyle={{ height: 50 }}
                textStyle={{ fontFamily: EFonts.SORA_SEMIBOLD, fontSize: 15, lineHeight: 19 }}
              />
            </View>
          </View>
        )}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  tabWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginHorizontal: 10,
    marginTop: 20,
    borderBottomWidth: 1,
    borderBottomColor: Color.lightBorder,
  },
  tabText: {
    fontFamily: EFonts.SORA_REGULAR,
    color: Color.lightBlack,
    marginBottom: 5,
    fontSize: 12,
    lineHeight: 15,
  },
  tabTextSelectedOvveride: {
    fontFamily: EFonts.SORA_SEMIBOLD,
    fontSize: 17,
    lineHeight: 21,
    color: Color.purple,
  },
  tabBorder: {
    borderBottomColor: 'transparent',
    flex: 1,
  },
  tabBorderSelectedOvveride: {
    borderBottomColor: Color.purple,
    borderBottomWidth: 1,
  },
});

export default SearchScreen;
