import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { UserResp } from 'src/common/response/user.resp';
import { ProfileResp, StatsResp } from 'src/common/response/profile.resp';
import { UserStatsResponse } from 'src/service/InterestService';

export interface userState {
  user: Partial<UserResp> | null;
  userProfile: Partial<ProfileResp> | null;
  stats: Partial<UserStatsResponse> | null;
}

const initialState: userState = {
  user: null,
  userProfile: null,
  stats: null,
};

export const userSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    incrementByAmount: (state, action: PayloadAction<number>) => {
      // state.value += action.payload
    },
    setUser: (state, action: PayloadAction<UserResp>) => {
      state.user = action.payload;
    },
    setUserProfile: (state, action: PayloadAction<ProfileResp>) => {
      state.userProfile = action.payload;
    },
    setStats: (state, action: PayloadAction<Partial<UserStatsResponse>>) => {
      state.stats = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, setUserProfile, setStats } = userSlice.actions;

export default userSlice.reducer;
