import { ImageBackground, StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native';
import { GenUtil } from 'src/util/GenUtil';
import { ProfileResp } from 'src/common/response/profile.resp';
import { LinearGradient } from 'expo-linear-gradient';
import { Color } from 'src/constant/Color';
import MoreOptionsAction from 'src/components/common/profileCard/actions/MoreOptionsAction';
import UserUtil from 'src/util/UserUtil';
import { useNavigation } from '@react-navigation/native';

const { height, width } = GenUtil.getDimension();

const CARD_HEIGHT = GenUtil.isWEB() ? height / 6 : height / 4;
const CARD_WIDTH = width * 0.35;

type Props = {
  item: ProfileResp;
};

const SmallProfileCard = ({ item }: Props): JSX.Element => {
  const navigation = useNavigation();

  const navigateToProfile = () => {
    navigation.navigate('otherpersonprofile', { uuid: item.uuid });
  };

  return (
    <TouchableWithoutFeedback onPress={navigateToProfile}>
      <View style={styles.container}>
        <View style={{ width: '100%', height: '100%' }}>
          <ImageBackground
            source={GenUtil.getProfilePic(
              item,
              require('src/assets/images/profileIcons/placeholder_profile.png'),
            )}
            style={styles.image}
          >
            <LinearGradient
              colors={['#00000000', '#000000']}
              style={{ height: height / 3.5, width: '100%' }}
            ></LinearGradient>
          </ImageBackground>
        </View>
        <View style={styles.info}>
          <View style={styles.details}>
            {item.isOnline ? (
              <View style={[styles.dot, styles.greenDot]}></View>
            ) : (
              <View style={[styles.dot, styles.redDot]}></View>
            )}
            <Text style={styles.infoText}>{item.fullName}</Text>
          </View>
          <View style={styles.details}>
            <Text style={styles.infoTextSmall}>{item.maritalStatus}</Text>
            <View style={styles.whiteDot} />
            <Text style={styles.infoTextSmall}>{UserUtil.getUserHeight(item.height)}</Text>
          </View>
        </View>
        <MoreOptionsAction profile={item} styles={styles.moreOption} />
      </View>
    </TouchableWithoutFeedback>
  );
};

export default SmallProfileCard;

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    minHeight: CARD_HEIGHT,
    maxHeight: CARD_HEIGHT,
    maxWidth: CARD_WIDTH,
    backgroundColor: '#fff',
    marginHorizontal: 5,
    borderRadius: 12,
  },
  image: {
    width: CARD_WIDTH,
    height: CARD_HEIGHT,
    resizeMode: 'cover',
  },
  info: {
    position: 'absolute',
    bottom: 10,
    left: 10,
  },
  infoText: {
    color: '#fff',
    fontSize: 12,
    fonWeight: '600',
    fontFamily: 'dmsans-bold',
  },
  details: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 3,
  },
  infoTextSmall: {
    fontSize: 10,
    color: Color.white,
  },
  greenDot: {
    backgroundColor: Color.green,
  },
  dot: {
    width: 5,
    height: 5,
    borderRadius: 3,
    marginRight: 4,
  },
  redDot: {
    backgroundColor: Color.red,
  },
  whiteDot: {
    width: 3,
    height: 3,
    borderRadius: 3,
    backgroundColor: Color.white,
    marginHorizontal: 5,
  },
  moreOption: {
    position: 'absolute',
    top: 15,
    right: 10,
  },
});
