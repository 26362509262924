import 'react-native-gesture-handler';
import useCachedResources from 'src/hooks/useCachedResources';
import useColorScheme from 'src/hooks/useColorScheme';
import { Provider as PaperProvider } from 'react-native-paper';
import { Text, View } from 'react-native';
import { GenUtil } from 'src/util/GenUtil';
import axios from 'axios';
import { Provider } from 'react-redux';
import { persistor, store } from 'src/store/store';
import { PersistGate } from 'redux-persist/integration/react';
import StorageUtil from 'src/util/StorageUtil';
import { useCallback, useEffect } from 'react';
import { isFbUserLoggedIn, signInToFirebase } from 'firebaseConfig';
import * as SplashScreen from 'expo-splash-screen';
import { setupApiInterceptors } from 'src/service/axios';
import AppContent from 'src/screens/AppContent';
import * as Sentry from '@sentry/react-native';

Sentry.init({
  dsn: 'https://88679eaba6c324522f94a7745b137e71@o4505753778192384.ingest.sentry.io/4505753778257920',
});

// Keep the splash screen visible while we fetch resources
// eslint-disable-next-line @typescript-eslint/no-floating-promises
SplashScreen.preventAutoHideAsync();

setupApiInterceptors(axios);

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  useEffect(() => {
    const firebaseToken = StorageUtil.getFirebaseAuthTokenFromLocalStorage();
    if (!isFbUserLoggedIn() && firebaseToken) {
      signInToFirebase(firebaseToken);
    }
  });

  const onLayoutRootView = useCallback(async () => {
    if (isLoadingComplete) {
      await SplashScreen.hideAsync();
    }
  }, [isLoadingComplete]);

  if (!isLoadingComplete) {
    return null;
  }

  console.log('isDesktopWeb', GenUtil.isDesktopWeb());

  return (
    <View style={{ flex: 1 }} onLayout={onLayoutRootView}>
      <PaperProvider>
        <Provider store={store}>
          <PersistGate loading={<Text>Loading...</Text>} persistor={persistor}>
            <AppContent />
          </PersistGate>
        </Provider>
      </PaperProvider>
    </View>
  );
}
