import { ProfileResp } from 'src/common/response/profile.resp';
import {
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { Color } from 'src/constant/Color';
import GalleryAction from 'src/components/common/profileCard/actions/GalleryAction';
import { Verification } from 'src/common/models/profile.model';
import UserUtil from 'src/util/UserUtil';
import InterestAction from 'src/components/common/profileCard/actions/InterestAction';
import StarProfileAction from 'src/components/common/profileCard/actions/StarProfileAction';
import { useNavigation } from '@react-navigation/native';
import MapPinSvg from 'src/assets/icons/map-pin.svg';
import UserTickSvg from 'src/assets/icons/user-tick.svg';
import RingSvg from 'src/assets/icons/ring.svg';
import CurrencySvg from 'src/assets/icons/currency.svg';
import VerifySvg from 'src/assets/icons/verify.svg';
import { GenUtil } from 'src/util/GenUtil';
import moment from 'moment';

type Props = {
  item: ProfileResp;
};

export default function HorizontalProfileCard({ item }: Props) {
  const navigation = useNavigation();

  const navigateToProfile = () => {
    navigation.navigate('otherpersonprofile', { uuid: item.uuid });
  };

  const lastViewDate = item?.profileView?.lastViewedAt && new Date(item?.profileView?.lastViewedAt);

  return (
    <TouchableWithoutFeedback onPress={navigateToProfile}>
      <View style={styles.container}>
        <View style={styles.content}>
          <Image
            source={GenUtil.getProfilePic(
              item,
              require('src/assets/images/profileIcons/placeholder_profile.png'),
            )}
            style={styles.imageBg}
            resizeMode={'cover'}
          />
          <View style={styles.galleryContainer}>
            <GalleryAction imagesCount={item.totalPhotos} />
          </View>
          <View style={styles.contentContainer}>
            <View style={styles.infoContainer}>
              <Text style={styles.infoText} numberOfLines={1}>
                {item.fullName}
              </Text>
              {item?.verifications?.find(
                (verification) => verification === Verification.IDVerified,
              ) ? (
                <VerifySvg style={{ marginRight: 8 }} />
              ) : (
                <></>
              )}
            </View>
            <View style={styles.infoContainer}>
              <MapPinSvg color={Color.black} />
              <Text style={styles.infoTextSmall}>{item.currentLocation}</Text>
            </View>
            <View style={styles.infoContainer}>
              <UserTickSvg color={Color.black} />
              <Text style={styles.infoTextSmall}>{item.age}Y,</Text>
              <Text style={styles.infoTextSmall}>
                {GenUtil.convertHeighttoHeightString(item.height)}
              </Text>
            </View>
            <View style={styles.infoContainer}>
              <RingSvg color={Color.black} />
              <Text style={styles.infoTextSmall}>{item.maritalStatus}</Text>
            </View>
            <View style={styles.infoContainer}>
              <CurrencySvg color={Color.black} />
              <Text style={styles.infoTextSmall}>{UserUtil.getUserIncome(item)}</Text>
            </View>

            {lastViewDate ? (
              <Text style={styles.viewText}>
                Viewed profile at {moment(lastViewDate).format('D MMM YYYY, h:mm a')}
              </Text>
            ) : null}

            <View style={[styles.infoContainer, styles.actionContainer]}>
              <InterestAction profile={item} variant="secondary" />
            </View>
          </View>
        </View>
        <View style={styles.starContainer}>
          <StarProfileAction profile={item} variant={'secondary'} />
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Color.white,
    width: '95%',
    alignSelf: 'center',
    marginVertical: 10,
    borderRadius: 12,
    shadowColor: '#171717',
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    elevation: 3,
    flex: 1,
  },
  imageBg: {
    width: '45%',
    height: 210,
    resizeMode: 'cover',
    borderRadius: 12,
  },
  galleryContainer: {
    position: 'absolute',
    top: 10,
    left: '34%',
  },
  content: {
    flexDirection: 'row',
  },
  contentContainer: {
    position: 'relative',
    width: '55%',
    flexDirection: 'column',
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  infoText: {
    fontSize: 15,
    fonWeight: '600',
    fontFamily: 'dmsans-bold',
    width: '90%',
  },
  infoTextSmall: {
    fontSize: 12,
    marginLeft: 5,
  },
  infoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 6,
  },
  actionContainer: {
    marginTop: 14,
  },
  viewText: {
    fontSize: 10,
    marginVertical: 5,
    width: '100%',
  },
  starContainer: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  deleteIcon: {
    width: 37,
    height: 37,
    borderRadius: 50,
    backgroundColor: '#FFE3E3',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 7,
  },
});
