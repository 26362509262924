import { View } from 'src/components/Themed';
import React, { useState } from 'react';
import ChoicesSelector from 'src/components/editProfileComponents/ChoicesSelector';
import { LanguageData, LanguageDataMap } from 'src/common/data/language.data';
import DOESNT_MATTER_OPTION from 'src/constant/DoesntMatter';

const MotherTongueSelectionComp = ({
  initialTongueIds = [],
  updatePartnerPref = async (_) => {},
  setSearchParams,
}) => {
  const [isDoneValidating, setIsDoneValidating] = useState(false);
  const [selectedLanguages, setSelectedLanguages] = useState(
    initialTongueIds.length ? initialTongueIds.map((id) => LanguageDataMap[id]) : [DOESNT_MATTER_OPTION],
  );

  const onPressDone = async () => {
    try {
      setIsDoneValidating(true);
      const selectedMotherTongueIds =
        selectedLanguages[0] === DOESNT_MATTER_OPTION
          ? []
          : selectedLanguages.map((s) => s.id);
      if (setSearchParams) {
        setSearchParams((prevParams) => ({
          ...prevParams,
          ...{ motherTongueIds: selectedMotherTongueIds },
        }));
        setIsDoneValidating(false);
        return;
      }
      await updatePartnerPref({ motherTongueIds: selectedMotherTongueIds });
      setIsDoneValidating(false);
    } catch (error) {
      console.log(error);
      setIsDoneValidating(false);
    }
  };

  const onPressRemove = async (updatedLanguages = []) => {
    console.log(updatedLanguages);
    try {
      const selectedMotherTongueIds = updatedLanguages.map((s) => s.id);
      if (setSearchParams) {
        setSearchParams((prevParams) => ({
          ...prevParams,
          ...{ motherTongueIds: selectedMotherTongueIds },
        }));
        return;
      }
      await updatePartnerPref({ motherTongueIds: selectedMotherTongueIds });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <View style={{ bottom: 20 }}>
      <ChoicesSelector
        dropdownItems={[DOESNT_MATTER_OPTION, ...LanguageData]}
        preprocessItems={(item: { name: string }) => item.name}
        placeHolder="Search Mother Tongue here..."
        selectedValues={selectedLanguages}
        setSelectedValues={setSelectedLanguages}
        onPressDone={onPressDone}
        onPressRemove={onPressRemove}
        isDoneValidating={isDoneValidating}
        title='Mother Tongue'
      />
    </View>
  );
};

export default MotherTongueSelectionComp;
