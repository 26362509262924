/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { View, Text, StyleSheet, ActivityIndicator, TouchableOpacity } from 'react-native';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { AlertTypeEnum, showError } from 'src/store/reducer/errorReducer';
import { Avatar } from 'react-native-paper';
import { GenUtil } from 'src/util/GenUtil';
import { ProfileResp } from 'src/common/response/profile.resp';
import { Gender } from 'src/common/models/profile.model';
import { Color } from 'src/constant/Color';
import CustomDrawerItem from './CustomDrawerItem';
import { getDaysRemaining } from 'src/util/date';
import { Icon } from 'react-native-elements';
import * as Clipboard from 'expo-clipboard';

const DrawerHeader = ({ navigation, state = { routeNames: [], index: 0 } }) => {
  const user: Partial<ProfileResp> = useSelector((state: RootState) => state.user.userProfile);
  const [avatar, setAvatar] = useState(null);
  const screenName = GenUtil.isDesktopWeb() ? 'membership' : 'Membership';
  const dispatch = useDispatch();

  const copyProfileID = async () => {
    await Clipboard.setStringAsync(user.profileId);
    dispatch(showError({ alertType: AlertTypeEnum.Default, message: 'Profile ID Copied' }));
  };

  useEffect(() => {
    if (user?.profilePhoto && user?.gender) {
      const profilePhoto = user?.profilePhoto;
      setAvatar(
        GenUtil.isEmpty(profilePhoto)
          ? user?.gender === Gender.Male
            ? require('../assets/images/placeholder-male.png')
            : require('../assets/images/placeholder-female.png')
          : {
              uri: profilePhoto?.originalUrl,
            },
      );
    }
  }, [user]);

  return (
    <React.Fragment>
      <View style={styles.wrapper}>
        {user && avatar ? (
          <View style={styles.details}>
            <View style={styles.avatarContainer}>
              <Avatar.Image size={65} source={avatar} />
              <View style={styles.badge}>
                <Text style={styles.badgeText}>{user?.membershipCategory}</Text>
              </View>
            </View>
            <View style={styles.info}>
              <Text style={styles.nameText}>{user.fullName}</Text>
              <TouchableOpacity
                style={{ flexDirection: 'row', marginTop: 7 }}
                onPress={copyProfileID}
              >
                <Text style={styles.idText}>{`Profile ID - ${user.profileId}`}</Text>
                <Icon name="copy" type="font-awesome" size={13} color={Color.gray} />
              </TouchableOpacity>
              <View style={styles.actions}>
                <TouchableOpacity onPress={() => navigation.navigate('editprofile', {screen: 'editprofile'})}>
                  <Text style={styles.actionText}>Edit Profile</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        ) : (
          <ActivityIndicator />
        )}
      </View>
      <CustomDrawerItem
        screenName={screenName}
        navigation={navigation}
        displayName={'My Membership'}
        isActive={state.routeNames[state.index] === 'mymembership'}
      />
      {user?.isMembershipActive ? (
        <TouchableOpacity
          style={styles.premiumMemberContainer}
          onPress={() => navigation.navigate(screenName)}
        >
          <Text style={styles.premiumHeaderText}>{user?.membershipCategory} Member</Text>
          <Text style={styles.premiumSubText}>
            {getDaysRemaining(user?.membershipExpiredOn)} remaining
          </Text>
        </TouchableOpacity>
      ) : (
        <></>
      )}
      <View style={{ marginTop: 5, marginBottom: 15, alignItems: 'center' }}>
        <View style={styles.divider}></View>
      </View>
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  drawerItem: {
    marginHorizontal: 10,
    paddingVertical: 10,
    paddingHorizontal: 10,
    borderRadius: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Color.white,
  },
  drawerItemText: {
    fontFamily: 'dmsans-regular',
    fontSize: 14,
    color: Color.lightBlack,
  },
  activeDrawerItemOvveride: {
    backgroundColor: Color.lightPurple,
  },
  activeDrawerTextOvveride: {
    color: Color.red,
  },
  wrapper: {
    flex: 1,
    padding: 15,
    justifyContent: 'center',
  },
  details: {
    flexDirection: 'row',
  },
  info: {
    flex: 1,
    marginLeft: 10,
  },
  nameText: {
    fontFamily: 'dmsans-medium',
    fontSize: 20,
  },
  idText: {
    fontFamily: 'dmsans-bold',
    fontSize: 12,
    color: Color.gray,
    marginRight: 3,
  },
  actions: {
    marginTop: 7,
    flexDirection: 'row',
  },
  actionText: {
    color: Color.red,
    fontFamily: 'dmsans-bold',
    fontSize: 12,
  },
  avatarContainer: {
    alignItems: 'center',
  },
  badge: {
    backgroundColor: Color.red,
    padding: 4,
    borderRadius: 3,
    alignItems: 'center',
    top: -5,
  },
  badgeText: {
    color: Color.white,
    fontFamily: 'dmsans-bold',
    fontSize: 10,
  },
  premiumMemberContainer: {
    flex: GenUtil.isWEB() ? null : 1,
    backgroundColor: Color.white,
    padding: 15,
    marginHorizontal: 16,
    marginVertical: 15,
    borderRadius: 10,
    elevation: 6,
    shadowColor: '#BEBEBE',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.22,
    shadowRadius: 24,
  },
  premiumHeaderText: {
    fontFamily: 'dmsans-medium',
    fontSize: 14,
  },
  premiumSubText: {
    fontFamily: 'dmsans-regular',
    fontSize: 12,
    color: Color.gray,
    marginTop: 5,
  },
  divider: {
    borderBottomWidth: 0.2,
    borderColor: Color.gray,
    width: '90%',
  },
});

export default DrawerHeader;
