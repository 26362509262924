import { ActivityIndicator, FlatList, Text, View } from 'react-native';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { ProfileService } from 'src/service/ProfileService';
import { ProfileResp } from 'src/common/response/profile.resp';
import LargeProfileCard from 'src/components/common/profileCard/LargeProfileCard';
import HomeSection from 'src/components/common/HomeSection';
import { ProfileTabsEnum } from 'src/screens/DynamicProfileList';

const HandPickerProfilesSection = () => {
  const [handpickedProfiles, setHandpickedProfiles] = useState<ProfileResp[]>(null);
  const refresh = useSelector((state: RootState) => state.ui.refresh);

  const fetchHandPickedProfiles = async () => {
    try {
      const response = await ProfileService.getProfiles({});

      if (response.success) {
        setHandpickedProfiles(response.data);
      } else {
        setHandpickedProfiles([]);
        console.log(response.message, response);
      }
    } catch (err) {
      setHandpickedProfiles([]);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchHandPickedProfiles();
  }, [refresh]);

  return (
    handpickedProfiles?.length > 0 ?
      <HomeSection
        title={'Handpicked Profiles For You'}
        navigateOnViewAll={{
          to: 'dynamicprofileslist',
          state: {
            title: 'Handpicked Profiles',
            tabs: [ProfileTabsEnum.HandPickedProfiles],
          },
        }}
      >
        {handpickedProfiles ? (
          <FlatList
            data={handpickedProfiles}
            renderItem={(item) => <LargeProfileCard item={item.item} />}
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            keyExtractor={(item) => item.uuid}
          />
        ) : (
          <View style={{ minHeight: 50, justifyContent: 'center' }}>
            <ActivityIndicator />
          </View>
        )}
      </HomeSection> : null
  );
};

export default HandPickerProfilesSection;
