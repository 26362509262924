import { StyleSheet, TouchableOpacity, Image } from 'react-native';
import { Text, View } from 'src/components/Themed';
import { Color } from 'src/constant/Color';
import { GenUtil } from 'src/util/GenUtil';
import EFonts from 'src/constant/EFonts';
import DOESNT_MATTER_OPTION from 'src/constant/DoesntMatter';

export default function PreferenceSeletedChip({
  preprocessSelectedItems = null,
  selectedValues = [],
  setSelectedValues = (_) => {},
  onPressRemove = null,
}) {
  const deleteChipHandler = async (item) => {
    const temp = [...selectedValues];

    // If the array only contains DOESNT_MATTER_OPTION, don't remove it
    if (temp.length === 1 && temp[0] === DOESNT_MATTER_OPTION.name) {
      return;
    }

    temp.splice(
      (preprocessSelectedItems ? temp.map((i) => preprocessSelectedItems(i)) : temp).indexOf(
        preprocessSelectedItems ? preprocessSelectedItems(item) : item,
      ),
      1,
    );

    // If the array becomes empty after removing an item, add DOESNT_MATTER_OPTION
    if (temp.length === 0) {
      temp.push(DOESNT_MATTER_OPTION.name);
    }

    onPressRemove && (await onPressRemove(temp));
    setSelectedValues(temp);
  };

  return (
    <View style={styles.chipsContainer}>
      {selectedValues.length === 1 && selectedValues?.[0] === DOESNT_MATTER_OPTION.name ? (
        <></>
      ) : (
        <>
          {selectedValues.map((res, index) => {
            return (
              <View key={index} style={styles.preferenceChip}>
                <Text style={styles.chipText}>
                  {preprocessSelectedItems ? preprocessSelectedItems(res) : res}
                </Text>
                <TouchableOpacity onPress={() => deleteChipHandler(res)}>
                  <Image
                    source={require('src/assets/icons/cancel.png')}
                    style={{ height: 10, width: 10, marginLeft: 12, marginRight: 4 }}
                  />
                </TouchableOpacity>
              </View>
            );
          })}
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  preferenceChip: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: GenUtil.isIOS() ? 15 : 20,
    minWidth: 50,
    textAlign: 'center',
    marginVertical: 5,
    marginHorizontal: 8,
    padding: 8,
    color: Color.purple,
    backgroundColor: '#EEEEEE',
  },
  chipText: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    lineHeight: 20,
    color: Color.lightBlack,
  },
  chipsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});
