import { useEffect, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';
import { useSelector } from 'react-redux';
import { ProfileMembershipDetailsResp } from 'src/common/response/profile-membership.resp';
import BuyMembershipComp from 'src/components/membership/BuyMembershipComp';
import UserMembershipComp from 'src/components/membership/UserMembershipComp';
import { ProfileService } from 'src/service/ProfileService';
import { RootState } from 'src/store/store';

export default function PremiumMemberScreen() {
  const { isMembershipActive } = useSelector((state: RootState) => state.user.userProfile);
  const [isLoading, setIsLoading] = useState(true);
  const [membershipDetails, setMembershipDetails] =
    useState<Partial<ProfileMembershipDetailsResp>>();

  const getMembershipDetails = async () => {
    if (isMembershipActive) {
      const response = await ProfileService.getMembershipDetails();
      setMembershipDetails(response.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getMembershipDetails().catch((error) =>
      console.log('error in fetching membership details', error),
    );
  }, []);

  return (
    <View style={{ flex: 1 }}>
      {isLoading ? (
        <ActivityIndicator style={{ alignSelf: 'center', flex: 1 }} />
      ) : isMembershipActive ? (
        <UserMembershipComp membershipDetails={membershipDetails} />
      ) : (
        <BuyMembershipComp />
      )}
    </View>
  );
}
