import { oc } from 'ts-optchain';
import { strings } from 'src/util/LocalizationUtil';

class Endpoints {
  static DYNAMIC_LINK_DOMAIN = 'https://changaapp.page.link';

  static S3_CDN_BASE_URL = 'https://cdn-b.changa.in';

  static BLAZE_CDN_BASE_URL = 'https://cdn-bz.changa.in';

  static STAGING_URL = 'https://testapi.matrimilan.com/api/v1';

  static APP_LINK = 'https://play.google.com/store/apps/details?id=com.bitcs.desitalent';

  static STAGING_CDN_URL = 'https://test-api-cdn.changa.in/api/v1';

  static PRODUCTION_URL = 'https://apiv2.changa.in/api/v1';

  static PRODUCTION_CDN_URL = 'https://api-cdn.changa.in/api/v1';

  static UPDATE_NOTIFICATION_PREFERENCES = Endpoints.PRODUCTION_URL + '/user-settings/notification';

  static SERVER_URL = Endpoints.STAGING_URL;

  static CDN_URL = Endpoints.PRODUCTION_CDN_URL;

  static PRIVACY_POLICY = 'https://www.changa.in/app/privacy-policy';

  static TERMS = 'https://www.changa.in/app/terms-and-condition';

  static COMMUNITY_GUIDELINES = 'https://www.changa.in/app/community';

  static ABOUT_US_LINK = 'https://www.changa.in/app/about';

  static SUPPORT_LINK = 'https://www.changa.in/app/faq';

  static FAQ = 'https://www.changa.in/app/faqs';

  static FACEBOOK_LINK = 'https://www.facebook.com/changaindian';

  static GET_USER_FROM_USERNAME = `${Endpoints.SERVER_URL}/user/username/`;

  static TWITTER_LINK = 'https://twitter.com/Changa28070332';

  static INSTAGRAM_LINK = 'https://www.instagram.com/changa_indiaofficial';

  static CHAT_URL = Endpoints.INSTAGRAM_LINK;

  static SHORTLISTED_PROFILE = `${Endpoints.SERVER_URL}/profile/shortlist`;

  static ADD_SHORTLISTED_PROFILE = `${Endpoints.SERVER_URL}/profile/shortlist/add`;

  static REMOVE_SHORTLISTED_PROFILE = `${Endpoints.SERVER_URL}/profile/shortlist/remove`;

  static PROFILE_DETAILS = `${Endpoints.SERVER_URL}/profile/details`;

  static SIMILAR_PROFILES = `${Endpoints.SERVER_URL}/profile/similar`;

  static RECOMMENDED_PROFILES = `${Endpoints.SERVER_URL}/profile/recommendation`;

  static SHORTLIST_PROFILES = `${Endpoints.SERVER_URL}/profile/shortlist`;

  static PROFILE_STATS = `${Endpoints.SERVER_URL}/profile/stats`;

  static PROFILES_SEARCH = `${Endpoints.SERVER_URL}/profile/search`;

  static RESTRICTED_PROFILES = `${Endpoints.SERVER_URL}/profile/exclude`;

  static RESTRICT_PROFILE = `${Endpoints.SERVER_URL}/profile/exclude/add`;

  static UNDO_RESTRICT_PROFILE = `${Endpoints.SERVER_URL}/profile/exclude/remove`;

  static PROFILE_UPDATE_BASIC = `${Endpoints.SERVER_URL}/profile/update/basic`;

  static PROFILE_UPDATE_FAMILY = `${Endpoints.SERVER_URL}/profile/update/family`;

  static PROFILE_UPDATE_EDUCATION = `${Endpoints.SERVER_URL}/profile/update/education`;

  static PROFILE_UPDATE_CAREER = `${Endpoints.SERVER_URL}/profile/update/career`;

  static PROFILE_UPDATE_LIFESTYLE = `${Endpoints.SERVER_URL}/profile/update/lifestyle`;

  static PROFILE_UPDATE_RELIGION = `${Endpoints.SERVER_URL}/profile/update/religion`;

  static PROFILE_UPDATE_ASTRO = `${Endpoints.SERVER_URL}/profile/update/astro`;

  static PROFILE_UPDATE_RELIGIOUS_BELIEFS = `${Endpoints.SERVER_URL}/profile/update/religious-beliefs`;

  static PROFILE_UPDATE_HOBBIES = `${Endpoints.SERVER_URL}/profile/update/hobbies`;

  static PROFILE_UPDATE_PARTIAL_UPDATE = `${Endpoints.SERVER_URL}/profile/update/partial-update`;

  static PROFILE_UPDATE_PARTNER_PREFERENCE = `${Endpoints.SERVER_URL}/profile/update/partner-preference`;

  static PROFILE_UPDATE_PRIVACY_SETTINGS = `${Endpoints.SERVER_URL}/profile/update/privacy-settings`;

  static GET_LAST_SEARCH_PARAMS = `${Endpoints.SERVER_URL}/profile/search-params`;

  static SEND_PROFILE_VIEW = `${Endpoints.SERVER_URL}/view/profile`;

  static PROFILE_VIEWERS = `${Endpoints.SERVER_URL}/view/profile/viewers`;

  static PROFILE_VIEWED = `${Endpoints.SERVER_URL}/view/profile/viewed`;

  static CAN_VIEW_CONTACT = `${Endpoints.SERVER_URL}/profile/contact/can-view`;

  static CONTACT_VIEWED = `${Endpoints.SERVER_URL}/view/contact/viewed`;

  static CONTACT_VIEWERS = `${Endpoints.SERVER_URL}/view/contact/viewers`;

  static SEND_CONTACT_VIEW = `${Endpoints.SERVER_URL}/profile/contact/view`;

  static SEND_INTEREST = `${Endpoints.SERVER_URL}/interest/send`;

  static FETCH_INTERESTS = `${Endpoints.SERVER_URL}/interest`;

  static ACTION_ON_INTEREST = `${Endpoints.SERVER_URL}/interest/action`;

  static DELETE_INTEREST = `${Endpoints.SERVER_URL}/interest`;

  static SEND_INTEREST_REMINDER = `${Endpoints.SERVER_URL}/interest/reminder`;

  static SEND_INTEREST_VIEW = `${Endpoints.SERVER_URL}/view/interest`;

  static USER_STATS = `${Endpoints.SERVER_URL}/stats`;

  static SIGN_UP = `${Endpoints.SERVER_URL}/user/signup`;

  static LOGIN = `${Endpoints.SERVER_URL}/user/login`;

  static LOGGED_IN_USER_DETAILS = `${Endpoints.SERVER_URL}/user/details`;

  static HEART_BEAT = `${Endpoints.SERVER_URL}/user/heartbeat`;

  static FIREBASE_TOKEN = `${Endpoints.SERVER_URL}/user/firebase-token`;

  static UPDATE_USER = `${Endpoints.SERVER_URL}/user`;

  static GET_APP_ENGINE = `${Endpoints.SERVER_URL}/app-engine`;

  static UPDATE_USER_DATA = `${Endpoints.SERVER_URL}/user/edit`; // put api

  static UPLOAD_USER_CONTACTS = `${Endpoints.SERVER_URL}/user/phone-book`; // put api

  static UPDATE_USER_EXCLUSIVE_STATUS = `${Endpoints.SERVER_URL}/user/activate-exclusive-membership`; // put api

  static REPORT_USER = `${Endpoints.SERVER_URL}/report/user`;

  static PRESIGNED_URL = `${Endpoints.SERVER_URL}/profile/media/pre-signed-url`;

  static ADD_MEDIA = `${Endpoints.SERVER_URL}/profile/media/add`;

  static UPDATE_MEDIA = `${Endpoints.SERVER_URL}/profile/media/update`;

  static UPLOAD_MEDIA = `${Endpoints.SERVER_URL}/profile/media/UPLOAD`;

  static DELETE_MEDIA = `${Endpoints.SERVER_URL}/profile/media`;

  static SEARCH = `${Endpoints.CDN_URL}/search/prefix`;

  static SEARCH_DATA = `${Endpoints.SERVER_URL}/video/`;

  static SEND_MESSAGE = `${Endpoints.SERVER_URL}/message/chat/`; // /message/chat/12322, put request

  static GET_GROUPS_FOR_USER = `${Endpoints.SERVER_URL}/message/chat`;

  static PUT_ATTRIBUTES_USER = `${Endpoints.SERVER_URL}/user/attributes`;

  static GET_GROUP_INFO = `${Endpoints.SERVER_URL}/message/chat/`; // message/chat/12322 post request

  static GET_ONLINE_USERS = `${Endpoints.SERVER_URL}/user/fetch-active-online-users`; // get list of online users

  static GET_APP_UPDATE_STATUS = `${Endpoints.SERVER_URL}/app-engine/app-version`;

  static GET_APP_CODEPUSH_STATUS = `${Endpoints.CDN_URL}/app-engine/app-codepush-version`;

  static GET_NOTIFICATIONS = `${Endpoints.SERVER_URL}/notification`;

  static SEND_OTP_FOR_PHONE_AUTH = `${Endpoints.SERVER_URL}/otp/send`;

  static VERIFY_OTP_FOR_PHONE_AUTH = `${Endpoints.SERVER_URL}/otp/verify`;

  static ELIGIBLE_OTP_FOR_PHONE_AUTH = `${Endpoints.SERVER_URL}/otp/eligible`;

  static SEND_FEEDBACK = `${Endpoints.SERVER_URL}/feedback`;

  static IMAGE_CDN_URL = 'https://cdn-bz.changa.in';

  static USER_CURRENT_STATE = `${Endpoints.SERVER_URL}/user/ip/state`;

  static UNBLOCK_CHAT = `${Endpoints.SERVER_URL}/message/un-block-chat/`;

  static BLOCK_CHAT = `${Endpoints.SERVER_URL}/message/block-chat/`;

  static PAYMENT_STATUS = `${Endpoints.SERVER_URL}/payment/status`;

  static INITIATE_PAYMENT = `${Endpoints.SERVER_URL}/payment/initiate`;

  static PREV_PAYMENT_DETAILS = `${Endpoints.SERVER_URL}/payment/details`;

  static GET_ALL_CHATS = `${Endpoints.SERVER_URL}/chat`;

  static SEND_CHAT_MESSAGE = `${Endpoints.SERVER_URL}/chat`; //body of type chatBody (reffer common folder)

  static MARK_SEEN = `${Endpoints.SERVER_URL}/chat/mark-seen`; //requires groupId in body

  static GET_CITIES_OF_COUNTRY = `${Endpoints.SERVER_URL}/data/location/country`;

  static LIST_PROFILES = `${Endpoints.SERVER_URL}/profile/list`;

  static SEARCH_AUTOCOMPLETE_LOCATION = `${Endpoints.SERVER_URL}/auto-complete/search/location`;

  static LIST_PRODUCTS = `${Endpoints.SERVER_URL}/order/product`;

  static PLACE_ORDER = `${Endpoints.SERVER_URL}/order`;

  static VERIFY_ORDER = `${Endpoints.SERVER_URL}/order/razorpay/verify`;

  static PROFILE_ACTIVITY = `${Endpoints.SERVER_URL}/activity`;

  static NOTIFICATIONS_SETTINGS = `${Endpoints.SERVER_URL}/profile/notification-settings`;

  static MEMBERSHIP_DETAILS = `${Endpoints.SERVER_URL}/profile/membership-details`;

  static FORGOT_PASSWORD = `${Endpoints.SERVER_URL}/user/forgot-password`;

  static RESET_PASSWORD = `${Endpoints.SERVER_URL}/user/reset-password`;

  static HEARTBEAT = `${Endpoints.SERVER_URL}/user/heartbeat`;
}
export const getError = (err: any) => {
  try {
    if (err?.response) {
      if (err?.response?.data?.message) {
        return err.response.data.message;
      }
      if (err?.response?.status === 500) {
        return 'Internal Server Error';
      }
      if (err?.response?.status === 404) {
        return 'Not found';
      }
      if (err?.response?.status === 403) {
        return 'Permission Denied';
      }
      if (err?.response?.status === 401) {
        return 'Unauthorized Access';
      }
      return oc(err).response.data.errorMessage(strings.something_went_wrong);
    }
    return 'Something went wrong';
  } catch (ex) {
    return 'Something went wrong';
  }
};

export default Endpoints;
