import { StyleSheet, Text, View, Image } from 'react-native'
import React from 'react'
import EFonts from 'src/constant/EFonts'
import Button from 'src/components/common/buttons/Button';

const ProfileNotFound = ({
  title = "We're Sorry",
  subHeading = "We've searched your interest and did not find any match",
  onPressButtonText = "Search Again",
  buttonVisibility = false,
  onButtonPress,
  overrideStyle = {
    bodyStyle : {},
    containerStyle: {},
    imageStyle : {}
  },
}:{
  title?: string
  subHeading?: string
  onPressButtonText?: string
  buttonVisibility: boolean
  onButtonPress: () => void
  overrideStyle?: {
    imageStyle?: object | null,
    bodyStyle?: object | null,
    containerStyle?: object | null,
  }
}) => {
  return (
    <View style={StyleSheet.compose(styles.container, overrideStyle.containerStyle)}>
      <Image source={require('src/assets/images/error/no-image-found.png')} style={StyleSheet.compose(styles.image, overrideStyle.imageStyle)} />
      <View style={StyleSheet.compose(styles.body, overrideStyle.bodyStyle)}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.subHeading}>{subHeading}</Text>
      </View>
      {buttonVisibility && <Button onPress={onButtonPress}>{onPressButtonText}</Button>}
    </View>
  )
}

export default ProfileNotFound

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    gap: 20,
  },
  body: {
    alignItems: 'center',
    gap: 10,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: EFonts.SORA_MEDIUM
  },
  image: {
    height: 150,
    width: 150,
  },
  subHeading: {
    textAlign: 'center',
  }
})