import { View } from 'src/components/Themed';
import React, { useState } from 'react';
import ChoicesSelector from 'src/components/editProfileComponents/ChoicesSelector';
import { ProfessionData, ProfessionDataMap } from 'src/common/data/education.data';
import { ProfileSearchBody } from 'src/common/dto/profile.dto';
import DOESNT_MATTER_OPTION from 'src/constant/DoesntMatter';

const ProfessionSelectionComp = ({
  initialProfessionIds = [],
  updatePartnerPref = async (_) => {},
  setSearchParams,
}) => {
  const [isDoneValidating, setIsDoneValidating] = useState(false);
  const [selectedProfessions, setSelectedProfessions] = useState(
    initialProfessionIds.length ? initialProfessionIds.map((id) => ProfessionDataMap[id]) : [DOESNT_MATTER_OPTION],
  );

  const onPressDone = async () => {
    try {
      setIsDoneValidating(true);
      const selectedProfessionIds =
        selectedProfessions[0] === DOESNT_MATTER_OPTION
          ? []
          : selectedProfessions.map((s) => s.id);
      if (setSearchParams) {
        setSearchParams((prevParams: Partial<ProfileSearchBody>) => ({
          ...prevParams,
          ...{ professionIds: selectedProfessionIds },
        }));
        setIsDoneValidating(false);
        return;
      }
      await updatePartnerPref({ professionIds: selectedProfessionIds });
      setIsDoneValidating(false);
    } catch (error) {
      console.log(error);
      setIsDoneValidating(false);
    }
  };

  const onPressRemove = async (updatedProfessions = []) => {
    console.log(updatedProfessions);
    try {
      const selectedProfessionIds = updatedProfessions.map((s) => s.id);
      if (setSearchParams) {
        setSearchParams((prevParams: Partial<ProfileSearchBody>) => ({
          ...prevParams,
          ...{ professionIds: selectedProfessionIds },
        }));
        return;
      }
      await updatePartnerPref({ professionIds: selectedProfessionIds });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <View style={{ bottom: 20 }}>
      <ChoicesSelector
        dropdownItems={[DOESNT_MATTER_OPTION, ...ProfessionData]}
        preprocessItems={(item: { name: string }) => item.name}
        placeHolder="Search Profession here..."
        selectedValues={selectedProfessions}
        setSelectedValues={setSelectedProfessions}
        onPressDone={onPressDone}
        onPressRemove={onPressRemove}
        isDoneValidating={isDoneValidating}
        title='Profession'
      />
    </View>
  );
};

export default ProfessionSelectionComp;
