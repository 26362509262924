import React, { useState, useEffect } from 'react';
import { StyleSheet, TouchableOpacity, Image } from 'react-native';
import { View, Text } from 'src/components/Themed';
import EFonts from 'src/constant/EFonts';
import { GenUtil } from 'src/util/GenUtil';
import { ProfileResp } from 'src/common/response/profile.resp';
import { Color } from 'src/constant/Color';
import InputAdornment from 'src/components/common/input/InputAdornment';
import ModalAdornment from 'src/components/editProfileComponents/atoms/ModalAdornment';
import { EducationLevel, EducationMedium, EducationRecord } from 'src/common/models/profile.model';
import { QualificationData, QualificationDataMap } from 'src/common/data/education.data';
import SaveButton from '../SaveButton';
import { ProfileService } from 'src/service/ProfileService';
import { ProfileUpdateService } from 'src/service/ProfileUpdateService';
import { useDispatch } from 'react-redux';
import { setUserProfile } from 'src/store/reducer/userReducer';
import { EducationDetailsBody } from 'src/common/dto/profile.dto';
import { Icon } from 'react-native-elements';

import { AlertTypeEnum, showError } from 'src/store/reducer/errorReducer';
const { width } = GenUtil.getDimension();

const degree = Object.values(QualificationDataMap).map((res) => res.name);

const years = [];
for (let i = 1970; i <= new Date().getFullYear(); i++) years.push(i.toString());

export default function Mygraduations({
  profileEducationDetails,
}: {
  profileEducationDetails: Partial<ProfileResp>;
}) {
  const [educationLevel, setEducatioonLevel] = useState(
    profileEducationDetails?.educationLevel ?? ('' as EducationLevel),
  );
  const [educationMedium, setEducatioonMedium] = useState(
    profileEducationDetails?.educationMedium ?? ('' as EducationMedium),
  );

  const [graduations, setGraduations] = useState(
    profileEducationDetails?.educations
      ?.filter((filterRes) => filterRes.qualification != 'High School')
      ?.map((res) => {
        return {
          college: res.college,
          qualification: res.qualification,
          yearStarted: res.yearStarted.toString(),
          yearCompleted: res.yearCompleted.toString(),
        };
      }),
  );

  const [graduationsCount, setGraduationsCount] = useState(Math.max(graduations?.length, 1));
  const components = [];

  const [aboutEducation, setAboutEducation] = useState(
    profileEducationDetails?.aboutEducation ?? '',
  );

  const schoolData = profileEducationDetails?.educations?.filter(
    (res) => !!(res.qualification === 'High School'),
  );
  const [schoolName, setSchoolName] = useState(schoolData?.[0]?.college ?? '');
  const [schoolYearStarted, setSchoolYearStarted] = useState(
    schoolData?.[0]?.yearStarted?.toString() ?? '',
  );
  const [schoolPassingYear, setSchoolPassingYear] = useState(
    schoolData?.[0]?.yearCompleted?.toString() ?? '',
  );
  const [schoolBoard, setSchoolBoard] = useState(profileEducationDetails?.schoolBoard ?? '');
  const [currentlySelectedCollege, setCurrentlySelectedCollege] = useState(null as string);
  const [currentlySelectedQualification, setCurrentlySelectedQualification] = useState('');
  const [currentlySelectedYearCompleted, setCurrentlySelectedYearCompleted] = useState('');
  const [currentlySelectedYearStarted, setCurrentlySelectedYearStarted] = useState('');
  const [index, setIndex] = useState();
  const [isValidating, setIsValidating] = useState(false);
  const aboutEduPlaceholder = `Example :- "I'm a graduated person. I have recently completed my masters degree and I'm looking forward to start my Phd"`;
  const dispatch = useDispatch();

  function onPressAddMore() {
    setCurrentlySelectedCollege(null);
    setCurrentlySelectedQualification('');
    setCurrentlySelectedYearStarted('');
    setCurrentlySelectedYearCompleted('');
    setGraduationsCount((prev) => prev + 1);
  }
  function renderImage() {
    return (
      <Image
        source={require('src/assets/images/editprofileicons/graduationHat.png')}
        style={{ height: 18, width: 18 }}
      />
    );
  }
  const ifAnyGraduationFieldIsEmpty = (i: number) => {
    return (
      !graduations?.[i] ||
      !graduations?.[i].college ||
      graduations?.[i].qualification === '' ||
      graduations?.[i].yearStarted === '' ||
      graduations?.[i].yearCompleted == ''
    );
  };
  const deleteGraduationHandler = (i: number) => {
    if (i < graduations.length) {
      setGraduations((prev) => {
        const arr = [];
        prev.map((res, id) => {
          if (id != i) arr.push(res);
        });
        return arr;
      });
    }
    setGraduationsCount((prev) => prev - 1);
    if (graduationsCount == 1) {
      onPressAddMore();
    }
  };

  const validateEducationData = (data: Partial<EducationDetailsBody>) => {
    const { aboutEducation, educations } = data;

    let errorMessage = '';
    if (!aboutEducation || !aboutEducation.length)
      errorMessage = 'About Education should not be empty.';
    else if (aboutEducation.length < 10)
      errorMessage = 'About Education length must be at least 10 characters long';

    educations.forEach((item) => {
      if (!item.college || !item.college.length) errorMessage = 'Name should not be empty.';
      else if (item.college.length < 2)
        errorMessage = 'Name length must be at least 2 characters long.';
      else if (!item.qualification || !item.qualification.length)
        errorMessage = 'Degree should not be empty.';
      else if (item.qualification.length < 2)
        errorMessage = 'Degree length must be at least 2 characters long.';
      else if (!item.yearCompleted || !item.yearStarted) errorMessage = 'Please enter valid Years';
      else if (item.yearCompleted < item.yearStarted)
        errorMessage = 'Year From should not be less than Year To.';
    });

    if (errorMessage.length > 0) {
      dispatch(showError({ alertType: AlertTypeEnum.Error, message: errorMessage }));
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    try {
      setIsValidating(true);

      const collageAndSchool: EducationRecord[] = [];
      if (graduations?.length) {
        graduations.map((res) => {
          let qualificationId: number;
          let category = '';
          QualificationData.map((qualification) => {
            if (res.qualification === qualification.name) {
              qualificationId = qualification.id;
              category = qualification.category;
            }
          });
          collageAndSchool.push({
            college: res.college,
            qualification: res.qualification,
            qualificationId: qualificationId,
            specialization: category,
            yearStarted: parseInt(res.yearStarted),
            yearCompleted: parseInt(res.yearCompleted),
          });
        });
      }
      // Adding school details
      if (!collageAndSchool.find((res) => res.qualification === 'High School')) {
        collageAndSchool.push({
          college: schoolName,
          qualification: 'High School',
          yearStarted: parseInt(schoolYearStarted),
          yearCompleted: parseInt(schoolPassingYear),
          qualificationId: 50,
        });
        // Editting school details
      } else {
        collageAndSchool.map((res) => {
          if (res.qualification === 'High School') {
            res.college = schoolName;
            res.yearStarted = parseInt(schoolYearStarted);
            res.yearCompleted = parseInt(schoolPassingYear);
          }
        });
      }
      const data: EducationDetailsBody = {
        aboutEducation: aboutEducation,
        highestQualificationId: 1,
        educationMedium: educationMedium,
        educationLevel: educationLevel,
        schoolBoard: schoolBoard,
        educations: collageAndSchool,
      };
      if (!validateEducationData(data)) return setIsValidating(false);
      const updateEducationDetailsResponse = await ProfileUpdateService.updateEducation(data);
      if (updateEducationDetailsResponse.httpCode === 200) {
        //update redux
        const updatedUserDetailsResponse = await ProfileService.getLoggedInUserDetails();
        dispatch(setUserProfile(updatedUserDetailsResponse?.data?.profile));
        dispatch(showError({ alertType: AlertTypeEnum.Success, message: 'Saved' }));
      }
      setIsValidating(false);
    } catch (error) {
      console.log(error);
      setIsValidating(false);
      dispatch(
        showError({ alertType: AlertTypeEnum.Error, message: GenUtil.getMessageFromError(error) }),
      );
    }
  };

  function renderGraduationSection() {
    for (let i = 0; i < graduationsCount; i++) {
      components.push(
        <View key={i}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View>{i === 0 && <Text style={styles.tittle}>Graduation</Text>}</View>
          </View>
          <View style={{ marginBottom: 8 }}>
            <Text style={styles.infoLabel}>College Name</Text>
            <InputAdornment
              placeholder="Type here..."
              textStyle={{ color: Color.lightBlack, fontFamily: EFonts.SORA_REGULAR }}
              value={graduations?.[i]?.college}
              icon={renderImage()}
              setValue={setCurrentlySelectedCollege}
              helperValue={i}
              setHelperValue={setIndex}
            />
          </View>

          <View style={styles.row}>
            <View style={{ width: width * 0.45 }}>
              <Text style={styles.infoLabel}>Year Started</Text>
              <ModalAdornment
                placeHolder="Select"
                modalItems={years.slice(
                  years.indexOf(profileEducationDetails?.dob?.split('-')[0] ?? '1970'),
                  years.length,
                )}
                value={graduations?.[i]?.yearStarted}
                setValue={setCurrentlySelectedYearStarted}
                helperValue={i}
                setHelperValue={setIndex}
                modalHeading={'Year Started'}
              />
            </View>
            <View style={{ width: width * 0.45 }}>
              <Text style={styles.infoLabel}>Passing Year</Text>
              <ModalAdornment
                disabled={!graduations?.[i]?.yearStarted}
                placeHolder="Select"
                modalItems={years.slice(years.indexOf(graduations?.[i]?.yearStarted), years.length)}
                value={graduations?.[i]?.yearCompleted}
                setValue={setCurrentlySelectedYearCompleted}
                helperValue={i}
                setHelperValue={setIndex}
                styleOverride={!graduations?.[i]?.yearStarted}
                modalHeading={'Passing Year'}
              />
            </View>
          </View>
          <View>
            <Text style={styles.infoLabel}>Degree</Text>
            <ModalAdornment
              modalItems={degree}
              placeHolder="Select your qualification"
              value={graduations?.[i]?.qualification}
              setValue={setCurrentlySelectedQualification}
              helperValue={i}
              setHelperValue={setIndex}
              modalHeading={'Degree'}
              maxValueLength={24}
            />
          </View>
          {graduations?.length != 0 && (
            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <TouchableOpacity
                style={{ alignItems: 'center', padding: 5 }}
                onPress={() => deleteGraduationHandler(i)}
              >
                <Icon name="delete-outline" color={Color.red} size={20} />
              </TouchableOpacity>
              {i === graduationsCount - 1 && (
                <TouchableOpacity
                  disabled={ifAnyGraduationFieldIsEmpty(i)}
                  onPress={onPressAddMore}
                  style={{ flexDirection: 'row', alignItems: 'center' }}
                >
                  <Icon
                    name="add"
                    color={ifAnyGraduationFieldIsEmpty(i) ? Color.gray : '#8041C6'}
                    size={16}
                  />
                  <Text
                    style={
                      ifAnyGraduationFieldIsEmpty(i)
                        ? styles.disabledAddMoreButton
                        : styles.addMoreButton
                    }
                  >
                    Add More
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          )}
          {i != graduationsCount - 1 && <View style={styles.divider}></View>}
        </View>,
      );
    }

    return components;
  }

  useEffect(() => {
    if (schoolPassingYear != '' && parseInt(schoolYearStarted) > parseInt(schoolPassingYear))
      setSchoolPassingYear('');
  }, [schoolYearStarted]);

  // Editting education history
  useEffect(() => {
    if (currentlySelectedCollege !== null && graduations?.[index]) {
      setGraduations((prev) => {
        prev[index].college = currentlySelectedCollege;
        return [...prev];
      });
    }
  }, [currentlySelectedCollege]);

  useEffect(() => {
    if (graduations?.[index] && currentlySelectedQualification) {
      setGraduations((prev) => {
        prev[index].qualification = currentlySelectedQualification;
        return [...prev];
      });
    }
  }, [currentlySelectedQualification]);

  useEffect(() => {
    if (graduations?.[index] && currentlySelectedYearStarted) {
      setGraduations((prev) => {
        prev[index].yearStarted = currentlySelectedYearStarted;
        if (
          graduations[index].yearCompleted != '' &&
          parseInt(currentlySelectedYearStarted) > parseInt(graduations[index].yearCompleted)
        )
          prev[index].yearCompleted = '';
        return [...prev];
      });
    }
  }, [currentlySelectedYearStarted]);

  useEffect(() => {
    if (graduations?.[index] && currentlySelectedYearCompleted) {
      setGraduations((prev) => {
        prev[index].yearCompleted = currentlySelectedYearCompleted;
        return [...prev];
      });
    }
  }, [currentlySelectedYearCompleted]);

  // Adding education history
  useEffect(() => {
    if (
      !graduations?.[index] &&
      (currentlySelectedCollege !== null ||
        currentlySelectedQualification ||
        currentlySelectedYearStarted ||
        currentlySelectedYearCompleted)
    ) {
      setGraduations((prev) => [
        ...prev,
        {
          college: currentlySelectedCollege,
          qualification: currentlySelectedQualification,
          yearStarted: currentlySelectedYearStarted,
          yearCompleted: currentlySelectedYearCompleted,
        },
      ]);
    }
  }, [
    currentlySelectedCollege,
    currentlySelectedYearStarted,
    currentlySelectedYearCompleted,
    currentlySelectedQualification,
  ]);

  return (
    <View style={styles.container}>
      <View style={{ marginBottom: 8 }}>
        <Text style={styles.infoLabel}>About Education</Text>

        <ModalAdornment
          isInputText={true}
          value={aboutEducation}
          inputTextFunction={setAboutEducation}
          placeHolder={aboutEduPlaceholder}
          modalHeading="Write about your education"
        />
      </View>

      <View style={{ marginBottom: 8 }}>
        <Text style={styles.infoLabel}>Education level</Text>
        <ModalAdornment
          placeHolder="Select your education level"
          modalItems={Object.values(EducationLevel)}
          icon={renderImage()}
          value={educationLevel}
          setValue={setEducatioonLevel}
          modalHeading={'Education level'}
          maxValueLength={24}
        />
      </View>

      <View style={{ marginBottom: 8 }}>
        <Text style={styles.infoLabel}>Education Medium</Text>
        <ModalAdornment
          placeHolder="Select your education medium"
          modalItems={Object.keys(EducationMedium)}
          icon={renderImage()}
          value={educationMedium}
          setValue={setEducatioonMedium}
          modalHeading={'Education Medium'}
          maxValueLength={24}
        />
      </View>

      <Text style={styles.tittle}>Add School Details</Text>
      <View style={{ marginBottom: 8 }}>
        <Text style={styles.infoLabel}>School Name</Text>
        <InputAdornment
          icon={renderImage()}
          textStyle={{ color: Color.lightBlack, fontFamily: EFonts.SORA_REGULAR }}
          value={schoolName}
          setValue={setSchoolName}
          placeholder="Type here..."
        />
      </View>

      <View style={styles.row}>
        <View style={{ width: width * 0.45 }}>
          <Text style={styles.infoLabel}>Year Started</Text>
          <ModalAdornment
            placeHolder="Select"
            modalItems={years.slice(
              years.indexOf(profileEducationDetails?.dob?.split('-')[0] ?? '1970'),
              years.length,
            )}
            value={schoolYearStarted}
            setValue={setSchoolYearStarted}
            modalHeading={'Year Started'}
          />
        </View>
        <View style={{ width: width * 0.45 }}>
          <Text style={styles.infoLabel}>Passing Year</Text>
          <ModalAdornment
            disabled={schoolYearStarted === ''}
            styleOverride={schoolYearStarted === ''}
            placeHolder="Select"
            modalItems={years.slice(years.indexOf(schoolYearStarted), years.length)}
            value={schoolPassingYear}
            setValue={setSchoolPassingYear}
            modalHeading={'Passing Year'}
            maxValueLength={24}
          />
        </View>
      </View>

      <View style={{ marginBottom: 8 }}>
        <Text style={styles.infoLabel}>Board</Text>
        <ModalAdornment
          modalItems={['ICSE', 'CBSE', 'State Board']}
          placeHolder="Select your school board..."
          value={schoolBoard}
          setValue={setSchoolBoard}
          modalHeading={'Board'}
        />
      </View>

      <View style={styles.divider}></View>

      {renderGraduationSection()}
      <View style={styles.saveButtonWrapper}>
        <SaveButton buttonText="Save" isValidating={isValidating} handleKeyPress={handleSave} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 5,
    marginHorizontal: 5,
  },
  header: {
    marginBottom: 10,
    fontFamily: EFonts.SORA_MEDIUM,
    fontSize: 17,
    lineHeight: 18,
    color: Color.lightBlack,
  },
  aboutTextStyle: {
    width: width * 0.9,
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    borderWidth: 1,
    borderColor: '#D6D6D6',
    lineHeight: 24,
    padding: 9,
    borderRadius: 8,
    marginTop: 4,
    marginBottom: 16,
  },
  infoLabel: {
    flex: 1,
    fontFamily: EFonts.SORA_REGULAR,
    fontStyle: 'normal',
    fontSize: GenUtil.isWEB() ? 12 : 10,
    lineHeight: 20,
    color: Color.lightGrey,
    alignItems: 'center',
    marginLeft: 12,
  },
  tittle: {
    color: '#2E2835',
    fontSize: 12,
    fontFamily: EFonts.SORA_SEMIBOLD,
    marginBottom: 10,
    marginLeft: 5,
  },
  row: {
    flexDirection: 'row',
    marginVertical: 5,
    justifyContent: 'space-between',
  },
  addMoreButton: {
    fontSize: 12,
    fontFamily: EFonts.SORA_MEDIUM,
    color: '#8041C6',
  },
  disabledAddMoreButton: {
    fontSize: 12,
    fontFamily: EFonts.SORA_MEDIUM,
    color: Color.gray,
  },
  divider: {
    marginVertical: 15,
    borderWidth: 1,
    borderStyle: 'dashed',
    width: '100%',
    borderColor: '#D6D6D6',
  },
  saveButtonWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});
