import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity } from 'react-native';
import { Icon } from 'react-native-elements';
import moment from 'moment';
import DateTimePickerModal from 'react-native-modal-datetime-picker';

import { GenUtil } from 'src/util/GenUtil';
import InputAdornment from 'src/components/common/input/InputAdornment';
import PrimaryButton from 'src/components/common/buttons/PrimaryButton';
import { Gender } from 'src/common/models/profile.model';
import ModalAdornment from '../editProfileComponents/atoms/ModalAdornment';
import { Color } from 'src/constant/Color';
import { DateField } from '../common/DateField';
import EFonts from 'src/constant/EFonts';

const { width } = GenUtil.getDimension();

const OnboardingNameAndDOBComp = ({
  relation = 'Self',
  gender = Gender.Male,
  next = () => {},
  prev = () => {},
  name = '',
  setName = () => {},
  formattedDate = '',
  setFormattedDate = (_) => {},
  infoDisabled = true,
  setInfoDisabled = (_) => {},
  setTempName = (_) => {},
  height,
  setHeight,
}) => {
  const [nameQuestion, setNameQuestion] = useState('Your Name');
  const [bdayQuestion, setBdayQuestion] = useState('When was you born?');
  const [heightQuestion, setHeightQuestion] = useState('What is your height?');
  const HeightData = [];
  for (let i = 140; i < 200; i++) {
    HeightData.push(i);
  }

  const handleDateChange = (date: Date) => {
    const d = moment(date.toISOString()).format('YYYY-MM-DD');
    setFormattedDate(d);
  };

  const generateQuestions = () => {
    if (relation === 'Self') {
      setNameQuestion('Your Name');
      setBdayQuestion('When were you born?');
      setHeightQuestion('What is your height?');
    } else if (relation === 'Brother' || relation === 'Nephew' || relation === 'Son') {
      setNameQuestion(`Your ${relation} Name`);
      setBdayQuestion('When was he born?');
      setHeightQuestion('What is his height?');
    } else if (relation === 'Sister' || relation === 'Niece' || relation === 'Daughter') {
      setNameQuestion(`Your ${relation} Name`);
      setBdayQuestion('When was she born?');
      setHeightQuestion('What is her height?');
    } else if (relation === 'Friend' || relation === 'Client' || relation === 'Relative') {
      setNameQuestion(`Your ${relation} Name`);
      setBdayQuestion(gender === Gender.Male ? 'When was he born?' : 'When was she born?');
      setHeightQuestion(`What is your ${relation} height?`);
    }
  };

  useEffect(() => {
    generateQuestions();
  }, []);

  useEffect(() => {
    if (!name.trim() || !formattedDate.trim()) {
      setInfoDisabled(true);
    } else {
      setInfoDisabled(false);
    }
  }, [name, formattedDate]);

  return (
    <View style={styles.container}>
      <ScrollView>
        <View style={styles.item}>
          <Text style={styles.questionText}>{nameQuestion}</Text>
          <InputAdornment
            placeholder="Enter name"
            icon={<Icon name="user" type="antdesign" size={20} />}
            endIcon={<Icon name="settings-outline" type="ionicon" size={20} />}
            value={name}
            setValue={setName}
            styleOverride
          />
        </View>
        <View style={styles.item}>
          <Text style={styles.questionText}>{bdayQuestion}</Text>
          <DateField
            placeholder="Birth Date"
            prevIcon={<Icon name="calendar" type="antdesign" size={20} />}
            nextIcon={<Icon name="settings-outline" type="ionicon" size={20} />}
            date={formattedDate}
            mode="date"
            onChange={handleDateChange}
            constainerStyle={styles.dateFieldStyle}
            textStyle={styles.textStyle}
            iconStyle={styles.dateFieldPrevIcon}
            iconNextStyle={styles.dateFieldNextIcon}
          />
        </View>
        <View style={styles.item}>
          <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
            <Text style={styles.questionText}>{heightQuestion}</Text>
            <Text style={styles.questionHint}>( in cm )</Text>
          </View>
          <Text style={styles.helperText}>
            Don't know your height now? Don't worry, you can change it later.
          </Text>
          <ModalAdornment
            modalItems={HeightData}
            value={height}
            setValue={setHeight}
            placeHolder="Select"
            selectedValues={[height]}
          />
        </View>
      </ScrollView>
      <View style={styles.buttonWrapper}>
        <PrimaryButton
          buttonDisabled={infoDisabled}
          isValidating={false}
          buttonText={'Next'}
          handleKeyPress={() => {
            setTempName(name);
            next();
          }}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: width * 0.05,
    paddingTop: 20,
    backgroundColor: 'transparent',
  },
  questionText: {
    fontSize: 28,
    fontFamily: 'sora-light',
    marginVertical: 10,
  },
  questionHint: {
    fontSize: 15,
    color: Color.lightGrey,
    fontFamily: 'sora-light',
    marginLeft: 10,
  },
  helperText: {
    fontSize: 12,
    color: Color.lightGrey,
    fontFamily: 'sora-light',
    marginBottom: 10,
  },
  item: {
    marginBottom: 25,
  },
  buttonWrapper: {
    position: 'absolute',
    bottom: 35,
    left: width * 0.1,
  },
  dateFieldStyle: {
    padding: GenUtil.isWEB() ? 9 : 10,
    backgroundColor: 'rgb(250, 250, 250);',
    borderWidth: 0,
    paddingHorizontal: 10,
  },
  textStyle: {
    backgroundColor: 'rgb(250, 250, 250);',
    fontSize: 18,
    fontFamily: EFonts.DMSANS_BOLD,
    color: '#b8bcd2',
  },
  dateFieldPrevIcon: {
    paddingLeft: 6,
  },
  dateFieldNextIcon: {
    paddingRight: 4,
  },
});

export default OnboardingNameAndDOBComp;
