import { FlatList, Image, SafeAreaView, Text, View } from 'react-native';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import ProfileListAndChatHeader from 'src/components/common/ProfileListAndChatHeader';
import { Color } from 'src/constant/Color';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { ProfileService } from 'src/service/ProfileService';
import EFonts from 'src/constant/EFonts';
import { ProfileSearchBody } from 'src/common/dto/profile.dto';
import { ProfileResp } from 'src/common/response/profile.resp';
import LargeProfileCard from 'src/components/common/profileCard/LargeProfileCard';
import { AlertTypeEnum, showError } from 'src/store/reducer/errorReducer';
import { GenUtil } from 'src/util/GenUtil';
import ProfileNotFound from 'src/components/errors/ProfileNotFound';
import { FullLoader } from 'src/components/loader/FullLoader';
import { ActivityIndicator } from 'react-native-paper';

const titles = ['Similar Profiles', 'Near you', 'Recently joined'];
const propertiesToConvertInArray = [
  'maritalStatuses',
  'professionIds',
  'qualificationIds',
  'religions',
  'statesAndCities',
];

const ListSearchedProfiles = ({ route }: { route: { params: Partial<ProfileSearchBody> } }) => {
  const isFocused = useIsFocused();
  const searchParams = convertStringToArray(route?.params);
  const [currentSearchParams, setCurrentSearchParams] =
    useState<Partial<ProfileSearchBody>>(searchParams);
  const [bestMatchingProfiles, setBestMatchingProfiles] = useState<ProfileResp[]>([]);
  const [currentVerticalPage, setCurrentVerticalPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const refresh = useSelector((state: RootState) => state.ui.refresh);
  const dispatch = useDispatch();

  const { width, height } = GenUtil.getDimension();

  function convertStringToArray(searchParams: Partial<ProfileSearchBody>) {
    if (!searchParams) return searchParams;
    for (const property of propertiesToConvertInArray) {
      if (property in searchParams && searchParams[property] !== '') {
        if (!Array.isArray(searchParams[property])) {
          searchParams[property] = searchParams[property].split(',');
        }
      } else if (property in searchParams) {
        searchParams[property] = [];
      }
    }
    return searchParams;
  }

  const getBestMatchingProfiles = async (page: number) => {
    const response = await ProfileService.getProfiles(currentSearchParams, page);
    return response.data;
  };

  useEffect(() => {
    getBestMatchingProfiles(0)
      .then((resp) => {
        setBestMatchingProfiles(resp);
        setCurrentVerticalPage(1);
        setLoading(false)
      })
      .catch((error) => {
        dispatch(
          showError({
            alertType: AlertTypeEnum.Error,
            message: GenUtil.getMessageFromError(error),
          }),
        )
        setLoading(false)
      }
    );
  }, [currentSearchParams, refresh]);

  useEffect(() => {
    if (isFocused) {
      setCurrentSearchParams(searchParams);
    }
  }, [isFocused]);

  const [selectedTitle, setSelectedTitle] = useState(titles[0]);
  const navigation = useNavigation();

  const handleTitlePress = (title) => {
    setSelectedTitle(title);
  };

  const onVerticalEndReach = () => {
    if (bestMatchingProfiles.length < 10) return;
    const page = currentVerticalPage;
    getBestMatchingProfiles(page)
      .then((resp) => {
        setBestMatchingProfiles([...bestMatchingProfiles, ...resp]);
        setCurrentVerticalPage(page + 1);
      })
      .catch((error) => console.log('Error in fetching more vertical profiles', error));
  };

  const handleRightIconPressed = () => {
    navigation.navigate('searchfiltersscreen', { currentSearchParams, setCurrentSearchParams });
  };

  return (
    <SafeAreaView style={{ backgroundColor: Color.white, flex: 1 }}>
      <ProfileListAndChatHeader
        heading={`${bestMatchingProfiles.length} Results Found`}
        rightIcon={<Image source={require('src/assets/icons/filterIcon.png')} />}
        onRightIconPressed={handleRightIconPressed}
      />
        {loading ? (
          <View style={{
            height: height / 1.5,
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <ActivityIndicator />
          <FullLoader message={''} />
        </View>
      ) : (
        bestMatchingProfiles.length ? (
          <FlatList
            data={[1]}
            keyExtractor={(item) => item.toString()}
            renderItem={() => (
              <View style={{ flex: 1 }}>
                <View style={{ marginTop: 30, marginBottom: 20, paddingLeft: 15 }}>
                  <Text
                    style={{
                      fontFamily: EFonts.SORA_SEMIBOLD,
                      fontSize: 15,
                      lineHeight: 19,
                      color: Color.lightBlack,
                    }}
                  >
                    Best Matches For You
                  </Text>
                </View>
                <View style={{ flex: 1 }}>
                  {bestMatchingProfiles.map((item, index) => {
                    return (
                      <View style={{ marginBottom: 16 }} key={index}>
                        <LargeProfileCard
                          item={item}
                          containerStyles={{ marginRight: 0 }}
                          isSearchedCard={true}
                        />
                      </View>
                    );
                  })}
                </View>
              </View>
            )}
            showsVerticalScrollIndicator={false}
            onEndReached={onVerticalEndReach}
          />
        ) : ( 
          <ProfileNotFound
            buttonVisibility={true}
            onButtonPress={() => navigation.navigate('search')}
          /> 
        )
      )}
    </SafeAreaView>
  );
};

export default ListSearchedProfiles;
