import { View, Text, StyleSheet, Image } from 'react-native';
import React from 'react';
import { Color } from 'src/constant/Color';
import EFonts from 'src/constant/EFonts';
import PhotoCollage from './PhotoCollage';
import { GenUtil } from 'src/util/GenUtil';

const InterestAcceptedCard = ({
  isSender,
  senderName,
  gender,
  otherUserGender,
  otherUserProfilePhoto,
  profilePhoto,
  msgTime,
}: {
  isSender: boolean;
  senderName: string;
  gender: string;
  otherUserGender: string;
  otherUserProfilePhoto: string;
  profilePhoto: string;
  msgTime: number | Date;
}) => {
  const message = isSender
    ? `You have accepted ${senderName}'s interest`
    : `${senderName} has accepted your interest.`;
  return (
    <View style={isSender ? styles.containerStyleForSender : styles.containerStyleForReceiver}>
      <View style={isSender ? styles.headerStyleForSender : styles.headerStyleForReceiver}>
        <View style={{ height: 68, width: 136 }}>
          <PhotoCollage
            size={68}
            smallCircleSize={42}
            icon={
              <Image
                style={{ height: 15, width: 15 }}
                source={require('src/assets/icons/tick.png')}
              />
            }
            profilePhoto={profilePhoto}
            gender={gender}
            otherUserGender={otherUserGender}
            otherUserProfilePhoto={otherUserProfilePhoto}
          />
        </View>
        <View
          style={{
            marginTop: 6,
            marginBottom: 8,
          }}
        >
          <Text style={styles.cardTitle}>Interest Accepted</Text>
        </View>
      </View>

      <View style={{ marginTop: 8, paddingHorizontal: isSender ? 0 : 8 }}>
        <Text
          style={{
            fontFamily: EFonts.SORA_REGULAR,
            fontSize: 12,
            lineHeight: 20,
            color: isSender ? Color.white : Color.lightBlack,
          }}
        >
          {message}
        </Text>
      </View>
      <View style={{ flex: 1 }}>
        <Text
          style={{
            color: isSender ? 'rgba(255, 255, 255, 0.8)' : '#595959',
            fontSize: 10,
            fontFamily: EFonts.SORA_REGULAR,
            textAlign: isSender ? 'right' : 'left',
            paddingHorizontal: 5,
          }}
        >
          {GenUtil?.getTimeFromTimeStamp(msgTime)}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  containerStyleForSender: {
    width: 230,
    backgroundColor: '#811EFF',
    borderRadius: 12,
    borderBottomRightRadius: 0,
    paddingHorizontal: 8,
    paddingBottom: 8,
  },
  containerStyleForReceiver: {
    width: 230,
    backgroundColor: '#F6F6F6',
    borderRadius: 12,
    borderBottomLeftRadius: 0,
    paddingBottom: 8,
  },
  headerStyleForSender: {
    borderBottomWidth: 1,
    borderColor: Color.white,
    paddingVertical: 8,
    alignItems: 'center',
  },
  headerStyleForReceiver: {
    backgroundColor: Color.purple,
    borderRadius: 12,
    paddingVertical: 8,
    alignItems: 'center',
  },
  cardTitle: {
    fontFamily: EFonts.SORA_SEMIBOLD,
    fontSize: 15,
    lineHeight: 20,
    color: Color.white,
  },
});

export default InterestAcceptedCard;
